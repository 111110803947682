import React, {ReactNode, useState} from "react";
import {MDBBtn, MDBCollapse} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiChevronDown, mdiChevronUp} from "@mdi/js";

export interface TextCollapseProps {
    target: ReactNode;

    children?: ReactNode;
}

export const TextCollapse: React.FC<TextCollapseProps> = (props) => {
    const {target} = props;

    const [open, setOpen] = useState(false);

    const onTargetClick = (event: React.MouseEvent) => {
        event.preventDefault();

        setOpen(!open);
    };

    return (
        <>
            <MDBBtn type="button" color="link" className="p-0 w-100" onClick={onTargetClick}>
                <div className="d-flex align-items-center">
                    <div className="flex-fill">
                        {target}
                    </div>
                    <Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} className="ms-3" />
                </div>
            </MDBBtn>

            <MDBCollapse open={open}>
                {props.children}
            </MDBCollapse>
        </>
    );
};
