import React, {useEffect, useState} from "react";
import {motion, useAnimationControls} from "framer-motion";

const calculateStrokeDasharray = (progress: number) => {
    return `${45 * 2 * Math.PI * progress / 100} ${45 * 2 * Math.PI}`;
};

export enum HeaderSyncStatusEnum {
    IN_PROGRESS = "IN_PROGRESS",
    PROBLEM = "PROBLEM",
    DONE = "DONE"
}

export interface HeaderSyncStatusIconProps {
    status: HeaderSyncStatusEnum;
}

export const HeaderSyncStatusIcon: React.FC<HeaderSyncStatusIconProps> = (props) => {
    const {status} = props;

    const [currentStatus, setCurrentStatus] = useState<HeaderSyncStatusEnum | undefined>(undefined);

    const problemControls = useAnimationControls();
    const doneControls = useAnimationControls();
    const uploadArrowControls = useAnimationControls();
    const circleControls = useAnimationControls();

    useEffect(() => {
        const newStatus = status;

        if (newStatus === HeaderSyncStatusEnum.IN_PROGRESS && currentStatus !== HeaderSyncStatusEnum.IN_PROGRESS) {
            // Start progress
            doneControls.start({opacity: 0});
            problemControls.start({opacity: 0});

            uploadArrowControls.start({opacity: 1, y: [0, -30]});
            circleControls.start({
                opacity: 1,
                rotate: [-90, -125],
                strokeDasharray: [calculateStrokeDasharray(0), calculateStrokeDasharray(20)]
            }, {repeat: 0, duration: 0.5, delay: 0.1});
            setTimeout(() => {
                circleControls.start({
                    rotate: [-125, 360 - 125]
                });
            }, 100 + 500);
        }

        if (currentStatus === HeaderSyncStatusEnum.IN_PROGRESS && newStatus !== HeaderSyncStatusEnum.IN_PROGRESS) {
            // Stop progress
            // circleControls.start({opacity: 0}, {repeat: 0});
            uploadArrowControls.start({opacity: 0}, {repeat: 0});
        }

        if (newStatus === HeaderSyncStatusEnum.PROBLEM) {
            // Make problem visible
            doneControls.start({opacity: 0});
            problemControls.start({opacity: 1});
            circleControls.start({opacity: 0}, {repeat: 0});
        }

        if (newStatus === HeaderSyncStatusEnum.DONE) {
            // Make 'done' visible
            problemControls.start({opacity: 0});
            doneControls.start({opacity: 1});

            if (currentStatus === HeaderSyncStatusEnum.IN_PROGRESS) {
                // Fill circle if complete
                circleControls.start({
                    strokeDasharray: calculateStrokeDasharray(100)
                }, {repeat: 0, duration: 1});
                circleControls.start({opacity: 0}, {repeat: 0, duration: 0.5, delay: 1});
            }
        }

        setCurrentStatus(newStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <div style={{position: "relative", width: "1.8rem", height: "1.8rem"}}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 24 24" style={{
                position: "absolute",
                width: "1.8rem",
                height: "1.8rem;",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            }}>
                <path style={{fill: status === HeaderSyncStatusEnum.PROBLEM ? "rgb(255, 165, 0)" : "green"}} d="M6.5 20q-2.28 0-3.89-1.57Q1 16.85 1 14.58q0-1.95 1.17-3.48q1.18-1.53 3.08-1.95q.63-2.3 2.5-3.72Q9.63 4 12 4q2.93 0 4.96 2.04Q19 8.07 19 11q1.73.2 2.86 1.5q1.14 1.28 1.14 3q0 1.88-1.31 3.19T18.5 20m-12-2h12q1.05 0 1.77-.73q.73-.72.73-1.77t-.73-1.77Q19.55 13 18.5 13H17v-2q0-2.07-1.46-3.54Q14.08 6 12 6Q9.93 6 8.46 7.46Q7 8.93 7 11h-.5q-1.45 0-2.47 1.03Q3 13.05 3 14.5q0 1.45 1.03 2.5q1.02 1 2.47 1m5.5-6Z"/>
            </svg>

            <div style={{
                position: "absolute",
                backgroundColor: "white",
                borderRadius: "50%",
                overflow: "hidden",
                width: 14,
                height: 14,
                right: -2,
                bottom: -1
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", width: "100%", height: "100%"}} preserveAspectRatio="xMidYMid meet"
                                          viewBox="0 0 24 24">
                    <motion.path fill="green" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83L9 20.42Z" animate={doneControls}/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", width: "100%", height: "100%"}} preserveAspectRatio="xMidYMid meet"
                                          viewBox="0 0 24 24">
                    <motion.path fill="orange" d="M10 3h4v11h-4V3m0 18v-4h4v4h-4Z" animate={problemControls}/>
                </svg>

                <div style={{transform: "translateY(-8px)"}}>
                    <motion.div key="arrow-1" animate={uploadArrowControls} initial={{y: -100}}
                                transition={{
                                    duration: 1.2,
                                    // ease: "easeInOut",
                                    // times: [0, 1],
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    repeatDelay: 0
                                }}
                                style={{position: "absolute"}}
                    >
                        <svg viewBox="0 0 24 24" role="presentation" style={{width: "100%", height: "100%"}}>
                            <motion.path
                                d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                                style={{fill: "#000"}}/>
                        </svg>
                    </motion.div>

                    <motion.div key="arrow-2"
                                animate={uploadArrowControls} initial={{y: -100}}
                                transition={{
                                    duration: 1.2,
                                    // ease: "easeInOut",
                                    // times: [0, 1],
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    repeatDelay: 0
                                }}
                                style={{position: "absolute", top: 30}}
                    >
                        <svg viewBox="0 0 24 24" role="presentation" style={{width: "100%", height: "100%"}}>
                            <motion.path
                                d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                                style={{fill: "#000"}}/>
                        </svg>
                    </motion.div>
                </div>

                <svg width={14} height={14} viewBox="0 0 100 100" style={{display: "block"}}>
                    <motion.circle transform="rotate(-90 50 50)" cx={50} cy={50} r={50 - 5} fill="none"
                                   stroke="#000" strokeWidth={10}
                                   strokeDasharray={`0 ${45 * 2 * Math.PI}`}
                                   animate={circleControls}
                                   transition={{duration: 1.2, repeat: Infinity, repeatType: "loop"}}
                    />
                </svg>
            </div>
        </div>
    );
};
