import React, {useMemo} from "react";
import {MDBBtn, MDBModalBody, MDBModalHeader} from "mdb-react-ui-kit";
import {CheckOutVanAdresRedenNietAfgewerktEnum} from "../../_generated/field-service-be-openapi";
import {Form, Formik} from "formik";
import {MDBFormikRadio} from "../../mdb-formik/MDBFormikRadio";
import {FrameModal} from "./FrameModal";
import * as Yup from "yup";
import {NietUitgevoerdWerk} from "../../workers/shared/snapshot/uitgevoerdWerkChecker";
import {NietUitgevoerdWerkList} from "../NietUitgevoerdWerkList";
import {DisableAutocompletePlaceholderInput} from "../DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../utilities/i18nUtils";
import {MDBFormikTextArea} from "../../mdb-formik/MDBFormikTextArea";

export interface AdresAfwerkenFormValues {
    reden?: CheckOutVanAdresRedenNietAfgewerktEnum;
    toelichting?: string;
}

export interface AdresAfwerkenModalProps {
    bezoekSessieId: string;
    nietUitgevoerdWerk: NietUitgevoerdWerk[];

    onConfirm?(reden: CheckOutVanAdresRedenNietAfgewerktEnum, toelichting?: string): void;

    onClose?(): void;
}

export const AdresBevestigenModal: React.FC<AdresAfwerkenModalProps> = (props) => {
    const {bezoekSessieId, nietUitgevoerdWerk, onConfirm, onClose} = props;

    const onSubmit = (values: AdresAfwerkenFormValues) => {
        onConfirm?.(values.reden!, values.toelichting);
        onClose?.();
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const validationSchema = useMemo(() => Yup.object({
        reden: Yup.string().required("Reden is verplicht"),
        toelichting: Yup.string()
            .when("reden", {
                is: CheckOutVanAdresRedenNietAfgewerktEnum.Andere,
                then: Yup.string().required("Toelichting is verplicht")
            })
    }), []);

    const {t} = useTranslation("serviceadres");

    const nietVoldoendeTijdDoorOpeningsurenLabel = t("AdresBevestigenModal.niet-voldoende-tijd-om-alle-opdrachten-af-te-werken-door-openingsuren-klant",
        "Niet voldoende tijd om alle opdrachten af te werken (openingsuren klant)");

    const nietVoldoendeTijdDoorEindeShiftLabel = t("AdresBevestigenModal.niet-voldoende-tijd-om-alle-opdrachten-af-te-werken-door-einde-shift",
        "Niet voldoende tijd om alle opdrachten af te werken (einde shift)");

    const planningsWijzigingLabel = t("AdresBevestigenModal.planningswijziging-vanuit-aqualex",
        "Planningswijziging vanuit AQUALEX");

    const andereTechnicusWerktVerderLabel = t("AdresBevestigenModal.andere-technicus-werkt-verder",
        "Andere technicus werkt verder");

    const andereLabel = t("AdresbevestigenModal.andere-reden", "Andere");

    const toelichtingLabel = t("AdresbevestigenModal.toelichting", "Toelichting");

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>Uitchecken van adres</MDBModalHeader>
            <MDBModalBody className="py-1">
                <Formik<AdresAfwerkenFormValues> initialValues={{}} onSubmit={onSubmit}
                                                 validationSchema={validationSchema}>
                    {({errors}) => (
                        <Form autoComplete="off">
                            <DisableAutocompletePlaceholderInput/>
                            <p>
                                {t("AdresbevestigenModal.er-zijn-nog-onafgewerkte-taken-op-dit-adres",
                                    "Er zijn nog onafgewerkte taken op dit adres. Waarom heb je deze taken niet kunnen afwerken?")}

                            </p>

                            <p>
                                <NietUitgevoerdWerkList bezoekSessieId={bezoekSessieId}
                                                        nietUitgevoerdWerk={nietUitgevoerdWerk} onClose={onClose}/>

                                <small className="text-muted ms-3">
                                    {t("AdresbevestigenModal.druk-op-een-van-de-taken-om-er-naar-te-navigeren",
                                        "Druk op één van de taken om ernaar te navigeren.")}
                                </small>
                            </p>

                            <div className="ms-2">
                                <MDBFormikRadio name="reden"
                                                id={CheckOutVanAdresRedenNietAfgewerktEnum.NietVoldoendeTijdDoorOpeningsuren}
                                                value={CheckOutVanAdresRedenNietAfgewerktEnum.NietVoldoendeTijdDoorOpeningsuren}
                                                label={nietVoldoendeTijdDoorOpeningsurenLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={CheckOutVanAdresRedenNietAfgewerktEnum.NietVoldoendeTijdDoorEindeShift}
                                                value={CheckOutVanAdresRedenNietAfgewerktEnum.NietVoldoendeTijdDoorEindeShift}
                                                label={nietVoldoendeTijdDoorEindeShiftLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={CheckOutVanAdresRedenNietAfgewerktEnum.PlanningswijzigingVanuitAqualex}
                                                value={CheckOutVanAdresRedenNietAfgewerktEnum.PlanningswijzigingVanuitAqualex}
                                                label={planningsWijzigingLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={CheckOutVanAdresRedenNietAfgewerktEnum.AndereTechnicusWerktVerder}
                                                value={CheckOutVanAdresRedenNietAfgewerktEnum.AndereTechnicusWerktVerder}
                                                label={andereTechnicusWerktVerderLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={CheckOutVanAdresRedenNietAfgewerktEnum.Andere}
                                                value={CheckOutVanAdresRedenNietAfgewerktEnum.Andere}
                                                label={andereLabel}/>

                                {errors.reden && <div className="text-danger">{errors.reden}</div>}

                                <MDBFormikTextArea name="toelichting" label={toelichtingLabel} className="mt-2"/>
                            </div>

                            <div className="d-flex justify-content-end align-items-center my-3">
                                <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                                    {t("AdresBevestigenModal.annuleren", "Annuleren")}
                                </MDBBtn>
                                <MDBBtn color="success" size="lg" className="ms-2" type="submit">
                                    {t("AdresBevestigenModal.oke", "Oké")}
                                </MDBBtn>
                            </div>
                        </Form>
                    )}
                </Formik>
            </MDBModalBody>
        </FrameModal>
    );
};
