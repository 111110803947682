import React, {useState} from "react";
import {MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle} from "mdb-react-ui-kit";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {useServiceWorker} from "../redux/slices/status/hooks";
import {AppVersionBadge} from "../components/AppVersionBadge";
import {AppEnvironmentBadge} from "../components/AppEnvironmentBadge";
import {ContentContainer} from "../components/ContentContainer";
import {database} from "../utilities/db/database";
import {useTranslation} from "../utilities/i18nUtils";

export const OverDezeApplicatePage: React.FC = () => {

    const {t} = useTranslation("algemeen");

    const [versieCardClicks, setVersieCardClicks] = useState(0);

    const serviceWorker = useServiceWorker();

    const onFactoryResetClick = () => {
        database.delete();
        console.log("Factory reset: deleted database");
        window.location.reload();
    };

    return (
        <GenericPage>
            <Header title={t("OverDezeApplicatiePage.titel", "Over deze applicatie")} sticky/>

            <ContentContainer>

                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardTitle>
                            {t("OverDezeApplicatiePage.omgeving", "Omgeving")}
                        </MDBCardTitle>

                        <MDBCardText>
                            <AppEnvironmentBadge/>
                        </MDBCardText>

                    </MDBCardBody>
                </MDBCard>

                <div onClick={(e) => {setVersieCardClicks(versieCardClicks + 1)}}>
                    <MDBCard background="light" className="shadow-sm mb-4">
                        <MDBCardBody>
                            <MDBCardTitle>
                                {t("OverDezeApplicatiePage.versie", "Versie")}
                            </MDBCardTitle>

                            <MDBCardText>
                                <p>{t("OverDezeApplicatiePage.app", "App:")}{" "}<AppVersionBadge/></p>
                                <p>{t("OverDezeApplicatiePage.service-worker", "Service worker:")}{" "}
                                    {serviceWorker && serviceWorker.version && (<><MDBBadge
                                        color="info">SW {serviceWorker.version}</MDBBadge><br/></>)}
                                </p>

                            </MDBCardText>

                        </MDBCardBody>
                    </MDBCard>
                </div>

                {versieCardClicks >= 5 &&
                    <MDBCard background="light" className="shadow-sm mb-4">
                        <MDBCardBody>
                            <MDBCardTitle>
                                {t("OverDezeApplicatiePage.factory-reset.titel", "Factory reset")}
                            </MDBCardTitle>

                            <MDBCardText>
                                <p>
                                    {t("OverDezeApplicatiePage.factory-reset-description",
                                        "Met deze functie worden alle gegevens in de field service app gewist. Na het klikken van deze knop moet je de field service app / je tablet volledig opnieuw opstarten. Gebruik deze functie enkel als je hiervoor toestemming hebt.")}
                                </p>
                                <MDBBtn type="button" size="lg" onClick={onFactoryResetClick} color="danger">
                                    {t("OverDezeApplicatePage.factory-reset", "Factory Reset")}
                                </MDBBtn>
                            </MDBCardText>

                        </MDBCardBody>
                    </MDBCard>
                }

            </ContentContainer>
        </GenericPage>
    );
};
