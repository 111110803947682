import {useEffect, useMemo, useRef} from "react";
import {motionValue, MotionValue} from "framer-motion";

/**
 * https://github.com/framer/motion/issues/182
 *
 * const { scrollYProgress } = useViewportScrollForElement(scrollRef.current || document.getElementById('scroll-container'));
 */
export const useViewportScrollForElement = (element: HTMLElement) => {
    const viewportMotionValues = useMemo(() => ({
        scrollX: motionValue(0),
        scrollY: motionValue(0),
        scrollXProgress: motionValue(0),
        scrollYProgress: motionValue(0)
    }), []);

    const setProgress = (offset: number, maxOffset: number, value: MotionValue) => {
        value.set(!maxOffset || !offset ? 0 : offset / maxOffset);
    };

    const hasEventListenerRef = useRef(false);

    useEffect(() => {
        if (!element) {
            return;
        }

        const updateScrollValues = () => {
            window.requestAnimationFrame(() => {
                const xOffset = element.scrollLeft;
                const yOffset = element.scrollTop;
                // Set absolute positions
                viewportMotionValues.scrollX.set(xOffset);
                viewportMotionValues.scrollY.set(yOffset);

                // Set 0-1 progress
                setProgress(
                    xOffset,
                    element.clientWidth - element.clientHeight,
                    viewportMotionValues.scrollXProgress
                );
                setProgress(
                    yOffset,
                    element.scrollHeight - element.clientHeight,
                    viewportMotionValues.scrollYProgress
                );
            });
        };

        if (!hasEventListenerRef.current) {
            hasEventListenerRef.current = true;
            if (typeof window === "undefined") return;
            updateScrollValues();
            window.addEventListener("resize", updateScrollValues);
            window.addEventListener("scroll", updateScrollValues, true);
        }

        return () => {
            hasEventListenerRef.current = false;
            window.removeEventListener("resize", updateScrollValues);
            window.removeEventListener("scroll", updateScrollValues, true);
        };
    }, [element, viewportMotionValues]);

    return viewportMotionValues;
}
