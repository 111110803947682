import React from "react";
import {BezoekSessieStatus, BezoekSessieToestelOnderhoud} from "../../../../workers/shared/snapshot/bezoekSessieState";
import {MDBCardDisabledOverlay} from "../../../../components/mdb/MDBCardDisabledOverlay";
import {NavLink} from "react-router-dom";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRipple, MDBRow} from "../../../../mdb-react-ui-kit/index";
import {MDBCardStatus, MDBCardStatusEnum} from "../../../../components/mdb/MDBCardStatus";
import Icon from "@mdi/react";
import {mdiChevronRight, mdiWrenchOutline} from "@mdi/js";
import {useTranslation} from "../../../../utilities/i18nUtils";

export interface ToestelOnderhoudCardProps {
    bezoekSessieStatus: BezoekSessieStatus;
    bezoekSessieId: string;
    toestelId: string;

    onderhoud: BezoekSessieToestelOnderhoud;

    toestelIngecheckt: boolean;
}

export const ToestelOnderhoudCard: React.FC<ToestelOnderhoudCardProps> = (props) => {
    const {bezoekSessieStatus, bezoekSessieId, toestelId, onderhoud, toestelIngecheckt} = props;

    const {t} = useTranslation("toestel");

    let onderhoudStatusColor: MDBCardStatusEnum | undefined = undefined;
    if (onderhoud?.uitgevoerd) {
        onderhoudStatusColor = MDBCardStatusEnum.DONE;
    } else if (onderhoud?.bezig) {
        onderhoudStatusColor = MDBCardStatusEnum.WARNING;
    }

    return (
        <MDBCard background="light" className="mt-3 mb-4 shadow-sm">
            {(!toestelIngecheckt && bezoekSessieStatus !== BezoekSessieStatus.GESLOTEN) &&
                <MDBCardDisabledOverlay text={t("gelieve-het-toestel-eerst-in-te-checken",
                    "Gelieve het toestel eerst in te checken")}/>}

            <NavLink
                to={`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}/opdracht/onderhoud`}>
                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    <MDBCardStatus status={onderhoudStatusColor}/>
                    <MDBCardHeader>
                                    <span className="d-flex align-items-center">
                                        <Icon path={mdiWrenchOutline} size={1} className="me-1"/>
                                        {t("algemeen:Titels.onderhoud", "Onderhoud")}
                                    </span>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol>
                                {!!onderhoud?.uitTeVoerenWerk?.stappen?.filtersVervangen?.length && (
                                    <MDBRow>
                                        <MDBCol sm="12" className="mb-2">
                                            <strong>
                                                {t("ToestelOnderhoudCard.filters-vervangen", "Filters vervangen")}
                                            </strong> {onderhoud?.uitTeVoerenWerk?.stappen?.filtersVervangen?.map(item => `${item.artikelOmschrijving} (${item.artikelNummer})`).join(", ")}
                                        </MDBCol>
                                    </MDBRow>
                                )}
                            </MDBCol>

                            <MDBCol size="auto" className="d-flex align-items-center justify-content-end">
                                <Icon path={mdiChevronRight} size={2}/>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBRipple>
            </NavLink>
        </MDBCard>
    );
};
