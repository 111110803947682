import React from "react";
import {MDBBtn, MDBSideNav, MDBSideNavItem, MDBSideNavMenu} from "mdb-react-ui-kit";
import {Assets} from "../assets/assets";
import Icon from "@mdi/react";
import {
    mdiAccountCircleOutline,
    mdiAccountClockOutline,
    mdiCalendarOutline,
    mdiCellphoneInformation,
    mdiFileDocumentMultipleOutline,
    mdiFolderAccountOutline,
    mdiWifiSync
} from "@mdi/js";
import {useHideSidenav, useShowSidenav, useSidenavOpen} from "../redux/slices/ui/hooks";
import {NavLink} from "react-router-dom";
import {useAuth} from "../redux/slices/auth/hooks";
import {useLogout} from "../redux/providers/authProvider";
import {AppVersionBadge} from "./AppVersionBadge";
import {useTranslation} from "../utilities/i18nUtils";
import {LanguageDropdown} from "./LanguageDropdown";

export const Sidenav: React.FC = () => {
    const isSideNavOpen = useSidenavOpen();
    const showSidenav = useShowSidenav();
    const hideSidenav = useHideSidenav();

    const auth = useAuth();
    const logout = useLogout();

    const setIsSideNavOpen = (open: boolean) => {
        if (open) {
            showSidenav();
        } else {
            hideSidenav();
        }
    };

    const onLinkClick = () => {
        hideSidenav();
    };

    const onLogoutClick = () => {
        logout();
    };

    const {t} = useTranslation("algemeen");

    return (
        <MDBSideNav open={isSideNavOpen} getOpenState={(open: boolean) => setIsSideNavOpen(open)} closeOnEsc right>
            <div className="d-flex flex-column flex-fill h-100">
                <MDBSideNavMenu>
                    <MDBSideNavItem className="text-center mb-4 py-3 px-4">
                        <img src={Assets.logoDark} className="img-fluid mb-2" alt="Logo"/>
                    </MDBSideNavItem>

                    <MDBSideNavItem>
                        <NavLink to="/" className="sidenav-link" exact onClick={onLinkClick}>
                            <Icon path={mdiCalendarOutline} size={1} className="me-3"/>
                            {t("Titels.planning", "Planning")}
                        </NavLink>
                        <NavLink to="/bezoeksessies" className="sidenav-link" onClick={onLinkClick}>
                            {/*<Icon path={mdiTooltipAccount} size={1}/>*/}
                            {/*<Icon path={mdiHomeAccount} size={1}/>*/}
                            <Icon path={mdiFolderAccountOutline} size={1} className="me-3"/>
                            {t("Titels.bezoeken", "Bezoeken")}
                        </NavLink>
                        <NavLink to="/shiften" className="sidenav-link" onClick={onLinkClick}>
                            <Icon path={mdiAccountClockOutline} size={1} className="me-3"/>
                            {t("Titels.shiften","Shiften")}
                        </NavLink>
                        <NavLink to="/documenten" className="sidenav-link" onClick={onLinkClick}>
                            <Icon path={mdiFileDocumentMultipleOutline} size={1} className="me-3"/>
                            {t("Titels.documenten","Documenten")}
                        </NavLink>
                        <NavLink to="/offlinebeheer" className="sidenav-link" onClick={onLinkClick}>
                            <Icon path={mdiWifiSync} size={1} className="me-3"/>
                            {t("Titels.offlinebeheer","Offlinebeheer")}
                        </NavLink>
                        <NavLink to="/over" className="sidenav-link" onClick={onLinkClick}>
                            <Icon path={mdiCellphoneInformation} size={1} className="me-3"/>
                            {t("Titels.over-deze-applicatie", "Over deze applicatie")}
                        </NavLink>
                    </MDBSideNavItem>
                </MDBSideNavMenu>

                <div className="flex-fill"/>

                <div className="text-center mb-4">
                    <div>
                        <Icon path={mdiAccountCircleOutline} size={1}/>
                    </div>

                    <div>
                        {t("Sidenav.welkom", "Welkom {{naam}}", {naam: auth?.user?.info.name})}
                    </div>

                    <MDBBtn type="button" color="link" onClick={onLogoutClick}>{t("algemeen:uitloggen", "Uitloggen")}</MDBBtn>
                </div>

                <div className="text-center mb-3 px-3">
                    <div className="mb-2">
                        <LanguageDropdown/>
                    </div>

                    <AppVersionBadge/>
                </div>
            </div>
        </MDBSideNav>
    );
};
