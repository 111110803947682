interface EnvHolder {
    _env_: Record<string, string>;
}

declare global {
    interface Window extends EnvHolder {}

    interface ServiceWorkerGlobalScope extends EnvHolder {}
}

declare const self: ServiceWorkerGlobalScope;

let scope: any;

if (typeof window !== "undefined") {
    scope = window;
} else {
    if (typeof self !== "undefined") {
        scope = self;
    } else {
        scope = global;
    }
}

if (!scope._env_) {
    scope._env_ = {};
}

export const globalScope = scope;
