import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback} from "react";
import {auth} from "./slice";
import {AuthStateUser} from "./types";

export const useSetUser = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: AuthStateUser) => dispatch(auth.actions.setUser(payload)), [dispatch]);
};

export const useAuth = () => {
    return useAppSelector((state) => state.auth)
};
