import React from "react";
import {Installatie} from "../../../_generated/field-service-be-openapi";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {Assets} from "../../../assets/assets";
import {TextCollapse} from "../../../components/TextCollapse";
import {ArtikelNrOmschrijving} from "../../../components/ArtikelNrOmschrijving";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface GeplandWerkInstallatieTaakProps {
    installatie: Installatie;
}

export const GeplandWerkInstallatieTaakCard: React.FC<GeplandWerkInstallatieTaakProps> = (props) => {
    const {
        installatie
    } = props;

    const {t} = useTranslation("serviceadres");

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={Assets.orderTypes.installatie}
                                 size={1}/> {t("planning:GeplandWerkInstallatieTaakCard.titel",
                "Installatie {{verkoopOrderNr}}",
                {verkoopOrderNr: installatie.oorsprong.verkoopOrderNr})}</MDBCardHeader>

            <MDBCardBody>
                <MDBRow tag="dl">
                    <MDBCol tag="dt" sm="2">
                        {t("planning:GeplandWerkInstallatieTaakCard.toestel", "Toestel")}
                    </MDBCol>
                    <MDBCol tag="dd" sm="4">
                        <strong>{installatie.teInstallerenArtikelNummer}</strong> {installatie.teInstallerenArtikelOmschrijving}
                    </MDBCol>
                </MDBRow>

                <MDBRow tag="dl">
                    <MDBCol tag="dt" sm="2">
                        {t("planning:GeplandWerkInstallatieTaakCard.inbegrepen-co2", "Inbegrepen CO₂")}
                    </MDBCol>
                    <MDBCol tag="dd" sm="4">
                        {installatie.co2Artikelen?.map(co2 => (
                            <div>{co2.aantalTeLeveren} x <strong>{co2.artikelNr}</strong> {co2.artikelOmschrijving}</div>
                        ))}
                        {!installatie.co2Artikelen?.length && (
                            <span>{t("planning:GeplandWerkInstallatieTaakCard.geen-CO2-artikelen-te-leveren",
                                "Geen CO₂-artikelen te leveren")}</span>
                        )}
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol className="mb-2">
                        <strong>{t("planning:GeplandWerkInstallatieCard.omschrijving", "Omschrijving")}</strong><br/>
                        {installatie?.techniekerInstructies ||
                            <span className="text-muted">{t("GeplandWerkInstallatieTaakCard.geen-techniekerinstructie", "Geen")}</span>}
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol size="12">
                        <TextCollapse target={<span
                            style={{fontSize: "1.2em"}}>{t("planning:GeplandWerkInstallatieCard.stuklijst", "Stuklijst")}</span>}>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th scope="col">{t("planning:GeplandWerkInstallatieCard.artikelnr-omschrijving", "Artikelnr. - Omschrijving")}</th>
                                        <th scope="col">{t("planning:GeplandWerkInstallatieCard.aantal", "Aantal")}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {!installatie?.teInstallerenComponenten?.length && (
                                        <tr>
                                            <td colSpan={3}>{t("planning:GeplandWerkInstallatieCard.geen-onderdelen", "Geen onderdelen")}</td>
                                        </tr>
                                    )}
                                    {installatie?.teInstallerenComponenten?.map(item => {
                                        return (
                                            <tr>
                                                <td className="align-middle">
                                                    <ArtikelNrOmschrijving nr={item.artikelNr} omschrijving={item.artikelOmschrijving}/>
                                                </td>
                                                <td className="align-middle">
                                                    {item.aantal}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </TextCollapse>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
