import {createSlice} from "@reduxjs/toolkit";
import {UiState} from "./types";
import {GenericCaseReducer} from "../../types";

export interface ModalCaseReducers {
    setHydrated: GenericCaseReducer<UiState, boolean>;

    showSidenav: GenericCaseReducer<UiState, undefined>;
    hideSidenav: GenericCaseReducer<UiState, undefined>;
    [k: string]: GenericCaseReducer<UiState, any>;
}

export const ui = createSlice<UiState, ModalCaseReducers>({
    name: "ui",
    initialState: {
        hydrated: false,
        sidenavOpen: false
    },
    reducers: {
        setHydrated(state, action) {
            state.hydrated = action.payload;
        },
        showSidenav(state) {
            state.sidenavOpen = true;
        },
        hideSidenav(state) {
            state.sidenavOpen = false;
        }
    }
});
