import {BezoekSessieEventMessage} from "../../../_generated/field-service-be-openapi";
import {ErrorRecord} from "../../../utilities/db/repository/errorRepository";

export enum NetworkStatus {
    ONLINE = "ONLINE",
    SLOW = "SLOW",
    OFFLINE = "OFFLINE"
}

export interface StatusServiceWorker {
    version: string;
    updateAvailable: boolean;
    newServiceWorker: ServiceWorker;
}

export interface StatusState {
    instanceId?: string;
    batteryPercentage?: number;

    serviceWorker?: StatusServiceWorker;

    browserNetworkStatus: NetworkStatus;
    serviceWorkerNetworkStatus: NetworkStatus;
    webSocketStatus: NetworkStatus;

    localEventsAmount: number;
    localFilesAmount: number;

    offlineSinceTimestamp?: string;
    planningLastSyncTimestamp?: string;
    planningLastVersieCheckTimestamp?: string;
    actieveBezoekSessieLastSyncTimestamp?: string;
    periodicSyncLastTimestamp?: string;

    cacheItems: {
        artikelen: number;
        dagPlanning: number;
        dagPlanningEntries: number;
        klanten: number;
        serviceAdressen: number;
        technici: number;
        toestellen: number;
        bestanden: number;
        localEvents: number;
        serverEvents: number;
        errors: number;
    };

    localEvents: BezoekSessieEventMessage[];
    errors: ErrorRecord[];

    syncingActieveBezoekSessies: boolean;
    syncingPlanning: boolean;
    syncingArtikelen: boolean;
    syncingArtikelAfbeeldingen: boolean;
    syncingEvents: boolean;
}
