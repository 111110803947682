import React from "react";

export interface LocationPinProps {
    className?: string;
}

export const LocationPin: React.FC<LocationPinProps> = (props) => {
    return (
        <div className={`location-pin-wrapper ${props.className || ""}`}>
            <div className="location-pin-ring"/>
            <div className="location-pin"/>
        </div>
    );
};
