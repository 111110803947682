import {resourceReducer} from "redux-resource";
import createActionCreators from "redux-resource-action-creators";

const reducer = resourceReducer("downloads");
const actions = createActionCreators("read", {
    resourceType: "downloads"
});

export const downloads = {
    reducer,
    actions
};
