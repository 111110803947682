import React from "react";

export interface MDBCardDisabledOverlayProps {
    text: string;
}

export const MDBCardDisabledOverlay: React.FC<MDBCardDisabledOverlayProps> = ({text}) => {
    return (
        <div className={`card-disabled-overlay`}><span>{text}</span></div>
    );
};
