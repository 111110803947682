import * as i18next from "react-i18next";
import {useCallback} from "react";

export interface UseTranslationOptions {
    keyPrefix?: string;
}

export const useTranslation = (ns?: string | string[], options?: UseTranslationOptions) => {
    const {t, i18n} = i18next.useTranslation(ns, {
        keyPrefix: options?.keyPrefix
    });

    const changeLanguage = useCallback((locale: string) => {
        i18n.changeLanguage(locale);
    }, [i18n]);

    return {
        t,
        language: i18n.language,
        changeLanguage
    };
};

export const installTranslationKey = (key: string, defaultMessage: string) => {
    return key;
};
