import React from "react";

export interface MonospacedLetterBoxProps {
    text: string;
}

export const MonospacedLetterBox: React.FC<MonospacedLetterBoxProps> = (props) => {
    const {text} = props;

    return (
        <span className="monospaced-letterbox text-nowrap">
            {Array.from(text).map((letter, index) => (
                <span key={index}>{letter}</span>
            ))}
        </span>
    );
};
