import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBLightbox,
    MDBLightboxItem,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {GenericPage} from "../../../components/GenericPage";
import {Header} from "../../../components/Header";
import {useParams} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiFileDocument} from "@mdi/js";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {ContentContainer} from "../../../components/ContentContainer";
import {InstallatieBijlageDownloadButton} from "../../../components/download/InstallatieBijlageDownloadButton";
import {ArtikelNrOmschrijving} from "../../../components/ArtikelNrOmschrijving";
import {Config} from "../../../utilities/config";
import {JaNee} from "../../../components/JaNee";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface InstallatieDossierPageParams {
    bezoekSessieId: string;
    installatieId: string;
}

export const InstallatieDossierPage: React.FC = () => {
    const {bezoekSessieId, installatieId} = useParams<InstallatieDossierPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const installatie = bezoekSessie?.installaties?.[installatieId];

    const {t} = useTranslation("serviceadres");

    if (!installatie) {
        return null;
    }

    return (
        <GenericPage>
            <Header title="Installatiedossier" sticky/>

            <ContentContainer>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader><Icon path={mdiFileDocument}
                                         size={1}/> {t("InstallatieDossierPage.dit-toestel", "Dit toestel")}</MDBCardHeader>

                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow tag="dl">
                                <MDBCol tag="dt" sm="2">
                                    {t("InstallatieDossierPage.toestel", "Toestel")}
                                </MDBCol>
                                <MDBCol tag="dd" sm="4">
                                    <ArtikelNrOmschrijving nr={installatie.uitTeVoerenWerk?.teInstallerenArtikelNummer}
                                                           omschrijving={installatie.uitTeVoerenWerk?.teInstallerenArtikelOmschrijving}/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow tag="dl">
                                <MDBCol tag="dt" sm="2">
                                    {t("InstallatieDossierPage.verkoper", "Verkoper")}
                                </MDBCol>
                                <MDBCol tag="dd" sm="4">
                                    {installatie.uitTeVoerenWerk?.verkoperNaam}
                                </MDBCol>
                            </MDBRow>

                            <dt>{t("InstallatieDossierPage.situatiefotos", "Situatiefoto's")}</dt>
                            <MDBLightbox>
                                <MDBRow className="mt-4">
                                    {installatie.uitTeVoerenWerk?.situatieFotos?.map((foto, index) =>
                                        <MDBCol key={index} xs="12" md="6" lg="3" className="mb-4 mb-lg-0">
                                            <div className="w-100 shadow-1-strong rounded mb-4" style={{
                                                height: 0,
                                                paddingTop: "100%",
                                                position: "relative",
                                                overflow: "hidden"
                                            }}>
                                                <MDBLightboxItem
                                                    fullscreenSrc={Config.BASE_URL + `/api/installatie/${installatieId}/situatiefoto/${foto.id}`}
                                                    src={Config.BASE_URL + `/api/installatie/${installatieId}/situatiefoto/${foto.id}`}
                                                    className="w-100 h-100" alt={foto.naam}
                                                    style={{
                                                        objectFit: "cover",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0
                                                    }}
                                                />
                                            </div>
                                        </MDBCol>
                                    )}
                                </MDBRow>
                            </MDBLightbox>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader><Icon path={mdiFileDocument}
                                         size={1}/> {t("InstallatieDossierPage.algemeen", "Algemeen")}</MDBCardHeader>

                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow tag="dl">
                                <MDBCol tag="dt" sm="4">
                                    {t("InstallatieDossierPage.er-zit-water-op-de-leiding", "Er zit water op de leiding")}
                                </MDBCol>
                                <MDBCol tag="dd" sm="8">
                                    <JaNee value={installatie.uitTeVoerenWerk?.waterOpDeLeiding}/>
                                </MDBCol>


                                <MDBCol tag="dt" sm="4">
                                    {t("InstallatieDossierPage.er-is-elektriciteit-aanwezig", "Er is elektriciteit aanwezig")}
                                </MDBCol>
                                <MDBCol tag="dd" sm="8">
                                    <JaNee value={installatie.uitTeVoerenWerk?.elektriciteitAanwezig}/>
                                </MDBCol>


                                <MDBCol tag="dt" sm="4">
                                    {t("InstallatieDossierPage.opmerkingen", "Opmerkingen")}
                                </MDBCol>
                                <MDBCol tag="dd" sm="8">
                                    {installatie.uitTeVoerenWerk?.algemeneOpmerking ||
                                        <span className="text-muted">{t("InstallatieDossierPage.geen-opmerkingen", "Geen")}</span>}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>

                        <dt>{t("InstallatieDossierPage.bijlagen", "Bijlagen")}</dt>
                        <MDBTable responsive>
                            <MDBTableHead>
                                <tr>
                                    <th>{t("InstallatieDossierPage.titel", "Titel")}</th>
                                    <th>{t("InstallatieDossierPage.downloaden", "Downloaden")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {!installatie.uitTeVoerenWerk?.bijlagen?.length && (
                                    <tr>
                                        <td colSpan={2}>{t("InstallatieDossierPage.geen-bijlagen-beschikbaar", "Geen bijlagen beschikbaar.")}</td>
                                    </tr>
                                )}

                                {installatie.uitTeVoerenWerk?.bijlagen?.map((bijlage, index) =>
                                    <tr key={index}>
                                        <td>
                                            {bijlage.naam}
                                        </td>
                                        <td>
                                            <InstallatieBijlageDownloadButton installatieId={installatieId}
                                                                              bijlageId={bijlage.id!}/>
                                        </td>
                                    </tr>
                                )}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </ContentContainer>
        </GenericPage>
    );
};
