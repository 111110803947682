import React from "react";
import {MDBCardStatus, MDBCardStatusEnum} from "../components/mdb/MDBCardStatus";
import {BezoekSessie, BezoekSessieStatus} from "../workers/shared/snapshot/bezoekSessieState";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBRow
} from "../mdb-react-ui-kit/index";
import {Adres} from "../components/Adres";
import Icon from "@mdi/react";
import {mdiCalendarRefresh, mdiCheck, mdiClose, mdiDotsVertical} from "@mdi/js";
import {Datum} from "../components/Datum";
import {BezoekSessieSluitenAankondigingPeriodeEnum} from "../_generated/field-service-be-openapi";
import {Tijdsduur} from "../components/Tijdsduur";
import {useKlanten} from "../redux/slices/klant/hooks";
import {useTechnici} from "../redux/slices/technicus/hooks";
import {FieldServiceModal} from "../components/FieldServiceModal";
import {
    useBezoekSessieById,
    useHerlaadBezoekUitTeVoerenWerk,
    useSluitBezoekSessie,
    useVoegTechnicusToe
} from "../redux/slices/bezoeksessie/hooks";
import {useHideModal, useShowModal} from "../redux/slices/modal/hooks";
import {useHistory} from "react-router-dom";
import {useTranslation} from "../utilities/i18nUtils";
import {Trans} from "react-i18next";
import {AdresAanwezigheidPill} from "../components/bezoek/AdresAanwezigheidPill";

export interface BezoeksessieCardProps {
    bezoekSessie: BezoekSessie;
}

export const BezoeksessieCard: React.FC<BezoeksessieCardProps> = (props) => {
    const {bezoekSessie} = props;

    const history = useHistory();

    const showModal = useShowModal();
    const hideModal = useHideModal();

    const klanten = useKlanten();
    const technici = useTechnici();

    const voegTechnicusToe = useVoegTechnicusToe();
    const sluitBezoekSessie = useSluitBezoekSessie();
    const herlaadBezoekUitTeVoerenWerk = useHerlaadBezoekUitTeVoerenWerk();

    useBezoekSessieById()

    const onOpenClick = (bezoekSessie: BezoekSessie) => {
        history.push(`/bezoek/${bezoekSessie.id}`);
    };

    const onSluitenClick = (bezoekSessie: BezoekSessie) => {
        showModal({
            type: FieldServiceModal.CONFIRM_MODAL,
            props: {
                title: t("BezoeksessieCard.bezoek-afsluiten-bevestigen", "Bezoek afsluiten bevestigen"),
                content: t("BezoeksessieCard.bent-u-zeker","Weet u zeker dat u dit bezoek wenst af te sluiten? Deze actie is definitief."),
                confirmColor: "danger",
                confirmLabel: t("BezoeksessieCard.ja-afsluiten", "Ja, afsluiten"),

                onConfirm: () => {
                    sluitBezoekSessie(bezoekSessie.id);
                },
                onClose: () => hideModal()
            }
        });
    };

    const onRefreshClick = (bezoekSessie: BezoekSessie) => {
        if (bezoekSessie) {
            herlaadBezoekUitTeVoerenWerk(bezoekSessie.id);
        }
    };

    const onTechnicusToevoegen = (bezoekSessie: BezoekSessie) => {
        showModal({
            type: FieldServiceModal.TECHNICUS_TOEVOEGEN_MODAL,
            props: {
                onClose() {
                    hideModal();
                },
                onConfirm(technicusId: string) {
                    voegTechnicusToe(bezoekSessie.id, technicusId);
                }
            }
        });
    };

    const {t} = useTranslation("bezoeken");

    return (
        <MDBCard background="light" className="shadow-sm mb-4" key={bezoekSessie.id}>
            <MDBCardStatus status={bezoekSessie.status === BezoekSessieStatus.GESLOTEN ? MDBCardStatusEnum.ERROR : MDBCardStatusEnum.DONE}/>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <div className="ps-0 position-relative">
                            <MDBCardTitle className="d-flex align-items-center">
                                <div>
                                    {bezoekSessie.serviceAdres?.klantId && klanten[bezoekSessie.serviceAdres.klantId]?.naam}{" "}

                                    <small
                                        className="text-muted font-weight-normal">{bezoekSessie.serviceAdres?.naam}</small>
                                </div>
                            </MDBCardTitle>
                            <MDBCardText>
                                <Adres adres={bezoekSessie.serviceAdres}/>
                            </MDBCardText>
                        </div>
                    </MDBCol>

                    <MDBCol size="auto" className="p-0">
                        <MDBDropdown>
                            <MDBDropdownToggle className="btn-link btn-light px-3 py-2 no-arrow"><Icon
                                path={mdiDotsVertical} size={1}/></MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem link childTag="div" onClick={() => onRefreshClick(bezoekSessie)}>
                                    <Icon path={mdiCalendarRefresh} size={1}/> {t("BezoeksessieCard.herladen", "Herladen")}
                                </MDBDropdownItem>
                                <MDBDropdownItem divider/>
                                <MDBDropdownItem link childTag="div" onClick={() => onSluitenClick(bezoekSessie)}>
                                    <Icon path={mdiClose} size={1}/> {t("algemeen:afsluiten", "Afsluiten")}
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>

            <MDBCardBody>
                <MDBRow>
                    <MDBCol size="6">
                        <p>
                            <Trans t={t} i18nKey={"BezoeksessieCard.bezoek-gestart-op"}>
                                Bezoek gestart op <strong><Datum datum={bezoekSessie.gestartOp}
                                                                 format="DD/MM/YYYY HH:mm"/></strong>
                            </Trans>
                        </p>
                    </MDBCol>
                    <MDBCol size="6">
                        <p>
                            <Trans t={t} i18nKey={"BezoeksessieCard.laatst-gewijzigd"}>
                                Laatst gewijzigd op <strong><Datum datum={bezoekSessie.laatstGewijzigd}
                                                                   format="DD/MM/YYYY HH:mm"/></strong>
                            </Trans>
                        </p>
                    </MDBCol>

                    <MDBCol size="12">
                        <p>
                            {Object.entries(bezoekSessie.adresAanwezigheid || {}).map(([technicusId, aanwezigheid]) => (
                                <span className="d-inline-flex align-items-center me-2" key={technicusId}>
                                            {technici[technicusId]?.naam}

                                    <AdresAanwezigheidPill aanwezigheid={aanwezigheid}></AdresAanwezigheidPill>

                                </span>
                            ))}
                        </p>
                    </MDBCol>

                    {bezoekSessie.status !== BezoekSessieStatus.GESLOTEN && bezoekSessie.sluitenAankondiging && (
                        <MDBCol size="12">
                            {bezoekSessie.sluitenAankondiging.periode === BezoekSessieSluitenAankondigingPeriodeEnum.VastTijdstip && (
                                <p>
                                      <Trans t={t} i18nKey={"BezoeksessieCard.wordt-automatisch-afgesloten"}>
                                        Wordt automatisch afgesloten om <Datum
                                        datum={bezoekSessie.sluitenAankondiging.tijdstip} format="DD/MM/YYYY HH:mm"/>
                                    </Trans>
                                </p>
                            )}
                            {bezoekSessie.sluitenAankondiging.periode === BezoekSessieSluitenAankondigingPeriodeEnum.PeriodeNaBepaaldTijdstip && (
                                <p>
                                    <Trans t={t} i18nKey={"BezoeksessieCard.wordt-automatisch-afgesloten-of-na-vertrek"}>
                                    Wordt automatisch afgesloten om <Datum
                                    datum={bezoekSessie.sluitenAankondiging.tijdstip} format="DD/MM/YYYY HH:mm"/> (of ten
                                    minste <Tijdsduur
                                    tijdsduur={bezoekSessie.sluitenAankondiging.periodeNaTijdstip!}
                                    eenheid="seconds" humanize/> nadat iedereen het serviceadres verlaten
                                    heeft)
                                    </Trans>
                                </p>
                            )}
                        </MDBCol>
                    )}
                    {bezoekSessie.status === BezoekSessieStatus.GESLOTEN && (
                        <MDBCol size="12">
                            <p>
                                <strong>{t("BezoeksessieCard.het-bezoek-is-afgesloten", "Het bezoek is afgesloten en kan niet meer aangepast worden.")}</strong>
                            </p>
                        </MDBCol>
                    )}

                    <MDBCol size="12" className="d-flex justify-content-end">
                        <small className="text-muted">
                            Id: {bezoekSessie.id.substring(0, 8)} | Events: {bezoekSessie.eventsSyncedAmount}/{bezoekSessie.eventsTotalAmount}
                        </small>
                        {bezoekSessie.eventsSyncedAmount === bezoekSessie.eventsTotalAmount && <Icon path={mdiCheck} color="green" size={0.8} className="ms-1"/>}
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>

            <MDBCardFooter>
                <MDBRow className="justify-content-between">
                    <MDBCol>
                        <MDBBtn type="button" color="link" block className="mb-2 text-nowrap" size="lg"
                                onClick={() => onOpenClick(bezoekSessie)}>
                            {t("algemeen:openen", "Openen")}
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol>
                        <MDBBtn type="button" color="link" block className="mb-2 btn-lg text-nowrap"
                                onClick={() => onTechnicusToevoegen(bezoekSessie)}>
                            {t("BezoeksessieCard.technicus-toevoegen", "Technicus toevoegen")}
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBCardFooter>
        </MDBCard>
    );
};
