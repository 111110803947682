import React from "react";
import {AdresBevestigenModal} from "./modals/AdresBevestigenModal";
import {useHideModal, useModalStack} from "../redux/slices/modal/hooks";
import {WisselStukModal} from "./modals/WisselStukModal";
import {ConfirmModal} from "./modals/ConfirmModal";
import {AdresBelemmerdModal} from "./modals/AdresBelemmerdModal";
import {ToestelInformatieAanpassenModal} from "./modals/ToestelInformatieAanpassenModal";
import {ReedsAanwezigOpServiceAdresModal} from "./modals/ReedsAanwezigOpServiceAdresModal";
import {TechnicusToevoegenModal} from "./modals/TechnicusToevoegenModal";
import {OnderwegModal} from "./modals/OnderwegModal";
import {StukToevoegenModal} from "./modals/StukToevoegenModal";
import {ErrorModal} from "./modals/ErrorModal";
import {Co2LeveringAfwijkendModal} from "./modals/Co2LeveringAfwijkendModal";
import {FieldServiceModal} from "./FieldServiceModal";
import {AccessoireLeveringAfwijkendModal} from "./modals/AccessoireLeveringAfwijkendModal";
import {ToestelStuklijstModal} from "./modals/ToestelStuklijstModal";
import {ToestelServiceLogModal} from "./modals/ToestelServiceLogModal";
import {ToestelBijlagenModal} from "./modals/ToestelBijlagenModal";

export const ModalMap: Record<FieldServiceModal, React.ComponentType<any>> = {
    ADRES_AFWERKEN_MODAL: AdresBevestigenModal,
    ADRES_BELEMMERD_MODAL: AdresBelemmerdModal,
    WISSEL_STUK_MODAL: WisselStukModal,
    STUK_TOEVOEGEN_MODAL: StukToevoegenModal,
    CONFIRM_MODAL: ConfirmModal,
    TOESTELINFORMATIE_AANPASSEN_MODAL: ToestelInformatieAanpassenModal,
    REEDS_AANWEZIG_OP_SERVICEADRES_MODAL: ReedsAanwezigOpServiceAdresModal,
    TECHNICUS_TOEVOEGEN_MODAL: TechnicusToevoegenModal,
    ONDERWEG_MODAL: OnderwegModal,
    CO2_LEVERING_AFWIJKEND_MODAL: Co2LeveringAfwijkendModal,
    ACCESSOIRE_LEVERING_AFWIJKEND_MODAL: AccessoireLeveringAfwijkendModal,

    TOESTEL_STUKLIJST_MODAL: ToestelStuklijstModal,
    TOESTEL_SERVICELOG_MODAL: ToestelServiceLogModal,
    TOESTEL_BIJLAGEN_MODAL: ToestelBijlagenModal,

    ERROR_MODAL: ErrorModal
};

export const ModalRoot: React.FC = () => {
    const stack = useModalStack();
    const hideModal = useHideModal();

    if (!stack || stack.length === 0) {
        return null;
    }

    return (
        <>
            {stack
                .map(({type, props}, index) => {
                    const SpecificModal: React.ComponentType<any> | undefined = ModalMap[type];

                    const onClose = () => {
                        props.onClose?.();

                        hideModal();
                    };

                    if (SpecificModal) {
                        return <SpecificModal key={type} {...props} onClose={onClose} style={{zIndex: 2000 + index * 2}} />
                    } else {
                        return null;
                    }
                })}
        </>
    );
};
