import {RpcProvider} from "worker-rpc";
import * as uuid from "uuid";

export interface MessageJson<TPayload = any, TType = string> {
    payload: TPayload | undefined | null;
    correlationId: string;
    type: TType;
}

export class Message<TPayload = any, TType = string> {
    public readonly payload: TPayload | undefined | null;
    public readonly correlationId: string;
    public readonly type: TType;

    constructor(readonly options: { payload?: TPayload | null; correlationId?: string; type: TType }) {
        this.payload = options.payload;
        this.correlationId = options.correlationId || uuid.v4();
        this.type = options.type;
    }

    public static fromJSON<TPayload = any, TType = string>(json: MessageJson<TPayload, TType>) {
        return new Message<TPayload, TType>({
            type: json.type,
            correlationId: json.correlationId,
            payload: json.payload
        });
    }

    public toJSON(): MessageJson<TPayload, TType> {
        return {
            payload: this.payload,
            correlationId: this.correlationId,
            type: this.type
        };
    }

}

export class SimpleRpcChannel<TPayload = any, TType = string> {

    private readonly channel: RpcProvider;

    constructor(rpcProvider: RpcProvider) {
        this.channel = rpcProvider;

        this.publish = this.publish.bind(this);
        this.sendRpc = this.sendRpc.bind(this);
        this.registerRpcHandler = this.registerRpcHandler.bind(this);
        this.subscribe = this.subscribe.bind(this);
    }

    /**
     * Send a message and wait for the answer.
     * @param message
     * @param transfer
     */
    public sendRpc(message: Message<TPayload, TType>, transfer?: Transferable[]): Promise<any> {
        return this.channel.rpc(message.type as unknown as string, message.toJSON(), transfer);
    }

    /**
     * Register a handler for a type of message. Returns a response in the handler.
     * Only works with "sendRpc".
     * @param type
     * @param handler
     */
    public registerRpcHandler(type: TType, handler: (payload: Message<TPayload, TType>) => any) {
        this.channel.registerRpcHandler<MessageJson<TPayload, TType>, any>(type as unknown as string, (payload) => handler(Message.fromJSON(payload as MessageJson<TPayload, TType>)));
    }

    /**
     * Send a message without waiting for an answer. Fire and forget.
     * @param message
     * @param transfer
     */
    public publish(message: Message<TPayload, TType>, transfer?: Transferable[]) {
        this.channel.signal(message.type as unknown as string, message.toJSON(), transfer);
    }

    /**
     * Register a listener for a type of message.
     * Only works with "publish".
     * @param type
     * @param handler
     */
    public subscribe(type: TType, handler: (message: Message<TPayload, TType>) => any) {
        const innerHandler = (payload: any) => handler(Message.fromJSON(payload as MessageJson<TPayload, TType>));

        this.channel.registerSignalHandler<MessageJson<TPayload, TType>>(type as unknown as string, innerHandler);

        return innerHandler;
    }

    /**
     * Unregister a listener for a type of message.
     * Only works with "publish".
     * @param type
     * @param handler
     */
    public unsubscribe(type: TType, handler: (message: MessageJson<TPayload, TType>) => any) {
        this.channel.deregisterSignalHandler<MessageJson<TPayload, TType>>(type as unknown as string, handler);
    }

}
