import React from "react";
import Icon from "@mdi/react";
import {mdiStop} from "@mdi/js";
import {DagPlanningStopRestModel} from "../../../_generated/field-service-be-openapi";

export interface StopMarkerProps {
    entry: DagPlanningStopRestModel;

    onClick?: () => void;
}

export const StopMarker: React.FC<StopMarkerProps> = (props) => {
    const {onClick} = props;

    let color = "#4e86f5";

    return (
        <div style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            backgroundColor: color,
            border: "2px solid #fff",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }} onClick={onClick}>
            <Icon path={mdiStop} size={1} color="#fff"/>
        </div>
    );
};
