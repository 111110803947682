import {Logger} from "./logger";

export class TimeoutError {}

export class PromiseUtils {

    private static logger: Logger = Logger.create("PromiseUtils");

    /**
     * Voert de callback uit tot maxRetries is overschreden.
     *
     * @param callback
     * @param timeoutMs tijd die de callback Promise heeft om te resolven
     * @param maxRetries max aantal keer dat de callback opnieuw moet gedaan worden
     */
    public static async retryWithTimeout(callback: () => Promise<any>, timeoutMs: number, maxRetries: number) {
        const doCall = () => {
            const timeoutPromise = new Promise<TimeoutError>((_, reject) => setTimeout(() => reject(new TimeoutError()), timeoutMs));
            const callPromise = callback();

            return Promise.race([
                timeoutPromise,
                callPromise
            ]);
        };

        let retryAmount = 0;

        while (retryAmount < maxRetries) {
            try {
                this.logger.debug("Calling callback...", {timeoutMs, maxRetries, retryAmount});

                const result = await doCall();

                this.logger.debug("Callback was successful, returning...");

                return result;
            } catch (error) {
                if (retryAmount + 1 >= maxRetries) {
                    throw error;
                }

                this.logger.warn("An error occurred running the callback, retrying...");
            }

            retryAmount++;
        }
    }

}