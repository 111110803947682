import React, {useEffect} from "react";
import {useSetBatteryPercentage} from "../slices/status/hooks";

export const BatteryProvider: React.FC = (props) => {
    const setBatteryPercentage = useSetBatteryPercentage();

    useEffect(() => {
        if (!("getBattery" in navigator)) {
            return;
        }

        const onChange = function (this: any) {
            const level = this.level;

            setBatteryPercentage(level * 100);
        };

        async function run() {
            const battery = await (navigator as any).getBattery();

            setBatteryPercentage(battery.level * 100);

            battery.addEventListener("levelchange", onChange);

            // Other events:
            // chargingchange
            // chargingtimechange
            // dischargingtimechange

            return () => {
                battery.removeEventListener("levelchange", onChange);
            };
        }

        const result = run();

        return () => {
            result.then((off) => off());
        };
    }, [setBatteryPercentage]);

    return null;
};
