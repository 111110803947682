import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, put} from "redux-saga/effects";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {modal} from "../modal/slice";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";

export const statusSaga = createSliceSaga({
    name: "statusSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * logError(action) {
            const {error} = action.payload;

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createFrontendErrorMessage(error));

            yield put(modal.actions.showModal({
                type: FieldServiceModal.ERROR_MODAL,
                props: {
                    error
                }
            }));
        },
        * removeError(action) {
            const {id} = action.payload;

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createRemoveErrorMessage(id));
        },
        * clearErrors() {
            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createClearErrorsMessage());
        }
    }
});
