import React, {ChangeEvent, useMemo, useState} from "react";
import {MDBCol, MDBInput, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiQrcodeScan} from "@mdi/js";
import {ServiceAdresToestelCard} from "./Toestel/ServiceAdresToestelCard";
import {BezoekSessie, BezoekSessieToestel} from "../../workers/shared/snapshot/bezoekSessieState";
import {useTechnici} from "../../redux/slices/technicus/hooks";
import {NavLink} from "react-router-dom";
import {MDBCardStatusEnum} from "../../components/mdb/MDBCardStatus";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ServiceAdresTakenTabProps {
    bezoekSessie: BezoekSessie;
}

export const ServiceAdresToestellenTab: React.FC<ServiceAdresTakenTabProps> = (props) => {
    const {bezoekSessie} = props;

    const technici = useTechnici();

    const [serienummer, setSerienummer] = useState("");

    const toestellen = useMemo(() => {
        return Object.values(bezoekSessie.toestellen);
    }, [bezoekSessie.toestellen]);

    const gevondenToestellen = useMemo(() => {
        if (!serienummer) {
            return toestellen;
        }

        return toestellen
            .filter((toestel) => {
                return toestel.informatie?.serieNummer?.includes(serienummer);
            });
    }, [serienummer, toestellen]);

    const toestellenMetWerk = useMemo(() => {
        return gevondenToestellen.filter(item => !item.informatie?.uitDienst && item.heeftUitTeVoerenWerk);
    }, [gevondenToestellen]);

    const toestellenZonderWerk = useMemo(() => {
        return gevondenToestellen.filter(item => !item.informatie?.uitDienst && !item.heeftUitTeVoerenWerk);
    }, [gevondenToestellen]);

    const toestellenUitDienst = useMemo(() => {
        return gevondenToestellen.filter(item => item.informatie?.uitDienst && !item.heeftUitTeVoerenWerk);
    }, [gevondenToestellen]);

    const renderToestel = (toestel: BezoekSessieToestel) => {
        let cardStatusColor: MDBCardStatusEnum | undefined = undefined;
        if (toestel.uitgevoerd) {
            cardStatusColor = MDBCardStatusEnum.DONE;
        } else if (toestel.bezig && !toestel.uitgevoerd) {
            cardStatusColor = MDBCardStatusEnum.WARNING;
        }

        return (
            <ServiceAdresToestelCard key={toestel.informatie?.id}
                                     toestel={toestel.informatie}
                                     link={`/bezoek/taken/${bezoekSessie.id}/toestel/${toestel.informatie?.id}`}
                                     active={toestel.aanwezig}

                                     cardStatusColor={cardStatusColor}

                                     onderhoud={!!toestel.uitTeVoerenWerk?.onderhoud}
                                     onderhoudFiltersVervangen={toestel.uitTeVoerenWerk?.onderhoud?.stappen?.filtersVervangen || []}
                                     interventie={!!toestel.uitTeVoerenWerk?.interventies?.length}

                                     actieveTechnicus={toestel.laatstActieveTechnicusId ? technici[toestel.laatstActieveTechnicusId] : undefined}
            />
        );
    };

    const {t} = useTranslation("serviceadres");

    const zoekenOpSerieNummerLabel = t("ServiceAdresToestellenTab.zoeken-op-serienummer", "Zoeken op (serie)nummer");

    return (
        <>
            <MDBRow className="align-items-center d-flex pb-2">
                <MDBCol className="pe-2">
                    <MDBInput type="text" label={zoekenOpSerieNummerLabel} size="lg" value={serienummer}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => setSerienummer(event.target.value)}/>
                </MDBCol>

                <MDBCol size="auto">
                    <NavLink to="/zoek-toestel" className="btn btn-primary btn-link">
                        <Icon color="white" path={mdiQrcodeScan} size={1}/>
                    </NavLink>
                </MDBCol>
            </MDBRow>

            {toestellenMetWerk.map(renderToestel)}

            {!!toestellenZonderWerk?.length && (
                <>
                    <h4>{t("ServiceAdresToestellenTab.toestellen-zonder-gepland-werk",
                        "Toestellen zonder gepland werk")}</h4>

                    {toestellenZonderWerk.map(renderToestel)}
                </>
            )}

            {!!toestellenUitDienst?.length && (
                <>
                    <h4>{t("ServiceAdresToestellenTab.toestellen-uit-dienst",
                        "Toestellen uit dienst")}</h4>

                    {toestellenUitDienst.map(renderToestel)}
                </>
            )}
        </>
    );
};
