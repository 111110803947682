/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ServiceAdres,
} from '../models';

export interface DownloadServiceAdresBijlage1Request {
    serviceAdresId: string;
    bijlageId: string;
    download?: boolean;
}

export interface DownloadServiceRapportRequest {
    serviceAdresId: string;
    serviceRapportId: string;
}

export interface GetServiceAdresByIdRequest {
    serviceAdresId: string;
}

/**
 * 
 */
export class ServiceAdresApi extends runtime.BaseAPI {

    /**
     */
    async downloadServiceAdresBijlage1Raw(requestParameters: DownloadServiceAdresBijlage1Request): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.serviceAdresId === null || requestParameters.serviceAdresId === undefined) {
            throw new runtime.RequiredError('serviceAdresId','Required parameter requestParameters.serviceAdresId was null or undefined when calling downloadServiceAdresBijlage1.');
        }

        if (requestParameters.bijlageId === null || requestParameters.bijlageId === undefined) {
            throw new runtime.RequiredError('bijlageId','Required parameter requestParameters.bijlageId was null or undefined when calling downloadServiceAdresBijlage1.');
        }

        const queryParameters: any = {};

        if (requestParameters.download !== undefined) {
            queryParameters['download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/serviceadres/{serviceAdresId}/bijlage/{bijlageId}`.replace(`{${"serviceAdresId"}}`, encodeURIComponent(String(requestParameters.serviceAdresId))).replace(`{${"bijlageId"}}`, encodeURIComponent(String(requestParameters.bijlageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadServiceAdresBijlage1(requestParameters: DownloadServiceAdresBijlage1Request): Promise<Blob> {
        const response = await this.downloadServiceAdresBijlage1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async downloadServiceRapportRaw(requestParameters: DownloadServiceRapportRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.serviceAdresId === null || requestParameters.serviceAdresId === undefined) {
            throw new runtime.RequiredError('serviceAdresId','Required parameter requestParameters.serviceAdresId was null or undefined when calling downloadServiceRapport.');
        }

        if (requestParameters.serviceRapportId === null || requestParameters.serviceRapportId === undefined) {
            throw new runtime.RequiredError('serviceRapportId','Required parameter requestParameters.serviceRapportId was null or undefined when calling downloadServiceRapport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/serviceadres/{serviceAdresId}/servicerapport/{serviceRapportId}/pdf`.replace(`{${"serviceAdresId"}}`, encodeURIComponent(String(requestParameters.serviceAdresId))).replace(`{${"serviceRapportId"}}`, encodeURIComponent(String(requestParameters.serviceRapportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadServiceRapport(requestParameters: DownloadServiceRapportRequest): Promise<Blob> {
        const response = await this.downloadServiceRapportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getServiceAdresByIdRaw(requestParameters: GetServiceAdresByIdRequest): Promise<runtime.ApiResponse<ServiceAdres>> {
        if (requestParameters.serviceAdresId === null || requestParameters.serviceAdresId === undefined) {
            throw new runtime.RequiredError('serviceAdresId','Required parameter requestParameters.serviceAdresId was null or undefined when calling getServiceAdresById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/serviceadres/{serviceAdresId}`.replace(`{${"serviceAdresId"}}`, encodeURIComponent(String(requestParameters.serviceAdresId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getServiceAdresById(requestParameters: GetServiceAdresByIdRequest): Promise<ServiceAdres> {
        const response = await this.getServiceAdresByIdRaw(requestParameters);
        return await response.value();
    }

}
