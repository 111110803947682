export class GpsUtils {

    public static getLocation() {
        return new Promise<GeolocationPosition>((resolve, reject) => {
            const onSuccess = (position: GeolocationPosition) => {
                resolve(position);
            };

            const onError = (error: GeolocationPositionError) => {
                reject(error);
            };

            navigator.geolocation.getCurrentPosition(onSuccess, onError, {
                enableHighAccuracy: true,
                maximumAge: 60_000,
                timeout: 10_000
            });
        });
    }
    
    public static checkPermission() {
        return new Promise<boolean>((resolve, reject) => {
            navigator.permissions.query({name: "geolocation"}).then((result) => {
                switch (result.state) {
                    case "granted":
                        return resolve(true);
                    case "denied":
                        return resolve(false);
                    case "prompt":
                        this.getLocation().then(() => resolve(true)).catch(() => resolve(false));
                }
            });
        });
    }

}
