import React from "react";
import {useApplicationStatus} from "../../redux/slices/status/hooks";
import {useBezoekSessies} from "../../redux/slices/bezoeksessie/hooks";
import {useHuidigeDagPlanning} from "../../redux/slices/dagPlanning/hooks";
import {DagPlanningBezoekEntryRestModel} from "../../_generated/field-service-be-openapi";
import Icon from "@mdi/react";
import {mdiCheck} from "@mdi/js";
import {useTranslation} from "../../utilities/i18nUtils";

export const PlanningEindeVanDeDagWidget: React.FC = (props) => {
    const offlineStatus = useApplicationStatus();
    const bezoeken = useBezoekSessies();
    const [dagPlanning] = useHuidigeDagPlanning();
    const {t} = useTranslation("planning");

    if (!dagPlanning || dagPlanning.entries.length === 0) {
        return null;
    }

    const alleBezoekenGedaan = dagPlanning.entries
        .filter(item => item._type === "BEZOEK")
        .every(bezoek => {
            const b = bezoek as DagPlanningBezoekEntryRestModel;
            const bezoekSessie = bezoeken[b.toekomstigBezoekSessieId];

            return bezoekSessie?.verlaten && !bezoekSessie?.aanwezig;
        });
    const alleEventsDoorgestuurd = offlineStatus.localEventsAmount === 0;

    if (!alleBezoekenGedaan) {
        return null;
    }

    if (alleEventsDoorgestuurd) {
        return (
            <div className="bg-success bg-opacity-25 p-3" style={{marginLeft: "-0.75rem", marginRight: "-0.75rem"}}>
                <div className="d-flex ">
                    <Icon path={mdiCheck} size={2.6} className="me-2"/>
                    <div>
                        <h4>{t("planning:PlanningEindeVanDeDagWidget.alles-is-afgewerkt", "Alles is afgewerkt")}</h4>
                        <span>{t("planning:PlanningEindeVanDeDagWidget.tijd-voor-een-glaasje-water", "Tijd voor een glaasje water!")}</span>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="bg-secondary bg-opacity-50 p-3" style={{marginLeft: "-0.75rem", marginRight: "-0.75rem"}}>
                <div className="d-flex text-muted">
                    <div>
                        <h4>{t("planning:PlanningEindeVanDeDagWidget.bijna-klaar", "Bijna klaar...")}</h4>
                        <span>
                            {t("planning:PlanningEindeVanDeDagWidget.nog-niet-alle-data-kon-naar-de-server-worden-doorgestuurd","Nog niet alle data kon naar de server doorgestuurd worden. Zorg voor een internetverbinding en klik op het wolkje rechtsboven.")}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
};
