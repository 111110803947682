import React, {useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBListGroup,
    MDBListGroupItem,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBSelect
} from "mdb-react-ui-kit";
import {FixedSizeList} from "react-window";
import {LightboxBackgroundImage} from "../LightboxBackgroundImage";
import {FrameModal} from "./FrameModal";
import {useDimensions} from "../../utilities/useDimensions";

export interface AdresAfwerkenModalProps {
    onClose?(): void;
}

export const WisselStukModal: React.FC<AdresAfwerkenModalProps> = (props) => {
    const {onClose} = props;

    const [activeIndex, setActiveIndex] = useState(0);

    const [listWrapperRef, listWrapperSize] = useDimensions();

    const onOkeClick = () => {
        onClose?.();
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const Item = (props: any) => {
        const {index, style} = props;

        return (
            <MDBListGroupItem style={style} active={index === activeIndex} onClick={() => setActiveIndex(index)} action
                              className="p-0 border-0">
                <MDBRow className="h-100">
                    <MDBCol sm="2" xs="1">
                        <LightboxBackgroundImage src="https://mdbcdn.b-cdn.net/img/new/standard/nature/184.jpg"
                                                 title={<span><strong>ASS000{index}</strong> ES-UNERCAQU-EM-ON</span>}/>
                    </MDBCol>

                    <MDBCol className="ps-4 p-2 d-flex align-items-center">
                        <span><strong>ASS000{index}</strong> ES-UNERCAQU-EM-ON</span>
                    </MDBCol>
                </MDBRow>
            </MDBListGroupItem>
        );
    }

    return (
        <FrameModal>
            <MDBModalHeader>Wissel stuk ASS0007</MDBModalHeader>

            <MDBModalBody>
                <MDBRow>
                    <MDBCol>
                        <MDBInput label="Zoeken"/>
                    </MDBCol>
                    <MDBCol>
                        <MDBSelect
                            label="Soort"
                            data={[
                                {text: "Filters", value: 1, defaultSelected: true}
                            ]}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <div className="d-flex flex-column" style={{height: "50vh"}}>
                <div className="flex-fill" ref={listWrapperRef}>
                    {listWrapperSize?.height && (
                        <MDBListGroup flush>
                            <FixedSizeList
                                width="100%"
                                height={listWrapperSize?.height}
                                itemSize={80}
                                overscanCount={10}
                                itemCount={2000}
                            >
                                {Item}
                            </FixedSizeList>
                        </MDBListGroup>
                    )}
                </div>
            </div>

            <MDBModalBody className="py-1">
                <div className="d-flex justify-content-end align-items-center my-3">
                    <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                        Annuleren
                    </MDBBtn>
                    <MDBBtn type="button" color="success" size="lg" className="ms-2" onClick={onOkeClick}>
                        Oké
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </FrameModal>
    );
};
