import React, {useCallback} from "react";
import {CameraPage} from "../../CameraPage";
import {useGoBack} from "../../../routes";
import {useToestelVoegFotoToe} from "../../../redux/slices/toestellen/hooks";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";

export interface ToestelFotoPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ToestelFotoPage: React.FC = (props) => {
    const {bezoekSessieId, toestelId} = useParams<ToestelFotoPageParams>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    const goBack = useGoBack();
    const toestelVoegFotoToe = useToestelVoegFotoToe();

    const onCapture = useCallback((dataUri: string) => {
        if (!bezoekSessie) {
            return;
        }

        toestelVoegFotoToe(bezoekSessie.id, toestelId, dataUri);

        goBack();
    }, [goBack, bezoekSessie, toestelId, toestelVoegFotoToe]);

    return (
        <CameraPage onCapture={onCapture}/>
    );
};
