import {resourceReducer} from "redux-resource";
import createActionCreators from "redux-resource-action-creators";

const reducer = resourceReducer("serviceAdressen");
const actions = createActionCreators("read", {
      resourceType: "serviceAdressen"
});

export const serviceAdressen = {
      reducer,
      actions
};
