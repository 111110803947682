import React from "react";

export interface CapitalizeProps {
    enabled?: boolean;
    children?: React.ReactNode;
}

export const Capitalize: React.FC<CapitalizeProps> = (props) => {
    const {enabled} = props;

    if (enabled) {
        return <span className="text-capitalize">{props.children}</span>;
    }

    return <>{props.children}</>;
};
