import React, {useEffect} from "react";
import {flatRoutes} from "./index";
import {AnimatedSwitch} from "../components/AnimatedSwitch";
import {ScrollToTop} from "./ScrollToTop";
import {useMe} from "../redux/slices/technicus/hooks";
import {useTranslation} from "../utilities/i18nUtils";

export const Routes: React.FC = () => {
    const {changeLanguage} = useTranslation();
    const me = useMe();

    useEffect(() => {
        if (me) {
            changeLanguage(me.taal);
        }
    }, [me, changeLanguage]);

    return (
        <>
            <ScrollToTop/>

            <AnimatedSwitch>
                {flatRoutes.map((route, index) => (
                    <route.route
                        key={route.key || index}
                        path={route.path}
                        exact={route.exact}
                        component={() => {
                            return <route.component/>;
                        }}/>
                ))}
            </AnimatedSwitch>
        </>
    );
};
