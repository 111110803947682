import React, {useMemo} from "react";
import {MDBBtn, MDBCol, MDBContainer, MDBModalBody, MDBModalHeader, MDBRow} from "mdb-react-ui-kit";
import {Form, Formik} from "formik";
import {MDBFormikInput} from "../../mdb-formik/MDBFormikInput";
import {BezoekSessieToestel} from "../../workers/shared/snapshot/bezoekSessieState";
import * as Yup from "yup";
import {FrameModal} from "./FrameModal";
import {DisableAutocompletePlaceholderInput} from "../DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../utilities/i18nUtils";
import {MDBFormikTextArea} from "../../mdb-formik/MDBFormikTextArea";
import {OnbewaardeGegevensMelding} from "../OnbewaardeGegevensMelding";
import {SaveDraftFormik} from "../SaveDraftFormik";

export interface ToestelInformatieAanpassenFormValues {
    serieNummer: string;
    locatieBeschrijving: string;
    gebouw: string;
    verdieping: string;
    lokaal: string;

    nieuweToestelOpmerking: string;
}

export interface ToestelInformatieAanpassenModalProps {
    toestel: BezoekSessieToestel;
    bezoekSessieId: string;
    serviceAdresId: string;

    onConfirm?(values: Partial<ToestelInformatieAanpassenFormValues>): void;

    onClose?(): void;
}

export const ToestelInformatieAanpassenModal: React.FC<ToestelInformatieAanpassenModalProps> = (props) => {
    const {toestel, bezoekSessieId, onConfirm, onClose} = props;

    const {t} = useTranslation("toestel");

    const initialValues = useMemo<ToestelInformatieAanpassenFormValues>(() => {
        return {
            serieNummer: toestel?.informatie?.serieNummer || "",
            locatieBeschrijving: toestel?.informatie?.locatieBeschrijving || "",
            gebouw: toestel?.informatie?.gebouw || "",
            verdieping: toestel?.informatie?.verdieping || "",
            lokaal: toestel?.informatie?.lokaalnummer || "",
            nieuweToestelOpmerking: toestel?.nieuweOpmerking || ""
        };
    }, [toestel]);

    const validationSchema = useMemo(() => Yup.object().shape({
        serieNummer: Yup.string().required("Serienummer is verplicht"),
        locatieBeschrijving: Yup.string().required("Locatiebeschrijving is verplicht"),
        gebouw: Yup.string().max(32, t("algemeen:Foutmeldingen.gebouw-max", "Gebouw kan maximum {{max}} karakters bevatten.", {max: 32}) as string),
        verdieping: Yup.string().max(16, t("algemeen:Foutmeldingen.verdieping-max", "Verdieping kan maximum {{max}} karakters bevatten.", {max: 16}) as string),
        lokaal: Yup.string().max(64, t("algemeen:Foutmeldingen.lokaal-max", "Lokaal kan maximum {{max}} karakters bevatten.", {max: 64}) as string),
        nieuweToestelOpmerking: Yup.string().optional()
    }), [t]);

    const onSubmit = (values: ToestelInformatieAanpassenFormValues) => {
        onConfirm?.({
            gebouw: toestel?.informatie?.gebouw === values.gebouw ? undefined : values.gebouw,
            verdieping: toestel?.informatie?.verdieping === values.verdieping ? undefined : values.verdieping,
            lokaal: toestel?.informatie?.lokaalnummer === values.lokaal ? undefined : values.lokaal,
            locatieBeschrijving: toestel?.informatie?.locatieBeschrijving === values.locatieBeschrijving ? undefined : values.locatieBeschrijving,
            nieuweToestelOpmerking: toestel?.nieuweOpmerking === values.nieuweToestelOpmerking ? undefined : values.nieuweToestelOpmerking,
            serieNummer: toestel?.informatie?.serieNummer === values.serieNummer ? undefined : values.serieNummer
        });
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>{t("ToestelInformatieAanpassenModal.titel", "Toestelinformatie aanpassen")}</MDBModalHeader>
            <MDBModalBody className="py-1">
                <MDBContainer fluid>
                    <Formik initialValues={initialValues} onSubmit={onSubmit}
                            validationSchema={validationSchema}>
                        {(formik) => {
                            const {isValid} = formik;

                            return (
                                <Form autoComplete="off">
                                    <OnbewaardeGegevensMelding draftValues={toestel.informatieDraftValues}/>
                                    <SaveDraftFormik formId={`toestelinformatie/${toestel.informatie?.id}`} bezoekSessieId={bezoekSessieId}/>

                                    <DisableAutocompletePlaceholderInput/>
                                    <MDBRow className="pt-3">
                                        <MDBCol md="6" className="mb-3">
                                            <h6 className="mb-3">{t("Labels.serienummer", "Serienummer")}</h6>
                                            <MDBFormikInput type="text" size="lg"
                                                            name="serieNummer"/>
                                        </MDBCol>
                                        <MDBCol md="6" className="mb-3">
                                            <h6 className="mb-3">{t("Labels.locatiebeschrijving", "Locatiebeschrijving")}</h6>
                                            <MDBFormikInput type="text" size="lg"
                                                            name="locatieBeschrijving"
                                                            placeholder={t("Placeholders.toestel-locatie-form.locatiebeschrijving", "vb. Gang naast drankautomaat of Keuken") as string}
                                            />
                                        </MDBCol>
                                        <MDBCol md="4" className="mb-3">
                                            <h6 className="mb-3">{t("Labels.gebouw", "Gebouw")} <small
                                                className="text-muted">{t("Titels.indien-van-toepassing", "(indien van toepassing)")}</small>
                                            </h6>
                                            <MDBFormikInput type="text" size="lg"
                                                            name="gebouw"
                                                            placeholder={t("Placeholders.toestel-locatie-form.gebouw", "vb. Blok A") as string}
                                            />
                                        </MDBCol>
                                        <MDBCol md="4" className="mb-3">
                                            <h6 className="mb-3">{t("Labels.verdieping", "Verdieping")} <small
                                                className="text-muted">{t("Titels.indien-van-toepassing", "(indien van toepassing)")}</small>
                                            </h6>
                                            <MDBFormikInput type="text" size="lg"
                                                            name="verdieping"
                                                            placeholder={t("Placeholders.toestel-locatie-form.verdieping", "vb. -1") as string}
                                            />
                                        </MDBCol>
                                        <MDBCol md="4" className="mb-3">
                                            <h6 className="mb-3">{t("Labels.lokaal", "Lokaal")} <small
                                                className="text-muted">{t("Titels.indien-van-toepassing", "(indien van toepassing)")}</small>
                                            </h6>
                                            <MDBFormikInput type="text" size="lg"
                                                            name="lokaal"
                                                            placeholder={t("Placeholders.toestel-locatie-form.lokaal", "vb. C./00/130") as string}
                                            />
                                        </MDBCol>

                                        <MDBCol xs="12" className="mb-3">
                                            <h6 className="mb-3">{t("Labels.opmerking-toevoegen", "Opmerking toevoegen")}</h6>
                                            <MDBFormikTextArea name="nieuweToestelOpmerking"/>
                                        </MDBCol>
                                    </MDBRow>

                                    <div className="d-flex justify-content-end align-items-center my-3">
                                        <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                                            {t("algemeen:Buttons.annuleren", "Annuleren")}
                                        </MDBBtn>
                                        <MDBBtn color="success" size="lg" className="ms-2" type="submit"
                                                disabled={!isValid}>
                                            {t("algemeen:Buttons.oke", "Oké")}
                                        </MDBBtn>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBContainer>
            </MDBModalBody>
        </FrameModal>
    );
};
