import React from "react";
import {MDBAlert, MDBBtn, MDBRow, MDBCol} from "mdb-react-ui-kit";
import {useTranslation} from "../utilities/i18nUtils";
import { useFormikContext } from "formik";

export interface OnbewaardeGegevensMeldingProps {
    draftValues?: any;
}

export const OnbewaardeGegevensMelding: React.FC<OnbewaardeGegevensMeldingProps> = (props) => {
    const {t} = useTranslation("bezoeken");
    const formikContext = useFormikContext<any>();

    const [show, setShow] = React.useState(!!props.draftValues);

    const onHerstel = () => {
        formikContext.setValues(props.draftValues);
        setShow(false);
    };

    return (
        <MDBAlert open={show} color="warning">
            <MDBRow between className="align-items-center">
                <MDBCol>
                    {t("OpgeslagenGegevensMelding.er-zijn-onbewaarde-gegevens-gevonden", "Er zijn onbewaarde gegevens gevonden. Wil je die herstellen?")}
                </MDBCol>

                <MDBCol size="auto">
                    <MDBBtn type="button" onClick={() => setShow(false)} color="light" className="me-3">
                        {t("OpgeslagenGegevensMelding.annuleren", "Annuleren")}
                    </MDBBtn>

                    <MDBBtn type="button" onClick={onHerstel}>
                        {t("OpgeslagenGegevensMelding.herstellen", "Herstellen")}
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        </MDBAlert>
    );
}

