import React from "react";
import {MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalTitle} from "mdb-react-ui-kit";
import {FrameModal} from "./FrameModal";
import {Toestel} from "../../_generated/field-service-be-openapi";
import {ToestelServiceLogList} from "../toestel/servicelog/ToestelServiceLogList";

export interface ToestelServiceLogModalProps {
    toestel: Toestel;

    onClose?(): void;
}

export const ToestelServiceLogModal: React.FC<ToestelServiceLogModalProps> = (props) => {
    const {toestel, onClose} = props;

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>
                <MDBModalTitle>Servicelog</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={onClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBContainer fluid>
                    <ToestelServiceLogList toestel={toestel}/>
                </MDBContainer>
            </MDBModalBody>
        </FrameModal>
    );
};
