import React, {useState} from "react";
import {MDBModal, MDBModalContent, MDBModalDialog} from "mdb-react-ui-kit";

export interface PopupModalProps {
    modalOptions?: typeof MDBModal["defaultProps"];
    modalDialogOptions?: typeof MDBModalDialog["defaultProps"];

    onClose?(): void;
    
    children?: React.ReactNode;
}

export const PopupModal: React.FC<PopupModalProps> = (props) => {
    const {modalOptions, modalDialogOptions, onClose} = props;

    // This internal state is needed for MDB to see that the modal was closed.
    // Otherwise the "modal-open" body class is kept.
    const [show, setShow] = useState(true);

    const onOpenState = (open: boolean) => {
        setShow(open);

        if (show !== open && !open) {
            onClose?.();
        }
    };

    return (
        <MDBModal {...modalOptions} open={show} setOpen={onOpenState}>
            <MDBModalDialog {...modalDialogOptions}>
                <MDBModalContent>
                    {props.children}
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};
