import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRipple, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAccountCircle, mdiAlphabetical, mdiChevronRight, mdiFountainPenTip} from "@mdi/js";
import {InstallatieCard} from "../../components/geplandWerk/InstallatieCard";
import {Co2LeveringCard} from "../../components/geplandWerk/Co2LeveringCard";
import {AccessoireLeveringCard} from "../../components/geplandWerk/AccessoireLeveringCard";
import {ToestellenCard} from "../../components/geplandWerk/ToestellenCard";
import {useTechnici} from "../../redux/slices/technicus/hooks";
import {MDBCardStatus, MDBCardStatusEnum} from "../../components/mdb/MDBCardStatus";
import {BezoekSessie} from "../../workers/shared/snapshot/bezoekSessieState";
import {MDBChip} from "../../components/mdb/MDBChip";
import {useTranslation} from "../../utilities/i18nUtils";
import {Installatie} from "../../_generated/field-service-be-openapi";

export interface ServiceAdresTakenTabProps {
    bezoekSessie: BezoekSessie;
}

const concatLocatie = (installatie?: Installatie) => (installatie?.gebouw ?? "") + (installatie?.verdieping ?? "") + (installatie?.lokaalNummer ?? "") + (installatie?.locatie ?? "");

export const ServiceAdresTakenTab: React.FC<ServiceAdresTakenTabProps> = (props) => {
    const {bezoekSessie} = props;

    const technici = useTechnici();

    const installaties = useMemo(() => {
        return bezoekSessie.getInstallaties()
            // Sorteer op Gebouw + verdieping + lokaal + locatie
            .sort((a, b) => concatLocatie(a.uitTeVoerenWerk).localeCompare(concatLocatie(b.uitTeVoerenWerk)));
    }, [bezoekSessie]);
    const accessoireLeveringen = useMemo(() => bezoekSessie.getAccessoireLeveringen(), [bezoekSessie]);

    const toestellen = useMemo(() => Object.values(bezoekSessie.toestellen), [bezoekSessie]);
    const hebbenToestellenTaken = useMemo(() => toestellen.some(toestel => toestel.heeftUitTeVoerenWerk), [toestellen]);

    const {t} = useTranslation("serviceadres");

    const handTekeningAltLabel = t("ServiceAdresTakenTab.handtekening", "Handtekening");

    return (
        <>
            <NavLink to={`/bezoek/taken/${bezoekSessie.id}/opdracht/co2-levering`}>
                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    <Co2LeveringCard technici={technici} levering={bezoekSessie?.co2Levering}/>
                </MDBRipple>
            </NavLink>

            {!!accessoireLeveringen?.length && (
                <NavLink to={`/bezoek/taken/${bezoekSessie.id}/opdracht/accessoire-levering`}>
                    <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                        <AccessoireLeveringCard leveringen={accessoireLeveringen}/>
                    </MDBRipple>
                </NavLink>
            )}

            {installaties?.map((installatie) => (
                <NavLink to={`/bezoek/taken/${bezoekSessie.id}/opdracht/installatie/${installatie.id}`}
                         key={installatie.id}>
                    <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                        <InstallatieCard installatie={installatie}/>
                    </MDBRipple>
                </NavLink>
            ))}

            {hebbenToestellenTaken && (
                <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
                    <NavLink to="#toestellen">
                        <ToestellenCard toestellen={toestellen}/>
                    </NavLink>
                </MDBCard>
            )}

            <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
                <NavLink to={`/bezoek/taken/${bezoekSessie.id}/handtekening`}>
                    <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                        <MDBCardStatus status={bezoekSessie.handtekening ? MDBCardStatusEnum.DONE : undefined}/>
                        <MDBCardHeader>
                            <MDBRow>
                                <MDBCol>
                                    <span className="d-flex align-items-center">
                                        <Icon path={mdiFountainPenTip} size={1} className="me-1"/>
                                        {t("ServiceAdresTakenTab.handtekening-klant",
                                            "Handtekening klant")}
                                    </span>
                                </MDBCol>

                                <MDBCol size="auto" className="d-flex">
                                    {bezoekSessie.handtekening?.laatstActieveTechnicusId && (
                                        <MDBChip className="my-0 me-0">
                                            <Icon path={mdiAccountCircle} size={1}/>

                                            {technici[bezoekSessie.handtekening.laatstActieveTechnicusId]?.naam}
                                        </MDBChip>
                                    )}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    {bezoekSessie.handtekening &&
                                    <img src={bezoekSessie.handtekening.image}
                                         alt={handTekeningAltLabel}
                                         style={{height: 100, width: "auto"}}/>}
                                </MDBCol>

                                <MDBCol size="auto"
                                        className="d-flex align-items-center justify-content-end">
                                    <Icon path={mdiChevronRight} size={2}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBRipple>
                </NavLink>
            </MDBCard>

            <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
                <NavLink to={`/bezoek/taken/${bezoekSessie.id}/intern`}>
                    <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                        <MDBCardStatus status={bezoekSessie.intern ? MDBCardStatusEnum.DONE : undefined}/>
                        <MDBCardHeader>
                            <MDBRow>
                                <MDBCol>
                                    <span className="d-flex align-items-center">
                                        <Icon path={mdiAlphabetical} size={1} className="me-1"/>
                                        {t("ServiceAdresTakenTab.interne-opvolging",
                                            "Interne opvolging")}
                                    </span>
                                </MDBCol>

                                <MDBCol size="auto" className="d-flex">
                                    {bezoekSessie.intern?.laatstActieveTechnicusId && (
                                        <MDBChip className="my-0 me-0">
                                            <Icon path={mdiAccountCircle} size={1}/>

                                            {technici[bezoekSessie.intern.laatstActieveTechnicusId]?.naam}
                                        </MDBChip>
                                    )}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    {bezoekSessie.intern && (
                                        <p>
                                            {t("ServiceAdresTakenTab.opmerking-ingevoerd", "Opmerking ingevoerd")}<br/>
                                            <small
                                                className="text-muted">
                                                {t("ServiceAdresTakenTab.opmerking-lengte",
                                                    "{{count}} karakters",
                                                    {count: bezoekSessie.intern.opmerking?.length})}</small>
                                        </p>
                                    )}
                                    {!bezoekSessie?.intern
                                        && <p className="text-muted">
                                            {t("ServiceAdresTakenTab.geen-opmerking", "Geen opmerking")}
                                    </p>}
                                </MDBCol>

                                <MDBCol size="auto"
                                        className="d-flex align-items-center justify-content-end">
                                    <Icon path={mdiChevronRight} size={2}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBRipple>
                </NavLink>
            </MDBCard>
        </>
    );
};
