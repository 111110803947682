import React from "react";
import {MDBBtn} from "mdb-react-ui-kit";
import {mdiFileDownload} from "@mdi/js";
import Icon from "@mdi/react";
import {useDownloadInstallatieBijlage} from "../../redux/slices/download/hooks";

export interface InstallatieBijlageDownloadButtonProps {
    installatieId: string;
    bijlageId: string;
}

export const InstallatieBijlageDownloadButton: React.FC<InstallatieBijlageDownloadButtonProps> = (props) => {
    const {installatieId, bijlageId} = props;

    const [downloadInstallatieBijlage] = useDownloadInstallatieBijlage(installatieId, bijlageId);

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();

        downloadInstallatieBijlage();
    };

    return (
        <MDBBtn type="button" onClick={onClick} color="link" className="p-0">
            <Icon path={mdiFileDownload} size={1}/>
        </MDBBtn>
    );
};
