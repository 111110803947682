import React from "react";
import {
    ToestelInGebruikServiceLogEntryRestModel,
    ToestelInstallatieServiceLogEntryRestModel,
    ToestelInterventieServiceLogEntryRestModel,
    ToestelOnderhoudServiceLogEntryRestModel,
    ToestelOpmerkingServiceLogEntryRestModel,
    ToestelServiceLogEntryRestModel,
    ToestelWatermeterStandServiceLogEntryRestModel
} from "../../../_generated/field-service-be-openapi";
import {ToestelOnderhoudServiceLogEntryComponent} from "./ToestelOnderhoudServiceLogEntryComponent";
import {ToestelInterventieServiceLogEntryComponent} from "./ToestelInterventieServiceLogEntryComponent";
import {ToestelOpmerkingServiceLogEntry} from "./ToestelOpmerkingServiceLogEntryComponent";
import {ToestelWatermeterStandServiceLogEntry} from "./ToestelWatermeterStandServiceLogEntryComponent";
import {ToestelInGebruikServiceLogEntry} from "./ToestelInGebruikServiceLogEntryComponent";
import {GenericServiceLogEntry} from "./GenericServiceLogEntryComponent";
import {ToestelUitDienstServiceLogEntry} from "./ToestelUitDienstServiceLogEntryComponent";
import {ToestelInstallatieServiceLogEntry} from "./ToestelInstallatieServiceLogEntryComponent";

export interface ToestelServiceLogEntryProps {
    entry: ToestelServiceLogEntryRestModel;
}

export const ToestelServiceLogEntryComponent: React.FC<ToestelServiceLogEntryProps> = (props) => {
    if (props.entry?._type) {
        switch (props.entry._type) {
            case "IN_GEBRUIK":
                return <ToestelInGebruikServiceLogEntry
                    entry={props.entry as ToestelInGebruikServiceLogEntryRestModel}/>
            case "UIT_DIENST":
                return <ToestelUitDienstServiceLogEntry
                    entry={props.entry as ToestelInGebruikServiceLogEntryRestModel}/>
            case "ONDERHOUD":
                return <ToestelOnderhoudServiceLogEntryComponent
                    entry={props.entry as ToestelOnderhoudServiceLogEntryRestModel}/>
            case "INSTALLATIE":
                return <ToestelInstallatieServiceLogEntry
                    entry={props.entry as ToestelInstallatieServiceLogEntryRestModel}/>
            case "INTERVENTIE":
                return <ToestelInterventieServiceLogEntryComponent
                    entry={props.entry as ToestelInterventieServiceLogEntryRestModel}/>
            case "OPMERKING":
                return <ToestelOpmerkingServiceLogEntry
                    entry={props.entry as ToestelOpmerkingServiceLogEntryRestModel}/>
            case "WATERMETER_STAND":
                return <ToestelWatermeterStandServiceLogEntry
                    entry={props.entry as ToestelWatermeterStandServiceLogEntryRestModel}/>
        }
    }

    return <GenericServiceLogEntry entry={props.entry}/>;
};
