import {SimpleRpcChannel} from "../../shared/channels/simpleRpcChannel";
import {RpcProvider} from "worker-rpc";

const browserRpc: RpcProvider = new RpcProvider((message, transfers) => {
    if (transfers) {
        navigator.serviceWorker.controller?.postMessage(message, transfers);
    } else {
        navigator.serviceWorker.controller?.postMessage(message);
    }
});

navigator.serviceWorker.onmessage = (event) => {
    browserRpc.dispatch(event.data);
};

/**
 * Channel that is used to communicate with the service worker from within the browser.
 */
export const browser2ServiceWorkerChannel = new SimpleRpcChannel(browserRpc);
