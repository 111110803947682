import React, {useCallback, useMemo} from "react";
import {MDBCol, MDBRow, MDBTypography} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import {GenericPage} from "../../../components/GenericPage";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {useParams} from "react-router-dom";
import {useGoBack} from "../../../routes";
import {Adres} from "../../../components/Adres";
import {Form, Formik} from "formik";
import {useInterneFeedbackToegevoegd} from "../../../redux/slices/serviceAdressen/hooks";
import * as Yup from "yup";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";
import {Trans} from "react-i18next";
import {BezoekMDBFormikTextArea} from "../../../mdb-formik/bezoek/BezoekMDBFormikTextArea";
import {OnbewaardeGegevensMelding} from "../../../components/OnbewaardeGegevensMelding";
import {SaveDraftFormik} from "../../../components/SaveDraftFormik";

export interface InternFormValues {
    opmerking?: string;
}

export interface InternPageProps {
    bezoekSessieId: string;
}

export const InternPage: React.FC = () => {
    const {bezoekSessieId} = useParams<InternPageProps>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    const interneFeedbackToegevoegd = useInterneFeedbackToegevoegd();

    const goBack = useGoBack();

    const onSubmit = useCallback((values: InternFormValues) => {
        if (bezoekSessie) {
            interneFeedbackToegevoegd(bezoekSessie.id, {
                opmerking: values.opmerking
            });

            goBack();
        }
    }, [bezoekSessie, goBack, interneFeedbackToegevoegd]);

    const initialValues: InternFormValues = useMemo(() => ({
        opmerking: bezoekSessie?.intern?.opmerking || ""
    }), [bezoekSessie]);

    const validationSchema = useMemo(() => Yup.object({
        opmerking: Yup.string().optional().max(10_000, "Opmerking kan maximum 10.000 karakters bevatten")
    }), []);

    const {t} = useTranslation("serviceadres");

    const interneOpmerkingLabel = t("InternPage.interne-opmerking", "Interne opmerking");

    return (
        <GenericPage>
            <Header title="Interne opvolging" subtitle={<><strong>{bezoekSessie?.serviceAdres?.naam}</strong> <Adres
                adres={bezoekSessie?.serviceAdres}/></>}/>

            <div className="container-lg pt-4 flex-fill d-flex flex-column bg-white">
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <Formik<InternFormValues> initialValues={initialValues} onSubmit={onSubmit}
                                          validationSchema={validationSchema}>
                    {
                        () => (
                            <Form autoComplete="off">
                                <OnbewaardeGegevensMelding draftValues={bezoekSessie?.internDraftValues}/>
                                <SaveDraftFormik formId={`intern/${bezoekSessieId}`} bezoekSessieId={bezoekSessieId}/>

                                <DisableAutocompletePlaceholderInput/>
                                <MDBRow className="mt-3 mb-2">
                                    <MDBCol size="12" className="mb-2">
                                        <MDBTypography note noteColor="info">
                                            <Trans t={t}
                                                i18nKey="InternPage.interne-opmerking-veld-omschrijving">
                                            Indien je interne opmerkingen wil doorgeven aan de AQUALEX administratie,
                                            kan je dit hier
                                            doen. Alles wat je hier ingeeft, krijgt de AQUALEX administratie te zien. De
                                            interne
                                            opmerkingen worden niet getoond op het servicerapport voor de klant. Als
                                            alles prima is verlopen, hoef je hier niets in te vullen: je bespaart dan
                                            ook werk voor de AQUALEX administratie.
                                            </Trans>
                                        </MDBTypography>
                                    </MDBCol>

                                    <MDBCol size="12" className="mb-4">
                                        <BezoekMDBFormikTextArea name="opmerking" label={interneOpmerkingLabel}
                                                              rows={10}
                                                              maxLength={10_000}/>
                                    </MDBCol>

                                    <MDBCol size="12">
                                        <BezoekMDBBtn block size="lg" type="submit">{t("InternPage.bewaren", "Bewaren")}</BezoekMDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </GenericPage>
    );
};
