import {useAppSelector} from "../../hooks";
import dayjs from "dayjs";
import {DagPlanningBezoekEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {requestStatuses, ResourceRequest} from "redux-resource";
import {useCallback, useEffect, useMemo} from "react";
import {useSessionStorageState} from "../../../utilities/storageHooks";
import {dateNow, parseDate} from "../../../utilities/dateUtils";
import {workerRegistry} from "../../../workers/shared/workerRegistry";
import {WebWorkerName} from "../../../workers/webworker/webWorkerName";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";
import {DagPlanningRecord} from "../../../utilities/db/repository/dagPlanningRepository";

export const useDagPlanning = (date: Date) => {
    const datum = dayjs(date).format("YYYY-MM-DD");
    const key = `me:${datum}`;

    const {dagPlanning, requestStatus, resourceMetadata} = useAppSelector((state) => {
        const requestStatus = state.dagPlanning.requests[key]?.status;
        let dagPlanning: DagPlanningRecord | undefined = undefined;
        let dagPlanningRequestMetadata: ResourceRequest = state.dagPlanning.requests[key];

        if (state.dagPlanning.lists[key]) {
            const dagPlanningId = state.dagPlanning.lists[key]?.[0];

            if (dagPlanningId) {
                dagPlanning = state.dagPlanning.resources[dagPlanningId];
            }
        }

        return {dagPlanning, requestStatus, resourceMetadata: dagPlanningRequestMetadata};
    });

    const syncPlanning = useCallback(() => {
        if (requestStatus === undefined || requestStatus === requestStatuses.IDLE) {
            workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER).publish(WorkerMessageFactory.createSyncPlanningMessage(datum));
        }
    }, [requestStatus, datum]);

    useEffect(() => {
        if (!dagPlanning) {
            syncPlanning();
        }
    }, [dagPlanning, syncPlanning, datum]);

    const loading = useAppSelector((state) => state.dagPlanning.requests[key]?.status === requestStatuses.PENDING);

    const metadata = useMemo(() => {
        return {
            request: resourceMetadata,
            loading
        };
    }, [loading, resourceMetadata]);

    return [dagPlanning, metadata] as [DagPlanningRecord | undefined, typeof metadata];
};

const datumToday = dateNow();
const datumTodayFormatted = dayjs(datumToday).format("YYYY-MM-DD");

export const useGeselecteerdeDag = () => {
    const [geselecteerdeDagString, setGeselecteerdeDagString] = useSessionStorageState("geselecteerdeDag", datumTodayFormatted);

    const setGeselecteerdeDag = useCallback((datum: string) => {
        setGeselecteerdeDagString(dayjs(datum).format("YYYY-MM-DD"));
    }, [setGeselecteerdeDagString]);

    useEffect(() => {
        if (geselecteerdeDagString) {
            workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER)
                .publish(WorkerMessageFactory.createDagPlanningDatumGewijzigdDoorGebruikerMessage(geselecteerdeDagString));
        }
    }, [geselecteerdeDagString]);

    const geselecteerdeDag: Date = useMemo(() => {
        if (geselecteerdeDagString) {
            return parseDate(geselecteerdeDagString, "YYYY-MM-DD").toDate();
        }

        return dateNow();
    }, [geselecteerdeDagString]);

    return [geselecteerdeDag, setGeselecteerdeDag] as [Date, (datum: string) => void];
};

export const useHuidigeDagPlanning = () => {
    const [geselecteerdeDag] = useGeselecteerdeDag();

    return useDagPlanning(geselecteerdeDag);
};

export const useOutOfSyncBezoeken = () => {
    const outOfSyncBezoeken = useAppSelector((state) => {
        const dagPlanningEntryResources = state.dagPlanningEntry.resources;
        const outOfSyncEntries = Object.values(dagPlanningEntryResources || {}).filter(item => item._type === "BEZOEK" && item.outOfSync) as DagPlanningBezoekEntryRestModel[];

        return outOfSyncEntries;
    });

    return outOfSyncBezoeken;
};
