import React, { useRef, useEffect } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import clsx from 'clsx';
import { EventHandlers, ScrollbarProps } from './types';

const MDBScrollbar = ({
  className,
  sidenav,
  tag: Tag = 'div',
  handlers = ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  wheelSpeed = 1,
  wheelPropagation = true,
  swipeEasing = true,
  minScrollbarLength,
  maxScrollbarLength,
  scrollingThreshold = 1000,
  useBothWheelAxes = false,
  suppressScrollX = false,
  suppressScrollY = false,
  scrollXMarginOffset = 0,
  scrollYMarginOffset = 0,
  scrollBarRef,
  onScrollY,
  onScrollX,
  onScrollUp,
  onScrollDown,
  onScrollLeft,
  onScrollRight,
  onYReachStart,
  onYReachEnd,
  onXReachStart,
  onXReachEnd,
  children,
  options: optionsProp, // prop considered to be removed in major release
  ...props
}: ScrollbarProps) => {
  const classes = clsx(sidenav && 'sidenav-menu', className);
  const scrollbarElRef = useRef<HTMLElement | null>(null);
  const psRef = useRef<PerfectScrollbar | null>(null);

  useEffect(() => {
    let scrollbarEl: HTMLElement | null = null;
    const handlersTemp: any[] = [];
    const options = {
      handlers,
      wheelSpeed,
      wheelPropagation,
      swipeEasing,
      minScrollbarLength,
      maxScrollbarLength,
      scrollingThreshold,
      useBothWheelAxes,
      suppressScrollX,
      suppressScrollY,
      scrollXMarginOffset,
      scrollYMarginOffset,
      ...optionsProp,
    };

    if (!scrollbarElRef.current) return;
    scrollbarEl = scrollbarElRef.current;
    psRef.current = new PerfectScrollbar(scrollbarEl, options);

    const eventHandlers: EventHandlers = {
      'ps-scroll-y': onScrollY,
      'ps-scroll-x': onScrollX,
      'ps-scroll-up': onScrollUp,
      'ps-scroll-down': onScrollDown,
      'ps-scroll-left': onScrollLeft,
      'ps-scroll-right': onScrollRight,
      'ps-y-reach-start': onYReachStart,
      'ps-y-reach-end': onYReachEnd,
      'ps-x-reach-start': onXReachStart,
      'ps-x-reach-end': onXReachEnd,
    };

    Object.keys(eventHandlers).forEach((eventName) => {
      const callback = eventHandlers[eventName];
      if (typeof callback === 'function' && scrollbarEl) {
        const callbackHandler = () => callback(scrollbarEl as HTMLElement);
        handlersTemp.push({ eventName, handler: callbackHandler });
        scrollbarEl.addEventListener(eventName, callbackHandler);
      }
    });

    scrollBarRef && scrollBarRef(psRef.current);

    return () => {
      handlersTemp.forEach((handlerObject) => {
        scrollbarEl?.removeEventListener(handlerObject.eventName, handlerObject.handler);
      });

      psRef.current?.destroy();
      psRef.current = null;
    };
  }, [
    handlers,
    wheelSpeed,
    wheelPropagation,
    swipeEasing,
    minScrollbarLength,
    maxScrollbarLength,
    scrollingThreshold,
    useBothWheelAxes,
    suppressScrollX,
    suppressScrollY,
    scrollXMarginOffset,
    scrollYMarginOffset,
    scrollBarRef,
    onScrollY,
    onScrollX,
    onScrollUp,
    onScrollDown,
    onScrollLeft,
    onScrollRight,
    onYReachStart,
    onYReachEnd,
    onXReachStart,
    onXReachEnd,
    optionsProp,
  ]);

  return (
    <Tag className={classes} ref={scrollbarElRef} {...props}>
      {children}
    </Tag>
  );
};

export default MDBScrollbar;
