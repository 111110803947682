import React, {MouseEvent, useState} from "react";
import {MDBBtn} from "../mdb-react-ui-kit";
import type {ButtonProps} from "../mdb-react-ui-kit/free/components/Button/types";

export const SingleClickButton: React.FC<ButtonProps> = (props) => {
    const {onClick, ...rest} = props;

    const [clicked, setClicked] = useState(false);

    const onBtnClick = (e: MouseEvent<any>) => {
        setClicked(true);

        onClick?.(e);
    };

    return (
        <MDBBtn {...rest} onClick={onBtnClick} disabled={rest.disabled || clicked}/>
    );
};
