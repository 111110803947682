import React from "react";
import {MDBCard, MDBCardBody, MDBCardText} from "mdb-react-ui-kit";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {ContentContainer} from "../components/ContentContainer";
import {useTranslation} from "../utilities/i18nUtils";

export const DocumentenPage: React.FC = () => {
    const {t} = useTranslation("algemeen");


    return (
        <GenericPage>
            <Header title={t("DocumentenPage.titel", "Documenten")} sticky/>

            <ContentContainer>

                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                         <MDBCardText>
                             {t("DocumentenPage.geen-documenten-beschikbaar","Geen documenten beschikbaar.")}
                        </MDBCardText>

                    </MDBCardBody>
                </MDBCard>

            </ContentContainer>
        </GenericPage>
    );
};
