import Dexie from "dexie";
import {BezoekSessieEventMessage, DagPlanningRestModel, Technicus} from "../../_generated/field-service-be-openapi";
import {BestandRecord} from "./repository/bestandRepository";
import {SyncStatusRecord} from "./repository/syncStatusRepository";
import {AuthRecord} from "./repository/authRepository";
import {DeviceInformationRecord} from "./repository/deviceInformationRepository";
import {ErrorRecord} from "./repository/errorRepository";
import {KlantRecord} from "./repository/klantRepository";
import {ServiceAdresRecord} from "./repository/serviceAdresRepository";
import {ArtikelRecord} from "./repository/artikelRepository";
import {ToestelRecord} from "./repository/toestelRepository";
import {DagPlanningEntryRecord} from "./repository/dagPlanningEntryRepository";
import {BezoekSessieSnapshotRecord} from "./repository/bezoekSessieSnapshotRepository";

export enum Databases {
    localEvents = "localEvents",
    serverEvents = "serverEvents",
    klanten = "klanten",
    serviceAdressen = "serviceAdressen",

    artikelen = "artikelen",

    toestellen = "toestellen",

    technici = "technici",
    dagPlanning = "dagPlanning",
    dagPlanningEntry = "dagPlanningEntry",
    bestanden = "bestanden",
    syncStatus = "syncStatus",
    auth = "auth",
    deviceInformation = "deviceInformation",
    errors = "errors",
    bezoekSessieSnapshots = "bezoekSessieSnapshots"
}

export class Database extends Dexie {

    public [Databases.localEvents]!: Dexie.Table<BezoekSessieEventMessage, string>;
    public [Databases.serverEvents]!: Dexie.Table<BezoekSessieEventMessage, string>;

    public [Databases.klanten]!: Dexie.Table<KlantRecord, string>;
    public [Databases.serviceAdressen]!: Dexie.Table<ServiceAdresRecord, string>;

    public [Databases.artikelen]!: Dexie.Table<ArtikelRecord, string>;

    public [Databases.toestellen]!: Dexie.Table<ToestelRecord, string>;

    public [Databases.technici]!: Dexie.Table<Technicus, string>;
    public [Databases.dagPlanning]!: Dexie.Table<DagPlanningRestModel, string>;
    public [Databases.dagPlanningEntry]!: Dexie.Table<DagPlanningEntryRecord, string>;
    public [Databases.bestanden]!: Dexie.Table<BestandRecord, string>;
    public [Databases.syncStatus]!: Dexie.Table<SyncStatusRecord, string>;
    public [Databases.auth]!: Dexie.Table<AuthRecord, string>;
    public [Databases.deviceInformation]!: Dexie.Table<DeviceInformationRecord, string>;
    public [Databases.errors]!: Dexie.Table<ErrorRecord, string>;

    public [Databases.bezoekSessieSnapshots]!: Dexie.Table<BezoekSessieSnapshotRecord, string>;

    constructor() {
        super("FieldService");

        const v1Schema: Partial<Record<Databases, string>> = {
            [Databases.localEvents]: "++id, bezoekSessieId, metadata.creatieTijdstip, payload._type",
            [Databases.serverEvents]: "++id, bezoekSessieId, metadata.creatieTijdstip, metadata.ontvangenTijdstip, payload._type",

            [Databases.klanten]: "++id, lastTouchedTimestamp",
            [Databases.serviceAdressen]: "++id, lastTouchedTimestamp",

            [Databases.artikelen]: "++id, bedrijfId",

            [Databases.toestellen]: "++id, lastTouchedTimestamp",

            [Databases.technici]: "++id, me",
            [Databases.dagPlanning]: "++id, datum, lastTouchedTimestamp",
            [Databases.dagPlanningEntry]: "++id, serviceAdresId, [_type+serviceAdresId], bezoekId, lastTouchedTimestamp",
            [Databases.bestanden]: "++id, uploadId, synced",
            [Databases.syncStatus]: "++id",

            [Databases.auth]: "++id",
            [Databases.deviceInformation]: "++id",
            [Databases.errors]: "++id, timestamp"
        };

        const v2Schema: Partial<Record<Databases, string>> = {
            [Databases.bezoekSessieSnapshots]: "++id, snapshotTimestamp"
        };

        const v3Schema: Partial<Record<Databases, string>> = {
            [Databases.serverEvents]: "++id, event.bezoekSessieId, isServerEvent, event.metadata.creatieTijdstip, event.metadata.ontvangenTijdstip, event.payload._type"
        };

        this.version(1).stores(v1Schema);
        this.version(2).stores(v2Schema);
        this.version(3).stores(v3Schema).upgrade((transaction) => {
            transaction.table(Databases.serverEvents).toCollection().modify((value, ref) => {
                ref.value = {
                    id: value.id,
                    event: value,
                    isServerEvent: 1
                };
            });

            transaction.table(Databases.localEvents).toCollection().each(event => {
                const newEvent = {
                    id: event.id,
                    event,
                    isServerEvent: 0
                };
                transaction.table(Databases.serverEvents).add(newEvent, newEvent.id);
            });
        });
    }

}

export const database = new Database();
