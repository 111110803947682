import React from "react";
import {MDBCol, MDBNavbarToggler} from "../mdb-react-ui-kit/index";
import {HeaderSyncStatus} from "../components/HeaderSyncStatus";
import Icon from "@mdi/react";
import {mdiMenu} from "@mdi/js";

export interface HomePageHeaderProps {
    showSidenav: () => void;
}

export const HomePageHeader: React.FC<HomePageHeaderProps> = (props) => {
    const {showSidenav} = props;

    return (
        <MDBCol size="auto"
                className="py-2 d-flex align-items-center justify-content-center">
            <div className="me-2">
                <HeaderSyncStatus/>
            </div>

            <MDBNavbarToggler
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => showSidenav()}
            >
                <Icon path={mdiMenu} color="var(--mdb-primary)" size={1}/>
            </MDBNavbarToggler>
        </MDBCol>
    )
};
