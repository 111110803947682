import React, {useCallback, useEffect} from "react";
import {ErrorBoundary} from "./ErrorBoundary";
import {useLogError} from "../../redux/slices/status/hooks";
import {Config} from "../../utilities/config";

export const ErrorBoundaryContainer: React.FC<{children?: React.ReactNode}> = (props) => {
    const logError = useLogError();

    const onError = useCallback((error: Error) => {
        if (Config.ERRORS_TO_SKIP.includes(error.name) || Config.ERRORS_TO_SKIP.includes(error.message)) {
            return;
        }

        logError(error);
    }, [logError]);

    useEffect(() => {
        const onErrorEvent = (error: ErrorEvent) => {
            onError(error.error);
        };

        const onPromiseRejectionEvent = (error: PromiseRejectionEvent) => {
            onError(error.reason);
        };

        window.addEventListener("error", onErrorEvent);
        window.addEventListener("unhandledrejection", onPromiseRejectionEvent);

        return () => {
            window.removeEventListener("error", onErrorEvent);
            window.removeEventListener("unhandledrejection", onPromiseRejectionEvent);
        };
    }, [onError]);

    return (
        <ErrorBoundary onError={onError}>
            {props.children}
        </ErrorBoundary>
    );
};
