import React from "react";
import {ToestelInterventie} from "../../_generated/field-service-be-openapi";
import {useToestel} from "../../redux/slices/toestellen/hooks";
import {InterventieOmschrijving} from "../../pages/ServiceAdres/Opdracht/InterventieOmschrijving";
import {useTranslation} from "../../utilities/i18nUtils";

export interface DagSamenvattingInterventieRowProps {
    toestelId: string;
    interventie: ToestelInterventie;
}

export const DagSamenvattingInterventieRow: React.FC<DagSamenvattingInterventieRowProps> = (props) => {
    const {toestelId, interventie} = props;

    const toestel = useToestel(toestelId);

    const {t} = useTranslation("planning");

    return (
        <tr>
            <td>{toestel.nr}</td>
            <td>
                <InterventieOmschrijving taakType={interventie.taakType} omschrijving={interventie.omschrijving}/>
            </td>
            <td>
                {!!interventie.meeTeGevenArtikels?.length ?
                    <span>{interventie.meeTeGevenArtikels.length} artikel(en)</span> :
                    <span className="text-muted">{t("PlanningOverzichtDagSamenvatting.geen", "Geen")}</span>}
            </td>
        </tr>
    );
};
