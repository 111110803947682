import React, {useEffect, useState} from "react";
import {LoadingPage} from "./LoadingPage";
import {MDBBtn} from "../mdb-react-ui-kit";
import {useTranslation} from "../utilities/i18nUtils";

export interface InloggenLoadingPageProps {

}

export const InloggenLoadingPage: React.FC<InloggenLoadingPageProps> = (props) => {
    const [showRetryButton, setShowRetryButton] = useState(false);

    useEffect(() => {
        const handle = setTimeout(() => setShowRetryButton(true), 6_000);

        return () => {
            clearTimeout(handle);
        };
    }, []);

    const onReloadClick = () => {
        window.location.reload();
    };

    const {t} = useTranslation("algemeen");

    const description = (
        <>
            <div className="display-6">
                {t("InloggenLoadingPage.inloggen", "Inloggen...")}
            </div>

            {showRetryButton && (
                <div className="mt-4">
                    <div className="mb-3">
                        <div>
                            {t("InloggenLoadingPage.het-lijkt-er-op-dat-het-inloggen-lang-duurt",
                                "Het lijkt er op dat het inloggen lang duurt.")}
                        </div>

                        <div>
                            <small className="text-muted">
                                {t("InloggenLoadingPage.het-herladen-van-de-applicatie-kan-helpen",
                                    "Het herladen van de applicatie kan helpen. Druk daarvoor op de onderstaande knop.")}
                            </small>
                        </div>
                    </div>

                    <MDBBtn color="secondary" outline onClick={onReloadClick}>
                        {t("InloggenLoadingPage.herlaad-de-applicatie", "Herlaad de applicatie")}
                    </MDBBtn>
                </div>
            )}
        </>
    );

    return (
        <LoadingPage description={description} descriptionClassName=""/>
    );
};
