import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router";
import {FrameModal} from "./modals/FrameModal";
import {MDBBtn, MDBModalBody, MDBModalHeader} from "../mdb-react-ui-kit/index";
import {Portal} from "react-portal";
import {UnregisterCallback} from "history";

export interface UnsavedChangesPromptProps {
    when: boolean;

    onOk?(): boolean;
    onCancel?(): boolean;
}

export const UnsavedChangesPrompt: React.FC<UnsavedChangesPromptProps> = (props) => {
    const {when, onOk, onCancel} = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    const unblock = useRef<UnregisterCallback>();

    useEffect(() => {
        if (when) {
            unblock.current = history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);

                return false;
            });
        } else {
            unblock.current?.();
        }

        return () => {
            unblock.current?.();
        };
    }, [history, when]);

    const onOkClick = useCallback(() => {
        const canRoute = onOk ? onOk() : true;

        if (canRoute) {
            history.block(() => {});
            history.push(currentPath);
        }
    }, [currentPath, history, onOk]);

    const onCancelClick = useCallback(() => {
        const canRoute = onCancel ? onCancel() : false;

        if (canRoute) {
            history.block(() => {});
            history.push(currentPath);
        }

        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    if (!showPrompt) {
        return null;
    }

    return (
        <Portal>
            <FrameModal onClose={onCancelClick}>
                <MDBModalHeader>
                    <div>
                        <strong>Opgelet: </strong> wijzigingen zijn niet opgeslagen
                    </div>
                </MDBModalHeader>

                <MDBModalBody className="py-1">
                    <p className="mb-0">
                        Er zijn wijzigingen aangebracht die niet opgeslagen zijn. Wil je de pagina toch verlaten?
                    </p>

                    <div className="d-flex justify-content-end align-items-center my-3">
                        <MDBBtn type="button" color="secondary" size="lg" className="ms-2" onClick={onCancelClick}>
                            Annuleren
                        </MDBBtn>
                        <MDBBtn type="button" color="danger" size="lg" className="ms-2" onClick={onOkClick}>
                            Verlaten
                        </MDBBtn>
                    </div>
                </MDBModalBody>
            </FrameModal>
        </Portal>
    );
}
