import {MDBListGroup, MDBListGroupItem} from "mdb-react-ui-kit";
import React, {ReactNode} from "react";
import {NietUitgevoerdWerk} from "../workers/shared/snapshot/uitgevoerdWerkChecker";
import {Config} from "../utilities/config";
import {BezoekSessieAccessoireLevering, BezoekSessieInstallatie} from "../workers/shared/snapshot/bezoekSessieState";
import {useHistory} from "react-router-dom";
import {UitTeVoerenWerkType} from "../workers/shared/snapshot/uitTeVoerenWerkType";

export interface NietUitgevoerdWerkListProps {
    bezoekSessieId: string;
    nietUitgevoerdWerk: NietUitgevoerdWerk[];
    onClose?(): void;
}

export const NietUitgevoerdWerkList: React.FC<NietUitgevoerdWerkListProps> = (props) => {
    const {bezoekSessieId, nietUitgevoerdWerk, onClose} = props;

    const history = useHistory();

    const renderNietUitgevoerdWerk = (item: NietUitgevoerdWerk) => {
        let extraInfo: ReactNode | undefined = undefined;
        let link: string | undefined = undefined;

        switch (item.type) {
            case UitTeVoerenWerkType.WATERMETER_STAND_OPNAME: {
                link = `/bezoek/taken/${bezoekSessieId}/toestel/${item.toestel?.informatie?.id}`;
                extraInfo = (<span>van toestel {item.toestel?.informatie?.omschrijving}</span>);
                break;
            }
            case UitTeVoerenWerkType.TOESTEL_ONDERHOUD: {
                link = `/bezoek/taken/${bezoekSessieId}/toestel/${item.toestel?.informatie?.id}`;
                extraInfo = (<span>van toestel {item.toestel?.informatie?.omschrijving}</span>);
                break;
            }
            case UitTeVoerenWerkType.TOESTEL_INTERVENTIE: {
                link = `/bezoek/taken/${bezoekSessieId}/toestel/${item.toestel?.informatie?.id}`;
                extraInfo = (<span>van toestel {item.toestel?.informatie?.omschrijving}</span>);
                break;
            }
            case UitTeVoerenWerkType.INSTALLATIE: {
                const installatie = item.werk as BezoekSessieInstallatie;
                link = `/bezoek/taken/${bezoekSessieId}/opdracht/installatie/${installatie.id}`;
                extraInfo = (<span>van {installatie.uitTeVoerenWerk?.teInstallerenArtikelOmschrijving}</span>);
                break;
            }
            case UitTeVoerenWerkType.ACCESSOIRE_LEVERING: {
                const accessoireLevering = item.werk as BezoekSessieAccessoireLevering;
                link = `/bezoek/taken/${bezoekSessieId}/opdracht/accessoire-levering`;
                extraInfo = (<span>van {accessoireLevering.uitTeVoerenWerk?.artikelOmschrijving}</span>);
                break;
            }
            case UitTeVoerenWerkType.CO2_LEVERING: {
                link = `/bezoek/taken/${bezoekSessieId}/opdracht/co2-levering`;
                break;
            }
            case UitTeVoerenWerkType.HANDTEKENING: {
                link = `/bezoek/taken/${bezoekSessieId}/handtekening`;
            }
        }

        const onClick = () => {
            if (link) {
                history.push(link);
                onClose?.();
            }
        };

        return (
            <MDBListGroupItem action={!!link} onClick={onClick} className="cursor-pointer">
                {Config.labels[item.type]} {extraInfo}
            </MDBListGroupItem>
        );
    };

    return (
        <MDBListGroup style={{maxHeight: "14.5em", overflowY: "auto"}}>
            {nietUitgevoerdWerk.map(item => renderNietUitgevoerdWerk(item))}
        </MDBListGroup>
    );
};
