import {createSlice} from "@reduxjs/toolkit";
import {ModalStackItem, ModalState} from "./types";
import {GenericCaseReducer} from "../../types";

export interface ModalCaseReducers {
    showModal: GenericCaseReducer<ModalState, ModalStackItem<any>>;
    hideModal: GenericCaseReducer<ModalState, undefined>;
    [k: string]: GenericCaseReducer<ModalState, any>;
}

export const modal = createSlice<ModalState, ModalCaseReducers>({
    name: "modal",
    initialState: {
        modalStack: []
    },
    reducers: {
        showModal(state, action) {
            state.modalStack.push(action.payload);
        },
        hideModal(state) {
            state.modalStack.pop();
        }
    }
});
