import {Config} from "./config";

export class GoogleMapsUtils {

    public static getGeoLink(lat: number, lng: number) {
        return `geo:${lat},${lng}?q=${lat},${lng}`;
    }

    /**
     * @see https://developers.google.com/maps/documentation/maps-static/start#location
     */
    public static getStaticImage(options: {
        center: string;
        zoom: number;
        width: number;
        height: number;
        maptype: "roadtype" | "satellite" | "hybrid" | "terrain",
        language?: string;
        markers?: {size?: string; color: string; label?: string; latitude: number; longitude: number; icon?: string}[],
        scale?: 1 | 2
    }) {
        const {center, zoom, width, height, maptype, markers, scale = 1} = options;

        const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";

        const params = new URLSearchParams({
            center,
            zoom: String(zoom),
            size: `${width}x${height}`,
            maptype,
            key: Config.GOOGLE_MAPS_KEY,
            scale: String(scale)
        });

        for (const marker of markers || []) {
            let markerDescription = `color:${marker.color}`;

            if (marker.icon) {
                markerDescription += `|icon:${marker.icon}`;
            }

            markerDescription += `|${marker.latitude},${marker.longitude}`;

            params.append("markers", markerDescription);
        }

        return `${baseUrl}?${params.toString()}`;
    }

}
