import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, put, select} from "redux-saga/effects";
import {
    AccessoireLeveringUitgevoerd,
    AdresBelemmerdRedenEnum,
    CheckOutVanAdresRedenNietAfgewerktEnum,
    Co2Geleverd,
    GpsLocatieGelogdOorsprongEnum,
    HandtekeningGezet,
    InstallatieTijdIngegeven,
    InstallatieUitgevoerd,
    InterneFeedbackToegevoegd,
    InterventieTijdIngegeven
} from "../../../_generated/field-service-be-openapi";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {PayloadAction} from "@reduxjs/toolkit";
import {selectMe} from "../technicus/slice";
import {EventMessagePayload} from "../../../workers/shared/channels/eventMessage";
import {RootState} from "../../store";
import {ReedsIngechecktOpServiceAdresError} from "./errors";
import {EventPayloadAction} from "../../types";
import {downloadFile, FileDownload, saveFile} from "../../../utilities/downloadUtils";
import {serviceAdressen} from "./slice";
import {Config} from "../../../utilities/config";
import {GpsUtils} from "../../../utilities/gpsUtils";
import {Logger} from "../../../utilities/logger";
import {downloads} from "../download/slice";
import {statusSaga} from "../status/saga";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";

const logger = Logger.create("ServiceAdressenSaga");

export const serviceAdressenSaga = createSliceSaga({
    name: "serviceAdressenSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * onderweg(action: PayloadAction<{ bezoekSessieId: string; callback?: (error?: Error) => void }>) {
            const {bezoekSessieId, callback} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;
            const isOnderwegOfAanwezig = yield select((state: RootState) => {
                for (const bezoekSessie of Object.values(state.bezoeksessies.bezoekSessies)) {
                    if (bezoekSessie.aanwezig || bezoekSessie.onderweg) {
                        return true;
                    }
                }

                return false;
            });

            if (isOnderwegOfAanwezig) {
                if (callback) {
                    yield call(callback, new ReedsIngechecktOpServiceAdresError());
                }

                return;
            }

            GpsUtils.getLocation()
                .then((location) => {
                    logger.debug("Got location (Onderweg)", location);

                    browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createBrowserBezoekSessieEventMessage({
                        event: {
                            _type: "GPS_LOCATIE_GELOGD",
                            latitude: location.coords.latitude,
                            longitude: location.coords.longitude,
                            accuracy: location.coords.accuracy,
                            oorsprong: GpsLocatieGelogdOorsprongEnum.Onderweg,
                            technicusId
                        },
                        bezoekSessieId,
                        technicusId
                    }));
                })
                .catch(error => logger.error("An error occurred getting current location (Onderweg)", error));

            const payload: EventMessagePayload = {
                event: {
                    _type: "ONDERWEG_NAAR_ADRES",
                    technicusId
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));

            if (callback) {
                yield call(callback);
            }
        },
        * checkIn(action: PayloadAction<{ bezoekSessieId: string; callback?: (error?: Error) => void }>) {
            const {bezoekSessieId, callback} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;
            const isOnderwegOfAanwezig = yield select((state: RootState) => {
                for (const bezoekSessie of Object.values(state.bezoeksessies.bezoekSessies)) {
                    if (bezoekSessie.id !== bezoekSessieId && (bezoekSessie.aanwezig || bezoekSessie.onderweg)) {
                        return true;
                    }
                }

                return false;
            });

            if (isOnderwegOfAanwezig) {
                if (callback) {
                    yield call(callback, new ReedsIngechecktOpServiceAdresError());
                }

                return;
            }

            GpsUtils.getLocation()
                .then((location) => {
                    logger.debug("Got location (CheckIn)", location);

                    browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createBrowserBezoekSessieEventMessage({
                        event: {
                            _type: "GPS_LOCATIE_GELOGD",
                            latitude: location.coords.latitude,
                            longitude: location.coords.longitude,
                            accuracy: location.coords.accuracy,
                            oorsprong: GpsLocatieGelogdOorsprongEnum.CheckIn,
                            technicusId
                        },
                        bezoekSessieId,
                        technicusId
                    }));
                })
                .catch(error => logger.error("An error occurred getting current location (CheckIn)", error));

            const payload: EventMessagePayload = {
                event: {
                    _type: "CHECK_IN_OP_ADRES",
                    technicusId
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));

            if (callback) {
                yield call(callback);
            }
        },
        * checkOut(action: PayloadAction<{ bezoekSessieId: string, redenNietAfgewerkt?: CheckOutVanAdresRedenNietAfgewerktEnum, toelichting?: string }>) {
            const {bezoekSessieId, redenNietAfgewerkt, toelichting} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            GpsUtils.getLocation()
                .then((location) => {
                    logger.debug("Got location (CheckOut)", location);

                    browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createBrowserBezoekSessieEventMessage({
                        event: {
                            _type: "GPS_LOCATIE_GELOGD",
                            latitude: location.coords.latitude,
                            longitude: location.coords.longitude,
                            accuracy: location.coords.accuracy,
                            oorsprong: GpsLocatieGelogdOorsprongEnum.CheckOut,
                            technicusId
                        },
                        bezoekSessieId,
                        technicusId
                    }));
                })
                .catch(error => logger.error("An error occurred getting current location (CheckOut)", error));

            const payload: EventMessagePayload = {
                event: {
                    _type: "CHECK_OUT_VAN_ADRES",
                    technicusId,
                    redenNietAfgewerkt,
                    toelichting
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * adresBelemmerd(action: PayloadAction<{ bezoekSessieId: string, reden?: AdresBelemmerdRedenEnum, toelichting?: string }>) {
            const {bezoekSessieId, reden, toelichting} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "ADRES_BELEMMERD",
                    technicusId,
                    reden,
                    toelichting
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * co2Levering(action: PayloadAction<Co2Geleverd & { bezoekSessieId: string }>) {
            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "CO2_GELEVERD",
                    leveringen: action.payload.leveringen,
                    technicusId: action.payload.technicusId,
                    toestemmingKlantAfwijkendeBestelling: action.payload.toestemmingKlantAfwijkendeBestelling
                },
                bezoekSessieId: action.payload.bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * levering(action: EventPayloadAction<AccessoireLeveringUitgevoerd>) {
            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "ACCESSOIRE_LEVERING_UITGEVOERD",
                    accessoireLeveringen: action.payload.accessoireLeveringen,
                    technicusId
                },
                bezoekSessieId: action.payload.bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * handtekeningGezet(action: EventPayloadAction<HandtekeningGezet>) {
            const {
                bezoekSessieId,
                handtekening,
                opmerkingKlant,
                extraEmailAdressen,
                predefinedEmailAdressen,
                ondertekenaar
            } = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "HANDTEKENING_GEZET",
                    handtekening,
                    ondertekenaar,
                    technicusId,
                    opmerkingKlant,
                    extraEmailAdressen,
                    predefinedEmailAdressen
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * interneFeedbackToegevoegd(action: PayloadAction<Partial<InterneFeedbackToegevoegd> & { bezoekSessieId: string }>) {
            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "INTERNE_FEEDBACK_TOEGEVOEGD",
                    opmerking: action.payload.opmerking,
                    technicusId
                },
                bezoekSessieId: action.payload.bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * installatieUitgevoerd(action: EventPayloadAction<InstallatieUitgevoerd>) {
            const {
                bezoekSessieId,
                opmerking,
                installatieId,
                geinstalleerdeComponenten,
                verbruikteArtikelen,
                fotos,
                resultaat,
                reden,
                nietGeinstalleerdReden,
                co2Artikelen,
                locatie,
                gebouw,
                verdieping,
                lokaalNummer,
            } = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "INSTALLATIE_UITGEVOERD",
                    technicusId,
                    installatieId,
                    fotos,
                    resultaat,
                    geinstalleerdeComponenten,
                    verbruikteArtikelen,
                    opmerking,
                    reden,
                    nietGeinstalleerdReden,
                    co2Artikelen,
                    locatie,
                    gebouw,
                    verdieping,
                    lokaalNummer,
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * installatieTijdIngegeven(action: EventPayloadAction<InstallatieTijdIngegeven>) {
            const {bezoekSessieId, installatieId, vanTijd, totTijd} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "INSTALLATIE_TIJD_INGEGEVEN",
                    technicusId,
                    installatieId,
                    vanTijd,
                    totTijd
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * interventieTijdIngegeven(action: EventPayloadAction<InterventieTijdIngegeven>) {
            const {bezoekSessieId, toestelId, interventieId, vanTijd, totTijd} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "INTERVENTIE_TIJD_INGEGEVEN",
                    technicusId,
                    toestelId,
                    interventieId,
                    vanTijd,
                    totTijd
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * downloadServiceRapport(action: PayloadAction<{ serviceAdresId: string; serviceRapportId: string; onSuccess?(): void }>) {
            const {serviceAdresId, serviceRapportId, onSuccess} = action.payload;

            const requestKey = `downloadServiceRapport:${serviceRapportId}`;
            const uri = Config.BASE_URL + `/api/serviceadres/${serviceAdresId}/servicerapport/${serviceRapportId}/pdf`;
            yield put(downloads.actions.pending({requestKey: uri}));

            try {
                const result = (yield call(downloadFile, uri, "Servicerapport")) as unknown as FileDownload;

                yield saveFile(result);

                yield put(downloads.actions.succeeded({requestKey, resources: [serviceRapportId]}));

                if (onSuccess) {
                    yield call(onSuccess);
                }
            } catch (error) {
                yield put(downloads.actions.failed({requestKey, requestProperties: {error}}));
                yield put(statusSaga.actions.logError({error}));
            }
        },
        * downloadBijlage(action: PayloadAction<{ serviceAdresId: string; bijlageId: string; onSuccess?(): void }>) {
            const {serviceAdresId, bijlageId, onSuccess} = action.payload;

            const requestKey = `downloadBijlage:${bijlageId}`;
            const uri = Config.BASE_URL + `/api/serviceadres/${serviceAdresId}/bijlage/${bijlageId}`;
            yield put(serviceAdressen.actions.pending({requestKey: uri}));

            try {
                const result = (yield call(downloadFile, uri, "Serviceadresbijlage")) as unknown as FileDownload;

                yield saveFile(result);

                yield put(serviceAdressen.actions.succeeded({requestKey, resources: [bijlageId]}));

                if (onSuccess) {
                    yield call(onSuccess);
                }
            } catch (error) {
                yield put(serviceAdressen.actions.failed({requestKey, requestProperties: {error}}));
                yield put(statusSaga.actions.logError({error}));
            }
        }
    }
});
