import React from "react";
import {
    GeplandWerkAccessoireBestellingTaakCard,
} from "./ServiceAdres/GeplandWerk/GeplandWerkAccessoireBestellingTaakCard";
import {GeplandWerkCo2BestellingTaak} from "./ServiceAdres/GeplandWerk/GeplandWerkCo2BestellingTaak";
import {GeplandWerkInstallatieTaakCard} from "./ServiceAdres/GeplandWerk/GeplandWerkInstallatieTaakCard";
import {GeplandWerkOnderhoudTaakCard} from "./ServiceAdres/GeplandWerk/GeplandWerkOnderhoudTaakCard";
import {GeplandWerkInterventieTaakCard} from "./ServiceAdres/GeplandWerk/GeplandWerkInterventieTaakCard";
import {ObjectUtils} from "../utilities/objectUtils";
import {ServiceAdres, UitTeVoerenWerk} from "../_generated/field-service-be-openapi";
import {GeplandWerkInstallatieCo2Card} from "./ServiceAdres/GeplandWerk/GeplandWerkInstallatieCo2Card";
import {useTranslation} from "../utilities/i18nUtils";

export interface UitTeVoerenWerkBezoekProps {
    serviceAdres?: ServiceAdres;
    uitTeVoerenWerk?: UitTeVoerenWerk;
}

export const UitTeVoerenWerkBezoek: React.FC<UitTeVoerenWerkBezoekProps> = (props) => {
    const {serviceAdres, uitTeVoerenWerk} = props;

    const {t} = useTranslation("planning");

    if (!serviceAdres || !uitTeVoerenWerk) {
        return null;
    }

    const heeftWerkAanToestellen = uitTeVoerenWerk?.toestellen?.some(item => Boolean(item.onderhoud) || Boolean(item.interventies?.length));
    const heeftWerk = heeftWerkAanToestellen || Boolean(uitTeVoerenWerk?.co2Leveringen?.length) ||
        Boolean(uitTeVoerenWerk?.accessoireLeveringen?.length) || Boolean(uitTeVoerenWerk?.installaties?.length);

    const leveringenGroupedById = ObjectUtils.groupBy(uitTeVoerenWerk.accessoireLeveringen, item => item.id);

    return (
        <>
            {!heeftWerk && (
                <p>{t("UitTeVoerenWerkBezoek.er-is-geen-gepland-werk-voor-dit-bezoek", "Er is geen gepland werk voor dit bezoek.")}</p>
            )}

            {uitTeVoerenWerk?.co2Leveringen && (
                <GeplandWerkCo2BestellingTaak co2Bestellingen={uitTeVoerenWerk.co2Leveringen}
                                              co2Artikelen={serviceAdres?.co2Artikelen || []}/>
            )}
            {uitTeVoerenWerk?.installaties && (
                <GeplandWerkInstallatieCo2Card co2VoorInstallaties={uitTeVoerenWerk.installaties}
                                               co2Artikelen={serviceAdres?.co2Artikelen || []}/>
            )}
            {Object.values(leveringenGroupedById).map(leveringen => (
                <GeplandWerkAccessoireBestellingTaakCard leveringen={leveringen || []}/>
            ))}
            {uitTeVoerenWerk?.installaties?.map((item) => <GeplandWerkInstallatieTaakCard installatie={item}/>)}

            {uitTeVoerenWerk?.toestellen?.map((toestel) => (
                <>
                    {toestel.onderhoud &&
                        <GeplandWerkOnderhoudTaakCard toestelId={toestel.toestelId} onderhoud={toestel.onderhoud}/>}
                    {toestel.interventies?.map((item) => <GeplandWerkInterventieTaakCard
                        toestelId={toestel.toestelId} interventie={item}/>)}
                </>
            ))}
        </>
    );
};
