import React from "react";
import {BezoekSessieStatus} from "../../../../workers/shared/snapshot/bezoekSessieState";
import {MDBCardDisabledOverlay} from "../../../../components/mdb/MDBCardDisabledOverlay";
import {NavLink} from "react-router-dom";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBRipple,
    MDBRow
} from "../../../../mdb-react-ui-kit/index";
import {MDBCardStatus, MDBCardStatusEnum} from "../../../../components/mdb/MDBCardStatus";
import Icon from "@mdi/react";
import {mdiAlarmLightOutline, mdiChevronRight} from "@mdi/js";
import {ToestelInterventieTaakTypeEnum} from "../../../../_generated/field-service-be-openapi";
import {InterventieOmschrijving} from "../../Opdracht/InterventieOmschrijving";
import {useTranslation} from "../../../../utilities/i18nUtils";

export interface ToestelInterventieCardProps {
    bezoekSessieId: string;
    toestelId: string;
    interventieId: string;

    omschrijving: string;
    taakType?: ToestelInterventieTaakTypeEnum;

    uitgevoerd: boolean;
    toestelIngecheckt: boolean;
    bezoekSessieStatus: BezoekSessieStatus;
}

export const ToestelInterventieCard: React.FC<ToestelInterventieCardProps> = (props) => {
    const {
        bezoekSessieId,
        toestelId,
        interventieId,
        omschrijving,
        taakType,
        uitgevoerd,
        toestelIngecheckt,
        bezoekSessieStatus
    } = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBCard background="light" className="mt-3 mb-4 shadow-sm">
            {(!toestelIngecheckt && bezoekSessieStatus !== BezoekSessieStatus.GESLOTEN) &&
                <MDBCardDisabledOverlay text={t("gelieve-het-toestel-eerst-in-te-checken",
                    "Gelieve het toestel eerst in te checken")}/>}

            <NavLink
                to={`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}/opdracht/interventie/${interventieId}`}>
                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    <MDBCardStatus status={uitgevoerd ? MDBCardStatusEnum.DONE : undefined}/>

                    <MDBCardHeader>
                                        <span className="d-flex align-items-center">
                                            <Icon path={mdiAlarmLightOutline} size={1} className="me-1"/>
                                            {t("algemeen:Titels.interventie", "Interventie")}
                                        </span>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow>
                                <MDBCol>
                                    <MDBRow>
                                        <MDBCol sm="6" className="mb-2">
                                            <strong>{t("ToestelInterventieCard.omschrijving-van-de-interventie",
                                                "Omschrijving van de interventie:")}</strong><br/>
                                            <InterventieOmschrijving taakType={taakType} omschrijving={omschrijving}/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol size="auto"
                                        className="d-flex align-items-center justify-content-end">
                                    <Icon path={mdiChevronRight} size={2}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBRipple>
            </NavLink>
        </MDBCard>
    );
};
