import React from "react";
import {LoadingSkeleton} from "../LoadingSkeleton";

export interface PlanningLoadingProps {

}

export const PlanningLoading: React.FC<PlanningLoadingProps> = React.memo((props) => {
    return (
        <>
            <LoadingSkeleton height={16} width="30%" className="mb-2"/>
            <LoadingSkeleton height={16} width="60%" className="mb-2"/>
            <LoadingSkeleton height={16} width="80%" className="mb-3"/>
            <LoadingSkeleton height={100} className="mb-3"/>
            <LoadingSkeleton height={100} className="mb-3"/>
            <LoadingSkeleton height={100} className="mb-3"/>
        </>
    );
});
