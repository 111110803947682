/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BezoekSessie,
    BezoekSessieEventMessage,
    BezoekSessieInsertEventResponse,
} from '../models';

export interface GetActieveBezoekSessiesRequest {
    datum?: string;
}

export interface GetActieveBezoekSessiesForTechnicusRequest {
    technicusId: string;
}

export interface GetEventsRequest {
    bezoekSessieId: string;
}

export interface HerlaadBezoekSessieUitTeVoerenWerkCommandRequest {
    bezoekSessieId: string;
    instanceId: string;
}

export interface InsertFrontEndEventCommandRequest {
    bezoekSessieEventMessage: BezoekSessieEventMessage;
}

export interface SluitBezoekSessieCommandRequest {
    bezoekSessieId: string;
    instanceId: string;
}

/**
 * 
 */
export class BezoekSessieApi extends runtime.BaseAPI {

    /**
     */
    async getActieveBezoekSessiesRaw(requestParameters: GetActieveBezoekSessiesRequest): Promise<runtime.ApiResponse<Array<BezoekSessie>>> {
        const queryParameters: any = {};

        if (requestParameters.datum !== undefined) {
            queryParameters['datum'] = (requestParameters.datum as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bezoeksessie/actief`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getActieveBezoekSessies(requestParameters: GetActieveBezoekSessiesRequest): Promise<Array<BezoekSessie>> {
        const response = await this.getActieveBezoekSessiesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getActieveBezoekSessiesForTechnicusRaw(requestParameters: GetActieveBezoekSessiesForTechnicusRequest): Promise<runtime.ApiResponse<Array<BezoekSessie>>> {
        if (requestParameters.technicusId === null || requestParameters.technicusId === undefined) {
            throw new runtime.RequiredError('technicusId','Required parameter requestParameters.technicusId was null or undefined when calling getActieveBezoekSessiesForTechnicus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bezoeksessie/{technicusId}/actief`.replace(`{${"technicusId"}}`, encodeURIComponent(String(requestParameters.technicusId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getActieveBezoekSessiesForTechnicus(requestParameters: GetActieveBezoekSessiesForTechnicusRequest): Promise<Array<BezoekSessie>> {
        const response = await this.getActieveBezoekSessiesForTechnicusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEventsRaw(requestParameters: GetEventsRequest): Promise<runtime.ApiResponse<Array<BezoekSessieEventMessage>>> {
        if (requestParameters.bezoekSessieId === null || requestParameters.bezoekSessieId === undefined) {
            throw new runtime.RequiredError('bezoekSessieId','Required parameter requestParameters.bezoekSessieId was null or undefined when calling getEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.bezoekSessieId !== undefined) {
            queryParameters['bezoekSessieId'] = requestParameters.bezoekSessieId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bezoeksessie/event`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getEvents(requestParameters: GetEventsRequest): Promise<Array<BezoekSessieEventMessage>> {
        const response = await this.getEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async herlaadBezoekSessieUitTeVoerenWerkCommandRaw(requestParameters: HerlaadBezoekSessieUitTeVoerenWerkCommandRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bezoekSessieId === null || requestParameters.bezoekSessieId === undefined) {
            throw new runtime.RequiredError('bezoekSessieId','Required parameter requestParameters.bezoekSessieId was null or undefined when calling herlaadBezoekSessieUitTeVoerenWerkCommand.');
        }

        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling herlaadBezoekSessieUitTeVoerenWerkCommand.');
        }

        const queryParameters: any = {};

        if (requestParameters.instanceId !== undefined) {
            queryParameters['instanceId'] = requestParameters.instanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bezoeksessie/{bezoekSessieId}/herlaad`.replace(`{${"bezoekSessieId"}}`, encodeURIComponent(String(requestParameters.bezoekSessieId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async herlaadBezoekSessieUitTeVoerenWerkCommand(requestParameters: HerlaadBezoekSessieUitTeVoerenWerkCommandRequest): Promise<void> {
        await this.herlaadBezoekSessieUitTeVoerenWerkCommandRaw(requestParameters);
    }

    /**
     */
    async insertFrontEndEventCommandRaw(requestParameters: InsertFrontEndEventCommandRequest): Promise<runtime.ApiResponse<BezoekSessieInsertEventResponse>> {
        if (requestParameters.bezoekSessieEventMessage === null || requestParameters.bezoekSessieEventMessage === undefined) {
            throw new runtime.RequiredError('bezoekSessieEventMessage','Required parameter requestParameters.bezoekSessieEventMessage was null or undefined when calling insertFrontEndEventCommand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bezoeksessie/event`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.bezoekSessieEventMessage,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async insertFrontEndEventCommand(requestParameters: InsertFrontEndEventCommandRequest): Promise<BezoekSessieInsertEventResponse> {
        const response = await this.insertFrontEndEventCommandRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sluitBezoekSessieCommandRaw(requestParameters: SluitBezoekSessieCommandRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bezoekSessieId === null || requestParameters.bezoekSessieId === undefined) {
            throw new runtime.RequiredError('bezoekSessieId','Required parameter requestParameters.bezoekSessieId was null or undefined when calling sluitBezoekSessieCommand.');
        }

        if (requestParameters.instanceId === null || requestParameters.instanceId === undefined) {
            throw new runtime.RequiredError('instanceId','Required parameter requestParameters.instanceId was null or undefined when calling sluitBezoekSessieCommand.');
        }

        const queryParameters: any = {};

        if (requestParameters.instanceId !== undefined) {
            queryParameters['instanceId'] = requestParameters.instanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bezoeksessie/{bezoekSessieId}/sluit`.replace(`{${"bezoekSessieId"}}`, encodeURIComponent(String(requestParameters.bezoekSessieId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sluitBezoekSessieCommand(requestParameters: SluitBezoekSessieCommandRequest): Promise<void> {
        await this.sluitBezoekSessieCommandRaw(requestParameters);
    }

}
