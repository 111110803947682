import React from "react";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";
import {MDBFormikInput, MDBFormikInputProps} from "../MDBFormikInput";

export const BezoekMDBFormikInput: React.FC<MDBFormikInputProps> = (props) => {
    const {bezoekSessieId} = useParams<{ bezoekSessieId?: string }>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    return (
        <MDBFormikInput {...props} disabled={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN || props.disabled}/>
    );
};
