import React from "react";
import {MDBSelect} from "mdb-react-ui-kit";
import {Field, FieldProps} from "formik";
import {SelectData, SelectV2Props} from "mdb-react-ui-kit/pro/forms/SelectV2/types";
import {SaveDraftUtils} from "../utilities/form/saveDraftUtils";

export type MDBFormikSelectProps = SelectV2Props & Partial<FieldProps> & {onChange?: (value: any) => any};

/**
 * Inspired by https://github.com/jannikbuschke/formik-antd/blob/master/src/input/index.tsx
 * @param props
 * @constructor
 */
export const MDBFormikSelect: React.FC<MDBFormikSelectProps> = (props) => {
    const {name, data, ...rest} = props;

    const getValidation = (error?: string) => {
        if (error) {
            return error;
        }

        return "";
    };

    return (
        <Field name={name}>
            {({field: {value, onChange, onBlur}, meta: {error}, form}: FieldProps) => {
                return (
                    <MDBSelect
                        {...rest}

                        preventFirstSelection
                        autoComplete="false"

                        name={name}
                        value={value}

                        data={data}
                        onBlur={(e: any) => SaveDraftUtils.onBlur(name!, form, () => onBlur(e))}

                        invalidFeedback={getValidation(error)}
                        validation={!!error}

                        className={`${rest.className || ""} ${!!error ? "is-invalid" : ""}`}
                        onChange={(selectedValue: SelectData | SelectData[]) => {
                            if (selectedValue) {
                                const value = Array.isArray(selectedValue) ? selectedValue.map(item => item.value) : selectedValue.value;

                                onChange({target: {name, value}});
                                props.onChange?.(value);
                            }
                        }}
                    />
                );
            }}
        </Field>
    );
};
