import React from "react";
import {useParams} from "react-router-dom";
import {OnderhoudPage} from "./OnderhoudPage";

export const ToestelOpdrachtPage: React.FC = () => {
    const {opdrachtId} = useParams<any>();

    if (opdrachtId === "onderhoud") {
        return <OnderhoudPage/>;
    }

    return null;
};
