import React, {MouseEvent} from "react";
import {MDBNavbarLink, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsPane} from "mdb-react-ui-kit";
import {Header} from "../../components/Header";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {GenericPage} from "../../components/GenericPage";
import {Adres} from "../../components/Adres";
import {ServiceAdresTakenTab} from "./ServiceAdresTakenTab";
import {ServiceAdresToestellenTab} from "./ServiceAdresToestellenTab";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {BezoekSessieGeslotenMelding} from "../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ServiceAdresTakenPageProps {
    bezoekSessieId: string;
}

export const ServiceAdresTakenPage: React.FC = () => {
    const {t} = useTranslation("toestel");

    const {bezoekSessieId} = useParams<ServiceAdresTakenPageProps>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    const location = useLocation();
    const history = useHistory();

    if (!bezoekSessie) {
        return null;
    }

    const activeTab = location.hash === "#toestellen" ? "toestellen" : "taken";

    const onToestellenClick = (event: MouseEvent<any>) => {
        event.preventDefault();
        history.replace({hash: "#toestellen"});
    };

    const onTakenClick = (event: MouseEvent<any>) => {
        event.preventDefault();
        history.replace({hash: "#taken"});
    };

    return (
        <GenericPage>
            <Header title={bezoekSessie.serviceAdres?.naam} subtitle={<Adres adres={bezoekSessie.serviceAdres}/>}/>

            <div className="flex-fill bg-white" style={{paddingBottom: "4rem"}}>
                <MDBTabs fill justify className="shadow-sm">
                    <MDBTabsItem>
                        <MDBNavbarLink active={activeTab === "taken"} href="#taken"
                                       onClick={onTakenClick}>{t("Labels.taken", "Taken")}</MDBNavbarLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBNavbarLink active={activeTab === "toestellen"} href="#toestellen"
                                       onClick={onToestellenClick}>{t("Labels.toestellen", "Toestellen")}</MDBNavbarLink>
                    </MDBTabsItem>
                </MDBTabs>

                <div className="container-lg pt-4">
                    <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                    <MDBTabsContent>
                        <MDBTabsPane open={activeTab === "taken"}>
                            <ServiceAdresTakenTab bezoekSessie={bezoekSessie}/>
                        </MDBTabsPane>

                        <MDBTabsPane open={activeTab === "toestellen"}>
                            <ServiceAdresToestellenTab bezoekSessie={bezoekSessie}/>
                        </MDBTabsPane>
                    </MDBTabsContent>
                </div>
            </div>
        </GenericPage>
    );
};
