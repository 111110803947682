import React from "react";
import {NavLink} from "react-router-dom";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBRipple,
    MDBRow
} from "../../../../mdb-react-ui-kit/index";
import {MDBCardStatus, MDBCardStatusEnum} from "../../../../components/mdb/MDBCardStatus";
import Icon from "@mdi/react";
import {mdiChevronRight, mdiQrcodeScan} from "@mdi/js";
import {useTranslation} from "../../../../utilities/i18nUtils";

export interface ToestelIncheckenCardProps {
    bezoekSessieId: string;
    toestelId: string;
    toestelIngecheckt: boolean;
}

export const ToestelIncheckenCard: React.FC<ToestelIncheckenCardProps> = (props) => {
    const {bezoekSessieId, toestelId, toestelIngecheckt} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBCard background="light" className="mt-3 mb-4 shadow-sm">
            <NavLink
                to={`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}/checkin`}>
                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    {toestelIngecheckt && <MDBCardStatus status={MDBCardStatusEnum.DONE}/>}
                    <MDBCardHeader>
                                <span className="d-flex align-items-center">
                                    <Icon path={mdiQrcodeScan} size={1} className="me-1"/>
                                    {t("ToestelIncheckenCard.toestel-inchecken", "Toestel inchecken")}
                                </span>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow>
                                <MDBCol>
                                    {toestelIngecheckt &&
                                        t("ToestelIncheckenCard.toestel-gevalideerd-met-behulp-van-het-serienummer",
                                            "Toestel gevalideerd met behulp van het serienummer")}
                                </MDBCol>

                                <MDBCol size="auto"
                                        className="d-flex align-items-center justify-content-end">
                                    <Icon path={mdiChevronRight} size={2}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBRipple>
            </NavLink>
        </MDBCard>
    );
};
