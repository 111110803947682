import {createSlice, PayloadAction, SliceCaseReducers} from "@reduxjs/toolkit";
import {StatusState} from "./types";
import {NetworkStatus} from "../status/types";
import {ErrorRecord} from "../../../utilities/db/repository/errorRepository";

export const status = createSlice<StatusState, SliceCaseReducers<StatusState>>({
    name: "status",
    initialState: {
        browserNetworkStatus: NetworkStatus.ONLINE,
        serviceWorkerNetworkStatus: NetworkStatus.ONLINE,
        webSocketStatus: NetworkStatus.ONLINE,

        localEventsAmount: 0,
        localFilesAmount: 0,

        syncingActieveBezoekSessies: false,
        syncingPlanning: false,
        syncingArtikelen: false,
        syncingArtikelAfbeeldingen: false,
        syncingEvents: false,

        cacheItems: {
            klanten: 0,
            artikelen: 0,
            bestanden: 0,
            dagPlanning: 0,
            dagPlanningEntries: 0,
            localEvents: 0,
            serverEvents: 0,
            serviceAdressen: 0,
            technici: 0,
            toestellen: 0,
            errors: 0
        },

        localEvents: [],
        errors: []
    },
    reducers: {
        setLocalEvents(state, action) {
            state.localEvents = action.payload;
        },
        setErrors(state, action: PayloadAction<ErrorRecord[]>) {
            state.errors = action.payload;
        },
        setBatteryPercentage(state, action) {
            state.batteryPercentage = action.payload;
        },
        setInstanceId(state, action) {
            state.instanceId = action.payload;
        },
        setSyncingArtikelen(state, action) {
            state.syncingArtikelen = action.payload;
        },
        setSyncingArtikelAfbeeldingen(state, action) {
            state.syncingArtikelAfbeeldingen = action.payload;
        },
        setSyncingActieveBezoekSessies(state, action) {
            state.syncingActieveBezoekSessies = action.payload;
        },
        setSyncingPlanning(state, action) {
            state.syncingPlanning = action.payload;
        },
        setSyncingEvents(state, action) {
            state.syncingEvents = action.payload;
        },
        setBrowserNetworkStatus(state, action) {
            state.browserNetworkStatus = action.payload;
        },
        setServiceWorkerNetworkStatus(state, action) {
            state.serviceWorkerNetworkStatus = action.payload;
        },
        setWebSocketStatus(state, action) {
            state.webSocketStatus = action.payload;
        },
        setServiceWorker(state, action) {
            state.serviceWorker = {
                ...state.serviceWorker,
                ...action.payload
            };
        },
        setEventsAmount(state, action: PayloadAction<{localEventsAmount: number;}>) {
            state.localEventsAmount = action.payload.localEventsAmount;
        },
        setFilesAmount(state, action: PayloadAction<{localFilesAmount: number;}>) {
            state.localFilesAmount = action.payload.localFilesAmount;
        },
        setCacheItems(state, action: PayloadAction<StatusState["cacheItems"]>) {
            state.cacheItems = action.payload;
        },
        setPlanningLastSyncTimestamp(state, action: PayloadAction<{planningLastSyncTimestamp: string;}>) {
            state.planningLastSyncTimestamp = action.payload.planningLastSyncTimestamp;
        },
        setPlanningLastVersieCheckTimestamp(state, action: PayloadAction<{planningLastVersieCheckTimestamp: string;}>) {
            state.planningLastVersieCheckTimestamp = action.payload.planningLastVersieCheckTimestamp;
        },
        setActieveBezoekSessieLastSyncTimestamp(state, action: PayloadAction<{actieveBezoekSessieLastSyncTimestamp: string;}>) {
            state.actieveBezoekSessieLastSyncTimestamp = action.payload.actieveBezoekSessieLastSyncTimestamp;
        },
        setOfflineSinceTimestamp(state, action: PayloadAction<{offlineSinceTimestamp: string;}>) {
            state.offlineSinceTimestamp = action.payload.offlineSinceTimestamp;
        },
        setPeriodicSyncLastTimestamp(state, action: PayloadAction<{timestamp: string}>) {
            state.periodicSyncLastTimestamp = action.payload.timestamp;
        }
    }
});
