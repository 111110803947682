import { useEffect } from 'react';

type useTimepickerBodyScrollProps = {
  isOpen: boolean;
  inline?: boolean;
};

export const useTimepickerBodyScroll = ({ isOpen, inline }: useTimepickerBodyScrollProps): void => {
  useEffect(() => {
    if (inline) return;

    const hasScroll = window.innerWidth > document.documentElement.clientWidth && window.innerWidth >= 576;
    const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;
    const scrollbarWidth = `${getScrollbarWidth()}px`;

    if (isOpen && hasScroll) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = scrollbarWidth;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, [isOpen, inline]);
};

export default useTimepickerBodyScroll;
