import {useAppSelector} from "../../hooks";
import {Klant} from "../../../_generated/field-service-be-openapi";

export const useKlanten = () => {
    return useAppSelector((state) => state.klanten.resources);
};

export const useKlant = (id?: string): Klant | undefined => {
    return useAppSelector((state) => id ? state.klanten.resources[id] : undefined);
};
