import React from "react";

export interface ErrorBoundaryProps {
    onError?: (...data: any) => any;
    children?: React.ReactNode;
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.props.onError?.(error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}
