import React, {useEffect, useRef} from "react";
import SignaturePadWrapper, {
    SignaturePadWrapperOptions,
    SignaturePadWrapperPoint,
    SignaturePadWrapperProps,
    SignaturePadWrapperRef
} from "react-signature-pad-wrapper";
import Icon from "@mdi/react";
import {mdiTrashCanOutline} from "@mdi/js";

export interface SignaturePadProps extends SignaturePadWrapperProps {
    containerClassName?: string;

    initialImage?: string;

    onEndData?: (data?: SignaturePadWrapperPoint[]) => void;
    onEndImage?: (image?: string) => void;

    error?: string;
    disabled?: boolean;
}

export const SignaturePad: React.FC<SignaturePadProps> = (props) => {
    const ref = useRef<SignaturePadWrapperRef>();

    const defaultOptions: SignaturePadWrapperOptions = {
        minWidth: 1,
        maxWidth: 2,
        penColor: "#383b3e",
        onEnd: () => {
            if (ref.current) {
                props.onEndData?.(ref.current?.toData());
                props.onEndImage?.(ref.current?.toDataURL());
            }
        }
    };

    const {width, height, redrawOnResize, containerClassName, initialImage, error, disabled} = props;

    const options = {...defaultOptions, ...props.options};

    useEffect(() => {
        if (initialImage && ref.current) {
            ref.current?.fromDataURL(initialImage);
        }
    }, [initialImage]);

    const onClearClick = () => {
        if (ref.current) {
            ref.current.clear();
            props.onEndData?.(undefined);
            props.onEndImage?.(undefined);
        }
    };

    return (
        <div className="form-outline">
            <div className={`form-control signature-wrapper ${disabled ? "disabled" : ""} ${containerClassName || ""} ${error ? "is-invalid" : ""}`}>
                <SignaturePadWrapper width={width} height={height} options={options} redrawOnResize={redrawOnResize}
                                     ref={ref}/>

                <div className="clear-button cursor-pointer" onClick={onClearClick}>
                    <Icon path={mdiTrashCanOutline} size={2}/>
                </div>
            </div>

            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
