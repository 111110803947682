/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArtikelRestModel,
} from '../models';

export interface ArtikelAfbeeldingRequest {
    artikelId: string;
    size?: ArtikelAfbeeldingSizeEnum;
}

/**
 * 
 */
export class ArtikelApi extends runtime.BaseAPI {

    /**
     */
    async artikelAfbeeldingRaw(requestParameters: ArtikelAfbeeldingRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.artikelId === null || requestParameters.artikelId === undefined) {
            throw new runtime.RequiredError('artikelId','Required parameter requestParameters.artikelId was null or undefined when calling artikelAfbeelding.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/artikel/{artikelId}/afbeelding`.replace(`{${"artikelId"}}`, encodeURIComponent(String(requestParameters.artikelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async artikelAfbeelding(requestParameters: ArtikelAfbeeldingRequest): Promise<Blob> {
        const response = await this.artikelAfbeeldingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getArtikelenRaw(): Promise<runtime.ApiResponse<Array<ArtikelRestModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/artikel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getArtikelen(): Promise<Array<ArtikelRestModel>> {
        const response = await this.getArtikelenRaw();
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ArtikelAfbeeldingSizeEnum {
    Original = 'ORIGINAL',
    Thumbnail = 'THUMBNAIL'
}
