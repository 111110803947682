import {Ref, useEffect, useState} from "react";
import {DimensionObject, useDimensions} from "./useDimensions";

export const useInitialDimensions = () => {
    const [initialDimensions, setInitialDimensions] = useState<DimensionObject | undefined>();

    const [ref, size] = useDimensions();

    useEffect(() => {
        if (!initialDimensions && size?.width) {
            setInitialDimensions(size);
        }
    }, [size, initialDimensions]);

    return [ref, initialDimensions || {
        height: 0,
        width: 0,
        x: 0,
        y: 0
    }] as [Ref<any>, DimensionObject];
};
