import React from "react";
import {useTranslation} from "../utilities/i18nUtils";
import {MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle} from "mdb-react-ui-kit";

export interface LanguageDropdownProps {
    className?: string;
}

export const LanguageDropdown: React.FC<LanguageDropdownProps> = (props) => {
    const {className} = props;

    const {t, language, changeLanguage} = useTranslation("algemeen");

    const onLanguageChange = (locale: string) => {
        changeLanguage(locale);
    };

    const talen = [
        {label: t("LanguageDropdown.nederlands", "Nederlands") + " (Nederlands)", locale: "nl", shortLabel: "NL"},
        {label: t("LanguageDropdown.frans", "Frans") + " (français)", locale: "fr", shortLabel: "FR"}
    ];

    const selectedTaal = talen.find(item => language.startsWith(item.locale));

    return (
        <MDBDropdown className={className}>
            <MDBDropdownToggle className="btn-link btn-light">
                {selectedTaal?.shortLabel}

                {/*<Icon path={mdiChevronDown} size={1}/>*/}
            </MDBDropdownToggle>

            <MDBDropdownMenu alignRight style={{zIndex: 1050}}>
                {talen.map(({label, locale}) => (
                    <MDBDropdownItem link childTag="div" key={locale} onClick={() => onLanguageChange(locale)}>
                        {label}
                    </MDBDropdownItem>
                ))}
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};
