import React, {useState} from "react";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {useLocalEvents} from "../redux/slices/status/hooks";
import {MDBBtn, MDBCol, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiShareVariantOutline} from "@mdi/js";
import {Config} from "../utilities/config";
import {ContentContainer} from "../components/ContentContainer";
import {Snackbar} from "../components/Snackbar";
import {useTranslation} from "../utilities/i18nUtils";

export const LokaleGebeurtenissenPage: React.FC = () => {
    const localEvents = useLocalEvents();

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const onShareClick = () => {
        if ("share" in navigator) {
            navigator.share({
                title: `Field Service ${Config.VERSION} - Local Events`,
                text: JSON.stringify(localEvents, undefined, 2)
            });
        } else if ("clipboard" in navigator) {
            // @ts-ignore
            navigator.clipboard.writeText(`Field Service ${Config.VERSION} - Local Events\n\n${JSON.stringify(localEvents, undefined, 2)}`);
            setSnackbarOpen(true);
        }
    };

    const {t} = useTranslation("algemeen");

    return (
        <GenericPage>
            <Header title={t("LokaleGebeurtenissenPage.lokale-gebeurtenissen",
                "Lokale gebeurtenissen")} sticky/>

            <ContentContainer>
                <MDBRow className="mb-3">
                    <MDBCol>

                    </MDBCol>
                    <MDBCol size="auto">
                        <MDBBtn type="button" color="primary" onClick={onShareClick}>
                            <Icon path={mdiShareVariantOutline} size={1}/>
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>

                <div>
                    {localEvents.map(item => (
                        <pre className="bg-light p-3" style={{borderRadius: 10}}>
                            {JSON.stringify(item, undefined, 2)}
                        </pre>
                    ))}
                </div>
            </ContentContainer>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} className="mb-6"
                      message="Informatie naar klembord gekopiëerd"/>
        </GenericPage>
    );
};
