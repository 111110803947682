import React from "react";

export interface JaNeeProps {
    value?: any | null;
}

export const JaNee: React.FC<JaNeeProps> = (props) => {
    const {value} = props;

    return (
        <>
            {!!value && "Ja"}
            {!value && "Nee"}
        </>
    );
};
