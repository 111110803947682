import React, {ReactNode} from "react";
import Skeleton, {SkeletonProps} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export interface LoadingSkeletonProps<T> extends SkeletonProps {
    item?: T | null;

    /**
     * Override the check (if check returns true, but loading is true, still show skeleton).
     */
    loading?: boolean;

    /**
     * Override the default check (not null and not undefined).
     * True means the item is safe to render.
     * @param item
     */
    check?(item?: T | null): boolean;

    children?: (item: T) => ReactNode;
}

const defaultCheck = <T, >(item: T) => item !== null && item !== undefined;

export const LoadingSkeleton = <T, >(props: LoadingSkeletonProps<T>) => {
    const { item, children, check, loading, ...rest } = props;

    const isDefined = props.check || defaultCheck;

    if ((loading === undefined && !isDefined(item)) ||
        (loading !== undefined && loading)) {
        return (
            <Skeleton {...rest} />
        );
    }

    if (!children) {
        return null;
    }

    return <>{children(item!)}</>;
};
