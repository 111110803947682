import React, {useMemo} from "react";
import Icon from "@mdi/react";
import {mdiClipboardListOutline} from "@mdi/js";
import {MDBCol, MDBRow} from "../../mdb-react-ui-kit";
import {NavLink} from "react-router-dom";
import {useDagPlanning, useGeselecteerdeDag} from "../../redux/slices/dagPlanning/hooks";
import {formatDate} from "../../utilities/dateUtils";
import {TimeAgo} from "../../utilities/TimeAgo";
import {useApplicationStatus, useSyncingPlanning} from "../../redux/slices/status/hooks";
import {PlanningVersieCheckEnum, PlanningVersieCheckIcon} from "../PlanningVersieCheckIcon";
import {useSyncFromServer} from "../../utilities/serviceWorkerHooks";
import {useTranslation} from "../../utilities/i18nUtils";

export const PlanningHeader: React.FC = () => {
    const [geselecteerdeDag] = useGeselecteerdeDag();

    const [dagPlanning] = useDagPlanning(geselecteerdeDag);
    const applicationStatus = useApplicationStatus();
    const syncingPlanning = useSyncingPlanning();
    const {t} = useTranslation("planning");

    const syncFromServer = useSyncFromServer();

    const isLegeDagPlanning = !dagPlanning?.entries?.length;

    const dagPlanningVersieTijdstipText = useMemo(() => {
        if (!isLegeDagPlanning && dagPlanning?.versieTijdstip) {
            return formatDate(dagPlanning.versieTijdstip, "DD-MM-YYYY HH:mm:ss");
        }

        return <span className="text-muted">{t("algemeen:onbekend", "Onbekend")}</span>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dagPlanning]);

    const syncStatus = syncingPlanning ? PlanningVersieCheckEnum.IN_PROGRESS : PlanningVersieCheckEnum.DONE;
    const heeftVersie = dagPlanning?.versie != null || !!dagPlanning?.versieTijdstip;

    const onClick = () => {
        syncFromServer(formatDate(geselecteerdeDag, "YYYY-MM-DD"));
    };

    return (
        <div className="bg-secondary bg-opacity-25 pt-2 pb-2 p-3"
             style={{marginLeft: "-0.75rem", marginRight: "-0.75rem"}}>
            <div className="mb-2 position-relative">
                <div className="position-absolute" style={{
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "var(--mdb-secondary)",
                    width: "2em",
                    height: 4,
                    borderRadius: 5
                }}/>
            </div>

            <MDBRow>
                <MDBCol>
                    <MDBRow className="g-2">
                        <MDBCol size="auto" onClick={onClick}>
                            <PlanningVersieCheckIcon status={syncStatus}/>
                        </MDBCol>

                        <MDBCol xs="auto" sm="3" className="col">
                            <div style={{fontSize: "0.8em"}}>
                                <div style={{lineHeight: 1}}><small>{t("planning:PlanningHeader.laatste-controle", "Laatste controle:")}</small></div>
                                <small className="text-muted">
                                    {applicationStatus.planningLastVersieCheckTimestamp &&
                                        <TimeAgo since={applicationStatus.planningLastVersieCheckTimestamp}/>}
                                    {!applicationStatus.planningLastVersieCheckTimestamp &&
                                        t("PlanningHeader.onbekend", "onbekend")}
                                </small>
                            </div>
                        </MDBCol>

                        {heeftVersie && (
                            <MDBCol className="ms-sm-3" xs="12" sm="auto">
                                <MDBRow style={{fontSize: "0.8em"}}>
                                    <MDBCol size="auto">
                                        <div style={{lineHeight: 1}}><small>{t("planning:PlanningHeader.versie", "Versie:")}</small></div>
                                        <small className="text-muted">
                                            #{dagPlanning?.versie}
                                        </small>
                                    </MDBCol>
                                    <MDBCol size="auto">
                                        <div style={{lineHeight: 1}}><small>{t("planning:PlanningHeader.gewijzigd-op", "Gewijzigd op:")}</small></div>
                                        <small className="text-muted">{dagPlanningVersieTijdstipText}</small>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        )}
                    </MDBRow>
                </MDBCol>

                <MDBCol size="auto">
                    <NavLink to="/overzicht">
                        <Icon path={mdiClipboardListOutline} color="var(--mdb-primary)" size={1}/>
                    </NavLink>
                </MDBCol>
            </MDBRow>
        </div>
    );
};
