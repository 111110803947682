import clsx from 'clsx';
import React from 'react';
import { motion } from 'framer-motion';
import { DatepickerModalContainerProps } from './types';

const MDBDatepickerModalContainer: React.FC<DatepickerModalContainerProps> = ({
  className,
  dropdown,
  children,
  styles,
  attributes,
  setPopperElement,
  style,
  onClosed,
  onOpened,
}) => {
  const animationConfig = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3 },
  };
  const dropdownClasses = clsx('datepicker-dropdown-container', className);
  const modalClasses = clsx('datepicker-modal-container', className);

  const onAnimationCompleteHandler = (event: { opacity: number }) => {
    event.opacity === 0 ? onClosed?.() : onOpened?.();
  };

  return (
    <>
      {dropdown ? (
        <motion.div
          style={{ position: 'absolute', zIndex: 1065, ...styles.popper }}
          {...attributes.popper}
          ref={setPopperElement as any}
          className={dropdownClasses}
          tabIndex={-1}
          {...animationConfig}
          onAnimationComplete={(event: { opacity: number }) => onAnimationCompleteHandler(event)}
        >
          {children}
        </motion.div>
      ) : (
        <motion.div
          {...animationConfig}
          className={modalClasses}
          style={style}
          onAnimationComplete={(event: { opacity: number }) => onAnimationCompleteHandler(event)}
        >
          {children}
        </motion.div>
      )}
    </>
  );
};

export default MDBDatepickerModalContainer;
