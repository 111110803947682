'use client';

import clsx from 'clsx';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import useAutocompleteClose from '../hooks/useAutocompleteClose';
import useAutocompleteResize from '../hooks/useAutocompleteResize';
import useAutocompleteAnimation from '../hooks/useAutocompleteAnimation';
import type { AutocompleteDropdownProps } from './types';
import type { Modifier } from 'react-popper';
import Portal from '../../../../utils/Portal';

const MDBAutocompleteDropdown: React.FC<AutocompleteDropdownProps> = ({
  className,
  customContent = null,
  inputRef,
  isOpen,
  isOpened,
  children,
  setOpenState,
  listHeight = '190px',
  onOpened,
  onClose,
  onClosed,
  ...props
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLInputElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const popperModifiers = useMemo(
    (): Modifier<string, Record<string, unknown>>[] => [
      {
        name: 'matchReferenceWidth',
        enabled: true,
        fn: ({ state, instance }) => {
          if (!popperElement) return;

          const popperWidth = popperElement[`offsetWidth`];

          const referenceWidth = state.rects.reference['width'];

          if (Math.round(popperWidth) === Math.round(referenceWidth)) return;

          popperElement.style['width'] = `${referenceWidth}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    [popperElement]
  );

  const isVisible = useAutocompleteAnimation({ isOpen });
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, { modifiers: popperModifiers });
  const classes = clsx('autocomplete-dropdown', isOpen && 'open', className);

  useAutocompleteClose({ isOpened, setOpenState, dropdownEl: popperElement, inputRef, onClose });
  useAutocompleteResize({ inputRef, dropdownEl: popperElement });

  useEffect(() => {
    if (!inputRef.current) return;

    setReferenceElement(inputRef.current);
  }, [inputRef]);

  useEffect(() => {
    isOpen && update?.();
  }, [isOpen, update, isVisible]);

  return (
    <>
      <Portal>
        <div
          className='autocomplete-dropdown-container'
          ref={setPopperElement}
          {...props}
          style={styles.popper}
          {...attributes.popper}
          onTransitionEnd={(event) => {
            if (event.propertyName === 'opacity') {
              if (isVisible) {
                onOpened?.();
              } else {
                onClosed?.();
              }
            }
          }}
        >
          <div className={classes}>
            <ul
              className='autocomplete-items-list'
              role='listbox'
              style={{ maxHeight: listHeight, display: isVisible ? 'block' : 'none' }}
            >
              {children}
            </ul>
            {customContent}
          </div>
        </div>
      </Portal>
    </>
  );
};

export default MDBAutocompleteDropdown;
