import React from "react";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";
import {MDBBtn} from "mdb-react-ui-kit";

export const BezoekMDBBtn: React.FC<typeof MDBBtn["defaultProps"]> = (props) => {
    const {bezoekSessieId} = useParams<{ bezoekSessieId?: string }>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    return (
        <MDBBtn type="button" {...props}
                disabled={props?.disabled || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>
    );
};
