import clsx from 'clsx';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import type { TimepickerModalProps } from './types';
import MDBTimePickerHeader from '../TimePickerHeader/TimePickerHeader';
import MDBTimePickerClock from '../TimePickerClock/TimePickerClock';
import MDBTimePickerFooter from '../TimePickerFooter/TimePickerFooter';
import { AnimatePresence, motion } from 'framer-motion';
import useTimepickerBodyScroll from '../hooks/useTimepickerBodyScroll';
import Portal from '../../../../utils/Portal';

const MDBTimepickerModal: React.FC<TimepickerModalProps> = ({
  className,
  isOpen,
  wrapperRef,
  inline,
  referenceElement,
  onOpened,
  onClosed,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const pickerClasses = clsx('timepicker-modal', !inline && 'position-fixed', className);

  const pickerWrapperClasses = clsx(
    'timepicker-wrapper',
    'animation',
    'h-100',
    'd-flex',
    'align-items-center',
    'justify-content-center',
    'flex-column',
    inline ? 'timepicker-wrapper-inline' : 'position-fixed',
    'fade',
    isOpen && 'show'
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const onAnimationCompleteHandler = (event: { opacity: number }) => {
    event.opacity === 0 ? onClosed() : onOpened();
  };
  useTimepickerBodyScroll({ isOpen, inline });

  return (
    <>
      {
        <Portal>
          <AnimatePresence>
            {isOpen &&
              (inline ? (
                <motion.div
                  className={pickerClasses}
                  style={styles.popper}
                  {...attributes.popper}
                  role='dialog'
                  ref={setPopperElement}
                  tabIndex={-1}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onAnimationComplete={(event: { opacity: number }) => onAnimationCompleteHandler(event)}
                >
                  <div ref={wrapperRef} className={pickerWrapperClasses}>
                    <div
                      className='d-flex align-items-center justify-content-center flex-column shadow timepicker-container'
                      style={{ overflowY: 'inherit' }}
                    >
                      <div className='d-flex flex-column timepicker-elements justify-content-around timepicker-elements-inline'>
                        <MDBTimePickerHeader />
                      </div>
                    </div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  className={pickerClasses}
                  role='dialog'
                  tabIndex={-1}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  onAnimationComplete={(event: { opacity: number }) => onAnimationCompleteHandler(event)}
                >
                  <div ref={wrapperRef} className={pickerWrapperClasses}>
                    <div className='d-flex align-items-center justify-content-center flex-column shadow timepicker-container'>
                      <div className='d-flex flex-column timepicker-elements justify-content-around'>
                        <MDBTimePickerHeader />
                        <MDBTimePickerClock />
                      </div>
                      <MDBTimePickerFooter />
                    </div>
                  </div>
                </motion.div>
              ))}
          </AnimatePresence>
        </Portal>
      }
    </>
  );
};

export default MDBTimepickerModal;
