import React from "react";
import {Header} from "../../components/Header";
import {GenericPage} from "../../components/GenericPage";
import {useParams} from "react-router-dom";
import {useServiceAdres} from "../../redux/slices/serviceAdressen/hooks";
import {Adres} from "../../components/Adres";
import {UitTeVoerenWerkBezoek} from "../UitTeVoerenWerkBezoek";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {useHuidigeDagPlanning} from "../../redux/slices/dagPlanning/hooks";
import {DagPlanningBezoekEntryRestModel} from "../../_generated/field-service-be-openapi";

export interface ServiceAdresOverzichtPageParams {
    bezoekSessieId: string;
}

export const ServiceAdresOverzichtPage: React.FC = () => {
    const {bezoekSessieId} = useParams<ServiceAdresOverzichtPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const [dagPlanning] = useHuidigeDagPlanning();

    const dagPlanningBezoekEntry = dagPlanning?.entries?.find(item => item._type === "BEZOEK" && item.toekomstigBezoekSessieId === bezoekSessieId) as DagPlanningBezoekEntryRestModel | undefined;

    let serviceAdres = useServiceAdres(dagPlanningBezoekEntry?.serviceAdresId);
    if (!serviceAdres && bezoekSessie) {
        serviceAdres = bezoekSessie.serviceAdres;
    }

    const uitTeVoerenWerk = bezoekSessie?.uitTeVoerenWerk || dagPlanningBezoekEntry?.uitTeVoerenWerk;

    return (
        <GenericPage>
            <Header title={serviceAdres?.naam} subtitle={<Adres adres={serviceAdres}/>}/>

            <div className="container-lg pt-3 flex-fill bg-white">
                <UitTeVoerenWerkBezoek serviceAdres={serviceAdres} uitTeVoerenWerk={uitTeVoerenWerk}/>
            </div>
        </GenericPage>
    );
};
