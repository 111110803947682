import React, {MouseEvent} from "react";
import Icon from "@mdi/react";
import {mdiCar} from "@mdi/js";
import {MDBCard, MDBCardBody, MDBCardTitle, MDBRipple} from "mdb-react-ui-kit";
import {Afstand} from "../Afstand";
import {LocationPin} from "../LocationPin";
import {useTranslation} from "../../utilities/i18nUtils";
import {formatNumberAsMinutes} from "../../utilities/dateUtils";

export interface PlanningRitCardProps {
    afstand: number;
    duurtijd: number;

    onClick?: () => void;

    active?: boolean;
    disabled?: boolean;

    className?: string;
}

export const PlanningRitCard: React.FC<PlanningRitCardProps> = (props) => {
    const {afstand, duurtijd, onClick, active, disabled, className} = props;

    const onCardClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (!disabled) {
            onClick?.();
        }
    };

    const {t, language} = useTranslation("planning");

    return (
        <div className={`ps-3 mb-4 ${className || ""}`}>
            <div className="timeline-drive">
                <div className="dot">
                    <Icon path={mdiCar} size={1} className="text-primary"/>
                </div>
                <MDBCard className="shadow-none">
                    <span onClick={onCardClick}>
                        <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                            <MDBCardBody>
                                <MDBCardTitle className="mb-0 d-flex align-items-center">
                                    {active && <LocationPin className="me-3"/>}

                                    <div>
                                        {t("PlanningRitCard.onderweg", "Onderweg")}{" "}
                                        <span className="text-muted font-smaller"><Afstand
                                            afstand={afstand} locale={language}/>
                                            {" "}{formatNumberAsMinutes(duurtijd, language)}
                                        </span>
                                    </div>
                                </MDBCardTitle>
                            </MDBCardBody>
                        </MDBRipple>
                    </span>
                </MDBCard>
            </div>
        </div>
    );
};
