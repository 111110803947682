const clockData = {
  hours: [
    {
      value: 1,
      left: '169px',
      bottom: '209.263px',
      angle: 30,
      id: `hour-1-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 2,
      left: '209.263px',
      bottom: '169px',
      angle: 60,
      id: `hour-2-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 3,
      left: '224px',
      bottom: '114px',
      angle: 90,
      id: `hour-3-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 4,
      left: '209.263px',
      bottom: '59px',
      angle: 120,
      id: `hour-4-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 5,
      left: '169px',
      bottom: '18.7372px',
      angle: 150,
      id: `hour-5-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 6,
      left: '114px',
      bottom: '4px',
      angle: 180,
      id: `hour-6-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 7,
      left: '59px',
      bottom: '18.7372px',
      angle: 210,
      id: `hour-7-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 8,
      left: '18.7372px',
      bottom: '59px',
      angle: 240,
      id: `hour-8-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 9,
      left: '4px',
      bottom: '114px',
      angle: 270,
      id: `hour-9-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 10,
      left: '18.7372px',
      bottom: '169px',
      angle: 300,
      id: `hour-10-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 11,
      left: '59px',
      bottom: '209.263px',
      angle: 330,
      id: `hour-11-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 12,
      left: '114px',
      bottom: '224px',
      angle: 360,
      id: `hour-12-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 13,
      left: '94px',
      bottom: '115.962px',
      angle: 30,
      id: `hour-13-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 14,
      left: '115.962px',
      bottom: '94px',
      angle: 60,
      id: `hour-14-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 15,
      left: '124px',
      bottom: '64px',
      angle: 90,
      id: `hour-15-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 16,
      left: '115.962px',
      bottom: '34px',
      angle: 120,
      id: `hour-16-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 17,
      left: '94px',
      bottom: '12.0385px',
      angle: 150,
      id: `hour-17-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 18,
      left: '64px',
      bottom: '4px',
      angle: 180,
      id: `hour-18-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 19,
      left: '34px',
      bottom: '12.0385px',
      angle: 210,
      id: `hour-19-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 20,
      left: '12.0385px',
      bottom: '34px',
      angle: 240,
      id: `hour-20-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 21,
      left: '4px',
      bottom: '64px',
      angle: 270,
      id: `hour-21-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 22,
      left: '12.0385px',
      bottom: '94px',
      angle: 300,
      id: `hour-22-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 23,
      left: '34px',
      bottom: '115.962px',
      angle: 330,
      id: `hour-23-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 24,
      left: '64px',
      bottom: '124px',
      angle: 360,
      id: `hour-0-${Math.floor(Math.random() * 1001)}`,
    },
  ],

  //   ---------------------------------------------------------------------- MINUTES --------------------------------------------------------------------------

  minutes: [
    {
      value: 0,
      left: '114px',
      bottom: '224px',
      angle: 360,
      id: `minute-0-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 5,
      left: '169px',
      bottom: '209.263px',
      angle: 30,
      id: `minute-5-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 10,
      left: '209.263px',
      bottom: '169px',
      angle: 60,
      id: `minute-10-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 15,
      left: '224px',
      bottom: '114px',
      angle: 90,
      id: `minute-15-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 20,
      left: '209.263px',
      bottom: '59px',
      angle: 120,
      id: `minute-20-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 25,
      left: '169px',
      bottom: '18.7372px',
      angle: 150,
      id: `minute-25-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 30,
      left: '114px',
      bottom: '4px',
      angle: 180,
      id: `minute-30-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 35,
      left: '59px',
      bottom: '18.7372px',
      angle: 210,
      id: `minute-35-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 40,
      left: '18.7372px',
      bottom: '59px',
      angle: 240,
      id: `minute-40-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 45,
      left: '4px',
      bottom: '114px',
      angle: 270,
      id: `minute-45-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 50,
      left: '18.7372px',
      bottom: '169px',
      angle: 300,
      id: `minute-50-${Math.floor(Math.random() * 1001)}`,
    },
    {
      value: 55,
      left: '59px',
      bottom: '209.263px',
      angle: 330,
      id: `minute-55-${Math.floor(Math.random() * 1001)}`,
    },
  ],
};

export default clockData;
