import React, {useMemo, useState} from "react";
import {MDBBtn, MDBTable, MDBTableBody, MDBTableHead} from "../../../mdb-react-ui-kit/index";
import {BezoekMDBFormikInput} from "../../../mdb-formik/bezoek/BezoekMDBFormikInput";
import {Co2Levering, ServiceAdresCo2ArtikelRestModel} from "../../../_generated/field-service-be-openapi";
import {Co2LeveringFormValues} from "../../../pages/ServiceAdres/Opdracht/Co2LeveringPage";
import {useFormikContext} from "formik";
import {Co2LeveringUtils} from "../../../utilities/co2LeveringUtils";
import {MDBCol, MDBRow} from "../../../mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAlert} from "@mdi/js";
import {Co2OnvolledigGeleverdReden} from "./Co2OnvolledigGeleverdReden";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface Co2LeveringFormProps {
    bestellingen?: Co2Levering[];

    co2Artikelen: ServiceAdresCo2ArtikelRestModel[];
}

export const Co2LeveringForm: React.FC<Co2LeveringFormProps> = (props) => {
    const {bestellingen, co2Artikelen} = props;

    const [showAanwezigeArtikelen, setShowAanwezigeArtikelen] = useState(true);

    const {values} = useFormikContext<Co2LeveringFormValues>();

    const gecombineerdeLeveringen = useMemo(() => Co2LeveringUtils.combineerGevraagdeLeveringen(bestellingen || []), [bestellingen]);

    const teTonenCo2Artikelen = useMemo(() => {
        const alleCo2Artikelen = co2Artikelen?.filter((item) => {
            if (showAanwezigeArtikelen) {
                return item.inGebruik || values.bestelling[item.id!] || bestellingen?.some(bestelling => bestelling.artikelId === item.id);
            }

            return true;
        });

        const hervulbare = alleCo2Artikelen?.filter((item) => item.hervulbaar)?.sort((a, b) => a.nummer.localeCompare(b.nummer));
        const nietHervulbare = alleCo2Artikelen?.filter((item) => !item.hervulbaar)?.sort((a, b) => a.nummer.localeCompare(b.nummer));

        // Toon eerst de niet-hervulbare en dan de hervulbare
        return [...(nietHervulbare || []), ...(hervulbare || [])];
    }, [co2Artikelen, values, showAanwezigeArtikelen, bestellingen]);

    const {t} = useTranslation("co2-bestelling");

    return (
        <MDBTable responsive striped borderless>
            <MDBTableHead>
                <tr>
                    <th>{t("artikel", "Artikel")}</th>
                    <th>{t("aantal-geleverd", "Aantal geleverd")}</th>
                    <th>{t("aantal-retour", "Aantal retour")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {/* TODO: alle co2-artikelen tonen en aantal per artikel optellen */}
                {teTonenCo2Artikelen?.map((item) => {
                    const onvolledigeLeveringReden = values.bestelling[item.id]?.onvolledigeLeveringReden;

                    return (
                        <tr key={item.id}>
                            <td>
                                <MDBRow className="flex-column">
                                    <MDBCol>
                                        <strong>{item.nummer}</strong> {item.omschrijving}
                                    </MDBCol>

                                    {onvolledigeLeveringReden && (
                                        <MDBCol>
                                            <small className="text-muted">
                                                <Icon path={mdiAlert} size={0.8} color="var(--mdb-warning)" className="me-1"/>
                                                {t("onvolledig-geleverd", "Onvolledig geleverd:")}{" "} <Co2OnvolledigGeleverdReden reden={onvolledigeLeveringReden}/>
                                            </small>
                                        </MDBCol>
                                    )}
                                </MDBRow>
                            </td>
                            <td>
                                <BezoekMDBFormikInput type="number"
                                                      placeholder={String(gecombineerdeLeveringen[item.id]?.gevraagdAantalGecombineerd ?? 0)}
                                                      name={`bestelling.${item.id}.geleverdAantal`}/>
                            </td>
                            <td>
                                {item.hervulbaar && (
                                    <BezoekMDBFormikInput type="number"
                                                          placeholder={String(gecombineerdeLeveringen[item.id]?.gevraagdOmruilAantal ?? 0)}
                                                          name={`bestelling.${item.id}.geleverdOmruilAantal`}/>
                                )}
                            </td>
                        </tr>
                    );
                })}

                {showAanwezigeArtikelen && (
                    <tr>
                        <td colSpan={3} className="text-center">
                            <MDBBtn type="button" color="link"
                                    onClick={() => setShowAanwezigeArtikelen(false)}>
                                {t("toon-meer-artikelen", "Toon meer artikelen...")}
                            </MDBBtn>

                            <div className="text-muted">
                                <small>{t("standaard-worden-enkel-aanwezige-artikelen-getoond",
                                    "Standaard worden enkel aanwezige artikelen getoond.")}</small>
                            </div>
                        </td>
                    </tr>
                )}
            </MDBTableBody>
        </MDBTable>
    );
};
