import * as React from "react";
import {Toestel} from "../../_generated/field-service-be-openapi";
import {useTranslation} from "../../utilities/i18nUtils";

interface ToestelLocatieProps {
    toestel: Toestel;
}

export const ToestelLocatie: React.FC<ToestelLocatieProps> = ({toestel}) => {

    const {t} = useTranslation("toestel");

    if (!toestel.gebouw && !toestel.verdieping && !toestel.lokaalnummer && !toestel.locatieBeschrijving) {
        return <span className="text-muted">{t("algemeen:Titels.onbekend", "Onbekend")}</span>
    }

    return (
        <span>
            <span>{toestel.locatieBeschrijving}</span><br/>
            <span
                className='text-muted'>{toestel.gebouw} {toestel.verdieping} {toestel.lokaalnummer}</span>
        </span>
    );
}
