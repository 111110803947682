import {useAppSelector} from "../../hooks";
import {useServiceAdres} from "../serviceAdressen/hooks";

export const useArtikelen = () => {
    return useAppSelector((state) => state.artikelen.resources);
};

export const useArtikel = (artikelId?: string) => {
    return useAppSelector((state) => artikelId ? state.artikelen.resources[artikelId] : undefined);
};

export const useArtikelenForServiceAdresId = (serviceAdresId: string) => {
    const serviceAdres = useServiceAdres(serviceAdresId);

    return useAppSelector((state) => {
        if (serviceAdres?.bedrijfId) {
            return state.artikelen.lists[serviceAdres.bedrijfId]?.map((id) => state.artikelen.resources[id]);
        }

        return [];
    });
};
