import {useAppSelector} from "../../hooks";
import {Technicus} from "../../../_generated/field-service-be-openapi";

export const useTechnici = () => {
    return useAppSelector((state) => state.technici.resources);
};

export const useMe = (): Technicus | undefined => {
    return useAppSelector((state) => state.technici.lists.me?.[0] ? state.technici.resources[state.technici.lists.me[0]] : undefined);
};
