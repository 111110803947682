import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, put} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {downloads} from "./slice";
import {downloadFile, FileDownload, saveFile} from "../../../utilities/downloadUtils";
import {Config} from "../../../utilities/config";
import {statusSaga} from "../status/saga";

export const downloadsSaga = createSliceSaga({
    name: "downloadsSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * downloadFile(action: PayloadAction<{ uri: string; omschrijving?: string; onSuccess?(): void }>) {
            const {uri, omschrijving, onSuccess} = action.payload;

            yield put(downloads.actions.pending({requestKey: uri}));

            try {
                const result = (yield call(downloadFile, uri, omschrijving)) as unknown as FileDownload;

                yield saveFile(result);

                yield put(downloads.actions.succeeded({requestKey: uri, resources: [uri]}));

                if (onSuccess) {
                    yield call(onSuccess);
                }
            } catch (error) {
                yield put(downloads.actions.failed({requestKey: uri, requestProperties: {error}}));
            }
        },
        * downloadInstallatieBijlage(action: PayloadAction<{ installatieId: string; bijlageId: string; onSuccess?(): void }>) {
            const {installatieId, bijlageId, onSuccess} = action.payload;

            const requestKey = `downloadInstallatieBijlage:${bijlageId}`;
            const uri = Config.BASE_URL + `/api/installatie/${installatieId}/bijlage/${bijlageId}`;
            yield put(downloads.actions.pending({requestKey: uri}));

            try {
                const result = (yield call(downloadFile, uri, "Installatiebijlage")) as unknown as FileDownload;

                yield saveFile(result);

                yield put(downloads.actions.succeeded({requestKey, resources: [bijlageId]}));

                if (onSuccess) {
                    yield call(onSuccess);
                }
            } catch (error) {
                yield put(downloads.actions.failed({requestKey, requestProperties: {error}}));
                yield put(statusSaga.actions.logError({error}));
            }
        }
    }
});
