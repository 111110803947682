import React from "react";
import {MDBCard, MDBCardBody, MDBCardText} from "../../../mdb-react-ui-kit/index";
import {ToestelServiceLogEntryComponent} from "./ToestelServiceLogEntryComponent";
import {Toestel} from "../../../_generated/field-service-be-openapi";

export interface ToestelServiceLogListProps {
    toestel: Toestel;
}

export const ToestelServiceLogList: React.FC<ToestelServiceLogListProps> = (props) => {
    const {toestel} = props;

    return (
        <ul className="timeline">
            {!toestel.serviceLogEntries?.length && (
                <MDBCard background="light" className="shadow-sm mb-4">
                    <MDBCardBody>
                        <MDBCardText>
                            Geen servicelog beschikbaar.
                        </MDBCardText>

                    </MDBCardBody>
                </MDBCard>
            )}
            {toestel.serviceLogEntries?.map((item) => (
                <li>
                    <ToestelServiceLogEntryComponent entry={item}/>
                </li>
            ))}
        </ul>
    );
};
