import {Config} from "../config";
import dayjs from "dayjs";
import {TokenType} from "./tokenType";

export class AuthUtils {

    public static async getTokenAndRefreshIfNeeded(accessToken: TokenType, refreshToken: string, skipRefresh?: boolean): Promise<[TokenType, {refreshed: boolean}]> {
        if (!skipRefresh && this.isTokenExpired(accessToken.expiresOn)) {
            return [await this.refreshToken(refreshToken), {refreshed: true}];
        } else {
            return [accessToken, {refreshed: false}];
        }
    }

    public static isTokenExpired(expiresOn: number) {
        const refreshBeforeExpirationInMinutes = 15;
        return dayjs(expiresOn).isBefore(dayjs().add(refreshBeforeExpirationInMinutes, "minutes"))
    }

    public static async refreshToken(refreshToken: string): Promise<TokenType> {
        const OAUTH_CLIENT_ID = Config.OAUTH_CLIENT_ID;

        const url = `${Config.OAUTH_AUTHORITY}/protocol/openid-connect/token`;
        const data = new URLSearchParams();
        data.append("client_id", OAUTH_CLIENT_ID);
        data.append("scope", Config.OAUTH_CLIENT_SCOPES);
        data.append("refresh_token", refreshToken);
        data.append("grant_type", "refresh_token");

        const refreshResponse = await fetch(url, {
            method: "post",
            body: data
        });
        if (refreshResponse.status === 400) {
            throw new Error("Token refresh failed with status code 400");
        }

        const refreshResult = await refreshResponse.json();

        const expiresOn = dayjs().add(refreshResult.expires_in, "seconds").valueOf();

        return {
            accessToken: refreshResult.access_token,
            refreshToken: refreshResult.refresh_token,
            idToken: refreshResult.id_token,
            expiresOn
        };
    }

}
