import {dataUriToBlob} from "./downloadUtils";
import {browser2ServiceWorkerChannel} from "../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {useCallback} from "react";
import {WorkerMessageFactory} from "../workers/shared/workerMessageFactory";

export const uploadIdToUrl = (uploadId: string) => {
    return `/upload/${uploadId}`;
};

export const uploadFile = (dataUri: string) => {
    const blob = dataUriToBlob(dataUri);

    return browser2ServiceWorkerChannel.sendRpc(WorkerMessageFactory.createUploadFileMessage(blob));
};

export const useUploadFile = () => {
    return useCallback((dataUri: string) => {
        return uploadFile(dataUri);
    }, []);
};
