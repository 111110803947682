import React, {CSSProperties} from "react";
import {motion, Variants} from "framer-motion";

const defaultPageVariants: Variants = {
    initial: {
        y: "5%"
    },
    in: {
        y: 0
    },
    out: {
        y: "-5%"
    }
};

export interface GenericPageProps {
    variants?: Variants;
    style?: CSSProperties;

    children?: React.ReactNode;
}

export const GenericPage: React.FC<GenericPageProps> = (props) => {
    const {variants = defaultPageVariants, style} = props;

    return (
        <motion.div initial="initial"
                    animate="in"
                    exit="out"
                    variants={variants}
                    transition={{type: "spring", duration: 0.2, bounce: 0, damping: 50, stiffness: 500, mass: 2}}
                    className="overflow-y-auto h-100 d-flex flex-column position-relative generic-page"
                    style={style}
                    id="content"
        >
            {props.children}
        </motion.div>
    );
};
