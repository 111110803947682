import React, {useCallback, useMemo} from "react";
import {Field, FieldProps} from "formik";
import {MDBTimepicker, MDBValidationItem} from "mdb-react-ui-kit";
import dayjs from "dayjs";
import type {TimepickerProps} from "../mdb-react-ui-kit/pro/forms/TimePicker/types";

export interface FormikTimeInputProps extends TimepickerProps {
    name: string;
}

const InnerTimeInput: React.FC<FormikTimeInputProps & FieldProps> = (props) => {
    const {name, field: {value, onChange, onBlur}, meta: {error}, ...rest} = props;

    const validation = useMemo(() => {
        if (error) {
            return error;
        }

        return " "; // Fix so default value "Invalid Time Format" is not taken because MDBootstrap checks "if (invalidLabel)" so empty string passes
    }, [error]);

    const onTimeChange = useCallback((newValue: string) => {
        if (!newValue) {
            return;
        }

        onChange({
            target: {
                name,
                value: newValue ? dayjs(newValue, "HH:mm") : undefined
            }
        });
    }, [onChange, name]);

    const timeValue = useMemo(() => value ? dayjs(value).format("HH:mm") : undefined, [value]);

    return (
        <MDBValidationItem invalid={!!validation} feedback={validation}>
            <MDBTimepicker {...rest}
                           value={timeValue}
                           onBlur={onBlur as any}
                           onChange={onTimeChange}
                           defaultValue={timeValue}

                           inputClasses={`${rest.className || ""} ${!!error ? "is-invalid" : ""}`}

                           format="24h"
                           inputLabel="Selecteer een tijd"
                           clearLabel="Herstel"
                           submitLabel="Oké"
                           cancelLabel="Annuleer"
            />
        </MDBValidationItem>
    );
};

/**
 * Remove whenever MDBootstrap fixes their MDBTimepicker component to allow listening for changes (after dialog confirm)
 */
export const FormikTimeInput: React.FC<FormikTimeInputProps> = (props) => {
    return (
        <Field name={props.name}>
            {(fieldProps: FieldProps) => {
                return (
                    <InnerTimeInput {...fieldProps} {...props}/>
                );
            }}
        </Field>
    );
};
