import React from "react";
import {MDBCard, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {GenericPage} from "../../components/GenericPage";
import {Header} from "../../components/Header";
import {useParams} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiFileDocument} from "@mdi/js";
import {ServiceAdresBijlageDownloadButton} from "../../components/download/ServiceAdresBijlageDownloadButton";
import {ContentContainer} from "../../components/ContentContainer";
import {useTranslation} from "../../utilities/i18nUtils";
import {useBezoekByBezoekSessieId} from "../../redux/slices/dagPlanningEntry/hooks";
import {useServiceAdres} from "../../redux/slices/serviceAdressen/hooks";

export interface ServiceAdresBijlagenPageParams {
    bezoekSessieId: string;
}

export const ServiceAdresBijlagenPage: React.FC = () => {
    const {bezoekSessieId} = useParams<ServiceAdresBijlagenPageParams>();

    const bezoek = useBezoekByBezoekSessieId(bezoekSessieId);
    const serviceAdres = useServiceAdres(bezoek?.serviceAdresId);

    const {t} = useTranslation("serviceadres");

    return (
        <GenericPage>
            <Header title={t("ServiceAdresBijlagenPage.titel", "Bijlagen")} sticky/>

            <ContentContainer>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader><Icon path={mdiFileDocument}
                                         size={1}/> {t("BijlagenCard.kop", "Bijlagen")}</MDBCardHeader>

                    <MDBTable responsive>
                        <MDBTableHead>
                            <tr>
                                <th>{t("BijlagenCard.titel", "Titel")}</th>
                                <th>{t("BijlagenCard.omschrijving", "Omschrijving")}</th>
                                <th>{t("BijlagenCard.downloaden", "Downloaden")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {!serviceAdres?.bijlagen?.length && (
                                <tr>
                                    <td colSpan={3}>{t("BijlagenCard.geen-bijlagen-beschikbaar", "Geen bijlagen beschikbaar")}.</td>
                                </tr>
                            )}
                            {serviceAdres?.bijlagen?.map(bijlage =>
                                <tr key={bijlage.id}>
                                    <td>
                                        {bijlage.naam}
                                    </td>
                                    <td>
                                        {bijlage.omschrijving}
                                    </td>
                                    <td>
                                        <ServiceAdresBijlageDownloadButton serviceAdresId={serviceAdres?.id}
                                                                           serviceRapportId={bijlage.id!}/>
                                    </td>
                                </tr>
                            )}

                        </MDBTableBody>
                    </MDBTable>
                </MDBCard>


            </ContentContainer>
        </GenericPage>
    );
};
