import {BezoekSessieEventError} from "../workers/serviceworkers/bezoekSessieEventError";

export class ErrorUtils {

    public static toString(error: Error): string {
        if (!error) {
            return "";
        }

        if (error.name === "BezoekSessieEventError") {
            return `${error.message}\n${JSON.stringify((error as BezoekSessieEventError).event)}`;
        }

        if (error.toString() === "[object Object]") {
            return `${error.name} ${error.message}`;
        }

        return error.toString();
    }

    public static toObject(error: Error | object): object {
        if ("toJSON" in error) {
            return (error as any).toJSON();
        }

        if (error instanceof Error) {
            return {
                name: error.name,
                message: error.message,
                stack: error.stack
            };
        }

        return error;
    }

}
