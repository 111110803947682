import React, {useMemo} from "react";
import {MDBBtn, MDBModalBody, MDBModalHeader} from "mdb-react-ui-kit";
import {useHistory} from "react-router-dom";
import {useKlant} from "../../redux/slices/klant/hooks";
import {useBezoekSessies} from "../../redux/slices/bezoeksessie/hooks";
import {AdresAanwezigheid} from "../../workers/shared/snapshot/bezoekSessieState";
import {useMe} from "../../redux/slices/technicus/hooks";
import {FrameModal} from "./FrameModal";
import {useTranslation} from "../../utilities/i18nUtils";
import {Trans} from "react-i18next";

export interface ReedsAanwezigOpServiceAdresModalProps {
    onClose?(): void;
}

export const ReedsAanwezigOpServiceAdresModal: React.FC<ReedsAanwezigOpServiceAdresModalProps> = (props) => {
    const {
        onClose
    } = props;

    const history = useHistory();
    const me = useMe();
    const bezoekSessies = useBezoekSessies();

    const onderwegOfAanwezigBezoekSessie = useMemo(() => {
        if (me) {
            for (const bezoekSessie of Object.values(bezoekSessies)) {
                if ([AdresAanwezigheid.AANWEZIG, AdresAanwezigheid.ONDERWEG].includes(bezoekSessie.adresAanwezigheid[me.id])) {
                    return bezoekSessie;
                }
            }
        }
    }, [bezoekSessies, me]);

    const klant = useKlant(onderwegOfAanwezigBezoekSessie?.serviceAdres?.klantId);

    const onOkeClick = () => {
        history.push(`/bezoek/${onderwegOfAanwezigBezoekSessie?.id}`);
        onClose?.();
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const {t} = useTranslation("bezoeken");

    const huidigServiceAdresNaam =  onderwegOfAanwezigBezoekSessie?.serviceAdres?.naam;

    const klantNaam = klant?.naam;

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>{t("ReedsAanwezigOpServiceAdresModal.titel",
                "U bent reeds onderweg naar of aanwezig op een serviceadres")}</MDBModalHeader>

            <MDBModalBody className="py-1">
                <p className="mb-0">
                    <Trans t={t}
                           i18nKey="ReedsAanwezigOpServiceAdresModal.reeds-aanwezig">
                        U bent reeds onderweg naar of aanwezig
                        op <strong>{{huidigServiceAdresNaam} as any}</strong> bij <strong>{{klantNaam} as any}</strong>.
                        Gelieve daar eerst uit te checken alvorens een nieuw serviceadres te openen.
                    </Trans>
                </p>

                <div className="d-flex justify-content-end align-items-center my-3">
                    <MDBBtn type="button" color="secondary" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                        {t("ReedsAanwezigOpServiceAdresModal.sluit", "Sluit")}
                    </MDBBtn>
                    <MDBBtn type="button" color="primary" size="lg" className="ms-2" onClick={onOkeClick}>
                        {t("ReedsAanwezigOpServiceAdresModal.oke-open-het-serviceadres", "Oké, open het serviceadres")}
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </FrameModal>
    );
};
