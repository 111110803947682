import React from "react";
import TA from "react-timeago";
// @ts-ignore
import nl from "react-timeago/lib/language-strings/nl";
// @ts-ignore
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import dayjs, {ConfigType} from "dayjs";

const nlFormatter = buildFormatter(nl);

export interface TimeAgoProps {
    since: ConfigType;
}

export const TimeAgo: React.FC<TimeAgoProps> = (props) => {
    const {since} = props;

    return <TA date={dayjs(since).toDate()} formatter={nlFormatter}/>;
};
