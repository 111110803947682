import useReactSessionStorageState from "use-session-storage-state";
import {useEffect, useState} from "react";

export const useSessionStorageState = <S>(key: string, defaultState?: S) => {
    return useReactSessionStorageState<S>(key, {
        defaultValue: defaultState,
        storageSync: false,
        serializer: {
            stringify: value => value as string,
            parse: value => value
        }
    });
};

export const useStorageEstimation = () => {
    const [estimate, setEstimate] = useState<StorageEstimate>();

    useEffect(() => {
        navigator.storage.estimate().then(setEstimate);
    }, []);

    return estimate;
};
