export class RequestUtils {

    public static async fetchResponseToError(response: Response) {
        const json = await response.clone().json();

        const error = {
            name: json.error,
            message: json.status + " " + json.error + " " + json.path,
            stack: "",
            status: response.status
        };

        return error;
    }

    public static async fetchWithTimeout(timeout: number, input: RequestInfo, init?: RequestInit) {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const response = await fetch(input, {
            ...(init || {}),
            signal: controller.signal
        });

        clearTimeout(id);

        return response;
    }

}
