import React, {MouseEvent, ReactNode, Ref, useMemo} from "react";
import {
    MDBBadge,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBRipple,
    MDBRow
} from "mdb-react-ui-kit";
import {NavLink} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiClockAlert} from "@mdi/js";
import {MDBCardStatus, MDBCardStatusEnum} from "../mdb/MDBCardStatus";
import {GoogleMapsUtils} from "../../utilities/googleMapsUtils";
import {LocationPin} from "../LocationPin";
import {Datum} from "../Datum";
import {Assets} from "../../assets/assets";
import {TruncateText} from "../TruncateText";
import {IndeterminateProgress} from "../IndeterminateProgress";
import {useTranslation} from "../../utilities/i18nUtils";

export interface PlanningStopCardProps {
    name: ReactNode;

    className?: string;

    link?: string;
    linkOnClick?: () => any;

    from?: string;
    fromEstimated?: string;
    toEstimated?: string;

    active?: boolean;
    loading?: boolean;
    disabled?: boolean;

    adres?: ReactNode;
    center?: google.maps.LatLngLiteral;

    onderhoudAantal?: number;
    interventieAantal?: number;
    co2BestellingAantal?: number;
    bestellingAantal?: number;
    installatieAantal?: number;

    bezoekInstructies?: string;
    vasteAdresInstructies?: string;

    cardStatus?: MDBCardStatusEnum;

    containerRef?: Ref<any>;
}

export const PlanningStopCard: React.FC<PlanningStopCardProps> = React.memo((props) => {
    const {
        name,
        className,
        link,
        linkOnClick,
        from,
        fromEstimated,
        toEstimated,
        active,
        loading,
        disabled,
        adres,
        onderhoudAantal,
        interventieAantal,
        co2BestellingAantal,
        bestellingAantal,
        installatieAantal,
        bezoekInstructies,
        vasteAdresInstructies,
        cardStatus,
        containerRef
    } = props;

    const mapsUrl = useMemo(() => {
        return GoogleMapsUtils.getStaticImage({
            center: `${props.center?.lat},${props.center?.lng}`,
            height: 100,
            width: 800,
            maptype: "roadtype",
            zoom: 15,
            markers: [
                {
                    latitude: props.center?.lat!,
                    longitude: props.center?.lng!,
                    color: "red",
                    size: "tiny"
                }
            ],
            scale: 2
        })
    }, [props.center?.lat, props.center?.lng]);

    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault();
        }

        linkOnClick?.();
    };

    const hasTaken = !!onderhoudAantal || !!interventieAantal || !!co2BestellingAantal || !!bestellingAantal || !!installatieAantal;

    const {t} = useTranslation("planning");

    return (
        <NavLink to={link || ""} onClick={onClick} className={className} ref={containerRef}>
            <MDBCard background="light">
                <MDBCardStatus status={cardStatus}/>

                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    <MDBCardBody className="position-relative">
                        <MDBRow>
                            <MDBCol size="auto">
                                <div className="pe-0 h-100 d-flex flex-column justify-content-between">
                                    <div>
                                        {from && (
                                            <MDBCardTitle className="d-flex align-items-center">
                                                <Datum datum={from} format="HH:mm" parseFormat="HH:mm:ss"/> <Icon
                                                path={mdiClockAlert} size={1} className="ms-1"/>
                                            </MDBCardTitle>
                                        )}
                                        <MDBCardTitle
                                            className="d-flex align-items-center text-muted">
                                            <Datum datum={fromEstimated} format="HH:mm" parseFormat="HH:mm:ss"/>
                                        </MDBCardTitle>
                                    </div>

                                    {toEstimated && (
                                        <MDBCardTitle className="d-flex align-items-center text-muted">
                                            <Datum datum={toEstimated} format="HH:mm" parseFormat="HH:mm:ss"/>
                                        </MDBCardTitle>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="ps-0 position-relative" style={{zIndex: 1000}}>
                                    <MDBCardTitle className="d-flex align-items-center">
                                        {active && <LocationPin className="me-3"/>}
                                        {name}
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        {adres}
                                    </MDBCardText>
                                </div>
                            </MDBCol>
                        </MDBRow>

                        {props.center && (
                            <div
                                className="d-none d-md-block"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    bottom: 0,

                                    width: 400,

                                    background: `linear-gradient(90deg, rgba(249,249,249,1) 0%, rgba(249,249,249,1) 20%, rgba(0,0,0,0) 100%), url('${mapsUrl}')`,
                                    backgroundSize: "cover"
                                }}
                            />
                        )}
                    </MDBCardBody>

                    {hasTaken && (
                        <MDBCardFooter className="d-flex justify-content-between">
                            <div>
                                {bezoekInstructies && (
                                    <small className="ms-2 text-muted">
                                        <TruncateText text={bezoekInstructies} max={50}/>{" "}
                                    </small>
                                )}

                                {vasteAdresInstructies && (
                                    <small className="text-muted">
                                        <TruncateText text={vasteAdresInstructies} max={50}/>
                                    </small>
                                )}
                            </div>

                            <div className="d-flex">
                                {!!onderhoudAantal && (
                                    <MDBBadge pill className="d-flex align-items-center me-2">
                                        <Icon path={Assets.orderTypes.onderhoud} size={0.7}
                                              className="text-primary me-1 text-white"/>
                                        {t("planning:PlanningStopCard.onderhoud-aantal-x-onderhoud",
                                            "{{aantal}} x onderhoud",
                                            {aantal: props.onderhoudAantal})}
                                    </MDBBadge>
                                )}

                                {!!interventieAantal && (
                                    <MDBBadge pill className="d-flex align-items-center me-2">
                                        <Icon path={Assets.orderTypes.interventie} size={0.7}
                                              className="text-primary me-1 text-white"/>
                                        {t("planning:PlanningStopCard.interventieAantal-x-interventie", "" +
                                            "{{aantal}} x interventie",
                                            {aantal: props.interventieAantal})}
                                    </MDBBadge>
                                )}

                                {!!co2BestellingAantal && (
                                    <MDBBadge pill className="d-flex align-items-center me-2">
                                        <Icon path={Assets.orderTypes.co2Levering} size={0.7}
                                              className="text-primary me-1 text-white"/>
                                        {t("PlanningStopCard.co2BestellingAantal-aantal-CO2",
                                            "{{aantal}} x CO₂",
                                            {aantal: props.co2BestellingAantal})}
                                    </MDBBadge>
                                )}

                                {!!bestellingAantal && (
                                    <MDBBadge pill className="d-flex align-items-center me-2">
                                        <Icon path={Assets.orderTypes.levering} size={0.7}
                                              className="text-primary me-1 text-white"/>
                                        {t("PlanningStopCard.aantal-accessoirelevering",
                                            "{{aantal}} x accessoirelevering",
                                            {aantal: props.bestellingAantal})}
                                    </MDBBadge>
                                )}

                                {!!installatieAantal && (
                                    <MDBBadge pill className="d-flex align-items-center me-2">
                                        <Icon path={Assets.orderTypes.installatie} size={0.7}
                                              className="text-primary me-1 text-white"/>
                                        {t("PlanningStopCard.aantal-installatie",
                                            "{{aantal}}x installatie",
                                            {aantal: props.installatieAantal})}
                                    </MDBBadge>
                                )}
                            </div>
                        </MDBCardFooter>
                    )}
                </MDBRipple>

                <IndeterminateProgress loading={loading}/>
            </MDBCard>
        </NavLink>
    );
});
