export enum MessageType {
    BROWSER_BEZOEKSESSIE_EVENT = "BROWSER_BEZOEKSESSIE_EVENT",

    CALCULATE_BEZOEKSESSIE_SNAPSHOT = "CALCULATE_BEZOEKSESSIE_SNAPSHOT",
    CALCULATE_BEZOEKSESSIE_SNAPSHOT_BY_ID = "CALCULATE_BEZOEKSESSIE_SNAPSHOT_BY_ID",
    BEZOEKSESSIE_SNAPSHOT = "BEZOEKSESSIE_SNAPSHOT",

    PLANNING_AANGEPAST = "PLANNING_AANGEPAST",

    SYNC_PLANNING = "SYNC_PLANNING",
    SYNC_PLANNING_GEBASEERD_OP_VERSIE = "SYNC_PLANNING_GEBASEERD_OP_VERSIE",

    SYNC_ACTIEVE_BEZOEKSESSIES = "SYNC_ACTIEVE_BEZOEKSESSIES",
    SYNC_BEZOEKSESSIE_BY_ID = "SYNC_BEZOEKSESSIE_BY_ID",
    SYNC_ARTIKELEN = "SYNC_ARTIKELEN",
    SYNC_ARTIKEL_AFBEELDINGEN = "SYNC_ARTIKELEN_AFBEELDINGEN",

    HYDRATE_FRONTEND = "HYDRATE_FRONTEND",
    HYDRATE_FRONTEND_COMPLETE = "HYDRATE_FRONTEND_COMPLETE",

    DAG_PLANNING_DATUM_GEWIJZIGD_DOOR_GEBRUIKER = "DAG_PLANNING_DATUM_GEWIJZIGD_DOOR_GEBRUIKER",
    DAG_PLANNING_OUT_OF_DATE = "DAG_PLANNING_OUT_OF_DATE",

    LOCAL_EVENT_CREATED = "LOCAL_EVENT_CREATED",
    SERVER_EVENT_CREATED = "SERVER_EVENT_CREATED",
    BEZOEK_SESSIE_CREATED = "BEZOEK_SESSIE_CREATED",
    BEZOEK_SESSIE_DELETED = "BEZOEK_SESSIE_DELETED",

    MANUAL_SYNC_TO_SERVER = "MANUAL_SYNC_TO_SERVER",
    SYNC_TO_SERVER_COMPLETE = "SYNC_TO_SERVER_COMPLETE",
    SERVER_BEZOEKSESSIE_EVENTS_SYNC_COMPLETE = "SERVER_BEZOEKSESSIE_EVENTS_SYNC_COMPLETE",
    SERVER_ACTIEVE_BEZOEKSESSIES_SYNC_COMPLETE = "SERVER_ACTIEVE_BEZOEKSESSIES_SYNC_COMPLETE",
    ARTIKELEN_SYNC_COMPLETE = "ARTIKELEN_SYNC_COMPLETE",
    ARTIKEL_AFBEELDINGEN_SYNC_COMPLETE = "ARTIKEL_AFBEELDINGEN_SYNC_COMPLETE",

    LOCAL_FILE_CREATED = "LOCAL_FILE_CREATED",
    UPLOAD_FILE = "UPLOAD_FILE",

    PERIODIC_SYNC_RUNNING = "PERIODIC_SYNC_RUNNING",

    SKIP_WAITING = "SKIP_WAITING",

    QR = "QR",
    GET_VERSION = "GET_VERSION",
    GET_WEB_SOCKET_STATUS = "GET_WEB_SOCKET_STATUS",

    USER_LOGGED_IN = "USER_LOGGED_IN",
    USER_LOGGED_OUT = "USER_LOGGED_OUT",
    TOKEN_REFRESHED = "TOKEN_REFRESHED",
    GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN",
    REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED",

    BROWSER_ONLINE = "BROWSER_ONLINE",
    BROWSER_OFFLINE = "BROWSER_OFFLINE",
    SW_ONLINE = "SW_ONLINE",
    SW_OFFLINE = "SW_OFFLINE",

    REDUX = "REDUX",

    FRONTEND_ERROR = "FRONTEND_ERROR",
    SW_ERROR = "SW_ERROR",
    REMOVE_ERROR = "REMOVE_ERROR",
    CLEAR_ERRORS = "CLEAR_ERRORS"
}
