import React from "react";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {MDBFormikCheckbox, MDBFormikCheckboxProps} from "../MDBFormikCheckbox";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";

export const BezoekMDBFormikCheckbox: React.FC<MDBFormikCheckboxProps> = (props) => {
    const {bezoekSessieId} = useParams<{ bezoekSessieId?: string }>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    return (
        <MDBFormikCheckbox {...props} disabled={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>
    );
};
