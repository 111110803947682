import React, {useCallback} from "react";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCol, MDBRow} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiInformationOutline} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {Form, Formik} from "formik";
import {useGoBack} from "../../../routes";
import {useMe} from "../../../redux/slices/technicus/hooks";
import {useToestel, useToestelCheckIn} from "../../../redux/slices/toestellen/hooks";
import TextFit from "react-textfit";
import {MonospacedLetterBox} from "../../../components/MonospacedLetterBox";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface ToestelCheckInFormValues {

}

export interface ToestelCheckInPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ToestelCheckInPage: React.FC = () => {
    const goBack = useGoBack();

    const {bezoekSessieId, toestelId} = useParams<ToestelCheckInPageParams>();

    const toestel = useToestel(toestelId);
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const technicus = useMe();
    const toestelCheckIn = useToestelCheckIn();

    const onSubmit = useCallback((values: ToestelCheckInFormValues) => {
        if (!bezoekSessie || !technicus) {
            return;
        }

        toestelCheckIn(bezoekSessie.id, toestelId);

        goBack();
    }, [bezoekSessie, toestelCheckIn, goBack, technicus, toestelId]);

    const {t} = useTranslation("serviceadres");

    const initialValues: ToestelCheckInFormValues = {};

    if (!serviceAdres || !toestel) {
        return null;
    }

    return (
        <GenericPage>
            <Header title="Toestel inchecken"
                    subtitle={toestel?.locatieBeschrijving}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader>
                        <Icon path={mdiInformationOutline} size={1}/> {t("ToestelCheckinPage.toestelinformatie", "Toestelinformatie")}
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.toestel", "Toestel:")} </strong><br/> {toestel.omschrijving}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.nummer", "Nummer:")} </strong><br/> {toestel.nr}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.serienummer", "Serienummer:")} </strong><br/> {toestel.serieNummer}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.installatiedatum","Installatiedatum:")}</strong><br/> {toestel.installatieDatum}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.type", "Type:")}</strong><br/> {toestel.transactieType}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.locatiebeschrijving", "Locatiebeschrijving:")}</strong><br/> {toestel.locatieBeschrijving ||
                                    <span className="text-muted">{t("algemeen:geen", "Geen")}</span>}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <Formik<ToestelCheckInFormValues> initialValues={initialValues} onSubmit={onSubmit}>
                    {
                        ({values}) => {
                            return (
                                <Form autoComplete="off">
                                    <DisableAutocompletePlaceholderInput/>
                                    <MDBRow>
                                        <MDBCol>
                                            <h2>{t("ToestelCheckInPage.komt-het-serienummer-overeen", "Komt het serienummer overeen?")}</h2>
                                        </MDBCol>
                                    </MDBRow>

                                    <TextFit mode="single">
                                        <div className="text-center mb-4">
                                            {toestel.serieNummer && <MonospacedLetterBox text={toestel.serieNummer}/>}
                                        </div>
                                    </TextFit>

                                    <BezoekMDBBtn size="lg" block type="submit">
                                        {t("ToestelCheckinPage.inchecken", "Inchecken")}
                                    </BezoekMDBBtn>
                                </Form>
                            );
                        }
                    }
                </Formik>
            </ContentContainer>
        </GenericPage>
    );
};
