import React from "react";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";
import {MDBFormikTextArea, MDBFormikTextAreaProps} from "../MDBFormikTextArea";

export const BezoekMDBFormikTextArea: React.FC<MDBFormikTextAreaProps> = (props) => {
    const {bezoekSessieId} = useParams<{ bezoekSessieId?: string }>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    return (
        <MDBFormikTextArea {...props} disabled={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN || props.disabled}/>
    );
};
