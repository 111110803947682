import {useCallback} from "react";
import {downloadsSaga} from "./saga";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {requestStatuses} from "redux-resource";

export interface UseDownloadFileByUriOptions {
    uri: string;
    omschrijving?: string;

    onSuccess?: () => void;
}

export const useDownloadFileCallback = () => {
    const dispatch = useAppDispatch();

    return useCallback((options: UseDownloadFileByUriOptions) => {
        const {uri, omschrijving, onSuccess} = options;

        dispatch(downloadsSaga.actions.downloadFile({uri, omschrijving, onSuccess}));
    }, [dispatch]);
}

export const useDownloadFileByUri = (options: UseDownloadFileByUriOptions) => {
    const {uri} = options;

    const downloadFile = useDownloadFileCallback();
    const state = useAppSelector((state) => ({
        loading: state.downloads.requests[uri]?.status === requestStatuses.PENDING,
        error: state.downloads.requests[uri]?.error
    }));

    const callback = useCallback(() => {
        downloadFile(options);
    }, [downloadFile, options]);

    return [callback, state] as [typeof callback, typeof state];
};

export const useDownloadInstallatieBijlage = (installatieId: string, bijlageId: string) => {
    const dispatch = useAppDispatch();

    const requestKey = `downloadInstallatieBijlage:${bijlageId}`;
    const state = useAppSelector((state) => ({
        loading: state.downloads.requests[requestKey]?.status === requestStatuses.PENDING,
        error: state.downloads.requests[requestKey]?.error
    }));

    const callback = useCallback(() => {
        dispatch(downloadsSaga.actions.downloadInstallatieBijlage({installatieId, bijlageId}))
    }, [dispatch, installatieId, bijlageId]);

    return [callback, state] as [typeof callback, typeof state];
};
