import React from "react";
import {AdresAanwezigheid} from "../workers/shared/snapshot/bezoekSessieState";
import {useMe} from "../redux/slices/technicus/hooks";

export interface InAdresAanwezigheidStatusProps {
    adresAanwezigheid?: Record<string, AdresAanwezigheid>;

    anyOf: (AdresAanwezigheid | undefined)[];

    children?: React.ReactNode;
}

export const InAdresAanwezigheidStatus: React.FC<InAdresAanwezigheidStatusProps> = (props) => {
    const {adresAanwezigheid, anyOf} = props;

    const technicus = useMe();

    if (!technicus) {
        return null;
    }

    const mijnAdresAanwezigheid = adresAanwezigheid?.[technicus.id];

    if (anyOf.includes(mijnAdresAanwezigheid)) {
        return <>{props.children}</>;
    }

    return null;
};
