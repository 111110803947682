import React from "react";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBListGroup, MDBListGroupItem} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {Assets} from "../../../assets/assets";
import {ToestelOnderhoud} from "../../../_generated/field-service-be-openapi";
import {useToestel} from "../../../redux/slices/toestellen/hooks";
import {mdiCheck} from "@mdi/js";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface GeplandWerkOnderhoudTaakProps {
    toestelId: string;
    onderhoud: ToestelOnderhoud;
}

export const GeplandWerkOnderhoudTaakCard: React.FC<GeplandWerkOnderhoudTaakProps> = (props) => {
    const {
        toestelId,
        onderhoud
    } = props;

    const toestel = useToestel(toestelId);

    const {t} = useTranslation("serviceadres");

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={Assets.orderTypes.levering}
                                 size={1}/>{t("planning:GeplandWerkOnderhoudTaakCard.titel",
                "Onderhoud {{artikelOmschrijving}} ({{toestelNr}}, {{locatieBeschrijving}})",
                {
                    artikelOmschrijving: toestel?.artikelOmschrijving,
                    toestelNr: toestel?.nr,
                    locatieBeschrijving: toestel?.locatieBeschrijving
                })}
            </MDBCardHeader>

            <MDBCardBody className="p-0">
                <MDBListGroup flush>
                    {onderhoud.stappen?.reinigenEnSpoelen &&
                        <MDBListGroupItem className="bg-light"><Icon path={mdiCheck}
                                                                     size={1}/> {t("planning:GeplandWerkOnderhoudTaakCard.reinigen-en-spoelen", "Reinigen en spoelen")}
                        </MDBListGroupItem>}
                    {onderhoud.stappen?.co2Vervangen &&
                        <MDBListGroupItem className="bg-light"><Icon path={mdiCheck}
                                                                     size={1}/> {t("planning:GeplandWerkOnderhoudTaakCard.CO2-vervangen", "CO₂ vervangen")}
                        </MDBListGroupItem>}
                    {onderhoud.stappen?.uvVervangen &&
                        <MDBListGroupItem className="bg-light"><Icon path={mdiCheck}
                                                                     size={1}/> {t("planning:GeplandWerkOnderhoudTaakCard.UV-vervangen", "UV vervangen")}
                        </MDBListGroupItem>}
                    {onderhoud.stappen?.tappuntWisselen &&
                        <MDBListGroupItem className="bg-light"><Icon path={mdiCheck}
                                                                     size={1}/> {t("planning:GeplandWerkOnderhoudTaakCard.tappunt-wisselen", "Tappunt wisselen")}
                        </MDBListGroupItem>}
                    {!!onderhoud.stappen?.filtersVervangen?.length &&
                        <MDBListGroupItem className="bg-light"><Icon path={mdiCheck}
                                                                     size={1}/> {t("planning:GeplandWerkOnderhoudTaakCard.filters-vervangen", "Filters vervangen")}
                            <p className="mr-5 mt-1">
                                {onderhoud.stappen?.filtersVervangen?.map((filter) =>
                                    <span key={filter.artikelId}>
                                        <strong>{filter.artikelNummer}</strong> {filter.artikelOmschrijving}<br/>
                                    </span>
                                )}
                            </p>
                        </MDBListGroupItem>
                    }
                    {onderhoud.stappen?.controleWerking &&
                        <MDBListGroupItem className="bg-light"><Icon path={mdiCheck}
                                                                     size={1}/> {t("planning:GeplandWerkOnderhoudTaakCard.controle-werking", "Controle werking")}
                        </MDBListGroupItem>}
                </MDBListGroup>
            </MDBCardBody>
        </MDBCard>
    );
};
