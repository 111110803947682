import React, {CSSProperties, ReactNode, useState} from "react";
import {Lightbox} from "./Lightbox";

export interface LightboxBackgroundImageProps {
    src: string;
    title?: ReactNode;
    caption?: ReactNode;
    className?: string;
    style?: CSSProperties;
}

export const LightboxBackgroundImage: React.FC<LightboxBackgroundImageProps> = (props) => {
    const {src, title, caption, className, style} = props;

    const [open, setOpen] = useState(false);

    return (
        <>
            <div style={{width: "100%", height: "100%", backgroundImage: `url(${src})`, ...(style || {})}}
                 className={`${className || ""} cursor-pointer bg-contain bg-no-repeat bg-center`}
                 onClick={() => setOpen(true)}
            />

            {src && <Lightbox images={[{url: src, title, caption}]} open={open} onClose={() => setOpen(false)} />}
        </>
    );
};
