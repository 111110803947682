import React, {ChangeEvent, useMemo, useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBListGroup,
    MDBListGroupItem,
    MDBModalBody,
    MDBModalHeader,
    MDBRow
} from "mdb-react-ui-kit";
import {FixedSizeList} from "react-window";
import {useTechnici} from "../../redux/slices/technicus/hooks";
import {FrameModal} from "./FrameModal";
import {Technicus} from "../../_generated/field-service-be-openapi";
import {useDimensions} from "../../utilities/useDimensions";

export interface TechnicusToevoegenModalProps {
    onConfirm?(technicusId: string): void;

    onClose?(): void;
}

export const TechnicusToevoegenModal: React.FC<TechnicusToevoegenModalProps> = (props) => {
    const {onConfirm, onClose} = props;

    const [activeTechnicusId, setActiveTechnicusId] = useState<string>();
    const [search, setSearch] = useState("");

    const [listWrapperRef, listWrapperSize] = useDimensions();
    const technici = useTechnici();

    const filteredTechnici = useMemo(() => {
        const sort = (a: Technicus, b: Technicus) => {
            return a.naam.localeCompare(b.naam);
        };

        if (!search) {
            return Object.values(technici).sort(sort);
        }

        return Object.values(technici).filter((item) => item.naam.toLowerCase().includes(search.toLowerCase())).sort(sort);
    }, [technici, search]);

    const onOkeClick = () => {
        if (activeTechnicusId) {
            onClose?.();

            if (onConfirm) {
                onConfirm(activeTechnicusId);
            }
        }
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const Item = (props: any) => {
        const {index, style} = props;

        const technicus = filteredTechnici[index];

        return (
            <MDBListGroupItem style={style} active={activeTechnicusId === technicus.id}
                              onClick={() => setActiveTechnicusId(technicus.id)} action className="p-0 border-0">
                <MDBRow className="h-100">
                    <MDBCol className="ps-4 p-2 d-flex align-items-center">
                        <span>{filteredTechnici[index]?.naam}</span>
                    </MDBCol>
                </MDBRow>
            </MDBListGroupItem>
        );
    }

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>Technicus toevoegen</MDBModalHeader>

            <MDBModalBody>
                <MDBRow>
                    <MDBCol>
                        <MDBInput label="Zoeken" value={search}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}/>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <div className="d-flex flex-column" style={{height: "50vh"}}>
                <div className="flex-fill" ref={listWrapperRef}>
                    {listWrapperSize?.height && (
                        <MDBListGroup flush>
                            <FixedSizeList
                                width="100%"
                                height={listWrapperSize?.height}
                                itemSize={60}
                                overscanCount={10}
                                itemCount={filteredTechnici.length}
                            >
                                {Item}
                            </FixedSizeList>
                        </MDBListGroup>
                    )}
                </div>
            </div>

            <MDBModalBody className="py-1">
                <div className="d-flex justify-content-end align-items-center my-3">
                    <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                        Annuleren
                    </MDBBtn>
                    <MDBBtn type="button" color="success" size="lg" className="ms-2" onClick={onOkeClick}
                            disabled={!activeTechnicusId}>
                        Oké
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </FrameModal>
    );
};
