import React from "react";
import {MDBCard, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {Assets} from "../../../assets/assets";
import {AccessoireLevering} from "../../../_generated/field-service-be-openapi";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface GeplandWerkAccessoireBestellingTaakProps {
    leveringen: AccessoireLevering[];
}

export const GeplandWerkAccessoireBestellingTaakCard: React.FC<GeplandWerkAccessoireBestellingTaakProps> = (props) => {
    const {t} = useTranslation("serviceadres");

    const {
        leveringen
    } = props;

    if (!leveringen) {
        return null;
    }

    const oorsprong = leveringen[0].oorsprong;

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={Assets.orderTypes.levering}
                                 size={1}/> {t("planning:GeplandWerkAccessoireBestellingTaakCard.titel", "Accessoirebestelling")} {oorsprong.bestellingNummer || oorsprong.verkoopOrderNr}</MDBCardHeader>

            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th>{t("planning:GeplandWerkAccessoireBestellingTaakCard.artikel", "Artikel")}</th>
                        <th>{t("planning:GeplandWerkAccessoireBestellingTaakCard.aantal-besteld", "Aantal-besteld")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {leveringen.length === 0 && (
                        <tr>
                            <td colSpan={2}>{t("planning:GeplandWerkAccessoireBestellingTaakCard.er-zijn-geen-artikelen-voor-deze-bestelling",
                                "Er zijn geen artikelen voor deze bestelling.")}</td>
                        </tr>
                    )}
                    {leveringen.map((item) => {
                        return (
                            <tr key={item.artikelId}>
                                <td>
                                    <strong>{item.artikelNr}</strong> {item.artikelOmschrijving}
                                </td>
                                <td>
                                    {item.gevraagdAantal}
                                </td>
                            </tr>
                        );
                    })}

                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    );
};
