import React from "react";
import Icon from "@mdi/react";
import {mdiAlertCircle, mdiWifiOff} from "@mdi/js";
import {useTranslation} from "../../utilities/i18nUtils";
import {useHuidigeDagPlanning} from "../../redux/slices/dagPlanning/hooks";
import {PlanningInfoBox} from "./PlanningInfoBox";

export interface PlanningErrorProps {
    error: {
        name: string;
        message: string;
        stack?: string;
        status?: number;
    };
}

export const PlanningError: React.FC<PlanningErrorProps> = React.memo((props) => {
    const {error} = props;
    const {t} = useTranslation("planning");

    const [dagPlanning] = useHuidigeDagPlanning();

    const isDagPlanningAanwezig = !!dagPlanning?.entries?.length;
    const isBackendFout = error.status != null && error.status >= 500;

    if (isBackendFout) {
        return (
            <PlanningInfoBox variant="danger" icon={<Icon path={mdiAlertCircle} size={2.6}/>} fullWidth>
                <p className="display-6">
                    {t("planning:PlanningError.er-is-een-fout-opgetreden", "Er is een fout opgetreden bij het ophalen van de meest recente planning")}
                </p>

                <p>
                    {t("planning:PlanningError.er-is-een-probleem-bij-het-ophalen-van-je-planning", "Er is een probleem bij het ophalen van je planning. Probeer later opnieuw. Als het probleem blijft bestaan, neem contact op met de IT Helpdesk.")}
                </p>
            </PlanningInfoBox>
        );
    }

    return (
        <PlanningInfoBox variant="warning" icon={<Icon path={mdiAlertCircle} size={2.6}/>} fullWidth>
            <p className="display-6">
                {t("planning:PlanningError.er-is-een-fout-opgetreden", "Er is een fout opgetreden bij het ophalen van de meest recente planning")}
            </p>

            <p><Icon path={mdiWifiOff} size={1}/> {t("planning:PlanningError.er-is-een-probleem-met-de-verbinding", "Er is een probleem met de verbinding")}</p>

            {isDagPlanningAanwezig && (
                <p>{t("planning:PlanningError.de-planning-hieronder-kan-mogelijks-verouderd-zijn", "De planning hieronder kan mogelijks verouderd zijn.")}</p>
            )}
        </PlanningInfoBox>
    );
});
