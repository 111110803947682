import React from "react";
import {Portal} from "react-portal";

export const FabContainer: React.FC<{children?: React.ReactNode}> = (props) => {
    return (
        <Portal>
            <div className="position-fixed" style={{right: "2em", bottom: "6em"}}>
                {props.children}
            </div>
        </Portal>
    );
};
