import React, {ReactNode, useMemo} from "react";
import {ServiceAdresOpeningsurenRestModel} from "../_generated/field-service-be-openapi";
import dayjs from "dayjs";
import {Datum} from "./Datum";
import {parseDate} from "../utilities/dateUtils";
import {useTranslation} from "../utilities/i18nUtils";

export interface OpeningsurenProps {
    openingsuren?: ServiceAdresOpeningsurenRestModel;

    dag?: keyof ServiceAdresOpeningsurenRestModel;
    geslotenLabel?: ReactNode;
    onbekendLabel?: ReactNode;
}

const dagen: (keyof ServiceAdresOpeningsurenRestModel)[] = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];

export const Openingsuren: React.FC<OpeningsurenProps> = (props) => {

    const {t} = useTranslation("serviceadres");

    const {openingsuren, dag,
        geslotenLabel = t("Openingsuren.gesloten", "Gesloten"),
        onbekendLabel = t("Openingsuren.onbekend", "Onbekend")} = props;

    const huidigeDag = useMemo(() => {
        if (dag) {
            return dag;
        }

        const now = dayjs();

        return dagen[now.day()];
    }, [dag]);

    const hasSlots = dagen.some(dag => openingsuren?.[dag]?.slot1 || openingsuren?.[dag]?.slot2);
    if (!hasSlots) {
        return <>{onbekendLabel}</>;
    }

    const huidigeOpeningsuren = openingsuren?.[huidigeDag];

    if (!huidigeOpeningsuren || (!huidigeOpeningsuren?.slot1 && !huidigeOpeningsuren?.slot2)) {
        return <>{geslotenLabel}</>;
    }

    return (
        <>
            {huidigeOpeningsuren.slot1 && (
                <div className="pe-2">
                    <Datum datum={parseDate(huidigeOpeningsuren.slot1.van, "HH:mm:ss")} format="HH:mm"/> - <Datum
                    datum={parseDate(huidigeOpeningsuren.slot1.tot, "HH:mm:ss")} format="HH:mm"/>
                </div>
            )}

            {huidigeOpeningsuren.slot2 && (
                <div>
                    <Datum datum={parseDate(huidigeOpeningsuren.slot2.van, "HH:mm:ss")} format="HH:mm"/> - <Datum
                    datum={parseDate(huidigeOpeningsuren.slot2.tot, "HH:mm:ss")} format="HH:mm"/>
                </div>
            )}
        </>
    );
};
