import React, {useCallback, useMemo, useState} from "react";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {useClearErrors, useRemoveError, useSavedErrors} from "../redux/slices/status/hooks";
import {MDBBtn, MDBCol, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiDelete, mdiDeleteSweep, mdiShareVariantOutline} from "@mdi/js";
import {Config} from "../utilities/config";
import {ContentContainer} from "../components/ContentContainer";
import {ErrorRecord} from "../utilities/db/repository/errorRepository";
import {Datum} from "../components/Datum";
import {Snackbar} from "../components/Snackbar";
import {ErrorUtils} from "../utilities/errorUtils";

export const FoutmeldingenPage: React.FC = () => {
    const savedErrors = useSavedErrors();
    const removeError = useRemoveError();
    const clearErrors = useClearErrors();

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const errors = useMemo(() => savedErrors.concat().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [savedErrors]);

    const onShareClick = (errorRecord: ErrorRecord) => {
        const {error, timestamp} = errorRecord;

        const errorText = `Foutmelding met Field Service ${Config.VERSION} om ${timestamp}: ${ErrorUtils.toString(error)}\n\nStack: ${error?.stack}`;

        if ("share" in navigator) {
            navigator.share({
                title: `Field Service ${Config.VERSION} - Foutmelding`,
                text: errorText
            });
        } else {
            // @ts-ignore
            navigator.clipboard.writeText(errorText);
            setSnackbarOpen(true);
        }
    };

    const onDeleteClick = async (errorRecord: ErrorRecord) => {
        if (errorRecord.id) {
            removeError(errorRecord.id);
        }
    };

    const onClearClick = useCallback(() => {
        clearErrors();
    }, [clearErrors]);

    return (
        <GenericPage>
            <Header title="Foutmeldingen" sticky/>

            <ContentContainer>
                <div>
                    <MDBRow className="mb-5">
                        <MDBCol/>
                        <MDBCol size="auto">
                            <MDBBtn type="button" color="danger" onClick={onClearClick}>
                                <Icon path={mdiDeleteSweep} size={1}/> Leegmaken
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {errors.map(item => (
                        <div key={item.id}>
                            <MDBRow className="mb-3">
                                <MDBCol className="d-flex align-items-end">
                                    <small className="text-muted ms-2">
                                        <Datum format="DD-MM-YYYY HH:mm:ss" datum={item.timestamp}/>
                                    </small>
                                </MDBCol>

                                <MDBCol size="auto">
                                    <MDBBtn type="button" color="primary" onClick={() => onShareClick(item)}
                                            className="me-2">
                                        <Icon path={mdiShareVariantOutline} size={1}/>
                                    </MDBBtn>
                                    <MDBBtn type="button" color="primary" onClick={() => onDeleteClick(item)}>
                                        <Icon path={mdiDelete} size={1}/>
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>

                            <pre className="bg-light p-3" style={{borderRadius: 10, maxHeight: "20rem"}}>
                                {ErrorUtils.toString(item.error)}

                                <br/><br/>
                                <code>
                                    {item.error?.stack}
                                </code>
                            </pre>
                        </div>
                    ))}
                </div>
            </ContentContainer>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}
                      className="mb-6"
                      message="Informatie naar klembord gekopiëerd"/>
        </GenericPage>
    );
};
