import React from "react";

export interface AfstandProps {
    afstand: number;
    locale?: string;
}

export const Afstand: React.FC<AfstandProps> = (props) => {
    const {afstand, locale} = props;

    return (
        <>
            {Intl.NumberFormat(locale, {style: "unit", unit: "kilometer", minimumFractionDigits: 2, maximumFractionDigits: 2}).format((afstand / 1000))}
        </>
    );
};
