/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Klant,
} from '../models';

export interface GetKlantByIdRequest {
    klantId: string;
}

/**
 * 
 */
export class KlantApi extends runtime.BaseAPI {

    /**
     */
    async getKlantByIdRaw(requestParameters: GetKlantByIdRequest): Promise<runtime.ApiResponse<Klant>> {
        if (requestParameters.klantId === null || requestParameters.klantId === undefined) {
            throw new runtime.RequiredError('klantId','Required parameter requestParameters.klantId was null or undefined when calling getKlantById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/klant/{klantId}`.replace(`{${"klantId"}}`, encodeURIComponent(String(requestParameters.klantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getKlantById(requestParameters: GetKlantByIdRequest): Promise<Klant> {
        const response = await this.getKlantByIdRaw(requestParameters);
        return await response.value();
    }

}
