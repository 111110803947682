import {
    AccessoireLevering,
    AccessoireLeveringOorsprong,
    AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum,
    BezoekSessieEventMessage,
    BezoekSessieGestart,
    BezoekSessieSluitenAankondiging,
    Co2Levering,
    ExtraEmailAdres,
    GeinstalleerdComponent,
    GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum,
    Installatie,
    InstallatieUitgevoerdCo2Artikel, InstallatieUitgevoerdNietGeinstalleerdRedenEnum,
    InstallatieUitgevoerdOmgevingsFoto,
    InstallatieUitgevoerdRedenEnum,
    InstallatieUitgevoerdResultaatEnum,
    InstallatieUitgevoerdVerbruiktArtikel,
    PredefinedEmailAdres,
    ServiceAdres,
    StukToegevoegd,
    StukVerwijderd,
    Toestel,
    ToestelInterventieUitgevoerdControleWerking,
    ToestelInterventieUitgevoerdVerbruiktArtikel,
    ToestelOnderhoud,
    ToestelOnderhoudUitgevoerdStappen,
    ToestelUitTeVoerenWerk,
    UitTeVoerenWerk,
    WaterstandOpgenomenResultaatEnum
} from "../../../_generated/field-service-be-openapi";
import {NietUitgevoerdWerk} from "./uitgevoerdWerkChecker";
import {InstallatieFormValues} from "../../../pages/ServiceAdres/Opdracht/InstallatiePage";
import {AccessoireLeveringFormValues} from "../../../pages/ServiceAdres/Opdracht/AccessoireLeveringPage";
import {Co2LeveringFormValues} from "../../../pages/ServiceAdres/Opdracht/Co2LeveringPage";
import {InternFormValues} from "../../../pages/ServiceAdres/Opdracht/InternPage";
import {OnderhoudFormValues} from "../../../pages/ServiceAdres/Opdracht/OnderhoudPage";
import {InterventieFormValues} from "../../../pages/ServiceAdres/Opdracht/InterventiePage";
import {ToestelInformatieAanpassenFormValues} from "../../../components/modals/ToestelInformatieAanpassenModal";

export enum BezoekSessieStatus {
    OPEN = "OPEN",
    GESLOTEN = "GESLOTEN"
}

export enum AdresAanwezigheid {
    OBSERVATOR = "OBSERVATOR",
    ONDERWEG = "ONDERWEG",
    AANWEZIG = "AANWEZIG",
    VERLATEN = "VERLATEN",
}

export interface BezoekSessieToestelInterventie {
    id: string;
    toestelId: string;

    draftValues?: InterventieFormValues;

    controleWerking?: ToestelInterventieUitgevoerdControleWerking;
    verbruikteArtikelen: ToestelInterventieUitgevoerdVerbruiktArtikel[];
    opmerking?: string;
    technicusId?: string;

    soortCode?: string;
    soortLabel?: string;

    bezig: boolean;
    uitgevoerd: boolean;
    verwijderd: boolean;

    technicusTijden: Record<string, TechnicusTijd>;
}

export interface BezoekSessieToestelOnderhoud {
    draftValues?: OnderhoudFormValues;
    uitTeVoerenWerk?: ToestelOnderhoud;

    form?: {
        uitgevoerdeStappen?: ToestelOnderhoudUitgevoerdStappen | null;
    };

    bezig: boolean;
    uitgevoerd: boolean;
    verwijderd: boolean;
}

export class BezoekSessieToestel {
    informatie?: Toestel;
    informatieDraftValues?: ToestelInformatieAanpassenFormValues;

    nieuweOpmerking?: string;

    uitTeVoerenWerk?: ToestelUitTeVoerenWerk;

    actieveTechnicusIds: string[];

    aanwezig: boolean;
    uitgevoerd: boolean;
    bezig: boolean;

    alleVereisteTakenUitgevoerd: boolean;
    heeftUitTeVoerenWerk: boolean;
    laatstActieveTechnicusId?: string;

    waterstand?: {
        resultaat: WaterstandOpgenomenResultaatEnum;
        meterstand?: number;
    };

    onderhoud?: BezoekSessieToestelOnderhoud;
    interventies: Record<string, BezoekSessieToestelInterventie>;

    stuklijstToegevoegdeStukken: StukToegevoegd[];
    stuklijstVerwijderdeStukken: StukVerwijderd[];

    constructor(props: ExcludeFunctionPropertyNames<BezoekSessieToestel>) {
        this.informatie = props.informatie;
        this.informatieDraftValues = props.informatieDraftValues;
        this.nieuweOpmerking = props.nieuweOpmerking;
        this.uitTeVoerenWerk = props.uitTeVoerenWerk;
        this.actieveTechnicusIds = props.actieveTechnicusIds;
        this.aanwezig = props.aanwezig;
        this.uitgevoerd = props.uitgevoerd;
        this.bezig = props.bezig;
        this.alleVereisteTakenUitgevoerd = props.alleVereisteTakenUitgevoerd;
        this.heeftUitTeVoerenWerk = props.heeftUitTeVoerenWerk;
        this.laatstActieveTechnicusId = props.laatstActieveTechnicusId;
        this.waterstand = props.waterstand;
        this.onderhoud = props.onderhoud;
        this.interventies = props.interventies;
        this.stuklijstToegevoegdeStukken = props.stuklijstToegevoegdeStukken;
        this.stuklijstVerwijderdeStukken = props.stuklijstVerwijderdeStukken;
    }

    getOnderhoud() {
        if (this.onderhoud?.verwijderd) {
            return undefined;
        }

        return this.onderhoud;
    }

    getInterventies() {
        return Object.values(this.interventies).filter(item => !item.verwijderd);
    }

}

export interface BezoekSessieCo2LeveringItem {
    artikelId: string;
    aantalGeleverd: number;
    aantalOmruilGeleverd: number;
    aantalGebruiksrecht: number;
    terPlaatse: boolean;
    onvolledigeLeveringReden?: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum;

    technicusId: string;
}

export interface BezoekSessieCo2Levering {
    draftValues?: Co2LeveringFormValues;
    uitTeVoerenWerk?: Co2Levering[];

    leveringen: Record<string, BezoekSessieCo2LeveringItem>;
    toestemmingKlantAfwijkendeBestelling?: boolean;

    bezig: boolean;
    uitgevoerd: boolean;
    laatstActieveTechnicusId?: string;
}

export interface TechnicusTijd {
    technicusId: string;
    vanTijd: string;
    totTijd?: string;
}

export interface BezoekSessieInstallatie {
    id: string;
    uitTeVoerenWerk?: Installatie;

    draftValues?: InstallatieFormValues;
    form?: {
        geinstalleerdeComponenten: Array<GeinstalleerdComponent>;
        verbruikteArtikelen: Array<InstallatieUitgevoerdVerbruiktArtikel>;
        opmerking?: string;
        technicusId: string;
        resultaat: InstallatieUitgevoerdResultaatEnum;
        fotos: Array<InstallatieUitgevoerdOmgevingsFoto>;
        reden?: InstallatieUitgevoerdRedenEnum;
        nietGeinstalleerdReden?: InstallatieUitgevoerdNietGeinstalleerdRedenEnum;
        co2Artikelen: Array<InstallatieUitgevoerdCo2Artikel>;

        locatie?: string;
        gebouw?: string;
        verdieping?: string;
        lokaalNummer?: string;
    };

    technicusTijden: Record<string, TechnicusTijd>;

    bezig: boolean;
    uitgevoerd: boolean;
    verwijderd: boolean;
    laatstActieveTechnicusId?: string;
}

export interface BezoekSessieAccessoireLeveringArtikel {
    artikelId: string;
    artikelNr: string;
    artikelOmschrijving: string;
    gevraagdAantal?: number;
    vorigGevraagdAantal?: number;
    geleverdAantal?: number;
    onvolledigeLeveringReden?: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum;
}

export interface BezoekSessieAccessoireLevering {
    id: string;
    uitTeVoerenWerk?: AccessoireLevering;
    artikelen: BezoekSessieAccessoireLeveringArtikel[];

    oorsprong?: AccessoireLeveringOorsprong;

    uitgevoerd: boolean;
    verwijderd: boolean;
    laatstActieveTechnicusId?: string;

    creatieTijdstip?: string;
}

type ExcludeFunctionPropertyNames<T> = Pick<T, {
    [K in keyof T]: T[K] extends Function ? never : K
}[keyof T]>;

export class BezoekSessie {
    id: string;
    status: BezoekSessieStatus;

    planningVersie: number;
    planningVersieTijdstip?: string;

    bezoekId?: string;
    serviceAdres?: ServiceAdres;
    uitTeVoerenWerk?: UitTeVoerenWerk;
    uitTeVoerenWerkVersieTijdstip?: string;

    aanwezig: boolean;
    onderweg: boolean;
    verlaten: boolean;
    nietUitgevoerdWerk: NietUitgevoerdWerk[];
    sluitenAankondiging?: BezoekSessieSluitenAankondiging;

    gestartOp?: string;
    beeindigdOp?: string;
    laatstGewijzigd?: string;

    geplandStart?: string;
    geplandEind?: string;
    afgesprokenStart?: string;
    voorzieneTijd?: number;

    actieveTechniciIds: string[];
    adresAanwezigheid: Record<string, AdresAanwezigheid>; // technicus-id

    toestellen: Record<string, BezoekSessieToestel>;
    co2Levering: BezoekSessieCo2Levering;

    accessoireLeveringenDraftValues?: AccessoireLeveringFormValues;
    accessoireLeveringen: Record<string, BezoekSessieAccessoireLevering>; // levering-id
    installaties: Record<string, BezoekSessieInstallatie>; // installatie-id

    handtekening?: {
        image: string;
        ondertekenaar: string;
        opmerking?: string;

        predefinedEmailAdressen: PredefinedEmailAdres[];
        extraEmailAdressen: ExtraEmailAdres[];
        laatstActieveTechnicusId?: string;
    };

    internDraftValues?: InternFormValues;
    intern?: {
        opmerking: string;
        laatstActieveTechnicusId?: string;
    };

    deviceInfoGelogdPerInstanceId: Record<string, boolean>;

    eventsTotalAmount: number;
    eventsSyncedAmount: number;

    constructor(props: ExcludeFunctionPropertyNames<BezoekSessie>) {
        this.id = props.id;
        this.status = props.status;
        this.planningVersie = props.planningVersie;
        this.planningVersieTijdstip = props.planningVersieTijdstip;
        this.bezoekId = props.bezoekId;
        this.serviceAdres = props.serviceAdres;
        this.uitTeVoerenWerk = props.uitTeVoerenWerk;
        this.uitTeVoerenWerkVersieTijdstip = props.uitTeVoerenWerkVersieTijdstip;
        this.aanwezig = props.aanwezig;
        this.onderweg = props.onderweg;
        this.verlaten = props.verlaten;
        this.nietUitgevoerdWerk = props.nietUitgevoerdWerk;
        this.sluitenAankondiging = props.sluitenAankondiging;
        this.gestartOp = props.gestartOp;
        this.beeindigdOp = props.beeindigdOp;
        this.laatstGewijzigd = props.laatstGewijzigd;
        this.geplandStart = props.geplandStart;
        this.geplandEind = props.geplandEind;
        this.afgesprokenStart = props.afgesprokenStart;
        this.voorzieneTijd = props.voorzieneTijd;
        this.actieveTechniciIds = props.actieveTechniciIds;
        this.adresAanwezigheid = props.adresAanwezigheid;
        this.toestellen = props.toestellen;
        this.co2Levering = props.co2Levering;
        this.accessoireLeveringen = props.accessoireLeveringen;
        this.installaties = props.installaties;
        this.handtekening = props.handtekening;
        this.intern = props.intern;
        this.internDraftValues = props.internDraftValues;
        this.deviceInfoGelogdPerInstanceId = props.deviceInfoGelogdPerInstanceId;
        this.eventsTotalAmount = props.eventsTotalAmount;
        this.eventsSyncedAmount = props.eventsSyncedAmount;
    }

    getInstallaties(): BezoekSessieInstallatie[] {
        return Object.values(this.installaties).filter(item => !item.verwijderd);
    }

    getAccessoireLeveringen(): BezoekSessieAccessoireLevering[]  {
        return Object.values(this.accessoireLeveringen).filter(item => !item.verwijderd);
    }

}

/* Redux */
export interface BezoekSessieState {
    loaded: boolean;
    bezoekSessies: Record<string, BezoekSessie>;
    bezoekIdMap: Record<string, string>;

    error?: {
        name: string;
        message: string;
        stack?: string;
    };
}

export type BezoekSessieGestartEventMessageExtended = Modify<BezoekSessieEventMessage, {
    payload: {
        type: "BEZOEK_SESSIE_GESTART";

        bezoekId: string;
        serviceAdres: ServiceAdres;
        toestellen: Record<string, Toestel>;
    } & BezoekSessieGestart;
}>;
