import React from "react";
import {KlantExtraCo2FlesBeleidEnum} from "../../_generated/field-service-be-openapi";
import {useTranslation} from "../../utilities/i18nUtils";
import {MDBTypography} from "../../mdb-react-ui-kit";
import {Trans} from "react-i18next";

export interface Co2GebruiksrechtWaarschuwingProps {
    aantal: number;
    extraCo2FlesBeleid?: KlantExtraCo2FlesBeleidEnum;
}

export const Co2GebruiksrechtWaarschuwing: React.FC<Co2GebruiksrechtWaarschuwingProps> = (props) => {
    const {aantal, extraCo2FlesBeleid} = props;

    const {t} = useTranslation("bezoeken");


    if (extraCo2FlesBeleid === KlantExtraCo2FlesBeleidEnum.Geen) {
        return null;
    }

    if (extraCo2FlesBeleid === KlantExtraCo2FlesBeleidEnum.Waarborg) {
        return (<MDBTypography note noteColor="warning" className="mb-2">
            <Trans t={t}
                   i18nKey={"co2waarborgwaarschuwing"}
                   count={aantal}>
                <strong>Opgelet: </strong> Er wordt {{aantal}} niet bestelde fles geleverd waarvoor de klant waarborg
                dient te betalen
            </Trans>
        </MDBTypography>)
    } else {
        return (<MDBTypography note noteColor="warning" className="mb-2">
            <Trans t={t}
                   i18nKey={"co2gebruiksrechtwaarschuwing"}
                   count={aantal}>
                <strong>Opgelet: </strong> Er wordt {{aantal}} niet bestelde fles geleverd waarvoor de klant gebruiksrecht
                dient te betalen
            </Trans>
        </MDBTypography>)
    }

}
