import React, {useCallback, useMemo, useRef} from "react";
import {
    MDBAlert,
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTypography
} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {
    mdiArchiveArrowUpOutline,
    mdiCheckUnderline,
    mdiChevronRight,
    mdiClipboardListOutline,
    mdiClockOutline,
    mdiImage,
    mdiMoleculeCo2,
    mdiPlus,
    mdiText,
    mdiTrashCan,
    mdiTrashCanOutline,
    mdiUndo
} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {Adres} from "../../../components/Adres";
import {NavLink, useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {Form, Formik, FormikProps} from "formik";
import {
    ArtikelRestModel,
    InstallatieUitgevoerdNietGeinstalleerdRedenEnum,
    InstallatieUitgevoerdOmgevingsFoto,
    InstallatieUitgevoerdRedenEnum,
    InstallatieUitgevoerdResultaatEnum,
    InstallatieUitgevoerdVerbruiktArtikel,
    StukToegevoegd,
    ToestelInterventieUitgevoerdVerbruiktArtikel
} from "../../../_generated/field-service-be-openapi";
import {useArtikelen} from "../../../redux/slices/artikel/hooks";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {useHideModal, useShowModal} from "../../../redux/slices/modal/hooks";
import {Tijdsduur} from "../../../components/Tijdsduur";
import {MDBFormikFileInputFileValue} from "../../../mdb-formik/MDBFormikFileInput";
import {LightboxBackgroundImage} from "../../../components/LightboxBackgroundImage";
import {useInstallatieTijdIngegeven, useInstallatieUitgevoerd} from "../../../redux/slices/serviceAdressen/hooks";
import {useGoBack} from "../../../routes";
import * as Yup from "yup";
import {AnySchema} from "yup";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {BezoekMDBFormikInput} from "../../../mdb-formik/bezoek/BezoekMDBFormikInput";
import {BezoekFormikTimeInput} from "../../../mdb-formik/bezoek/BezoekFormikTimeInput";
import {BezoekMDBFormikSelect} from "../../../mdb-formik/bezoek/BezoekMDBFormikSelect";
import {Strikethrough} from "../../../components/Strikethrough";
import {parseDate} from "../../../utilities/dateUtils";
import {Dayjs} from "dayjs";
import {useTechnici} from "../../../redux/slices/technicus/hooks";
import {Datum} from "../../../components/Datum";
import {uploadIdToUrl} from "../../../utilities/uploadUtils";
import {ArtikelNrOmschrijving} from "../../../components/ArtikelNrOmschrijving";
import {MDBFileInput} from "../../../mdb-formik/MDBFileInput";
import {Assets} from "../../../assets/assets";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";
import {Trans} from "react-i18next";
import {SaveDraftFormik} from "../../../components/SaveDraftFormik";
import {OnbewaardeGegevensMelding} from "../../../components/OnbewaardeGegevensMelding";
import {BezoekMDBFormikTextArea} from "../../../mdb-formik/bezoek/BezoekMDBFormikTextArea";
import moment from "moment";

interface InstallatieCo2ArtikelFormValue {
    artikelId: string;
    aantal?: number;
}

export interface InstallatieFormValues {
    fotos: Partial<MDBFormikFileInputFileValue>[];

    /**
     * Key: artikel-id
     * Value: aantal
     */
    geinstalleerdeComponenten: Record<string, { aantal: number; serieNummer?: string; }>;
    verbruikteArtikelen: InstallatieUitgevoerdVerbruiktArtikel[];
    co2Artikelen: InstallatieCo2ArtikelFormValue[];

    resultaat: InstallatieUitgevoerdResultaatEnum | null;
    reden: InstallatieUitgevoerdRedenEnum | null;
    nietGeinstalleerdReden: InstallatieUitgevoerdNietGeinstalleerdRedenEnum | null;

    opmerking: string;

    vanTijd: any | null;
    totTijd: any | null;

    locatie: string;
    gebouw: string;
    verdieping: string;
    lokaalNummer: string;
}

export interface InstallatiePageParams {
    bezoekSessieId: string;
    installatieId: string;
}

export const flattenErrors = (
    errors: any
): string[] => {
    return Object.values(errors || {})
        .flatMap((value) =>
            typeof value === "object" ? flattenErrors(value) : value
        )
        .filter((v): v is string => typeof v === "string")
}

export const InstallatiePage: React.FC = () => {
    const {bezoekSessieId, installatieId} = useParams<InstallatiePageParams>();

    const technici = useTechnici();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const installatie = bezoekSessie?.installaties?.[installatieId];

    const installatieUitgevoerd = useInstallatieUitgevoerd();
    const installatieTijdIngegeven = useInstallatieTijdIngegeven();

    const showModal = useShowModal();
    const hideModal = useHideModal();
    const goBack = useGoBack();

    const artikelen = useArtikelen();

    const fileInputRef = useRef<HTMLInputElement>();

    const {t} = useTranslation("serviceadres");

    const onStukToevoegenClick = useCallback((formik: FormikProps<InstallatieFormValues>) => {
        showModal({
            type: FieldServiceModal.STUK_TOEVOEGEN_MODAL,
            props: {
                serviceAdresId: bezoekSessie?.serviceAdres?.id,
                onConfirm: (artikel: ArtikelRestModel) => {
                    formik.setFieldValue("verbruikteArtikelen", [
                        ...(formik.values.verbruikteArtikelen || []),
                        {
                            artikelId: artikel.id,
                            aantal: 1
                        } as ToestelInterventieUitgevoerdVerbruiktArtikel
                    ]);
                },
                onClose: () => hideModal()
            }
        });
    }, [showModal, hideModal, bezoekSessie]);

    const onStukVerwijderenClick = useCallback((item: StukToegevoegd, formik: FormikProps<InstallatieFormValues>) => {

        showModal({
            type: FieldServiceModal.CONFIRM_MODAL,
            props: {
                title: () => t("InstallatiePage.bevestig", "Bevestig"),
                content: () => t("InstallatieDossierPage.weet-u-zeker-dat-dit-stuk-verwijderd-mag-worden",
                    "Weet u zeker dat dit stuk verwijderd mag worden?"),
                confirmColor: "danger",
                onConfirm: () => {
                    formik.setFieldValue("verbruikteArtikelen", (formik.values.verbruikteArtikelen || []).filter((stuk) => stuk.artikelId !== item.artikelId));
                },
                onCancel: () => {

                },
                onClose: () => hideModal()
            }
        });
    }, [showModal, hideModal, t]);

    const onSubmit = useCallback(async (values: InstallatieFormValues) => {
        if (bezoekSessie) {
            installatieTijdIngegeven({
                bezoekSessieId: bezoekSessie.id,
                installatieId,
                vanTijd: values.vanTijd!.toISOString(),
                totTijd: values.totTijd?.toISOString()
            });

            const fotos: InstallatieUitgevoerdOmgevingsFoto[] = values.fotos.map(item => ({
                uploadId: item.uploadId
            }));

            installatieUitgevoerd({
                bezoekSessieId: bezoekSessie.id,
                opmerking: values.opmerking,
                locatie: values.locatie,
                gebouw: values.gebouw,
                verdieping: values.verdieping,
                lokaalNummer: values.lokaalNummer,
                installatieId,
                geinstalleerdeComponenten: Object.entries(values.geinstalleerdeComponenten || {}).map(([artikelId, {
                    aantal,
                    serieNummer
                }]) => ({
                    artikelId,
                    aantal,
                    serieNummer
                })),
                verbruikteArtikelen: values.verbruikteArtikelen as any,
                resultaat: values.resultaat!,
                fotos,
                reden: values.reden || undefined,
                nietGeinstalleerdReden: values.nietGeinstalleerdReden || undefined,
                co2Artikelen: values.co2Artikelen.map((item) => ({
                    artikelId: item.artikelId,
                    aantalGeleverd: item.aantal || 0
                }))
            });

            goBack();
        }
    }, [bezoekSessie, goBack, installatieId, installatieUitgevoerd, installatieTijdIngegeven]);

    const initialValues = useMemo<InstallatieFormValues | undefined>(() => {
        if (!installatie?.uitTeVoerenWerk) {
            return undefined;
        }

        let geinstalleerdeComponenten: Record<string, { aantal: number; serieNummer?: string; }> = {};
        if (installatie?.form?.geinstalleerdeComponenten) {
            geinstalleerdeComponenten = installatie?.form?.geinstalleerdeComponenten?.reduce((prev, component) => ({
                ...prev,
                [component.artikelId]: {aantal: component.aantal ?? 1, serieNummer: component.serieNummer}
            }), {});
        } else if (installatie?.uitTeVoerenWerk?.teInstallerenComponenten) {
            geinstalleerdeComponenten = installatie?.uitTeVoerenWerk.teInstallerenComponenten.reduce((prev, component) => ({
                ...prev,
                [component.artikelId]: {aantal: component.aantal ?? 1}
            }), {});
        }

        return {
            fotos: installatie?.form?.fotos?.map(foto => ({
                uploadId: foto.uploadId,
                url: uploadIdToUrl(foto.uploadId!),
                file: undefined
            })) || [],

            geinstalleerdeComponenten,
            verbruikteArtikelen: installatie?.form?.verbruikteArtikelen as any || [],
            resultaat: installatie?.form?.resultaat || null,

            opmerking: installatie?.form?.opmerking || "",
            reden: installatie?.form?.reden || null,
            nietGeinstalleerdReden: installatie?.form?.nietGeinstalleerdReden || null,

            vanTijd: null,
            totTijd: null,

            co2Artikelen: installatie?.uitTeVoerenWerk?.co2Artikelen?.map((co2Artikel) => {
                const value = installatie?.form?.co2Artikelen?.find(item => item.artikelId === co2Artikel.artikelId);

                return {
                    artikelId: co2Artikel.artikelId,
                    aantal: value?.aantalGeleverd
                };
            }),

            locatie: installatie?.form?.locatie || installatie?.uitTeVoerenWerk?.locatie || "",
            gebouw: installatie?.form?.gebouw || installatie?.uitTeVoerenWerk?.gebouw || "",
            verdieping: installatie?.form?.verdieping || installatie?.uitTeVoerenWerk?.verdieping || "",
            lokaalNummer: installatie?.form?.lokaalNummer || installatie?.uitTeVoerenWerk?.lokaalNummer || "",
        };
    }, [installatie]);

    const validationSchema = useMemo(() => Yup.lazy(obj => {
        const geinstalleerdeComponentenKeys = Object.keys(obj.geinstalleerdeComponenten || {});

        const geinstalleerdeComponenten: Record<string, AnySchema> = {};
        for (const key of geinstalleerdeComponentenKeys) {
            geinstalleerdeComponenten[key] = Yup.object({
                serieNummer: Yup.string().nullable().test("serieNummer-verplicht",
                    () => t("algemeen:Foutmeldingen.serienummer-is-verplicht", "Serienummer is verplicht"),
                    function (value) {
                        const aantal = (this as any).from[1]?.value?.[key]?.aantal; // parent->parent->aantal
                        if (!aantal) {
                            return true;
                        }

                        const teInstallerenComponent = installatie?.uitTeVoerenWerk?.teInstallerenComponenten?.find(item => item.artikelId === key);

                        if (teInstallerenComponent?.serieNummerOpvragen && (!value || value.trim().length === 0)) {
                            return false;
                        }

                        return true;
                    })
            });
        }

        return Yup.object({
            vanTijd: Yup.object().required(() => t("algemeen:Foutmeldingen.van-tijd-is-verplicht", "Van-tijd is verplicht")).nullable(),
            totTijd: Yup.object().required(() => t("algemeen:Foutmeldingen.tot-tijd-is-verplicht", "Tot-tijd is verplicht")).nullable()
                .test("vanTijd-required-when-totTijd-is-filled",
                    () => t("algemeen:Foutmeldingen.gelieve-eerst-van-tijd-in-te-vullen", "Gelieve eerst van-tijd in te vullen"),
                    function (value) {
                        if (!this.parent.vanTijd && !!value) {
                            return false;
                        }

                        return true;
                    })
                .test("totTijd-after-vanTijd", () => t("algemeen:Foutmeldingen.tot-tijd-moet-na-van-tijd-liggen", "Tot-tijd moet na van-tijd liggen"), function (value) {
                    const vanTijd: Dayjs = this.parent.vanTijd;
                    const totTijd = value as unknown as Dayjs;

                    if (!vanTijd || !totTijd) {
                        return true;
                    }

                    if (vanTijd.isValid?.() && totTijd.isValid?.()) {
                        return totTijd.isAfter(vanTijd);
                    }

                    return true;
                }),
            geinstalleerdeComponenten: Yup.object(geinstalleerdeComponenten),
            co2Artikelen: Yup.array(Yup.object({
                aantal: Yup.number().required(() => t("algemeen:Foutmeldingen.co2-aantal-is-verplicht", "CO₂ aantal is verplicht")).nullable()
            })).nullable(),
            verbruikteArtikelen: Yup.array(
                Yup.object({
                    artikelId: Yup.string().required(),
                    aantal: Yup.number()
                        .required(() => t("algemeen:Foutmeldingen.aantal-is-verplicht", "Aantal is verplicht"))
                        .min(0, () => t("algemeen:Foutmeldingen.aantal-moet-positief-zijn", "Aantal moet positief zijn"))
                })
            ).nullable(),
            fotos: Yup.array().min(0, () => t("algemeen:Foutmeldingen.minstens-een-foto-is-verplicht", "Minstens één foto is verplicht")).nullable(),
            locatie: Yup.string().max(100, () => t("algemeen:Foutmeldingen.locatie-maximale-lengte", "Locatie kan maximum {{lengte}} karakters bevatten.", {lengte: 100})).optional().nullable(),
            gebouw: Yup.string().max(32, () => t("algemeen:Foutmeldingen.gebouw-maximale-lengte", "Gebouw kan maximum {{lengte}} karakters bevatten.", {lengte: 32})).optional().nullable(),
            verdieping: Yup.string().max(16, () => t("algemeen:Foutmeldingen.verdieping-maximale-lengte", "Verdieping kan maximum {{lengte}} karakters bevatten.", {lengte: 16})).optional().nullable(),
            lokaalNummer: Yup.string().max(64, () => t("algemeen:Foutmeldingen.lokaalnummer-maximale-lengte", "Lokaalnummer kan maximum {{lengte}} karakters bevatten.", {lengte: 64})).optional().nullable(),
            resultaat: Yup.string().required(() => t("algemeen:Foutmeldingen.resultaat-is-verplicht", "Resultaat is verplicht")).nullable(),
            reden: Yup.string().nullable().optional().nullable().when("resultaat", {
                is: InstallatieUitgevoerdResultaatEnum.NogNietOperationeel,
                then: Yup.string().required(() => t("algemeen:Foutmeldingen.reden-is-verplicht", "Reden is verplicht")).nullable()
            }),
            opmerking: Yup.string().optional().nullable(),
        });
    }), [installatie, t]);

    const processDraftValues = useCallback((values?: any) => {
        if (!values) {
            return null;
        }

        return {
            ...values,
            vanTijd: moment(values.vanTijd),
            totTijd: moment(values.totTijd)
        };
    }, []);

    if (!installatie || !initialValues) {
        return null;
    }

    const dossierAantal = (installatie.uitTeVoerenWerk?.bijlagen?.length || 0) + (installatie.uitTeVoerenWerk?.situatieFotos?.length || 0);

    const locatie = installatie?.uitTeVoerenWerk?.locatie || t("algemeen:onbekend", "Onbekend");
    const gebouw = installatie?.uitTeVoerenWerk?.gebouw || t("algemeen:onbekend", "Onbekend");
    const verdieping = installatie?.uitTeVoerenWerk?.verdieping || t("algemeen:onbekend", "Onbekend");
    const lokaal = installatie?.uitTeVoerenWerk?.lokaalNummer || t("algemeen:onbekend", "Onbekend");

    const vanLabel = t("algemeen:van", "Van");
    const totLabel = t("algemeen:tot", "Tot");
    const serienummerLabel = t("algemeen:Titels.serienummer",
        "Serienummer");
    const redenLabel = t("InstallatiePage.waarom-is-het-toestel-nog-niet-operationeel",
        "Waarom is het toestel nog niet operationeel?");

    return (
        <GenericPage>
            <Header
                title={t("InstallatiePage.hoofding", "Installatie {{locatie}}", {locatie: installatie?.uitTeVoerenWerk?.locatie || t("algemeen:onbekend", "onbekend")})}
                subtitle={<><strong>{serviceAdres?.naam}</strong> <Adres adres={serviceAdres}/></>}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <Formik<InstallatieFormValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize
                                               validationSchema={validationSchema} validateOnChange={false}
                                               validateOnBlur={false} validateOnMount={false}>
                    {(formik) => {
                        return (
                            <Form autoComplete="off">
                                <OnbewaardeGegevensMelding draftValues={processDraftValues(installatie.draftValues)}/>
                                <SaveDraftFormik formId={`installatie/${installatie.id}`}
                                                 bezoekSessieId={bezoekSessieId}/>

                                <DisableAutocompletePlaceholderInput/>
                                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                                    <MDBCardHeader>
                                        <Icon path={Assets.orderTypes.installatie} size={1}/> Installatie
                                    </MDBCardHeader>

                                    <MDBCardBody>
                                        <MDBCardText>
                                            <MDBRow>
                                                <MDBCol md="8" className="mb-2">
                                                    <Trans t={t}
                                                           i18nKey="InstallatiePage.locatie-beschrijving">
                                                        <strong>Locatiebeschrijving: </strong><br/> {{locatie}}
                                                        <br/>
                                                        Gebouw: {{gebouw}},
                                                        Verdieping: {{verdieping}},
                                                        Lokaal: {{lokaal}}
                                                    </Trans>
                                                </MDBCol>
                                                <MDBCol md="4" className="mb-2">
                                                    <strong>{t("Labels.voorziene-tijd", "Voorziene tijd:")}{" "} </strong><br/>
                                                    {/* TODO: voorziene tijd op installatie voorzien? */}
                                                    {!!bezoekSessie?.voorzieneTijd &&
                                                        <Tijdsduur tijdsduur={bezoekSessie?.voorzieneTijd}
                                                                   eenheid="minutes"/>}
                                                    {!bezoekSessie?.voorzieneTijd &&
                                                        <span className="text-muted">
                                                            {t("algemeen:onbekend", "onbekend")}
                                                        </span>}
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow>
                                                <MDBCol md="8" className="mb-2">
                                                    <strong>{t("algemeen:Labels.omschrijving", "Omschrijving:")}{" "} </strong><br/> {installatie?.uitTeVoerenWerk?.techniekerInstructies ||
                                                    <span className="text-muted">{t("algemeen:geen", "Geen")}</span>}
                                                </MDBCol>
                                                <MDBCol md="4" className="mb-2">
                                                    <strong>{t("toestel:Labels.toestel", "Toestel:")} </strong><br/>
                                                    <ArtikelNrOmschrijving
                                                        nr={installatie?.uitTeVoerenWerk?.teInstallerenArtikelNummer}
                                                        omschrijving={installatie?.uitTeVoerenWerk?.teInstallerenArtikelOmschrijving}/>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCardText>
                                    </MDBCardBody>

                                    <MDBCardFooter>
                                        <NavLink
                                            to={`/bezoek/taken/${bezoekSessieId}/opdracht/installatie/${installatieId}/dossier`}
                                            className="btn btn-link btn-lg">{t("InstallatiePage.installatiedossier", "Installatiedossier")}
                                            {!!dossierAantal &&
                                                <MDBBadge pill>{dossierAantal}</MDBBadge>}
                                        </NavLink>
                                    </MDBCardFooter>
                                </MDBCard>

                                <h6 className="mb-3">
                                    <Icon path={mdiClockOutline} size={1}/>
                                    {t("InstallatiePage.tijdsregistratie", "Tijdsregistratie")}
                                </h6>
                                <MDBCard className="shadow-sm mb-3" background="light">
                                    <MDBCardBody size="12">
                                        {Object.keys(installatie?.technicusTijden || {}).length === 0 && (
                                            <span className="text-muted">{t("InstallatiePage.nog-geen-tijden-ingegeven",
                                                "Nog geen tijden ingegeven.")}</span>
                                        )}
                                        {Object.values(installatie?.technicusTijden || {})?.map((tijd) => {
                                            const technicus = technici[tijd.technicusId];

                                            return (
                                                <div className="d-flex align-items-center">
                                                    <MDBBadge>
                                                        <Datum format="HH:mm" datum={tijd.vanTijd}/>
                                                    </MDBBadge>

                                                    <Icon path={mdiChevronRight} size={0.8}/>

                                                    <MDBBadge className="me-3">
                                                        <Datum format="HH:mm" datum={tijd.totTijd}/>
                                                    </MDBBadge>

                                                    <span>
                                                        {technicus.naam}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </MDBCardBody>
                                </MDBCard>

                                <MDBRow className="mb-3">
                                    <MDBCol size="6" onClick={() => formik.setFieldValue("vanTijd", parseDate())}>
                                        <MDBBtn type="button" color="primary" block size="lg">
                                            {t("InstallatiePage.installatie-inchecken", "Installatie inchecken")}</MDBBtn>
                                    </MDBCol>
                                    <MDBCol size="6" onClick={() => formik.setFieldValue("totTijd", parseDate())}>
                                        <MDBBtn type="button" color="primary" block size="lg">
                                            {t("InstallatiePage.installatie-uitchecken", "Installatie uitchecken")}</MDBBtn>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="mb-3">
                                    <MDBCol size="6">
                                        <BezoekFormikTimeInput label={vanLabel} name="vanTijd"/>
                                    </MDBCol>

                                    <MDBCol size="6">
                                        <BezoekFormikTimeInput label={totLabel} name="totTijd"/>
                                    </MDBCol>
                                </MDBRow>

                                <h6>
                                    <Icon path={mdiClipboardListOutline}
                                          size={1}/> {t("InstallatiePage.stuklijst-te-installeren", "Stuklijst te installeren")}
                                </h6>
                                <MDBRow className="mb-3">
                                    <MDBCol size="12">
                                        <MDBTable responsive>
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope="col"/>
                                                    <th scope="col">{t("algemeen:Titels.artikelnr-omschrijving", "Artikelnr. - Omschrijving")}</th>
                                                    <th scope="col"/>
                                                    <th scope="col"/>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {!installatie?.uitTeVoerenWerk?.teInstallerenComponenten?.length && (
                                                    <tr>
                                                        <td colSpan={4}>{t("InstallatiePage.geen-onderdelen", "Geen onderdelen")}</td>
                                                    </tr>
                                                )}
                                                {installatie?.uitTeVoerenWerk?.teInstallerenComponenten?.map(item => {
                                                    const aantal = formik.values.geinstalleerdeComponenten[item.artikelId]?.aantal;

                                                    return (
                                                        <tr>
                                                            <td>
                                                                {item.aantal} x
                                                            </td>
                                                            <td className="align-middle">
                                                                <Strikethrough
                                                                    strikethrough={aantal === 0}><strong>{item.artikelNr}</strong> {item.artikelOmschrijving}
                                                                </Strikethrough>
                                                            </td>
                                                            <td>
                                                                {item.serieNummerOpvragen && (formik.values.geinstalleerdeComponenten[item.artikelId]?.aantal === undefined
                                                                    || formik.values.geinstalleerdeComponenten[item.artikelId]?.aantal === 1) && (
                                                                    <BezoekMDBFormikInput label={serienummerLabel}
                                                                                          name={`geinstalleerdeComponenten.${item.artikelId}.serieNummer`}/>
                                                                )}
                                                            </td>
                                                            <td className="align-middle text-right text-nowrap text-primary">
                                                                {aantal === 1 && (
                                                                    <MDBBtn type="button" color="link"
                                                                            className="px-2 py-1"
                                                                            onClick={() => formik.setFieldValue(`geinstalleerdeComponenten.${item.artikelId}.aantal`, 0)}>

                                                                        <Icon path={mdiTrashCanOutline}
                                                                              size={1}/>
                                                                    </MDBBtn>
                                                                )}
                                                                {aantal === 0 && (
                                                                    <MDBBtn type="button" color="link"
                                                                            className="px-2 py-1"
                                                                            onClick={() => formik.setFieldValue(`geinstalleerdeComponenten.${item.artikelId}.aantal`, 1)}>

                                                                        <Icon path={mdiUndo}
                                                                              size={1}/>
                                                                    </MDBBtn>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="mb-3">
                                    <MDBCol size="12">
                                        <MDBRow className="d-flex align-items-center">
                                            <MDBCol className="d-flex align-items-center">
                                                <h6><Icon path={mdiMoleculeCo2} size={1}
                                                          className="me-2"/> {t("InstallatiePage.co2", "CO₂")}</h6>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>

                                    <MDBCol size="12">
                                        <MDBTable responsive borderless striped>
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope="col">{t("InstallatiePage.omschrijving", "Omschrijving")}</th>
                                                    <th scope="col">{t("InstallatiePage.aantal-te-leveren", "Aantal te leveren")}</th>
                                                    <th scope="col">{t("InstallatiePage.geleverd-aantal", "Geleverd aantal")}</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {!installatie?.uitTeVoerenWerk?.co2Artikelen?.length && (
                                                    <tr>
                                                        <td colSpan={3}>{t("Labels.geen-co2-artikelen-te-leveren", "Geen CO₂-artikelen te leveren")}</td>
                                                    </tr>
                                                )}

                                                {installatie?.uitTeVoerenWerk?.co2Artikelen?.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td className="align-middle">
                                                                <ArtikelNrOmschrijving nr={item.artikelNr}
                                                                                       omschrijving={item.artikelOmschrijving}/>
                                                            </td>
                                                            <td className="align-middle">
                                                                {item.aantalTeLeveren}
                                                            </td>
                                                            <td className="align-middle text-right text-nowrap">
                                                                <BezoekMDBFormikInput type="number" step="1" min="0"
                                                                                      name={`co2Artikelen[${index}].aantal`}
                                                                                      wrapperStyle={{maxWidth: "5rem"}}/>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </MDBTableBody>
                                        </MDBTable>

                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="mb-3">
                                    <MDBCol size="12">
                                        <MDBRow className="d-flex align-items-center">
                                            <MDBCol className="d-flex align-items-center">
                                                <h6><Icon path={mdiArchiveArrowUpOutline} size={1}
                                                          className="me-2"/>
                                                    {t("InstallatiePage.verbruikte-artikelen", "Verbruikte artikelen")}
                                                </h6>
                                            </MDBCol>
                                            <MDBCol size="auto">
                                                <BezoekMDBBtn onClick={() => onStukToevoegenClick(formik)}
                                                              type="button">
                                                    <Icon path={mdiPlus}
                                                          size={1}/> {t("InstallatiePage.artikel-toevoegen", "Artikel toevoegen")}
                                                </BezoekMDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>

                                    <MDBCol size="12">
                                        <MDBTable responsive borderless striped>
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope="col">{t("algemeen:Titels.artikelnr-omschrijving", "Artikelnr. - Omschrijving")}</th>
                                                    <th scope="col">{t("algemeen:Titels.aantal", "Aantal")}</th>
                                                    <th scope="col"/>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {!formik.values.verbruikteArtikelen?.length && (
                                                    <tr>
                                                        <td colSpan={3}>{t("InstallatiePage.geen-verbruikte-artikelen-toegevoegd",
                                                            "Geen verbruikte artikelen toegevoegd")}</td>
                                                    </tr>
                                                )}

                                                {formik.values.verbruikteArtikelen?.map((item, index) => {
                                                    const artikel = artikelen[item.artikelId];

                                                    return (
                                                        <tr>
                                                            <td className="align-middle">
                                                                <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                                            </td>
                                                            <td className="align-middle">
                                                                <BezoekMDBFormikInput type="number" step="1"
                                                                                      name={`verbruikteArtikelen[${index}].aantal`}
                                                                                      wrapperStyle={{maxWidth: "5rem"}}/>
                                                            </td>
                                                            <td className="align-middle text-right text-nowrap">
                                                                <BezoekMDBBtn color="link" className="px-2 py-1"
                                                                              onClick={() => onStukVerwijderenClick(item, formik)}>
                                                                    <Icon path={mdiTrashCanOutline} size={1}/>
                                                                </BezoekMDBBtn>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </MDBTableBody>
                                        </MDBTable>

                                    </MDBCol>
                                </MDBRow>

                                <h6 className="mb-3"><Icon path={mdiImage} size={1}/> {t("Labels.fotos", "Foto's")}</h6>
                                <MDBRow className="mb-3">
                                    <MDBCol size="12">
                                        <MDBTypography note noteColor="info">
                                            {t("InstallatiePage.neem-tenminste-twee-fotos",
                                                "Neem tenminste twee foto's: een overzichtsfoto waarbij de omgeving van de kraan zichtbaar is en een foto van de technische voorzieningen.")}

                                        </MDBTypography>
                                    </MDBCol>

                                    <MDBCol size="12" className="mt-2 ms-2">
                                        <div className="d-flex flex-wrap">
                                            {formik.values.fotos.map((foto, fotoIndex) => (
                                                <div key={foto.file?.name}
                                                     style={{padding: "0.4rem", position: "relative"}}>
                                                    <LightboxBackgroundImage
                                                        title={t("InstallatiePage.omgevingsfoto", "Omgevingsfoto")}
                                                        src={foto.url || ""}
                                                        style={{
                                                            width: "10rem",
                                                            height: "10rem",
                                                            borderRadius: 8,
                                                            backgroundSize: "contain",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center"
                                                        }}/>

                                                    <div style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        right: 0
                                                    }}>
                                                        <MDBBtn type="button" color="link"
                                                                className="p-1"
                                                                onClick={() => formik.setFieldValue(
                                                                    "fotos",
                                                                    formik.values.fotos.filter((_, index) => index !== fotoIndex)
                                                                )}
                                                        >
                                                            <Icon path={mdiTrashCan} size={1}/>
                                                        </MDBBtn>
                                                    </div>
                                                </div>
                                            ))}
                                            <div style={{padding: "0.4rem", position: "relative"}}>
                                                <MDBBtn type="button" tag="div"
                                                        color="none"
                                                    // outline={true}
                                                        className="d-flex flex-column align-items-center justify-content-center"
                                                        onClick={() => fileInputRef.current?.click()}
                                                        style={{
                                                            width: "10rem",
                                                            height: "10rem",
                                                            border: "2px dashed #dee2e6",
                                                        }}>
                                                    <Icon path={mdiPlus} size={4} color="#dee2e6"/>
                                                    <h5>Foto toevoegen</h5>

                                                    <MDBFileInput type="file" accept="image/*" name="fotos"
                                                                  onChange={(e: any) => {
                                                                      const selectie = e.target.value as MDBFormikFileInputFileValue[];

                                                                      formik.setFieldValue(
                                                                          "fotos",
                                                                          formik.values.fotos.concat(selectie)
                                                                      );
                                                                  }}
                                                                  multiple
                                                                  style={{display: "none"}}
                                                                  className="no-outline"
                                                                  wrapperClass="form-notch-fix"
                                                                  innerRef={fileInputRef as any}
                                                    />
                                                </MDBBtn>
                                            </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="align-items-baseline">
                                    <MDBCol lg="3" md="6" xs="12" className="mb-3">
                                        <h6 className="mb-3">{t("InstallatiePage.toestel-locatie-form.locatie", "Locatiebeschrijving")}</h6>
                                        <BezoekMDBFormikInput name="locatie"
                                                              placeholder={t("toestel:Placeholders.toestel-locatie-form.locatiebeschrijving", "vb. Gang naast drankautomaat of Keuken") as string}/>
                                    </MDBCol>
                                    <MDBCol lg="3" md="6" xs="12" className="mb-3">
                                        <h6 className="mb-3">{t("toestel:Titels.gebouw", "Gebouw")} <small
                                            className="text-muted">{t("Titels.indien-van-toepassing", "(indien van toepassing)")}</small>
                                        </h6>
                                        <BezoekMDBFormikInput name="gebouw"
                                                              placeholder={t("toestel:Placeholders.toestel-locatie-form.gebouw", "vb. Blok A") as string}/>
                                    </MDBCol>
                                    <MDBCol lg="3" md="6" xs="12" className="mb-3">
                                        <h6 className="mb-3">{t("toestel:Titels.verdieping", "Verdieping")} <small
                                            className="text-muted">{t("Titels.indien-van-toepassing", "(indien van toepassing)")}</small>
                                        </h6>
                                        <BezoekMDBFormikInput name="verdieping"
                                                              placeholder={t("toestel:Placeholders.toestel-locatie-form.verdieping", "vb. -1") as string}/>
                                    </MDBCol>
                                    <MDBCol lg="3" md="6" xs="12" className="mb-3">
                                        <h6 className="mb-3">{t("toestel:Titels.lokaal", "Lokaal")} <small
                                            className="text-muted">{t("Titels.indien-van-toepassing", "(indien van toepassing)")}</small>
                                        </h6>
                                        <BezoekMDBFormikInput name="lokaalNummer"
                                                              placeholder={t("toestel:Placeholders.toestel-locatie-form.lokaal", "vb. C./00/130") as string}/>
                                    </MDBCol>
                                </MDBRow>

                                <h6 className="mb-3"><Icon path={mdiCheckUnderline}
                                                           size={1}/> {t("InstallatiePage.resultaat.label", "Resultaat")}
                                </h6>
                                <MDBRow>
                                    <MDBCol size="12" className="mb-3">
                                        <BezoekMDBFormikSelect
                                            name="resultaat"
                                            label={t("InstallatiePage.resultaat.label", "Resultaat") as string}
                                            data={[
                                                {
                                                    text: t("InstallatiePage.resultaat.volledig-afgewerkt", "Volledig afgewerkt") as string,
                                                    value: InstallatieUitgevoerdResultaatEnum.VolledigAfgewerkt
                                                },
                                                {
                                                    text: t("InstallatiePage.resultaat.nog-niet-operationeel", "Nog niet operationeel") as string,
                                                    value: InstallatieUitgevoerdResultaatEnum.NogNietOperationeel
                                                },
                                                {
                                                    text: t("InstallatiePage.resultaat.niet-geinstalleerd", "Niet geïnstalleerd") as string,
                                                    value: InstallatieUitgevoerdResultaatEnum.NietGeinstalleerd
                                                }
                                            ]}

                                            onChange={() => formik.setFieldValue("reden", "")}
                                        />
                                    </MDBCol>

                                    {formik.values.resultaat === InstallatieUitgevoerdResultaatEnum.NogNietOperationeel && (
                                        <MDBCol size="12">
                                            <BezoekMDBFormikSelect
                                                name="reden"
                                                className="mb-3"
                                                label={redenLabel}
                                                data={[
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nog-geen-water",
                                                            "Nog geen water") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.NogGeenWater
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nog-geen-elektriciteit",
                                                            "Nog geen elektriciteit") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.NogGeenElektriciteit
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nog-geen-werkblad", "Nog geen werkblad") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.NogGeenWerkblad
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nodige-uitsparingen-in-werkblad-nog-niet-klaar",
                                                            "Nodige uitsparingen in werkblad niet klaar") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.NodigeUitpsaringenInWerkbladNietKlaar
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.pompbak-is-nog-niet-geïnstalleerd",
                                                            "Pompbak is nog niet geïnstalleerd") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.PompbakNogNietGeinstalleerd
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.afvoer-is-nog-niet-aangesloten",
                                                            "Afvoer is nog niet aangesloten") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.AfvoerNogNietAangesloten
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.verkeerde-kraan-of-toestel-mee",
                                                            "Verkeerde kraan of toestel mee") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.VerkeerdeKraanOfToestelMee
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.te-installeren-toestel-werkt-niet-naar-behoren",
                                                            "Te installeren toestel werkt niet naar behoren, waardoor retour Aqualex") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.TeInstallerenToestelWerktNietWaardoorRetour
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.kraan-te-hoog",
                                                            "Kraan te hoog (kan niet tussen werkblad en bovenkast, komt voor venster, ...)") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.KraanTeHoog
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.toestel-kan-niet-in-de-kast",
                                                            "Toestel kan niet in de kast (te groot)") as string,
                                                        value: InstallatieUitgevoerdRedenEnum.ToestelTeGroot
                                                    }
                                                ]}
                                            />
                                        </MDBCol>
                                    )}

                                    {formik.values.resultaat === InstallatieUitgevoerdResultaatEnum.NietGeinstalleerd && (
                                        <MDBCol size="12">
                                            <BezoekMDBFormikSelect
                                                name="nietGeinstalleerdReden"
                                                className="mb-3"
                                                label={t("InstallatiePage.waarom-is-het-toestel-niet-geinstalleerd", "Waarom is het toestel niet geïnstalleerd?") as string}
                                                data={[
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nog-geen-water",
                                                            "Nog geen water") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.NogGeenWater
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nog-geen-elektriciteit",
                                                            "Nog geen elektriciteit") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.NogGeenElektriciteit
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nog-geen-werkblad", "Nog geen werkblad") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.NogGeenWerkblad
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.nodige-uitsparingen-in-werkblad-nog-niet-klaar",
                                                            "Nodige uitsparingen in werkblad niet klaar") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.NodigeUitsparingenInWerkbladNietKlaar
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.verkeerde-kraan-of-toestel-mee",
                                                            "Verkeerde kraan of toestel mee") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.VerkeerdeKraanOfToestelMee
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.te-installeren-toestel-werkt-niet-naar-behoren",
                                                            "Te installeren toestel werkt niet naar behoren, waardoor retour Aqualex") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.TeInstallerenToestelWerktNietWaardoorRetour
                                                    },
                                                    {
                                                        text: t("InstallatiePage.nog-niet-operationeel-reden.toestel-kan-niet-in-de-kast",
                                                            "Toestel kan niet in de kast (te groot)") as string,
                                                        value: InstallatieUitgevoerdNietGeinstalleerdRedenEnum.ToestelTeGroot
                                                    }
                                                ]}
                                            />
                                        </MDBCol>
                                    )}
                                </MDBRow>

                                <h6 className="mb-3"><Icon path={mdiText}
                                                           size={1}/> {t("InstallatiePage.overig", "Overig")}</h6>
                                <MDBRow className="mb-2">
                                    <MDBCol size="12" className="mb-3">
                                        <BezoekMDBFormikTextArea name="opmerking" label="Opmerking" rows={4}/>
                                    </MDBCol>

                                    <MDBCol size="12" className="mb-3">
                                        <MDBAlert color="danger" open={Object.values(formik.errors).length > 0}>
                                            <ul>
                                                {flattenErrors(formik.errors).map((msg, idx) => (
                                                    <li key={idx}>{msg}</li>
                                                ))}
                                            </ul>
                                        </MDBAlert>
                                    </MDBCol>

                                    <MDBCol size="12">
                                        <BezoekMDBBtn block size="lg"
                                                      type="submit">{t("algemeen:bewaren", "Bewaren")}</BezoekMDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </Form>
                        );
                    }}
                </Formik>

            </ContentContainer>
        </GenericPage>
    );
};
