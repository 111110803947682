import React from "react";
import {useHuidigeDagPlanning} from "../redux/slices/dagPlanning/hooks";
import {useServiceAdressen} from "../redux/slices/serviceAdressen/hooks";
import {DagPlanningBezoekEntryRestModel} from "../_generated/field-service-be-openapi";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {useKlanten} from "../redux/slices/klant/hooks";
import {Datum} from "../components/Datum";
import {PlanningLeeg} from "../components/dagPlanning/PlanningLeeg";
import {UitTeVoerenWerkBezoek} from "./UitTeVoerenWerkBezoek";
import {PlanningOverzichtDagSamenvatting} from "../components/planningOverzicht/PlanningOverzichtDagSamenvatting";
import {useTranslation} from "../utilities/i18nUtils";

export const PlanningOverzichtPage: React.FC = () => {
    const [dagPlanning] = useHuidigeDagPlanning();
    const serviceAdressen = useServiceAdressen();
    const klanten = useKlanten();

    const bezoeken = (dagPlanning?.entries?.filter((item) => item._type === "BEZOEK") || []) as DagPlanningBezoekEntryRestModel[];

    const {t} = useTranslation("planning");

    const renderBezoek = (bezoek: DagPlanningBezoekEntryRestModel) => {
        const serviceAdres = serviceAdressen[bezoek.serviceAdresId];
        const klant = klanten[serviceAdres.klantId];

        return (
            <div className="mb-5">
                <h5>{klant?.naam} <small className="text-muted">{serviceAdres?.naam}</small></h5>
                <hr/>

                <UitTeVoerenWerkBezoek serviceAdres={serviceAdres} uitTeVoerenWerk={bezoek.uitTeVoerenWerk}/>
            </div>
        );
    };

    return (
        <GenericPage>
            <Header sticky title={t("Titels.planningsoverzicht","Planningsoverzicht")}
                    subtitle={dagPlanning && <Datum format="DD-MM-YYYY" datum={dagPlanning.datum}/>}/>

            {dagPlanning && (
                <div className="mt-4 mb-4 bg-secondary">
                    <div className="container-lg flex-fill pt-4 pb-2">
                        <PlanningOverzichtDagSamenvatting datum={dagPlanning.datum} bezoeken={bezoeken}/>
                    </div>
                </div>
            )}

            <div className="container-lg pt-3 flex-fill bg-white">
                {(!bezoeken || !bezoeken.length) && <div className="px-4"><PlanningLeeg/></div>}

                {bezoeken.map((item) => renderBezoek(item))}
            </div>
        </GenericPage>
    );
};
