import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, put, select} from "redux-saga/effects";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {selectMe} from "../technicus/slice";
import {EventMessagePayload} from "../../../workers/shared/channels/eventMessage";
import {PayloadAction} from "@reduxjs/toolkit";
import {downloadFile, FileDownload, saveFile} from "../../../utilities/downloadUtils";
import {ToestelInformatieAangepast, ToestelInterventieUitgevoerd} from "../../../_generated/field-service-be-openapi";
import {EventPayloadAction} from "../../types";
import {Config} from "../../../utilities/config";
import {downloads} from "../download/slice";
import {statusSaga} from "../status/saga";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";

export const toestellenSaga = createSliceSaga({
    name: "toestellenSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * checkIn(action) {
            const {bezoekSessieId, toestelId} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "CHECK_IN_BIJ_TOESTEL",
                    toestelId,
                    technicusId
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * checkOut(action) {
            const {bezoekSessieId, toestelId} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "CHECK_OUT_VAN_TOESTEL",
                    toestelId,
                    technicusId,
                    nietUitgevoerdWerk: []
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * onderhoudUitgevoerd(action) {
            const {bezoekSessieId, toestelId, uitgevoerdeStappen} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "TOESTEL_ONDERHOUD_UITGEVOERD",
                    toestelId,
                    technicusId,
                    uitgevoerdeStappen
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * waterstandOpgenomen(action) {
            const {bezoekSessieId, toestelId, resultaat, meterstand} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "WATERSTAND_OPGENOMEN",
                    toestelId,
                    technicusId,
                    meterstand,
                    resultaat
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * voegFotoToe(action: PayloadAction<{ bezoekSessieId: string; toestelId: string; uploadId: string; }>) {
            const {bezoekSessieId, toestelId, uploadId} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "TOESTEL_FOTO_TOEGEVOEGD",
                    toestelId,
                    technicusId,
                    uploadId
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * toestelInformatieAangepast(action: EventPayloadAction<ToestelInformatieAangepast>) {
            const {
                bezoekSessieId,
                toestelId,
                serieNummer,
                serieNummerOrigineel,
                locatieBeschrijving,
                locatieBeschrijvingOrigineel,
                gebouw,
                gebouwOrigineel,
                verdieping,
                verdiepingOrigineel,
                lokaal,
                lokaalOrigineel,
                opmerking
            } = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "TOESTEL_INFORMATIE_AANGEPAST",
                    toestelId,
                    technicusId,
                    serieNummer,
                    serieNummerOrigineel,
                    locatieBeschrijving,
                    locatieBeschrijvingOrigineel,
                    gebouw,
                    gebouwOrigineel,
                    verdieping,
                    verdiepingOrigineel,
                    lokaal,
                    lokaalOrigineel,

                    opmerking
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * toestelStuklijstAangepast(action) {
            const {
                bezoekSessieId,
                toestelId,
                toegevoegdeStukken,
                verwijderdeStukken,
                verwisseldeStukken
            } = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "TOESTEL_STUKLIJST_AANGEPAST",
                    toestelId,
                    technicusId,
                    toegevoegdeStukken,
                    verwijderdeStukken,
                    verwisseldeStukken
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * interventieUitgevoerd(action: EventPayloadAction<ToestelInterventieUitgevoerd>) {
            const {
                bezoekSessieId,
                interventieId,
                toestelId,
                opmerking,
                controleWerking,
                soortCode,
                soortLabel,
                verbruikteArtikelen
            } = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "TOESTEL_INTERVENTIE_UITGEVOERD",
                    interventieId,
                    toestelId,
                    technicusId,
                    opmerking,
                    controleWerking,
                    soortCode,
                    soortLabel,
                    verbruikteArtikelen
                },
                bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * downloadBijlage(action: PayloadAction<{ toestelId: string; bijlageId: string; save?: boolean; onSuccess?(fileDownload: FileDownload): void }>) {
            const {toestelId, bijlageId, save = true, onSuccess} = action.payload;

            const requestKey = `downloadBijlage:${bijlageId}`;
            const uri = Config.BASE_URL + `/api/toestel/${toestelId}/bijlage/${bijlageId}`;
            yield put(downloads.actions.pending({requestKey: uri}));

            try {
                const result = (yield call(downloadFile, uri, "Toestelbijlage")) as unknown as FileDownload;

                if (save) {
                    yield saveFile(result);
                }

                yield put(downloads.actions.succeeded({requestKey, resources: [bijlageId]}));

                if (onSuccess) {
                    yield call(onSuccess, result);
                }
            } catch (error) {
                yield put(downloads.actions.failed({requestKey, requestProperties: {error}}));
                yield put(statusSaga.actions.logError({error}));
            }
        }
    }
});
