import React, {useMemo} from "react";
import {formatDate, parseDate} from "../utilities/dateUtils";
import dayjs, {ConfigType} from "dayjs";
import {useTranslation} from "../utilities/i18nUtils";

export interface DatumProps {
    datum?: ConfigType;
    parseFormat?: string;

    format: string;

    relativeDay?: boolean;
}

export const Datum: React.FC<DatumProps> = (props) => {

    const {t} = useTranslation("algemeen");

    const {datum, parseFormat, format, relativeDay} = props;

    const parsedDate = useMemo(() => {
        return parseDate(datum, parseFormat);
    }, [datum, parseFormat]);

    if (!datum) {
        return <span className="text-muted">{t("Datum.datum-onbekend", "onbekend")}</span>;
    }

    if (relativeDay) {
        const dayDifference = dayjs(parsedDate).startOf("day").diff(dayjs().startOf("day"), "day");

        switch (dayDifference) {
            case -2:
                return <>{t("Datum.eergisteren", "Eergisteren")}</>;
            case -1:
                return <>{t("Datum.gisteren", "Gisteren")}</>;
            case 0:
                return <>{t("Datum.vandaag", "Vandaag")}</>;
            case 1:
                return <>{t("Datum.morgen", "Morgen")}</>;
            case 2:
                return <>{t("Datum.overmorgen", "Overmorgen")}</>;
        }
    }

    return (
        <>
            {formatDate(parsedDate, format)}
        </>
    );
};
