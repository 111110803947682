import React, {useEffect, useRef} from "react";
import {LottieRefCurrentProps} from "lottie-react";
import {Assets} from "../../assets/assets";
import {useTranslation} from "../../utilities/i18nUtils";
import Icon from "@mdi/react";
import {mdiInformationVariant} from "@mdi/js";
import {DagPlanningRestModel} from "../../_generated/field-service-be-openapi";
import {Config} from "../../utilities/config";
import {PlanningInfoBox} from "./PlanningInfoBox";

export interface PlanningLeegProps {
    dagPlanning?: DagPlanningRestModel;
}

export const PlanningLeeg: React.FC<PlanningLeegProps> = React.memo((props) => {
    const {dagPlanning} = props;

    const {t} = useTranslation("planning");

    const ref = useRef<LottieRefCurrentProps | null>();

    useEffect(() => {
        if (ref.current) {
            ref.current.setSpeed(0.8);

            setTimeout(() => ref.current?.play(), 1000);
        }
    }, [ref]);

    const message = dagPlanning ? Config.categoryLabels.planningLeegTekst[dagPlanning.status] : "";

    return (
        <PlanningInfoBox variant="info" icon={<Icon path={mdiInformationVariant} size={2.6}/>}>
            <p className="display-6">{t("planning:PlanningLeeg.niets-gepland-vandaag", "Er staat niets gepland vandaag")}</p>

            <p className="text-muted">{message}</p>

            <div style={{width: "20%"}} className="mb-4">
                <img src={Assets.emptyFolder} className="img-fluid" alt="Leeg"/>
            </div>
        </PlanningInfoBox>
    );
});
