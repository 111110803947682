import {FormikProps} from "formik/dist/types";

export class SaveDraftUtils {

    static onBlur(fieldName: string, form: FormikProps<any>, cb?: any) {
        form.setFormikState((prev) => {
            const previousBlurs = (prev as any)["blurs"] || {};
            const previousBlurForField = previousBlurs[fieldName] || 0;

            return {
                ...prev,
                blurs: {
                    ...previousBlurs,
                    [fieldName]: previousBlurForField + 1
                }
            };
        });

        cb?.();
    }

}
