import React, {ReactNode} from "react";

export interface PlanningInfoBoxProps {
    icon?: ReactNode;
    variant: "info" | "danger" | "warning" | "success" | "primary" | "secondary" | "light" | "dark";

    fullWidth?: boolean;

    children?: ReactNode;
}

export const PlanningInfoBox: React.FC<PlanningInfoBoxProps> = (props) => {
    const {icon, variant, fullWidth} = props;

    return (
        <div className={`bg-${variant} bg-opacity-25 p-3`} style={fullWidth ? {marginTop: "-1.5rem", marginLeft: "-1.25rem", marginRight: "-1.25rem"} : {borderRadius: "1rem"}}>
            <div className="d-flex">
                <div className="me-2">
                    {icon}
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    );
};
