import React from "react";
import {useDownloadServiceRapport} from "../../redux/slices/serviceAdressen/hooks";
import {MDBBtn} from "mdb-react-ui-kit";
import {mdiFilePdfBox} from "@mdi/js";
import Icon from "@mdi/react";

export interface ServiceRapportDownloadLinkProps {
    serviceAdresId: string;
    serviceRapportId: string;
}

export const ServiceRapportDownloadButton: React.FC<ServiceRapportDownloadLinkProps> = (props) => {
    const {serviceAdresId, serviceRapportId} = props;

    const [downloadServiceRapport] = useDownloadServiceRapport(serviceAdresId, serviceRapportId);

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();

        downloadServiceRapport();
    };

    return (
        <MDBBtn type="button" onClick={onClick} color="link" className="p-0">
            <Icon path={mdiFilePdfBox} size={1}/>
        </MDBBtn>
    );
};
