import React, {forwardRef} from "react";
import {MDBNavbar} from "mdb-react-ui-kit";
import {motion} from "framer-motion";
import type {NavbarProps} from "../../mdb-react-ui-kit/free/navigation/Navbar/types";

const MotionMDBNavbarRef: any = forwardRef((props, ref) => (
    <MDBNavbar {...props} ref={ref} />
));

export const MotionMDBNavbar = motion<NavbarProps>(MotionMDBNavbarRef);
