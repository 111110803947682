import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback, useEffect, useState} from "react";
import {status} from "./slice";
import {NetworkStatus, StatusServiceWorker} from "./types";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {statusSaga} from "./saga";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";

export const useServiceWorker = () => useAppSelector((state) => state.status.serviceWorker);

export const useSetServiceWorker = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: Partial<StatusServiceWorker>) => dispatch(status.actions.setServiceWorker(payload)), [dispatch]);
};

export const useBrowserNetworkStatus = () => useAppSelector((state) => state.status.browserNetworkStatus);
export const useServiceWorkerNetworkStatus = () => useAppSelector((state) => state.status.serviceWorkerNetworkStatus);

export const useNetworkStatus = () => {
    const browserNetworkStatus = useBrowserNetworkStatus();
    const serviceWorkerNetworkStatus = useServiceWorkerNetworkStatus();

    if (browserNetworkStatus === NetworkStatus.ONLINE && serviceWorkerNetworkStatus === NetworkStatus.ONLINE) {
        return NetworkStatus.ONLINE;
    }

    if (browserNetworkStatus === NetworkStatus.SLOW || serviceWorkerNetworkStatus === NetworkStatus.SLOW) {
        return NetworkStatus.SLOW;
    }

    if (browserNetworkStatus === NetworkStatus.OFFLINE || serviceWorkerNetworkStatus === NetworkStatus.OFFLINE) {
        return NetworkStatus.OFFLINE;
    }

    return NetworkStatus.OFFLINE;
};

export const useSetBrowserNetworkStatus = () => {
    const dispatch = useAppDispatch();

    return useCallback((networkStatus: NetworkStatus) => dispatch(status.actions.setBrowserNetworkStatus(networkStatus)), [dispatch]);
};

export const useSetServiceWorkerNetworkStatus = () => {
    const dispatch = useAppDispatch();

    return useCallback((networkStatus: NetworkStatus) => dispatch(status.actions.setServiceWorkerNetworkStatus(networkStatus)), [dispatch]);
};

export const useSetBatteryPercentage = () => {
    const dispatch = useAppDispatch();

    return useCallback((percentage: number) => dispatch(status.actions.setBatteryPercentage(percentage)), [dispatch]);
};

export const useLogError = () => {
    const dispatch = useAppDispatch();

    return useCallback((error: Error) => dispatch(statusSaga.actions.logError({error})), [dispatch]);
};

export const useRemoveError = () => {
    const dispatch = useAppDispatch();

    return useCallback((id: string) => dispatch(statusSaga.actions.removeError({id})), [dispatch]);
};

export const useClearErrors = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => dispatch(statusSaga.actions.clearErrors()), [dispatch]);
};

export const useApplicationStatus = () => {
    return useAppSelector((state) => ({
        localEventsAmount: state.status.localEventsAmount,
        localFilesAmount: state.status.localFilesAmount,

        offlineSinceTimestamp: state.status.offlineSinceTimestamp,
        planningLastSyncTimestamp: state.status.planningLastSyncTimestamp,
        planningLastVersieCheckTimestamp: state.status.planningLastVersieCheckTimestamp,
        actieveBezoekSessieLastSyncTimestamp: state.status.actieveBezoekSessieLastSyncTimestamp,
        periodicSyncLastTimestamp: state.status.periodicSyncLastTimestamp,

        cacheItems: state.status.cacheItems
    }));
};

export const useSyncingActieveBezoekSessies = () => useAppSelector((state) => state.status.syncingActieveBezoekSessies);
export const useSyncingPlanning = () => useAppSelector((state) => state.status.syncingPlanning);
export const useSyncingArtikelen = () => useAppSelector((state) => state.status.syncingArtikelen);
export const useSyncingArtikelAfbeeldingen = () => useAppSelector((state) => state.status.syncingArtikelAfbeeldingen);
export const useSyncingEvents = () => useAppSelector((state) => state.status.syncingEvents);

export const useInstanceId = () => useAppSelector((state) => state.status.instanceId);
export const useLocalEvents = () => useAppSelector((state) => state.status.localEvents);
export const useBatteryPercentage = () => useAppSelector((state) => state.status.batteryPercentage);
export const useSavedErrors = () => useAppSelector((state) => state.status.errors);

export const useWebSocketStatus = () => {
    const [state, setState] = useState<NetworkStatus>();

    useEffect(() => {
        const getStatus = () => {
            browser2ServiceWorkerChannel.sendRpc(WorkerMessageFactory.createGetWebSocketStatusMessage()).then((value) => {
                setState(value.connected);
            });
        };

        getStatus();
        const intervalHandle = setInterval(() => getStatus(), 10_000);

        return () => {
            if (intervalHandle) {
                clearInterval(intervalHandle);
            }
        };
    }, []);

    return state;
};
