import {useFormikContext} from "formik";
import React from "react";
import {Prompt} from "react-router-dom";
import {useUiFormDraft} from "redux/slices/ui/hooks";
import {useDeepCompareEffect} from "use-deep-compare";
import {UiFormDraftOorsprongEnum} from "../_generated/field-service-be-openapi";

export interface SaveDraftFormikProps {
    formId: string;
    bezoekSessieId: string;
}

const serializeFormValues = (values: any): { [key: string]: object; } => {
    // Zorgt ervoor dat bijvoorbeeld tijdstippen juist omgezet worden naar strings
    return JSON.parse(JSON.stringify(values));
};

export const SaveDraftFormik: React.FC<SaveDraftFormikProps> = (props) => {
    const {formId, bezoekSessieId} = props;

    const uiFormDraft = useUiFormDraft();

    const formikContext = useFormikContext();
    const blurs = (formikContext as any).blurs;

    const sendFormDraft = (oorsprong: UiFormDraftOorsprongEnum) => {
        uiFormDraft({
            bezoekSessieId,
            formId,
            formValues: serializeFormValues(formikContext.values),
            oorsprong
        });
    };

    useDeepCompareEffect(() => {
        if (formikContext.dirty) {
            sendFormDraft(UiFormDraftOorsprongEnum.InputBlur);
        }
    }, [blurs]);

    return (
        <Prompt
            message={(location, action) => {
                if (["POP", "REPLACE"].includes(action) && formikContext.dirty) {
                    sendFormDraft(UiFormDraftOorsprongEnum.PageLeave);
                }

                return true;
            }}
        />
    );
};