import React from "react";
import {
    AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum
} from "../../../_generated/field-service-be-openapi";

const labelMap: Record<AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum, string> = {
    [AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.AnderSoortArtikelGeleverd]: "Ander soort artikel geleverd",
    [AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.KlantAkkoord]: "Klant akkoord",
    [AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.TeWeinigArtikelenBeschikbaar]: "Te weinig artikelen beschikbaar",
    [AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.VraagKlant]: "Vraag klant"
};

export interface AccessoireLeveringOnvolledigGeleverdRedenProps {
    reden: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum;
}

export const AccessoireLeveringOnvolledigGeleverdReden: React.FC<AccessoireLeveringOnvolledigGeleverdRedenProps> = (props) => {
    const {reden} = props;

    return (
        <>{labelMap[reden]}</>
    );
};

export const labelForReden = (reden: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum): string => {
    return labelMap[reden];
}
