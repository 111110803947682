import React, {useMemo} from "react";
import {MDBBtn, MDBModalBody, MDBModalHeader} from "mdb-react-ui-kit";
import {AdresBelemmerdRedenEnum} from "../../_generated/field-service-be-openapi";
import {Form, Formik} from "formik";
import {MDBFormikRadio} from "../../mdb-formik/MDBFormikRadio";
import {FrameModal} from "./FrameModal";
import * as Yup from "yup";
import {DisableAutocompletePlaceholderInput} from "../DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../utilities/i18nUtils";
import {MDBFormikTextArea} from "../../mdb-formik/MDBFormikTextArea";

export interface AdresBelemmerdFormValues {
    reden?: AdresBelemmerdRedenEnum;
    toelichting?: string;
}

export interface AdresAfwerkenModalProps {
    onConfirm?(reden: AdresBelemmerdRedenEnum, toelichting?: string): void;

    onClose?(): void;
}

export const AdresBelemmerdModal: React.FC<AdresAfwerkenModalProps> = (props) => {
    const {onConfirm, onClose} = props;

    const onSubmit = (values: AdresBelemmerdFormValues) => {
        onConfirm?.(values.reden!, values.toelichting);
        onClose?.();
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const validationSchema = useMemo(() => Yup.object({
        reden: Yup.string().required("Reden is verplicht"),
        toelichting: Yup.string()
            .when("reden", {
                is: AdresBelemmerdRedenEnum.Andere,
                then: Yup.string().required("Toelichting is verplicht")
            })
    }), []);

    const {t} = useTranslation("serviceadres");

    const adresNietGevondenLabel = t("AdresBelemmerdModal.het-adres-niet-gevonden",
        "Het adres niet gevonden");

    const adresOnbereikbaarLabel = t("AdresBelemmerdModal.het-adres-was-onbereikbaar",
        "Het adres was onbereikbaar (wegenwerken, blockage)");

    const adresUitzonderlijkGeslotenLabel = t("AdresBelemmerdPage.het-adres-was-uitzonderlijk-gesloten",
        "Het adres was gesloten (uitzonderlijke sluiting)");

    const adresGeslotenEnIncorrecteOpeningsurenLabel = t("adresBelemmerdModal.het-adres-was-gesloten-openingsuren-incorrect",
        "Het adres was gesloten (openingsuren incorrect)");

    const adresGeslotenENAangekomenBuitenDeOpeningsurenLabel = t("adresBelemmerdModal.het-adres-was-gesloten-buiten-openinsuren-aangekomen",
        "Het adres was gesloten (openingsuren correct maar buiten openingsuren aangekomen)")

    const klantIsVerhuisdLabel = t("adresBelemmerdModal.klant-is-verhuisd",
        "Klant is verhuisd");

    const onvoldoendeTijdDoorOpeningsurenLabel = t("adresBelemmerdModal.onvoldoende-tijd-door-openingsuren-klant",
        "Niet voldoende tijd om alle opdrachten af te werken (openingsuren klant)");

    const onvoldoendeTijdDoorEindeShiftLabel = t("adresBelemmerdModal.onvoldoende-tijd-door-einde-shift",
        "Niet voldoende tijd om alle opdrachten af te werken (einde shift)");

    const planningsWijzigingVanuitAqualexLabel = t("adresBelemmerdModal.planningswijziging-vanuit-aqualex",
        "Planningswijziging vanuit AQUALEX");

    const andereRedenLabel = t("adresBelemmerdModal.adres-belemmerd-andere-reden", "Andere");

    const toelichtingLabel = t("adresBelemmerdModal.adres-belemmerd-toelichting", "Toelichting");

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>Adres belemmerd</MDBModalHeader>
            <MDBModalBody className="py-1">
                <Formik<AdresBelemmerdFormValues> initialValues={{}} onSubmit={onSubmit}
                                                  validationSchema={validationSchema}>
                    {({errors}) => (
                        <Form autoComplete="off">
                            <DisableAutocompletePlaceholderInput/>
                            <p className="mb-0">
                                Waarom heb je deze taken niet kunnen afwerken?
                            </p>

                            <div className="ms-2">
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.AdresNietGevonden}
                                                value={AdresBelemmerdRedenEnum.AdresNietGevonden}
                                                label={adresNietGevondenLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.AdresOnbereikbaar}
                                                value={AdresBelemmerdRedenEnum.AdresOnbereikbaar}
                                                label={adresOnbereikbaarLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.AdresUitzonderlijkGesloten}
                                                value={AdresBelemmerdRedenEnum.AdresUitzonderlijkGesloten}
                                                label={adresUitzonderlijkGeslotenLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.AdresGeslotenOpeningsurenIncorrect}
                                                value={AdresBelemmerdRedenEnum.AdresGeslotenOpeningsurenIncorrect}
                                                label={adresGeslotenEnIncorrecteOpeningsurenLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.AdresGeslotenOpeningsurenCorrectMaarBuitenOpeningsurenAangekomen}
                                                value={AdresBelemmerdRedenEnum.AdresGeslotenOpeningsurenCorrectMaarBuitenOpeningsurenAangekomen}
                                                label={adresGeslotenENAangekomenBuitenDeOpeningsurenLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.KlantVerhuisd}
                                                value={AdresBelemmerdRedenEnum.KlantVerhuisd}
                                                label={klantIsVerhuisdLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.NietVoldoendeTijdDoorOpeningsuren}
                                                value={AdresBelemmerdRedenEnum.NietVoldoendeTijdDoorOpeningsuren}
                                                label={onvoldoendeTijdDoorOpeningsurenLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.NietVoldoendeTijdDoorEindeShift}
                                                value={AdresBelemmerdRedenEnum.NietVoldoendeTijdDoorEindeShift}
                                                label={onvoldoendeTijdDoorEindeShiftLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.PlanningswijzigingVanuitAqualex}
                                                value={AdresBelemmerdRedenEnum.PlanningswijzigingVanuitAqualex}
                                                label={planningsWijzigingVanuitAqualexLabel}/>
                                <MDBFormikRadio name="reden"
                                                id={AdresBelemmerdRedenEnum.Andere}
                                                value={AdresBelemmerdRedenEnum.Andere}
                                                label={andereRedenLabel}/>

                                {errors.reden && <div className="text-danger">{errors.reden}</div>}

                                <MDBFormikTextArea name="toelichting" label={toelichtingLabel} className="mt-2"/>
                            </div>

                            <div className="d-flex justify-content-end align-items-center my-3">
                                <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                                    {t("algemeen:annuleren", "Annuleren")}
                                </MDBBtn>
                                <MDBBtn color="success" size="lg" className="ms-2" type="submit">
                                    {t("algemeen:oké", "Oké")}
                                </MDBBtn>
                            </div>
                        </Form>
                    )}
                </Formik>
            </MDBModalBody>
        </FrameModal>
    );
};
