import React, {useMemo} from "react";
import {MDBBtn, MDBModalBody, MDBModalHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {
    AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum
} from "../../_generated/field-service-be-openapi";
import {Form, Formik} from "formik";
import {FrameModal} from "./FrameModal";
import {BezoekMDBFormikSelect} from "../../mdb-formik/bezoek/BezoekMDBFormikSelect";
import * as Yup from "yup";
import {AnySchema} from "yup";
import {AccessoireLeveringFormOrder} from "../../pages/ServiceAdres/Opdracht/AccessoireLeveringPage";
import {labelForReden} from "../opdracht/accessoirelevering/AccessoireLeveringOnvolledigGeleverdReden";
import {BezoekSessieAccessoireLevering} from "../../workers/shared/snapshot/bezoekSessieState";
import {DisableAutocompletePlaceholderInput} from "../DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../utilities/i18nUtils";


export interface AccessoireLeveringAfwijkendFormValues {
    accessoireLevering: Record<string, Record<string, {
        reden: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum;
    }>>;
}

export interface AccessoireLeveringAfwijkendModalProps {
    uitTeVoerenAccessoireLeveringen: BezoekSessieAccessoireLevering[];
    ingegevenLeveringen: Record<string, AccessoireLeveringFormOrder>;

    onConfirm?(values: AccessoireLeveringAfwijkendFormValues): void;

    onClose?(): void;
}

export const AccessoireLeveringAfwijkendModal: React.FC<AccessoireLeveringAfwijkendModalProps> = (props) => {
    const {
        ingegevenLeveringen,
        uitTeVoerenAccessoireLeveringen,
        onConfirm,
        onClose
    } = props;

    const onSubmit = (values: AccessoireLeveringAfwijkendFormValues) => {
        onConfirm?.(values);
        onClose?.();
    };

    const getGeleverdAantal = (leveringId: string, artikelId: string) => {
        return ingegevenLeveringen?.[leveringId].geleverd?.[artikelId] || 0;
    };

    const afwijkendeAccessoireLeveringen = uitTeVoerenAccessoireLeveringen.flatMap(item => {
        return item.artikelen.map(artikel => {
            return {
                id: item.id,
                artikelId: artikel.artikelId,
                artikelNr: artikel.artikelNr,
                artikelOmschrijving: artikel.artikelOmschrijving,
                gevraagdAantal: artikel.gevraagdAantal,
                geleverdAantal: getGeleverdAantal(item.id, artikel.artikelId),
                onvolledigeLeveringReden: artikel.onvolledigeLeveringReden
            };
        }).filter(item => item.geleverdAantal !== item.gevraagdAantal);
    });

    const validationSchema = useMemo(() => Yup.lazy(obj => {
        const redenShape = Yup.object({
            reden: Yup.string().required("Reden is verplicht")
        });

        const accessoireLeveringArtikelSchemas: Record<string, Record<string, AnySchema>> = {};
        for (const afwijkendeIngegevenLevering of afwijkendeAccessoireLeveringen) {
            accessoireLeveringArtikelSchemas[afwijkendeIngegevenLevering.id] = {
                ...accessoireLeveringArtikelSchemas[afwijkendeIngegevenLevering.id],
                [afwijkendeIngegevenLevering.artikelId]: redenShape
            };
        }

        const accessoireLeveringSchemas: Record<string, AnySchema> = {};
        for (const leveringId of Object.keys(accessoireLeveringArtikelSchemas)) {
            const artikelSchema: Record<string, AnySchema> = {};
            for (const artikelId of Object.keys(accessoireLeveringArtikelSchemas[leveringId])) {
                artikelSchema[artikelId] = redenShape;
            }
            accessoireLeveringSchemas[leveringId] = Yup.object(artikelSchema);
        }

        return Yup.object({
            accessoireLevering: Yup.object(accessoireLeveringSchemas)
        });
    }), [afwijkendeAccessoireLeveringen]);

    const initialValues = useMemo(() => {
        const accessoireLevering: AccessoireLeveringAfwijkendFormValues["accessoireLevering"] = {};

        for (const afwijkendeAccessoireLevering of afwijkendeAccessoireLeveringen) {
            if (afwijkendeAccessoireLevering.onvolledigeLeveringReden) {
                accessoireLevering[afwijkendeAccessoireLevering.id] = {
                    ...accessoireLevering[afwijkendeAccessoireLevering.id],
                    [afwijkendeAccessoireLevering.artikelId]: {
                        reden: afwijkendeAccessoireLevering?.onvolledigeLeveringReden
                    }
                };
            }
        }

        return {
            accessoireLevering
        } as AccessoireLeveringAfwijkendFormValues;
    }, [afwijkendeAccessoireLeveringen]);

    const {t} = useTranslation("serviceadres");

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>Waarom is er minder geleverd?</MDBModalHeader>
            <MDBModalBody className="py-1">
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {({isValid, errors}) => (
                        <Form autoComplete="off">
                            <DisableAutocompletePlaceholderInput/>
                            <MDBTable responsive striped borderless>
                                <MDBTableHead>
                                    <tr>
                                        <th>{t("algemeen:Titels.artikel", "Artikel")}</th>
                                        <th>{t("algemeen:gevraagd-aantal", "Gevraagd aantal")}</th>
                                        <th>{t("algemeen:geleverd-aantal", "Geleverd aantal")}</th>
                                        <th></th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {afwijkendeAccessoireLeveringen.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td className="text-nowrap">
                                                    <strong>{item.artikelNr}</strong> {item.artikelOmschrijving}
                                                </td>
                                                <td className="text-nowrap">
                                                    {item.gevraagdAantal}
                                                </td>
                                                <td className="text-nowrap">
                                                    {getGeleverdAantal(item.id, item.artikelId)}
                                                </td>
                                                <td className="w-100">
                                                    <div className="mb-4">
                                                        <BezoekMDBFormikSelect
                                                            id={`accessoireLevering.${item.id}.${item.artikelId}.reden`}
                                                            name={`accessoireLevering.${item.id}.${item.artikelId}.reden`}
                                                            label="Reden"
                                                            data={[
                                                                {
                                                                    text: labelForReden(AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.TeWeinigArtikelenBeschikbaar),
                                                                    value: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.TeWeinigArtikelenBeschikbaar
                                                                },
                                                                {
                                                                    text: labelForReden(AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.AnderSoortArtikelGeleverd),
                                                                    value: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.AnderSoortArtikelGeleverd
                                                                },
                                                                {
                                                                    text: labelForReden(AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.KlantAkkoord),
                                                                    value: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.KlantAkkoord
                                                                },
                                                                {
                                                                    text: labelForReden(AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.VraagKlant),
                                                                    value: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum.VraagKlant
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </MDBTableBody>
                            </MDBTable>

                            <MDBBtn size="lg" type="submit" block className="mb-2" disabled={!isValid}>
                                {t("algemeen:bewaren", "Bewaren")}
                            </MDBBtn>
                        </Form>
                    )}
                </Formik>
            </MDBModalBody>
        </FrameModal>
    );
};
