import React from "react";
import {formatDuration, humanizeDuration} from "../utilities/dateUtils";
import {DurationUnitType} from "dayjs/plugin/duration";

export interface TijdsduurProps {
    tijdsduur: number;
    eenheid: DurationUnitType;

    format?: string;
    humanize?: boolean;
}

export const Tijdsduur: React.FC<TijdsduurProps> = (props) => {
    const {tijdsduur, eenheid, format = "HH:mm", humanize} = props;

    let formatted: string;
    if (humanize) {
        formatted = humanizeDuration(tijdsduur, eenheid);
    } else {
        formatted = formatDuration(format, tijdsduur, eenheid);
    }

    return (
        <>
            {formatted}
        </>
    );
};
