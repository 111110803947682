import React from "react";
import {MDBBtn, MDBContainer, MDBModalBody, MDBModalHeader, MDBModalTitle} from "mdb-react-ui-kit";
import {FrameModal} from "./FrameModal";
import {Toestel} from "../../_generated/field-service-be-openapi";
import {ToestelBijlagenTable} from "../toestel/ToestelBijlagenTable";

export interface ToestelBijlagenModalProps {
    toestel: Toestel;

    onClose?(): void;
}

export const ToestelBijlagenModal: React.FC<ToestelBijlagenModalProps> = (props) => {
    const {toestel, onClose} = props;

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>
                <MDBModalTitle>Toestelbijlagen</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={onClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBContainer fluid className="px-1 py-2">
                    <ToestelBijlagenTable toestel={toestel}/>
                </MDBContainer>
            </MDBModalBody>
        </FrameModal>
    );
};
