// Source: https://github.com/Swizec/useDimensions

import {useCallback, useLayoutEffect, useState} from "react";

export interface DimensionObject {
    width: number;
    height: number;
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
}

export type UseDimensionsHook = [
    React.LegacyRef<any>,
    DimensionObject | undefined,
    HTMLElement | null
];

export interface UseDimensionsArgs {
    liveMeasure?: boolean;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
    const rect = node.getBoundingClientRect();

    let top = rect.top;
    let left = rect.left;
    let x = rect.left;
    let y = rect.top;
    if ("x" in rect) {
        top = rect.x;
        x = rect.x;
    }
    if ("y" in rect) {
        left = rect.y;
        y = rect.y;
    }

    return {
        width: rect.width,
        height: rect.height,
        top,
        left,
        x,
        y,
        right: rect.right,
        bottom: rect.bottom
    };
}

export function useDimensions({
                           liveMeasure = true
                       }: UseDimensionsArgs = {}): UseDimensionsHook {
    const [dimensions, setDimensions] = useState<DimensionObject>();
    const [node, setNode] = useState(null);

    const ref = useCallback((node: any) => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node))
                );
            measure();

            if (liveMeasure) {
                window.addEventListener("resize", measure);
                window.addEventListener("scroll", measure);

                return () => {
                    window.removeEventListener("resize", measure);
                    window.removeEventListener("scroll", measure);
                };
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node]);

    return [ref, dimensions, node];
}
