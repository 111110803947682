import React from "react";
import {MDBCard, MDBCardBody, MDBCardText} from "mdb-react-ui-kit";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {useBezoekSessies} from "../redux/slices/bezoeksessie/hooks";
import {useSyncingActieveBezoekSessies} from "../redux/slices/status/hooks";
import {LoadingDroplet} from "../components/LoadingDroplet";
import {ContentContainer} from "../components/ContentContainer";
import {BezoeksessieCard} from "./BezoeksessieCard";
import {useTranslation} from "../utilities/i18nUtils";

export const BezoeksessiesPage: React.FC = () => {

    const syncingActieveBezoekSessies = useSyncingActieveBezoekSessies();
    const bezoekSessies = useBezoekSessies();

    const actieveBezoekSessies = bezoekSessies && Object.values(bezoekSessies)
        .sort((a, b) => new Date(b.laatstGewijzigd || "").getTime() - new Date(a.laatstGewijzigd || "").getTime());

    const {t} = useTranslation("bezoeken");

    return (
        <GenericPage>
            <Header title={t("algemeen:Titels.bezoeken", "Bezoeken")} sticky/>

            <ContentContainer>
                {syncingActieveBezoekSessies && (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                        <div style={{width: "50%"}}>
                            <LoadingDroplet bg={false}/>
                        </div>

                        <p className="display-6">{t("BezoeksessiesPage.even-geduld", "Even geduld...")}</p>
                    </div>
                )}

                {!syncingActieveBezoekSessies && !actieveBezoekSessies?.length && (
                    <MDBCard background="light" className="shadow-sm mb-4">
                        <MDBCardBody>
                            <MDBCardText>
                                {t("BezoeksessiesPage.er-zijn-geen-actieve-bezoeken", "Er zijn geen actieve bezoeken.")}
                            </MDBCardText>

                        </MDBCardBody>
                    </MDBCard>
                )}

                {actieveBezoekSessies?.map((item) => (
                    <BezoeksessieCard key={item.id} bezoekSessie={item}/>
                ))}
            </ContentContainer>
        </GenericPage>
    );
};
