import React from "react";
import {useSyncingActieveBezoekSessies, useSyncingArtikelen, useSyncingPlanning} from "../redux/slices/status/hooks";
import {Capitalize} from "./Capitalize";
import {Opsomming} from "./Opsomming";
import {mdiSync} from "@mdi/js";
import Icon from "@mdi/react";
import {useTranslation} from "../utilities/i18nUtils";

export const HeaderLoadingStatus: React.FC = (props) => {
    const syncingPlanning = useSyncingPlanning();
    const syncingActieveBezoekSessies = useSyncingActieveBezoekSessies();
    const syncingArtikelen = useSyncingArtikelen();

    const syncingStuff = [
        syncingPlanning ? "planning" : undefined,
        syncingActieveBezoekSessies ? "bezoeken" : undefined,
        syncingArtikelen ? "artikelen" : undefined
    ]
        .filter(Boolean)
        .map((text, index) => <Capitalize enabled={index === 0}>{text}</Capitalize>);

    const {t} = useTranslation("algemeen");

    if (syncingStuff.length === 0) {
        return null;
    }

    return (
        <>
            <span className="me-2 d-none d-lg-inline">
                <Opsomming items={syncingStuff}/> {t("HeaderLoadingStatus.laden", "laden...")}
            </span>

            <Icon path={mdiSync} spin={-1} size={1}/>
        </>
    );
};
