import streamSaver from "streamsaver";
import * as contentDisposition from "content-disposition-header";
import prettyBytes from "pretty-bytes";
import {getAccessToken} from "../redux/providers/authProvider";
import {Buffer} from "buffer";

// mitm.html en sw.js moeten in /public/streamsaver folder staan
streamSaver.mitm = "/streamsaver/mitm.html";

export interface FileDownload {
    naam: string;
    mediaType: string;
    content: ReadableStream<Uint8Array>;
    body: Body;
}

const getFileNaam = (contentDispositionHeader: string | null) => {
    if (!contentDispositionHeader) {
        return "download";
    }

    const parsed = contentDisposition.parse(contentDispositionHeader);
    return parsed.parameters.filename || "download";
};

/**
 * download a file using the authenticated fetch
 * @param url
 * @param omschrijving
 * @param options
 */
export const downloadFile = async (url: string, omschrijving?: string, options: any = {}): Promise<FileDownload> => {
    const token = getAccessToken();
    if (!token) {
        throw new Error("No valid token");
    }

    const response = await fetch(url, {
        ...options,
        headers: {
            Authorization: `Bearer ${token?.accessToken}`
        }
    });

    if (response.status === 500) {
        const responseBody = await response.text();
        throw new Error("Er is iets fout gelopen bij het downloaden van het bestand: " + responseBody);
    }

    if (response.status === 404) {
        throw new Error("File not found");
    }

    return {
        naam: getFileNaam(response.headers.get("Content-Disposition")),
        mediaType: response.headers.get("Content-Type") || "application/octet-stream", //fallback
        content: response.body!,
        body: response
    };
};

export const saveFile = (file: FileDownload) => {
    const fileStream = streamSaver.createWriteStream(file.naam);

    file.content.pipeTo(fileStream);
};

export const getReadableBytes = (bytes: number) => {
    return prettyBytes(bytes, {locale: "nl"});
};

export const dataUriToBlob = (dataUri: string) => {
    const buffer = Buffer.from(dataUri.split(",")[1], "base64");

    const mimeString = dataUri.split(",")[0].split(':')[1].split(';')[0]

    return new Blob([buffer], {type: mimeString});
};

export const blobToDataUri = (blob: Blob) => {
    return new Promise<string>((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            if (event.target) {
                resolve(event.target.result as string);
            }
        };

        fileReader.readAsDataURL(blob);
    });
};
