import React from "react";
import {useParams} from "react-router-dom";
import {AccessoireLeveringPage} from "./AccessoireLeveringPage";
import {Co2LeveringPage} from "./Co2LeveringPage";
import {InstallatiePage} from "./InstallatiePage";

export const OpdrachtPageName: React.FC = () => {
    const {opdrachtId} = useParams<any>();

    return <span className="text-capitalize">{opdrachtId}</span>;
};

export const OpdrachtPage: React.FC = () => {
    const {opdrachtId} = useParams<any>();

    if (opdrachtId === "accessoire-levering") {
        return <AccessoireLeveringPage/>;
    } else if (opdrachtId === "co2-levering") {
        return <Co2LeveringPage/>;
    } else if (opdrachtId === "installatie") {
        return <InstallatiePage/>;
    }

    return null;
};
