import React from "react";
import Lottie, {LottieComponentProps} from "lottie-react";
import {Assets} from "../assets/assets";

export interface LoadingDropletProps {
    bg?: boolean;

    lottieProps?: Partial<LottieComponentProps>;
}

/**
 * https://lottiefiles.com/19324-prominent-loading-indicator
 * @constructor
 */
export const LoadingDroplet: React.FC<LoadingDropletProps> = (props) => {
    const bg = props.bg !== undefined ? props.bg : true;

    return (
        <Lottie {...props.lottieProps} animationData={bg ? Assets.loadingDripAnimation : Assets.loadingDripDarkNoBgAnimation}/>
    );
};
