/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BezoekSessieBundleRestModel,
    DagBundleRestModel,
    DagBundleVersieRestModel,
} from '../models';

export interface DagBundleVoorBezoekSessieIdRequest {
    bezoekSessieId: string;
}

export interface DagBundleVoorDatumEnTechnicusRequest {
    datum: string;
    technicusId: string;
}

export interface DagBundleVoorDatumEnTechnicusVersieRequest {
    datum: string;
    technicusId: string;
}

/**
 * 
 */
export class BundleApi extends runtime.BaseAPI {

    /**
     */
    async dagBundleVoorBezoekSessieIdRaw(requestParameters: DagBundleVoorBezoekSessieIdRequest): Promise<runtime.ApiResponse<BezoekSessieBundleRestModel>> {
        if (requestParameters.bezoekSessieId === null || requestParameters.bezoekSessieId === undefined) {
            throw new runtime.RequiredError('bezoekSessieId','Required parameter requestParameters.bezoekSessieId was null or undefined when calling dagBundleVoorBezoekSessieId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bundle/{bezoekSessieId}`.replace(`{${"bezoekSessieId"}}`, encodeURIComponent(String(requestParameters.bezoekSessieId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async dagBundleVoorBezoekSessieId(requestParameters: DagBundleVoorBezoekSessieIdRequest): Promise<BezoekSessieBundleRestModel> {
        const response = await this.dagBundleVoorBezoekSessieIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dagBundleVoorDatumEnTechnicusRaw(requestParameters: DagBundleVoorDatumEnTechnicusRequest): Promise<runtime.ApiResponse<DagBundleRestModel>> {
        if (requestParameters.datum === null || requestParameters.datum === undefined) {
            throw new runtime.RequiredError('datum','Required parameter requestParameters.datum was null or undefined when calling dagBundleVoorDatumEnTechnicus.');
        }

        if (requestParameters.technicusId === null || requestParameters.technicusId === undefined) {
            throw new runtime.RequiredError('technicusId','Required parameter requestParameters.technicusId was null or undefined when calling dagBundleVoorDatumEnTechnicus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bundle/dag/{datum}/{technicusId}`.replace(`{${"datum"}}`, encodeURIComponent(String(requestParameters.datum))).replace(`{${"technicusId"}}`, encodeURIComponent(String(requestParameters.technicusId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async dagBundleVoorDatumEnTechnicus(requestParameters: DagBundleVoorDatumEnTechnicusRequest): Promise<DagBundleRestModel> {
        const response = await this.dagBundleVoorDatumEnTechnicusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dagBundleVoorDatumEnTechnicusVersieRaw(requestParameters: DagBundleVoorDatumEnTechnicusVersieRequest): Promise<runtime.ApiResponse<DagBundleVersieRestModel>> {
        if (requestParameters.datum === null || requestParameters.datum === undefined) {
            throw new runtime.RequiredError('datum','Required parameter requestParameters.datum was null or undefined when calling dagBundleVoorDatumEnTechnicusVersie.');
        }

        if (requestParameters.technicusId === null || requestParameters.technicusId === undefined) {
            throw new runtime.RequiredError('technicusId','Required parameter requestParameters.technicusId was null or undefined when calling dagBundleVoorDatumEnTechnicusVersie.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bundle/dag/{datum}/{technicusId}/versie`.replace(`{${"datum"}}`, encodeURIComponent(String(requestParameters.datum))).replace(`{${"technicusId"}}`, encodeURIComponent(String(requestParameters.technicusId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async dagBundleVoorDatumEnTechnicusVersie(requestParameters: DagBundleVoorDatumEnTechnicusVersieRequest): Promise<DagBundleVersieRestModel> {
        const response = await this.dagBundleVoorDatumEnTechnicusVersieRaw(requestParameters);
        return await response.value();
    }

}
