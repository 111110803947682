import React, {ChangeEvent, LegacyRef} from "react";
import {MDBInput} from "mdb-react-ui-kit";
import {FieldProps} from "formik";
import {uploadFile, uploadIdToUrl} from "../utilities/uploadUtils";

export type MDBFileInputProps = typeof MDBInput["defaultProps"] & Partial<FieldProps> & {innerRef?: LegacyRef<any>;};

export interface MDBFileInputFileValue {
    file: File;
    uploadId?: string;
    url?: string;
}

export const MDBFileInput: React.FC<MDBFileInputProps> = (props) => {
    const {name, value, onChange, onBlur, innerRef, ...rest} = props;

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        const existingFileArray = (value || []) as unknown as MDBFileInputFileValue[];

        const fileArray: MDBFileInputFileValue[] = [];
        for (const existingFile of existingFileArray) {
            fileArray.push(existingFile);
        }

        for (const file of Array.from(files || [])) {
            const existingFile = existingFileArray.find((item) => item.file?.name === file?.name);

            let uploadId = existingFile?.uploadId;
            if (!uploadId) {
                const dataUrl = await new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = error => reject(error);
                });

                uploadId = await uploadFile(dataUrl);
            }

            fileArray.push({
                file,
                uploadId,
                url: uploadIdToUrl(uploadId!)
            });
        }

        onChange?.({
            target: {
                name,
                value: fileArray
            }
        } as any);

        onBlur?.({
            target: {
                name,
                getAttribute: () => name
            }
        } as any);
    };

    return (
        <MDBInput {...rest} ref={innerRef as any} name={name} value={value} type="file" onChange={onFileChange}/>
    );
};
