import React, {Ref} from "react"
import {
    AbstractDagPlanningEntryRestModel,
    DagPlanningBezoekEntryRestModel,
    DagPlanningHeaderRestModel,
    DagPlanningPauzeRestModel,
    DagPlanningRestModel,
    DagPlanningRitRestModel,
    DagPlanningStopRestModel,
    DagPlanningStopRestModelTypeEnum,
    DagPlanningTextRestModel
} from "../../_generated/field-service-be-openapi";
import {PlanningPauzeCard} from "./PlanningPauzeCard";
import {PlanningStopCard} from "./PlanningStopCard";
import {Adres} from "../Adres";
import {AdresAanwezigheid} from "../../workers/shared/snapshot/bezoekSessieState";
import {FieldServiceModal} from "../FieldServiceModal";
import {formatDate} from "../../utilities/dateUtils";
import {PlanningRitCard} from "./PlanningRitCard";
import {useMe} from "../../redux/slices/technicus/hooks";
import {
    useBezoekSessies,
    useHasSnapshotEverBeenLoaded,
    useStartBezoekSessieEnOnderweg
} from "../../redux/slices/bezoeksessie/hooks";
import {useHideModal, useShowModal} from "../../redux/slices/modal/hooks";
import {useServiceAdressen} from "../../redux/slices/serviceAdressen/hooks";
import {useKlanten} from "../../redux/slices/klant/hooks";
import {MDBCard, MDBCardBody, MDBCardText} from "mdb-react-ui-kit";
import {useSyncingPlanning} from "../../redux/slices/status/hooks";
import {MDBCardStatusEnum} from "../mdb/MDBCardStatus";
import {useTranslation} from "../../utilities/i18nUtils";

export interface PlanningEntryCardProps {
    entry: AbstractDagPlanningEntryRestModel;
    index: number;

    datum: Date;

    dagPlanning?: DagPlanningRestModel;

    containerRef?: Ref<any>;
    className?: string;
}

export const PlanningEntryCard: React.FC<PlanningEntryCardProps> = (props) => {
    const {entry, index, datum, dagPlanning, containerRef, className} = props;

    const technicus = useMe();
    const bezoekSessies = useBezoekSessies();
    const serviceAdressen = useServiceAdressen();
    const klanten = useKlanten();

    const hasSnapshotEverBeenLoaded = useHasSnapshotEverBeenLoaded();
    const syncingPlanning = useSyncingPlanning();

    const isLoading = !hasSnapshotEverBeenLoaded || syncingPlanning;

    const showModal = useShowModal();
    const hideModal = useHideModal();

    const startBezoekSessieEnOnderweg = useStartBezoekSessieEnOnderweg();

    const {t} = useTranslation("planning");

    if (!entry || !dagPlanning || !technicus) {
        return null;
    }

    switch (entry._type) {
        case "HEADER": {
            const headerEntry = entry as DagPlanningHeaderRestModel;

            return (
                <h2>{headerEntry.header}</h2>
            );
        }
        case "TEXT": {
            const textEntry = entry as DagPlanningTextRestModel;

            return (
                <MDBCard className="note-info shadow-sm mb-4" style={{borderTop: "4px solid var(--mdb-info)"}}>
                    <MDBCardBody>
                        <MDBCardText>{textEntry.text}</MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            );
        }
        case "PAUZE": {
            const pauzeEntry = entry as DagPlanningPauzeRestModel;

            return (
                <PlanningPauzeCard duurtijd={pauzeEntry.duurtijd} key={entry.id}/>
            );
        }
        case "STOP": {
            const stopEntry = entry as DagPlanningStopRestModel;

            let active = false;
            let name: string;
            switch (stopEntry.type) {
                case DagPlanningStopRestModelTypeEnum.Bestemming:
                    name = t("PlanningEntryCard.bestemming","Bestemming");
                    break;
                case DagPlanningStopRestModelTypeEnum.Stop:
                    name = t("PlanningEntryCard.stop", "Stop");
                    break;
                case DagPlanningStopRestModelTypeEnum.Vertrek:
                    name = t("PlanningEntryCard.vertrek", "Vertrek");

                    active = hasSnapshotEverBeenLoaded;
                    if (technicus) {
                        for (const bezoekSessie of Object.values(bezoekSessies)) {
                            if (bezoekSessie.adresAanwezigheid[technicus.id]) {
                                active = false;
                                break;
                            }
                        }
                    }
                    break;
            }

            return (
                <PlanningStopCard
                    key={entry.id}

                    containerRef={containerRef}
                    className={`mb-4 dag-planning-entry ${className || ""}`}

                    name={name}
                    fromEstimated={stopEntry.geplandStart as any}
                    from={stopEntry.displayStart as any}
                    active={active}
                    adres={<span className="text-muted"><Adres adres={stopEntry}/></span>}

                    disabled={isLoading}
                />
            );
        }
        case "RIT": {
            const ritEntry = entry as DagPlanningRitRestModel;
            const ritNaarBezoekEntry = dagPlanning?.entries?.[index + 1] as DagPlanningBezoekEntryRestModel | DagPlanningStopRestModel;

            let active = false;
            if ((ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel)?.serviceAdresId && technicus) {
                for (const bezoekSessie of Object.values(bezoekSessies)) {
                    if (bezoekSessie.serviceAdres?.id === (ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel).serviceAdresId) {
                        active = bezoekSessie.adresAanwezigheid[technicus.id] === AdresAanwezigheid.ONDERWEG;

                        break;
                    }
                }
            }

            const onClick = () => {
                showModal({
                    type: FieldServiceModal.ONDERWEG_MODAL,
                    props: {
                        coordinaten: (ritNaarBezoekEntry as DagPlanningStopRestModel).coordinaat,
                        serviceAdresId: (ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel).serviceAdresId,
                        onOnderwegClick() {
                            startBezoekSessieEnOnderweg(
                                formatDate(datum, "YYYY-MM-DD"),
                                (ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel).serviceAdresId,
                                (ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel).bezoekId,
                                (ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel).toekomstigBezoekSessieId,
                                (ritNaarBezoekEntry as DagPlanningBezoekEntryRestModel).uitTeVoerenWerk,
                                dagPlanning.versie!,
                                dagPlanning.versieTijdstip!,
                                (error) => {
                                    hideModal();
                                }
                            );
                        },
                        onClose() {
                            hideModal();
                        }
                    }
                });
            };

            return (
                <PlanningRitCard key={entry.id}
                                 duurtijd={ritEntry.duurtijd || 0}
                                 afstand={ritEntry.afstand || 0}
                                 active={active}
                                 onClick={onClick}
                                 className="mb-4"

                                 disabled={isLoading}
                />
            );
        }
        case "BEZOEK": {
            const bezoekEntry = entry as DagPlanningBezoekEntryRestModel;

            const serviceAdres = serviceAdressen[bezoekEntry.serviceAdresId];

            let active = false;
            let status: MDBCardStatusEnum | undefined = undefined;

            const interventieAantal = bezoekEntry.uitTeVoerenWerk?.toestellen?.map((toestel) => toestel.interventies?.length || 0).reduce((prev: number, curr: number) => prev + curr, 0);
            const onderhoudAantal = bezoekEntry.uitTeVoerenWerk?.toestellen?.map((toestel) => toestel.onderhoud ? 1 : 0).reduce((prev: number, curr: number) => prev + curr, 0);
            const co2BestellingAantal = bezoekEntry.uitTeVoerenWerk?.co2Leveringen?.length;
            const bestellingAantal = bezoekEntry.uitTeVoerenWerk?.accessoireLeveringen?.length;
            const installatieAantal = bezoekEntry.uitTeVoerenWerk?.installaties?.length;

            const bezoekSessie = bezoekSessies[bezoekEntry.toekomstigBezoekSessieId];

            if (bezoekSessie) {
                const adresAanwezigheden = Object.values(bezoekSessie.adresAanwezigheid);
                if (adresAanwezigheden.includes(AdresAanwezigheid.VERLATEN)) {
                    if (bezoekSessie.eventsSyncedAmount === bezoekSessie.eventsTotalAmount) {
                        status = MDBCardStatusEnum.DONE;
                    } else {
                        status = MDBCardStatusEnum.DONE_EVENTS_MISSING;
                    }
                } else if (adresAanwezigheden.includes(AdresAanwezigheid.AANWEZIG)) {
                    status = MDBCardStatusEnum.IN_PROGRESS;
                }

                active = bezoekSessie.aanwezig;
            }

            return (
                <PlanningStopCard
                    containerRef={containerRef}
                    key={entry.id}

                    className={`mb-4 dag-planning-entry ${className || ""}`}

                    name={serviceAdres && <div>
                        {klanten[serviceAdres.klantId!].naam} <small
                        className="text-muted font-weight-normal">{serviceAdres.naam}</small></div>}
                    link={`/bezoek/${bezoekEntry.toekomstigBezoekSessieId}`}

                    fromEstimated={bezoekEntry.geplandStart}
                    toEstimated={bezoekEntry.geplandEind}
                    from={bezoekEntry.displayStart}

                    active={active}

                    adres={<Adres adres={serviceAdres}/>}
                    center={serviceAdres?.coordinaten?.[0] && {
                        lat: serviceAdres?.coordinaten?.[0].latitude!,
                        lng: serviceAdres?.coordinaten?.[0].longitude!
                    }}

                    cardStatus={status}

                    interventieAantal={interventieAantal}
                    onderhoudAantal={onderhoudAantal}
                    co2BestellingAantal={co2BestellingAantal}
                    bestellingAantal={bestellingAantal}
                    installatieAantal={installatieAantal}

                    vasteAdresInstructies={serviceAdres?.vasteInstructie}
                    bezoekInstructies={bezoekEntry.bezoekInstructie}

                    loading={isLoading}
                    disabled={isLoading}
                />
            );
        }
    }
};
