import React from "react";
import {MDBAlert} from "mdb-react-ui-kit";
import {useTranslation} from "../utilities/i18nUtils";

export interface BezoekSessieGeslotenMeldingProps {
    show?: boolean;
}

export const BezoekSessieGeslotenMelding: React.FC<BezoekSessieGeslotenMeldingProps> = (props) => {

    const {t} = useTranslation("bezoeken");

    if (!props.show) {
        return null;
    } else {
        return (
            <MDBAlert open color="danger">{t("BezoekSessieGeslotenMelding.bezoek-afgesloten",
                "Het bezoek is afgesloten. Er zijn geen acties meer mogelijk")}</MDBAlert>
        );
    }
}

