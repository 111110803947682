import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback} from "react";
import {toestellenSaga} from "./saga";
import {useMe} from "../technicus/hooks";
import {
    StukToegevoegd,
    StukVerwijderd,
    ToestelInformatieAangepast,
    ToestelInterventieUitgevoerd,
    ToestelOnderhoudUitgevoerdStappen,
    WaterstandOpgenomenResultaatEnum
} from "../../../_generated/field-service-be-openapi";
import {useHuidigServiceAdres} from "../serviceAdressen/hooks";
import {EventPayload} from "../../types";
import {requestStatuses} from "redux-resource";
import {FileDownload} from "../../../utilities/downloadUtils";

export const useToestellen = () => {
    return useAppSelector((state) => state.toestellen.resources);
}

export const useToestellenByServiceAdres = (serviceAdresId: string) => {
    return useAppSelector((state) => state.toestellen.lists[`toestel:serviceAdres:${serviceAdresId}`]?.map((id) => state.toestellen.resources[id]) || []);
};

export const useToestel = (id: string) => {
    return useAppSelector((state) => state.toestellen.resources[id]);
};

export const useToestelCheckIn = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string) => {
        dispatch(toestellenSaga.actions.checkIn({bezoekSessieId, toestelId}));
    }, [dispatch]);
}

export const useToestelCheckOut = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string) => {
        dispatch(toestellenSaga.actions.checkOut({bezoekSessieId, toestelId}));
    }, [dispatch]);
};

export const useHuidigToestel = () => {
    const technicus = useMe();
    const huidigServiceAdres = useHuidigServiceAdres();

    return useAppSelector((state) => {
        if (!technicus || !huidigServiceAdres) {
            return undefined;
        }

        for (const bezoekSessie of Object.values(state.bezoeksessies.bezoekSessies)) {
            if (bezoekSessie.serviceAdres?.id === huidigServiceAdres.id) {
                for (const toestel of Object.values(bezoekSessie.toestellen)) {
                    if (toestel.actieveTechnicusIds?.includes(technicus.id)) {
                        return toestel;
                    }
                }
            }
        }
    });
};

export const useToestelOnderhoudUitgevoerd = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string, uitgevoerdeStappen?: ToestelOnderhoudUitgevoerdStappen) => {
        dispatch(toestellenSaga.actions.onderhoudUitgevoerd({bezoekSessieId, toestelId, uitgevoerdeStappen}));
    }, [dispatch]);
};

export const useToestelInterventieUitgevoerd = () => {
    const dispatch = useAppDispatch();

    return useCallback((action: EventPayload<ToestelInterventieUitgevoerd>) => {
        dispatch(toestellenSaga.actions.interventieUitgevoerd(action));
    }, [dispatch]);
};

export const useToestelVoegFotoToe = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string, uploadId: string) => {
        dispatch(toestellenSaga.actions.voegFotoToe({bezoekSessieId, toestelId, uploadId}));
    }, [dispatch]);
};

export const useToestelWaterstandOpgenomen = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string, resultaat: WaterstandOpgenomenResultaatEnum, meterstand?: number) => {
        dispatch(toestellenSaga.actions.waterstandOpgenomen({bezoekSessieId, toestelId, resultaat, meterstand}));
    }, [dispatch]);
};

export const useToestelInformatieAangepast = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string, payload: Partial<ToestelInformatieAangepast>) => {
        dispatch(toestellenSaga.actions.toestelInformatieAangepast({bezoekSessieId, toestelId, ...payload}));
    }, [dispatch]);
};

export const useToestelStuklijstAangepast = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, toestelId: string, payload: { toegevoegdeStukken: StukToegevoegd[], verwijderdeStukken: StukVerwijderd[] }) => {
        dispatch(toestellenSaga.actions.toestelStuklijstAangepast({bezoekSessieId, toestelId, ...payload}));
    }, [dispatch]);
};

export const useDownloadToestelBijlage = (toestelId: string, bijlageId: string) => {
    const dispatch = useAppDispatch();

    const requestKey = `downloadBijlage:${bijlageId}`;
    const state = useAppSelector((state) => ({
        loading: state.downloads.requests[requestKey]?.status === requestStatuses.PENDING,
        error: state.downloads.requests[requestKey]?.error
    }));

    const callback = useCallback((save = true, onSuccess?: (fileDownload: FileDownload) => void) => {
        dispatch(toestellenSaga.actions.downloadBijlage({toestelId, bijlageId, save, onSuccess}))
    }, [dispatch, toestelId, bijlageId]);

    return [callback, state] as [typeof callback, typeof state];
};
