import React, {useEffect, useState} from "react";
import {browser2ServiceWorkerChannel} from "../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {useBezoekSessieSetLoaded, useBezoekSessieSnapshot} from "../slices/bezoeksessie/hooks";
import {useIsHydrated, useSetHydrated} from "../slices/ui/hooks";
import {MessageType} from "../../workers/shared/channels/messageTypes";
import {
    useCalculateBezoeksessieSnapshot,
    useHydrateFrontend,
    useManualSyncToServer,
    useSyncArtikelen,
    useSyncFromServer
} from "../../utilities/serviceWorkerHooks";
import {Logger} from "../../utilities/logger";
import {LoadingPage} from "../../pages/LoadingPage";
import {workerRegistry} from "../../workers/shared/workerRegistry";
import {WebWorkerName} from "../../workers/webworker/webWorkerName";
import {dateNow, formatDate} from "../../utilities/dateUtils";
import {useTranslation} from "../../utilities/i18nUtils";

const logger = Logger.create("SnapshotProvider")

export const SnapshotProvider: React.FC<{children?: React.ReactNode}> = (props) => {
    const [loaded, setLoaded] = useState(false);

    const setHydrated = useSetHydrated();
    const hydrated = useIsHydrated();

    const bezoekSessieSnapshot = useBezoekSessieSnapshot();

    const syncFromServer = useSyncFromServer();
    const syncArtikelen = useSyncArtikelen();
    const manualSyncToServer = useManualSyncToServer();
    const hydrateFrontend = useHydrateFrontend();
    const calculateBezoekSessieSnapshot = useCalculateBezoeksessieSnapshot();
    const setBezoekSessieLoaded = useBezoekSessieSetLoaded();

    const {t} = useTranslation("algemeen");

    useEffect(() => {
        async function run() {
            workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SNAPSHOT_WEB_WORKER)
                .subscribe(MessageType.BEZOEKSESSIE_SNAPSHOT, (message) => {
                    setBezoekSessieLoaded();

                    logger.info("Received snapshot", message);
                    const {snapshots} = message.payload;

                    for (const {snapshot} of snapshots) {
                        // TODO: meerdere snapshots in één call kunnen doen
                        bezoekSessieSnapshot(snapshot);
                    }

                    if (!loaded) {
                        setLoaded(true);
                    }
                });

            browser2ServiceWorkerChannel.subscribe(MessageType.HYDRATE_FRONTEND_COMPLETE, () => {
                setHydrated(true);
            });

            syncArtikelen();

            manualSyncToServer();
            hydrateFrontend();
            calculateBezoekSessieSnapshot();
        }

        run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loaded) {
            const datum = formatDate(dateNow(), "YYYY-MM-DD");

            syncFromServer(datum);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    if (!hydrated) {
        return <LoadingPage description={t("Labels.gegevens-inlezen", "Gegevens inlezen...")}/>;
    }

    return <>{props.children}</>;
};
