import {resourceReducer} from "redux-resource";
import {RootState} from "../../store";

export const selectMe = (state: RootState) => state.technici.resources[state.technici.lists.me[0]];

const reducer = resourceReducer("technici");

export const technici = {
      reducer
};
