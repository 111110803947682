import React from "react";
import {
    MDBBadge,
    MDBCard,
    MDBCardBody,
    MDBCardSubTitle,
    MDBCardTitle,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit/index";
import {
    DagPlanningBezoekEntryRestModel,
    ServiceAdresCo2ArtikelRestModel
} from "../../_generated/field-service-be-openapi";
import {useServiceAdressen} from "../../redux/slices/serviceAdressen/hooks";
import {DagSamenvattingInterventieRow} from "./DagSamenvattingInterventieRow";
import {ArtikelNrOmschrijving} from "../ArtikelNrOmschrijving";
import {useTranslation} from "../../utilities/i18nUtils";

export interface PlanningOverzichtDagSamenvattingProps {
    datum: string;

    bezoeken: DagPlanningBezoekEntryRestModel[];
}

export const PlanningOverzichtDagSamenvatting: React.FC<PlanningOverzichtDagSamenvattingProps> = (props) => {
    const {bezoeken} = props;

    const serviceAdressen = useServiceAdressen();

    const {t} = useTranslation("planning");

    const renderCo2 = () => {
        let aantalBezoekenMetCo2Leveringen = 0;
        const aantalPerType: Record<string, { artikel?: ServiceAdresCo2ArtikelRestModel, aantal: number; extraAantal: number; hervulbaar: boolean }> = {};

        for (const bezoek of bezoeken) {
            const serviceAdres = serviceAdressen[bezoek.serviceAdresId];

            if (bezoek.uitTeVoerenWerk.co2Leveringen.length > 0) {
                aantalBezoekenMetCo2Leveringen += 1;
            }

            for (const co2Levering of bezoek.uitTeVoerenWerk.co2Leveringen) {
                if (!aantalPerType[co2Levering.artikelId]) {
                    const artikel = serviceAdres.co2Artikelen.find(item => item.id === co2Levering.artikelId);
                    if (artikel) {
                        aantalPerType[co2Levering.artikelId] = {
                            artikel,
                            aantal: 0,
                            extraAantal: 0,
                            hervulbaar: artikel.hervulbaar
                        };
                    }
                }

                aantalPerType[co2Levering.artikelId].aantal += co2Levering.gevraagdAantal;
                aantalPerType[co2Levering.artikelId].extraAantal += co2Levering.gevraagdExtraAantal || 0;


            }

            for (const installatie of bezoek.uitTeVoerenWerk.installaties) {
                for (const co2artikel of installatie.co2Artikelen) {
                    if (!aantalPerType[co2artikel.artikelId]) {
                        const artikel = serviceAdres.co2Artikelen.find(item => item.id === co2artikel.artikelId);
                        if (artikel) {
                            aantalPerType[co2artikel.artikelId] = {
                                artikel,
                                aantal: 0,
                                extraAantal: 0,
                                hervulbaar: artikel.hervulbaar
                            };
                        }
                    }

                    aantalPerType[co2artikel.artikelId].aantal += co2artikel.aantalTeLeveren;


                }
            }


        }

        if (!Object.keys(aantalPerType).length) {
            return null;
        }

        return (
            <MDBCol style={{flexBasis: "50%"}}>
                <MDBCard shadow="1" className="mb-3 text-primary">
                    <MDBCardBody className="pb-0">
                        <MDBCardTitle>
                            <MDBRow>
                                <MDBCol>
                                    {t("PlanningOverzichtDagSamenvatting.co2-leveringen-titel", "CO₂-leveringen")}
                                </MDBCol>
                                <MDBCol size="auto">
                                    <MDBBadge>{aantalBezoekenMetCo2Leveringen}</MDBBadge>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>
                    </MDBCardBody>

                    <MDBTable small responsive className="table--no-bottom-border mb-0">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.co2-artikel", "Artikel")}</th>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.co2-artikel-aantal","Aantal")}</th>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.co2-artikel-aantal-retour", "Aantal retour")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {Object.values(aantalPerType).map(item => (
                                <tr>
                                    <td>
                                        <ArtikelNrOmschrijving nr={item.artikel?.nummer!}
                                                               omschrijving={item.artikel?.omschrijving}/>
                                    </td>
                                    <td>{item.aantal + item.extraAantal}</td>
                                    <td>{item.hervulbaar ? <span>{item.aantal}</span> :
                                        <span className="text-muted">N.v.t.</span>}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCard>
            </MDBCol>
        );
    };

    const renderAccessoireBestellingen = () => {
        const bestellingNummers = Array.from(new Set(bezoeken
            .flatMap(item => item.uitTeVoerenWerk.accessoireLeveringen)
            .map(item => item.oorsprong.bestellingNummer)));

        if (!bestellingNummers.length) {
            return null;
        }

        return (
            <MDBCol style={{flexBasis: "50%"}}>
                <MDBCard shadow="1" className="mb-3 text-primary">
                    <MDBCardBody>
                        <MDBCardTitle>
                            <MDBRow>
                                <MDBCol>{t("PlanningOverzichtDagSamenvatting.accessoirebestellingen-titel","Accessoirebestellingen")}</MDBCol>
                                <MDBCol size="auto">
                                    <MDBBadge>{bestellingNummers.length}</MDBBadge>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>

                        <p>{bestellingNummers.join(", ")}</p>

                        <small className="text-muted">{t("PlanningOverzichtDagSamenvatting.zie-hieronder", "Zie hieronder")}</small>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        );
    };

    const renderInstallaties = () => {
        let aantalInstallaties = 0;
        const aantalPerType: Record<string, { artikelNr: string; artikelOmschrijving: string, aantal: number; }> = {};

        for (const bezoek of bezoeken) {
            for (const installatie of bezoek.uitTeVoerenWerk.installaties) {
                if (!aantalPerType[installatie.teInstallerenArtikelId]) {
                    aantalPerType[installatie.teInstallerenArtikelId] = {
                        artikelNr: installatie.teInstallerenArtikelNummer,
                        artikelOmschrijving: installatie.teInstallerenArtikelOmschrijving,
                        aantal: 0
                    };
                }

                aantalInstallaties += 1;
                aantalPerType[installatie.teInstallerenArtikelId].aantal += 1;
            }
        }

        if (!Object.keys(aantalPerType).length) {
            return null;
        }

        return (
            <MDBCol style={{flexBasis: "50%"}}>
                <MDBCard shadow="1" className="mb-3 text-primary">
                    <MDBCardBody className="pb-0">
                        <MDBCardTitle>
                            <MDBRow>
                                <MDBCol>{t("PlanningOverzichtDagSamenvatting.installaties-titel", "Installaties")}</MDBCol>
                                <MDBCol size="auto">
                                    <MDBBadge>{aantalInstallaties}</MDBBadge>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>
                    </MDBCardBody>

                    <MDBTable small responsive className="table--no-bottom-border mb-0">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.installaties-artikel", "Artikel")}</th>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.installaties-aantal", "Aantal")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {Object.values(aantalPerType).map(item => (
                                <tr>
                                    <td>
                                        <ArtikelNrOmschrijving nr={item.artikelNr}
                                                               omschrijving={item.artikelOmschrijving}/>
                                    </td>
                                    <td>{item.aantal}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCard>
            </MDBCol>
        );
    };

    const renderOnderhouden = () => {
        let aantalOnderhouden = 0;
        let aantalOnderhoudenMetFiltersVervangen = 0;

        const filterAantalPerType: Record<string, { artikelNr: string; artikelOmschrijving?: string, aantal: number; }> = {};

        for (const bezoek of bezoeken) {
            for (const toestel of bezoek.uitTeVoerenWerk.toestellen) {
                const onderhoud = toestel.onderhoud;
                if (!onderhoud) {
                    continue;
                }

                aantalOnderhouden += 1;
                if (onderhoud.stappen?.filtersVervangen?.length) {
                    aantalOnderhoudenMetFiltersVervangen += 1;

                    for (const filter of onderhoud.stappen.filtersVervangen) {
                        if (!filterAantalPerType[filter.artikelId]) {
                            filterAantalPerType[filter.artikelId] = {
                                aantal: 0,
                                artikelOmschrijving: filter.artikelOmschrijving,
                                artikelNr: filter.artikelNummer
                            }
                        }

                        filterAantalPerType[filter.artikelId].aantal += 1;
                    }
                }
            }
        }

        if (aantalOnderhouden === 0) {
            return null;
        }

        const filterValues = Object.values(filterAantalPerType);

        const onderhoudenCardText = (aantalOnderhouden : number, aantalOnderhoudenMetFiltersVervangen : number) => {
            return [t("PlanningOverzichtDagSamenvatting.aantalOnderhouden", "{{count}} onderhouden", {count: aantalOnderhouden}),
                    t("PlanningOverzichtDagSamenvatting.aantalOnderhoudenMetFiltersVervangen", "waarvan {{aantal}} met filters vervangen.", {aantal: aantalOnderhoudenMetFiltersVervangen})].join(", ")
        }

        return (
            <MDBCol style={{flexBasis: "50%"}}>
                <MDBCard shadow="1" className="mb-3 text-primary">
                    <MDBCardBody className="pb-0">
                        <MDBCardTitle>
                            <MDBRow>
                                <MDBCol>{t("PlanningOverzichtDagSamenvatting.onderhouden-titel","Onderhouden")}</MDBCol>
                                <MDBCol size="auto">
                                    <MDBBadge>{aantalOnderhouden}</MDBBadge>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>

                        <MDBCardSubTitle>
                            {onderhoudenCardText(aantalOnderhouden, aantalOnderhoudenMetFiltersVervangen)}
                        </MDBCardSubTitle>
                    </MDBCardBody>

                    {filterValues.length > 0 && (
                        <MDBTable small responsive className="table--no-bottom-border mb-0">
                            <MDBTableHead>
                                <tr>
                                    <th scope="col">{t("PlanningOverzichtDagSamenvatting.filter","Filter")}</th>
                                    <th scope="col">{t("PlanningOverzichtDagSamenvatting.aantal","Aantal")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {filterValues.map(item => (
                                    <tr>
                                        <td>
                                            <ArtikelNrOmschrijving nr={item.artikelNr}
                                                                   omschrijving={item.artikelOmschrijving}/>
                                        </td>
                                        <td>{item.aantal}</td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBCard>
            </MDBCol>
        );
    };

    const renderInterventies = () => {
        const toestellen = bezoeken
            .flatMap(item => item.uitTeVoerenWerk.toestellen)
            .filter(item => item.interventies.length > 0);
        const aantalInterventies = bezoeken
            .flatMap(item => item.uitTeVoerenWerk.toestellen)
            .reduce((prev, curr) => prev + curr.interventies.length, 0);

        if (!toestellen.length) {
            return null;
        }

        return (
            <MDBCol style={{flexBasis: "50%"}}>
                <MDBCard shadow="1" className="mb-3 text-primary">
                    <MDBCardBody>
                        <MDBCardTitle>
                            <MDBRow>
                                <MDBCol>{t("PlanningOverzichtDagSamenvatting.interventies-titel","Interventies")}</MDBCol>
                                <MDBCol size="auto">
                                    <MDBBadge>{aantalInterventies}</MDBBadge>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>
                    </MDBCardBody>

                    <MDBTable small responsive className="table--no-bottom-border mb-0">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.toestel", "Toestel")}</th>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.probleemomschrijving", "Probleemomschrijving")}</th>
                                <th scope="col">{t("PlanningOverzichtDagSamenvatting.artikelen", "Artikelen")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {toestellen.map(toestel => (
                                <>
                                    {toestel.interventies.map(interventie => <DagSamenvattingInterventieRow
                                        toestelId={toestel.toestelId} interventie={interventie}/>)}
                                </>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCard>
            </MDBCol>
        );
    };

    const bezoekenMetWerk = bezoeken?.some(bezoek => {
        const uitTeVoerenWerk = bezoek.uitTeVoerenWerk;

        const heeftWerkAanToestellen = uitTeVoerenWerk?.toestellen?.some(item => Boolean(item.onderhoud) || Boolean(item.interventies?.length));
        const heeftWerk = heeftWerkAanToestellen || Boolean(uitTeVoerenWerk?.co2Leveringen?.length) ||
            Boolean(uitTeVoerenWerk?.accessoireLeveringen?.length) || Boolean(uitTeVoerenWerk?.installaties?.length);

        return heeftWerk;
    });

    return (
        <>
            <MDBRow>
                <h5 className='text-white'>{t("PlanningOverzichtDagSamenvatting.samenvatting-van-de-dag", "Samenvatting van de dag")}</h5>

                {!bezoekenMetWerk && (
                    <MDBCol>
                        <MDBCard shadow="1" className="mb-3 text-primary">
                            <MDBCardBody>
                                {t("PlanningOverzichtDagSamenvatting.geen-gepland-werk", "Geen gepland werk.")}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                )}

                {renderCo2()}
                {renderAccessoireBestellingen()}
                {renderInstallaties()}
                {renderOnderhouden()}
                {renderInterventies()}
            </MDBRow>
        </>
    );
};
