/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Technicus,
} from '../models';

export interface GetTechnicusByIdRequest {
    technicusId: string;
}

/**
 * 
 */
export class TechnicusApi extends runtime.BaseAPI {

    /**
     */
    async getTechniciRaw(): Promise<runtime.ApiResponse<Array<Technicus>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technicus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getTechnici(): Promise<Array<Technicus>> {
        const response = await this.getTechniciRaw();
        return await response.value();
    }

    /**
     */
    async getTechnicusByIdRaw(requestParameters: GetTechnicusByIdRequest): Promise<runtime.ApiResponse<Technicus>> {
        if (requestParameters.technicusId === null || requestParameters.technicusId === undefined) {
            throw new runtime.RequiredError('technicusId','Required parameter requestParameters.technicusId was null or undefined when calling getTechnicusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technicus/{technicusId}`.replace(`{${"technicusId"}}`, encodeURIComponent(String(requestParameters.technicusId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getTechnicusById(requestParameters: GetTechnicusByIdRequest): Promise<Technicus> {
        const response = await this.getTechnicusByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTechnicusMeRaw(): Promise<runtime.ApiResponse<Technicus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technicus/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getTechnicusMe(): Promise<Technicus> {
        const response = await this.getTechnicusMeRaw();
        return await response.value();
    }

}
