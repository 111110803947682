import React from "react";

export interface AdresProps {
    adres?: {
        adres?: string;
        adres2?: string;
        postcode?: string;
        plaats?: string;
        landCode?: string;
    };
}

export const Adres: React.FC<AdresProps> = (props) => {
    const {adres} = props;

    if (!adres) {
        return null;
    }

    const adresString = [
        [adres.adres, adres.adres2].filter(item => item).join(" "),
        [adres.postcode, adres.plaats].join(" "),
        adres.landCode
    ].join(", ");

    return <>{adresString}</>;
};
