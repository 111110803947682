import {useAppDispatch, useAppSelector} from "../../hooks";
import {requestStatuses} from "redux-resource";
import {DagPlanningByTechnicusIdAndDatumRequest} from "../../../_generated/field-service-be-openapi";
import useDeepCompareEffect from "use-deep-compare-effect";
import {shiftenSaga} from "./saga";

export const useFetchShiften = (request: DagPlanningByTechnicusIdAndDatumRequest, skip?: boolean) => {

    const {technicusId, van, tot} = request;

    const state = useAppSelector((state) => ({
        loading: state.shiften.requests[`technicusId=${technicusId}_van=${van}_tot${tot}`]?.status === requestStatuses.PENDING,
        error: state.shiften.requests[`technicusId=${technicusId}_van=${van}_tot${tot}`]?.error,
        shiften: state.shiften.requests[`technicusId=${technicusId}_van=${van}_tot${tot}`]?.ids?.map(id => state.shiften.resources[id]) || []
    }));

    const dispatch = useAppDispatch();

    useDeepCompareEffect(() => {
        if (! skip) {
            dispatch(shiftenSaga.actions.fetchShiften({request}))
        }
    }, [request, skip]);


    return state;
};
