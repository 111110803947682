import React, {HTMLAttributes} from "react";

export interface MDBChipProps extends HTMLAttributes<any> {
    size?: "md" | "lg";
    outlineColor?: string;
}

export const MDBChip: React.FC<MDBChipProps> = (props) => {
    const {size, outlineColor} = props;

    return (
        <div {...props} className={`${props.className || ""} chip ${size ? "chip-" + size : ""} ${outlineColor ? `chip-outline btn-outline-${outlineColor}` : ""}`}>
            {props.children}
        </div>
    );
};
