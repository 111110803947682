import {all} from "redux-saga/effects";
import {bezoeksessieSaga} from "./slices/bezoeksessie/saga";
import {serviceAdressenSaga} from "./slices/serviceAdressen/saga";
import {toestellenSaga} from "./slices/toestellen/saga";
import {downloadsSaga} from "./slices/download/saga";
import {shiftenSaga} from "./slices/shiften/saga";
import {statusSaga} from "./slices/status/saga";
import { uiSaga } from "./slices/ui/saga";

export function *rootSaga() {
    yield all([
        bezoeksessieSaga.saga(),
        serviceAdressenSaga.saga(),
        toestellenSaga.saga(),
        downloadsSaga.saga(),
        shiftenSaga.saga(),
        statusSaga.saga(),
        uiSaga.saga()
    ]);
}

