import {Config} from "./config";

export class Logger {

    private _name?: string;
    private overrideEnabled?: boolean;

    constructor(name?: string, overrideEnabled?: boolean) {
        this._name = name;
        this.overrideEnabled = overrideEnabled;
    }

    public static create(name?: string, overrideEnabled?: boolean) {
        return new Logger(name, overrideEnabled);
    }

    public setEnabled(enabled?: boolean) {
        this.overrideEnabled = enabled;
    }

    public debug(message?: any, ...extra: any[]): void {
        if (this.isEnabled()) {
            console.debug(...this.createArgs(message, ...extra));
        }
    }

    public error(message?: any, ...extra: any[]): void {
        if (this.isEnabled()) {
            console.error(...this.createArgs(message, ...extra));
        }
    }

    public fatal(message?: any, ...extra: any[]): void {
        if (this.isEnabled()) {
            console.error(...this.createArgs(message, ...extra));
        }
    }

    public info(message?: any, ...extra: any[]): void {
        if (this.isEnabled()) {
            console.info(...this.createArgs(message, ...extra));
        }
    }

    public warn(message?: any, ...extra: any[]): void {
        if (this.isEnabled()) {
            console.warn(...this.createArgs(message, ...extra));
        }
    }

    public trace(message?: any, ...extra: any[]): void {
        if (this.isEnabled()) {
            console.trace(...this.createArgs(message, ...extra));
        }
    }

    public createLabel(label: string, color = "#0095ff") {
        const css = `background: ${color}; color: #fff; border-radius: 8px; padding: 2px 6px; margin: 2px 6px 2px 2px;`;

        return [`%c${label}`, css];
    }

    private isEnabled() {
        return Config.LOGGING_ENABLED || this.overrideEnabled;
    }

    private get name() {
        if (this._name) {
            return this._name;
        }

        return "";
    }

    private createArgs(message?: any, ...extra: any[]) {
        const options = extra[extra.length - 1] || {};
        const labels = options?.labels?.map((label: string) => this.createLabel(label)) || [];

        const [defaultLabel, defaultLabelCss] = this.createLabel(`${this.name}`);

        let text = defaultLabel;
        const args = [defaultLabelCss];

        for (const [label, css] of labels) {
            text += label;
            args.push(css);
        }

        text += `%c${message}`;
        args.push("");

        return [text, ...args, ...extra];
    }

}
