import React from "react";

export enum MDBCardStatusEnum {
    DONE = "DONE",
    DONE_EVENTS_MISSING = "DONE_EVENTS_MISSING",
    IN_PROGRESS = "IN_PROGRESS",
    WARNING = "WARNING",
    ERROR = "ERROR"
}

export interface MDBCardStatusProps {
    status?: MDBCardStatusEnum;
}

export const MDBCardStatus: React.FC<MDBCardStatusProps> = ({status}) => {
    let className = "";
    if (status === MDBCardStatusEnum.DONE) {
        className = "bg-success";
    } else if (status === MDBCardStatusEnum.DONE_EVENTS_MISSING) {
        className = "bg-success bg-opacity-25";
    } else if (status === MDBCardStatusEnum.IN_PROGRESS) {
        className = "bg-info";
    } else if (status === MDBCardStatusEnum.WARNING) {
        className = "bg-warning";
    } else if (status === MDBCardStatusEnum.ERROR) {
        className = "bg-error";
    }

    return (
        <div className={`card-status ${className}`} />
    );
};
