import React from "react";

export interface StrikethroughProps {
    strikethrough?: boolean;

    children?: React.ReactNode;
}

export const Strikethrough: React.FC<StrikethroughProps> = (props) => {
    const {strikethrough} = props;

    if (strikethrough === undefined || strikethrough === true) {
        return <del>{props.children}</del>;
    }

    return <>{props.children}</>;
};
