import React, {useCallback, useEffect, useRef, useState} from "react";
import {GenericPage} from "../../../components/GenericPage";
import Webcam from "react-webcam";
import Icon from "@mdi/react";
import {mdiCameraFlipOutline} from "@mdi/js";
import {useHistory, useParams} from "react-router-dom";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {useDimensions} from "../../../utilities/useDimensions";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";

export interface ScanToestelPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ScanToestelPage: React.FC = () => {
    const {bezoekSessieId, toestelId} = useParams<ScanToestelPageParams>();

    const history = useHistory();

    const [containerRef, containerSize] = useDimensions();
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0);

    const videoRef = useRef<any>();

    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const videoDevices = mediaDevices.filter((device) => device.kind === "videoinput");

            // Label contains "camera2 0, facing back", "camera2 2, facing back", "camera2 1, facing front", ...
            // Comparing the strings gives us the sort we need (first the backs, then the fronts)
            setDevices(
                videoDevices.sort((v1, v2) => v1.label.localeCompare(v2.label))
            );
        },
        [setDevices]
    );

    // https://github.com/mozmorris/react-webcam/issues/244
    const stopTracks = () => {
        if (videoRef.current) {
            let stream = videoRef.current.video.srcObject;
            if (stream) {
                videoRef.current.video.srcObject = null;

                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    stream.removeTrack(track);
                    track.stop();
                });
                stream = null; // this is probably redundant, but it fixes it so I'm happy.
            }
        }
    };

    useEffect(() => {
        return () => stopTracks();
    });

    useEffect(
        () => {
            navigator.mediaDevices?.enumerateDevices?.().then(handleDevices);
        },
        [handleDevices]
    );

    useEffect(() => {
        stopTracks();
    }, [selectedDeviceIndex]);

    const videoConstraints: MediaStreamConstraints["video"] = {
        width: {ideal: 4096},
        height: {ideal: 2160},
        deviceId: devices?.[selectedDeviceIndex]?.deviceId
    };

    const capture = React.useCallback(
        () => {
            if (videoRef.current) {
                return videoRef.current.getScreenshot({
                    width: 640,
                    height: 360
                });
            }
        },
        [videoRef]
    );

    const onFlipClick = () => {
        if (selectedDeviceIndex + 1 === devices.length) {
            setSelectedDeviceIndex(0);

            return;
        }

        setSelectedDeviceIndex(selectedDeviceIndex + 1);
    };

    const doScan = useCallback(async () => {
        const image = capture();

        if (image) {
            const qrMessage = {
                buffer: Buffer.from(image?.substring("data:image/jpeg;base64,".length), "base64"),
                width: videoConstraints.width,
                height: videoConstraints.height
            };

            const data = await browser2ServiceWorkerChannel.sendRpc(WorkerMessageFactory.createQrMessage(qrMessage));

            if (data) {
                history.replace(`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}?qr=${data}`);

                return;
            }
        }

        setTimeout(doScan, 100);
    }, [capture, history, videoConstraints.height, videoConstraints.width, bezoekSessieId, toestelId]);

    useEffect(() => {
        doScan();
    }, [doScan]);

    return (
        <GenericPage style={{zIndex: 3000}}>
            <div className="w-100 flex-fill bg-black d-flex justify-content-center align-items-center"
                 ref={containerRef}>
                {devices?.[selectedDeviceIndex]?.deviceId && containerSize?.height && (
                    <Webcam audio={false} videoConstraints={videoConstraints} width={containerSize.width}
                            height={containerSize.height} ref={videoRef} style={{width: "100%", height: "auto"}}
                            onUserMediaError={(error) => console.error(error)}
                            screenshotFormat="image/jpeg"
                    />
                )}
            </div>

            <div style={{position: "absolute", right: "1rem", bottom: "1rem"}}>
                <div onClick={onFlipClick}>
                    <Icon path={mdiCameraFlipOutline} size={2} color="white"/>
                </div>
            </div>
        </GenericPage>
    );
};
