import {Configuration, FetchParams, Middleware, RequestContext} from "../../../_generated/field-service-be-openapi";
import {authAcquireTokenSilentOrRedirect, getAccessToken} from "../../../redux/providers/authProvider";
import {Config} from "../../config";

export const authMiddleware: Middleware = {
    async pre(context: RequestContext): Promise<FetchParams | void> {
        if (context.url.includes("/ping")) {
            return;
        }

        const token = getAccessToken();
        if (!token) {
            await authAcquireTokenSilentOrRedirect();
        }

        if (token) {
            const headers = context.init.headers as Record<string, string>;
            headers.Authorization = `Bearer ${token.accessToken}`;
        }
    }
};

export const configuration = new Configuration({
    basePath: Config.BASE_URL,
    middleware: [authMiddleware]
});
