import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback} from "react";
import {serviceAdressenSaga} from "./saga";
import {
    AccessoireLeveringUitgevoerd,
    AdresBelemmerdRedenEnum,
    CheckOutVanAdresRedenNietAfgewerktEnum,
    Co2Geleverd,
    HandtekeningGezet,
    InstallatieTijdIngegeven,
    InstallatieUitgevoerd,
    InterneFeedbackToegevoegd,
    InterventieTijdIngegeven
} from "../../../_generated/field-service-be-openapi";
import {useMe} from "../technicus/hooks";
import {AdresAanwezigheid} from "../../../workers/shared/snapshot/bezoekSessieState";
import {EventPayload} from "../../types";
import {requestStatuses} from "redux-resource";

export const useServiceAdressen = () => {
    return useAppSelector((state) => state.serviceAdressen.resources);
};

export const useServiceAdres = (id?: string) => {
    return useAppSelector((state) => id ? state.serviceAdressen.resources[id] : undefined);
};

export const useServiceAdresOnderweg = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, callback?: (error?: Error) => void) => {
        dispatch(serviceAdressenSaga.actions.onderweg({bezoekSessieId, callback}));
    }, [dispatch]);
};

export const useServiceAdresCheckIn = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, callback?: (error?: Error) => void) => {
        dispatch(serviceAdressenSaga.actions.checkIn({bezoekSessieId, callback}));
    }, [dispatch]);
};

export const useServiceAdresCheckOut = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, redenNietAfgewerkt?: CheckOutVanAdresRedenNietAfgewerktEnum, toelichting?: string) => {
        dispatch(serviceAdressenSaga.actions.checkOut({bezoekSessieId, redenNietAfgewerkt, toelichting}));
    }, [dispatch]);
};

export const useAdresBelemmerd = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, reden?: AdresBelemmerdRedenEnum, toelichting?: string) => {
        dispatch(serviceAdressenSaga.actions.adresBelemmerd({bezoekSessieId, reden, toelichting}));
    }, [dispatch]);
};

export const useServiceAdresCo2LeveringEvent = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, payload: Co2Geleverd) => {
        dispatch(serviceAdressenSaga.actions.co2Levering({...payload, bezoekSessieId}));
    }, [dispatch]);
};

export const useServiceAdresLevering = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: EventPayload<AccessoireLeveringUitgevoerd>) => {
        dispatch(serviceAdressenSaga.actions.levering(payload));
    }, [dispatch]);
};

export const useHuidigServiceAdres = () => {
    const technicus = useMe();

    return useAppSelector((state) => {
        if (!technicus) {
            return undefined;
        }

        for (const bezoekSessie of Object.values(state.bezoeksessies.bezoekSessies)) {
            if ([AdresAanwezigheid.AANWEZIG, AdresAanwezigheid.OBSERVATOR].includes(bezoekSessie.adresAanwezigheid[technicus.id])) {
                return bezoekSessie.serviceAdres;
            }
        }
    });
};

export const useServiceAdresHandtekeningGezet = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: EventPayload<HandtekeningGezet>) => {
        dispatch(serviceAdressenSaga.actions.handtekeningGezet(payload));
    }, [dispatch]);
};

export const useInterneFeedbackToegevoegd = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, payload: Partial<InterneFeedbackToegevoegd>) => {
        dispatch(serviceAdressenSaga.actions.interneFeedbackToegevoegd({bezoekSessieId, ...payload}));
    }, [dispatch]);
};

export const useInstallatieUitgevoerd = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: EventPayload<InstallatieUitgevoerd>) => {
        dispatch(serviceAdressenSaga.actions.installatieUitgevoerd(payload));
    }, [dispatch]);
};

export const useInstallatieTijdIngegeven = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: EventPayload<InstallatieTijdIngegeven>) => {
        dispatch(serviceAdressenSaga.actions.installatieTijdIngegeven(payload));
    }, [dispatch]);
};

export const useInterventieTijdIngegeven = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: EventPayload<InterventieTijdIngegeven>) => {
        dispatch(serviceAdressenSaga.actions.interventieTijdIngegeven(payload));
    }, [dispatch]);
};

export const useDownloadServiceRapport = (serviceAdresId: string, serviceRapportId: string) => {
    const dispatch = useAppDispatch();

    const requestKey = `downloadServiceRapport:${serviceRapportId}`;
    const state = useAppSelector((state) => ({
        loading: state.downloads.requests[requestKey]?.status === requestStatuses.PENDING,
        error: state.downloads.requests[requestKey]?.error
    }));

    const callback = useCallback(() => {
        dispatch(serviceAdressenSaga.actions.downloadServiceRapport({serviceAdresId, serviceRapportId}))
    }, [dispatch, serviceAdresId, serviceRapportId]);

    return [callback, state] as [typeof callback, typeof state];
};

export const useDownloadServiceAdresBijlage = (serviceAdresId: string, bijlageId: string) => {
    const dispatch = useAppDispatch();

    const requestKey = `downloadBijlage:${bijlageId}`;
    const state = useAppSelector((state) => ({
        loading: state.downloads.requests[requestKey]?.status === requestStatuses.PENDING,
        error: state.downloads.requests[requestKey]?.error
    }));

    const callback = useCallback(() => {
        dispatch(serviceAdressenSaga.actions.downloadBijlage({serviceAdresId, bijlageId}))
    }, [dispatch, serviceAdresId, bijlageId]);

    return [callback, state] as [typeof callback, typeof state];
};
