'use client';

import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState, forwardRef } from 'react';
import MDBInput from '../../../free/forms/Input/Input';
import Timepicker from '../TimePicker/TimePicker';
import Datepicker from '../Datepicker/Datepicker';
import type { DateTimepickerProps } from './types';
import { isValidDate, isValidTime } from './utils/isValid';
import { parseDate } from '../Datepicker/utils/date-utils';
import { defaultDatepickerProps } from '../Datepicker/types';

const ANIMATION_DURATION = 300;

const MDBDateTimepicker = forwardRef<HTMLInputElement, DateTimepickerProps>(
  (
    {
      className,
      label = 'Select Date and Time',
      labelStyle,
      labelClass,
      labelRef,
      inputClass = '',
      inputRef,
      inline,
      disabled,
      defaultTime = '',
      defaultDate = '',
      value,
      invalidLabel,
      inputToggle = false,
      timepickerOptions,
      datepickerOptions,
      showFormat,
      dateFormat = 'dd/mm/yyyy',
      timeFormat = '12h',
      appendValidationInfo = true,
      onChange,
      onOpen,
      onClose,
      onDatepickerOpen,
      onDatepickerClose,
      onTimepickerOpen,
      onTimepickerClose,
      ...props
    }: DateTimepickerProps,
    ref
  ) => {
    const isDateTimepickerOpened = useRef(false);
    const [timepickerOpen, setTimepickerOpen] = useState(false);
    const [datepickerOpen, setDatepickerOpen] = useState(false);

    const [datePickerValue, setDatePickerValue] = useState(defaultDate);
    const [timePickerValue, setTimePickerValue] = useState(defaultTime);
    const [combinedValue, setCombinedValue] = useState('');

    const referenceElement = useRef<any>();

    const splittedDate = datePickerValue ? combinedValue.split(',')[0] : null;
    const splittedTime = timePickerValue ? combinedValue.split(', ')[1] : null;

    const classes = clsx('form-outline', 'datetimepicker', className);

    const getInputClasses = () => {
      const date =
        splittedDate &&
        parseDate(splittedDate, dateFormat, defaultDatepickerProps.monthsFull, defaultDatepickerProps.monthsShort);

      if (combinedValue && !splittedDate && !splittedTime) {
        return inputClass + ' ' + 'is-invalid';
      }

      if (splittedDate && !isValidDate(date)) {
        return inputClass + ' ' + 'is-invalid';
      }

      if (splittedTime && !isValidTime(splittedTime)) {
        return inputClass + ' ' + 'is-invalid';
      }
      return inputClass;
    };

    const handleDatepickerValueChange = useCallback((value: string) => {
      setDatePickerValue(value);
    }, []);

    const handleTimepickerValueChange = useCallback((value: string) => {
      setTimePickerValue(value);
    }, []);

    const handleDatepickerClose = useCallback(() => {
      setDatepickerOpen(false);
      isDateTimepickerOpened.current = false;
      onDatepickerClose?.();
    }, [onDatepickerClose]);

    const handleTimepickerClose = useCallback(() => {
      onTimepickerClose?.();
      setTimepickerOpen(false);
      isDateTimepickerOpened.current = false;
    }, [onTimepickerClose]);

    const handleDatepickerOpen = useCallback(() => {
      onDatepickerOpen?.();
    }, [onDatepickerOpen]);

    const handleTimepickerOpen = useCallback(() => {
      onTimepickerOpen?.();
    }, [onTimepickerOpen]);

    const handleOpen = () => {
      setDatepickerOpen(true);
      onOpen?.();
      isDateTimepickerOpened.current = true;
    };

    const handleDatetimepickerModeToggle = useCallback(() => {
      if (datepickerOpen) {
        setDatepickerOpen(false);
        setTimeout(() => {
          setTimepickerOpen(true);
        }, ANIMATION_DURATION);
      } else if (timepickerOpen) {
        setTimepickerOpen(false);
        setTimeout(() => {
          setDatepickerOpen(true);
        }, ANIMATION_DURATION);
      }
    }, [datepickerOpen, timepickerOpen]);

    useEffect(() => {
      if (!timePickerValue || !datePickerValue) {
        return;
      }

      onChange?.(`${datePickerValue}, ${timePickerValue}`);

      if (value) {
        return;
      }

      setCombinedValue(`${datePickerValue}, ${timePickerValue}`);
    }, [timePickerValue, datePickerValue, onChange, value]);

    useEffect(() => {
      if (value) {
        setCombinedValue(value);
        onChange?.(value);
      }
    }, [value, onChange]);

    useEffect(() => {
      if (!datepickerOpen && !timepickerOpen && !isDateTimepickerOpened.current) {
        onClose?.();
      }
    }, [datepickerOpen, timepickerOpen, onClose]);

    const handleInputChange = (newValue: string) => {
      setCombinedValue(newValue);

      const [newDate, newTime] = newValue.split(', ');

      setDatePickerValue(newDate || '');
      setTimePickerValue(newTime || '');
    };

    return (
      <>
        <div className={classes} ref={referenceElement} {...props}>
          <MDBInput
            label={label}
            labelStyle={labelStyle}
            labelClass={labelClass}
            ref={inputRef || ref}
            labelRef={labelRef}
            placeholder={showFormat ? `${dateFormat}, ${timeFormat}` : undefined}
            value={value || combinedValue}
            onChange={(e) => {
              handleInputChange(e.target.value);
              onChange?.(e.target.value);
            }}
            className={appendValidationInfo ? getInputClasses() : inputClass}
            disabled={disabled}
            onClick={() => {
              if (inputToggle) {
                handleOpen();
              }
            }}
          >
            {invalidLabel && <div className='invalid-feedback'>{invalidLabel}</div>}
          </MDBInput>
          {!inputToggle && (
            <button
              type='button'
              className='datetimepicker-toggle-button'
              onClick={handleOpen}
              disabled={disabled}
              style={{ pointerEvents: disabled ? 'none' : 'initial' }}
            >
              <i className='far fa-calendar datepicker-toggle-icon' />
            </button>
          )}
        </div>
        <Datepicker
          {...datepickerOptions}
          inline={inline}
          onChange={handleDatepickerValueChange}
          format={dateFormat}
          datetimepickerRef={referenceElement.current}
          defaultValue={defaultDate}
          value={datePickerValue}
          onClose={handleDatepickerClose}
          onOpen={handleDatepickerOpen}
          isInDatetimepicker
          open={datepickerOpen}
          onDatetimepickerModeSwitch={handleDatetimepickerModeToggle}
        />
        <Timepicker
          {...timepickerOptions}
          inline={inline}
          onChange={handleTimepickerValueChange}
          format={timeFormat}
          datetimepickerRef={referenceElement.current}
          defaultValue={defaultTime}
          value={timePickerValue}
          onClose={handleTimepickerClose}
          onOpen={handleTimepickerOpen}
          isInDatetimepicker
          open={timepickerOpen}
          onDatetimepickerModeSwitch={handleDatetimepickerModeToggle}
        />
      </>
    );
  }
);

MDBDateTimepicker.displayName = 'MDBDateTimepicker';
export default MDBDateTimepicker;
