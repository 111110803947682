import React, {useMemo} from "react";
import {MDBCard, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {GenericPage} from "../../components/GenericPage";
import {Header} from "../../components/Header";
import {useParams} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiFileDocument} from "@mdi/js";
import {Datum} from "../../components/Datum";
import {ServiceRapportDownloadButton} from "../../components/download/ServiceRapportDownloadButton";
import {ContentContainer} from "../../components/ContentContainer";
import {useServiceAdres} from "../../redux/slices/serviceAdressen/hooks";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ServiceAdresServiceRapportenPageParams {
    adresId: string;
}

export const ServiceAdresServiceRapportenPage: React.FC = () => {
    const {adresId} = useParams<ServiceAdresServiceRapportenPageParams>();

    const serviceAdres = useServiceAdres(adresId);

    const gesorteerdeServiceRapporten = useMemo(() => {
        return serviceAdres?.serviceRapporten?.sort((a, b) => new Date(b.tijdstip!).getTime() - new Date(a.tijdstip!).getTime());
    }, [serviceAdres]);

    const {t} = useTranslation("serviceadres");

    return (
        <GenericPage>
            <Header title={t("ServiceAdresServiceRapportenPage.servicerapporten", "Servicerapporten")} sticky/>

            <ContentContainer>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader><Icon path={mdiFileDocument}
                                         size={1}/> {t("ServiceAdresServiceRapportenPage.servicerapporten", "Servicerapporten")}</MDBCardHeader>

                    <MDBTable responsive>
                        <MDBTableHead>
                            <tr>
                                <th>{t("ServiceAdresServiceRapportenPage.datum", "Datum")}</th>
                                <th>{t("ServiceAdresServiceRapportenPage.titel", "Titel")}</th>
                                <th>{t("ServiceAdresServiceRapportenPage.downloaden", "Downloaden")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {!serviceAdres?.serviceRapporten?.length && (
                                <tr>
                                    <td colSpan={3}>{t("ServiceAdresServiceRapportenPage.geen-servicerapporten=beschikbaar", "Geen servicerapporten beschikbaar.")}</td>
                                </tr>
                            )}
                            {serviceAdres && gesorteerdeServiceRapporten?.map(serviceRapport =>
                                <tr key={serviceRapport.id}>
                                    <td>
                                        <Datum datum={serviceRapport.tijdstip} format="DD-MM-YYYY HH:mm"/>
                                    </td>
                                    <td>
                                        {serviceRapport.titel}
                                    </td>
                                    <td>
                                        <ServiceRapportDownloadButton serviceAdresId={serviceAdres.id}
                                                                      serviceRapportId={serviceRapport.id!}/>
                                    </td>
                                </tr>
                            )}

                        </MDBTableBody>
                    </MDBTable>
                </MDBCard>


            </ContentContainer>
        </GenericPage>
    );
};
