import React from "react";
import {Co2Levering, ServiceAdresCo2ArtikelRestModel} from "../../../_generated/field-service-be-openapi";
import {MDBCard, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {Assets} from "../../../assets/assets";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface GeplandWerkCo2BestellingTaakProps {
    co2Bestellingen: Co2Levering[];
    co2Artikelen: ServiceAdresCo2ArtikelRestModel[];
}

export const GeplandWerkCo2BestellingTaak: React.FC<GeplandWerkCo2BestellingTaakProps> = (props) => {

    const {t} = useTranslation("serviceadres");

    const {
        co2Bestellingen,
        co2Artikelen
    } = props;

    if (co2Bestellingen.length === 0) {
        return null;
    }

    const totaleCo2: Record<string, {
        gevraagdAantal: number;
        gevraagdExtraAantal: number;
        gevraagdOmruilAantal: number;
        oorsprong: string;
    }> = {};

    for (let item of co2Bestellingen) {
        totaleCo2[item.artikelId] = {
            gevraagdAantal: (totaleCo2[item.artikelId]?.gevraagdAantal || 0) + item.gevraagdAantal,
            gevraagdExtraAantal: (totaleCo2[item.artikelId]?.gevraagdExtraAantal || 0) + (item.gevraagdExtraAantal || 0),
            gevraagdOmruilAantal: (totaleCo2[item.artikelId]?.gevraagdOmruilAantal || 0) + (item.gevraagdOmruilAantal || 0),
            oorsprong: Array.from(new Set([totaleCo2[item.artikelId]?.oorsprong, item.oorsprong.bestellingNummer || item.oorsprong.verkoopOrderNr].filter(Boolean))).join(" + ")
        };
    }

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={Assets.orderTypes.levering}
                                 size={1}/>{t("planning:GeplandWerk.co2-bestelling.titel", "CO₂-bestelling")}</MDBCardHeader>

            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th>{t("planning:GeplandWerk.co2-bestelling.bestelling", "Bestelling")}</th>
                        <th>{t("planning:GeplandWerk.co2-bestelling.artikel", "Artikel")}</th>
                        <th>{t("planning:GeplandWerk.co2-bestelling.aantal", "Aantal")}</th>
                        <th>{t("planning:GeplandWerk.co2-bestelling.aantal-retour", "Aantal retour")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {Object.entries(totaleCo2).map(([artikelId, item]) => {
                        const artikel = co2Artikelen.find(co2Artikel => co2Artikel.id === artikelId);

                        return (
                            <tr key={artikelId}>
                                <td>{item.oorsprong}</td>
                                <td>
                                    <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                </td>
                                <td>
                                    {item.gevraagdAantal + (item.gevraagdExtraAantal || 0)}
                                </td>
                                <td>
                                    {artikel?.hervulbaar ? item.gevraagdOmruilAantal || 0 :
                                        <span className="text-muted">nvt</span>}
                                </td>
                            </tr>
                        );
                    })}
                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    );
};
