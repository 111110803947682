import React, {useEffect, useState} from "react";
import {useApplicationStatus, useSyncingEvents} from "../redux/slices/status/hooks";
import {useManualSyncToServer} from "../utilities/serviceWorkerHooks";
import {HeaderSyncStatusEnum, HeaderSyncStatusIcon} from "./HeaderSyncStatusIcon";
import {MDBCol, MDBRow} from "../mdb-react-ui-kit";

export const HeaderSyncStatus: React.FC = () => {
    const offlineStatus = useApplicationStatus();
    const syncingEvents = useSyncingEvents();
    const manualSyncToServer = useManualSyncToServer();

    const [status, setStatus] = useState<HeaderSyncStatusEnum | undefined>(HeaderSyncStatusEnum.DONE);

    const onUploadClick = () => {
        manualSyncToServer();
    };

    useEffect(() => {
        if (!syncingEvents && offlineStatus.localEventsAmount > 0) {
            let timeout = 0;
            if (status === HeaderSyncStatusEnum.IN_PROGRESS) {
                // If previous status was in progress, let's show it at least 1 second
                timeout = 1_000;
            }

            setTimeout(() => setStatus(HeaderSyncStatusEnum.PROBLEM), timeout);
        } else if (syncingEvents) {
            setStatus(HeaderSyncStatusEnum.IN_PROGRESS);
        } else {
            setStatus(HeaderSyncStatusEnum.DONE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncingEvents, offlineStatus]);

    return (
        <MDBRow center middle>
            <MDBCol>
                <span onClick={onUploadClick}>
                    <HeaderSyncStatusIcon status={status || HeaderSyncStatusEnum.DONE}/>
                </span>
            </MDBCol>
        </MDBRow>
    );
};
