import React, {ChangeEvent, useMemo, useState} from "react";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBListGroup,
    MDBListGroupItem,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBSelect
} from "mdb-react-ui-kit";
import {FixedSizeList} from "react-window";
import {LightboxBackgroundImage} from "../LightboxBackgroundImage";
import {FrameModal} from "./FrameModal";
import {useArtikelenForServiceAdresId} from "../../redux/slices/artikel/hooks";
import {ArtikelRestModel} from "../../_generated/field-service-be-openapi";
import {useDimensions} from "../../utilities/useDimensions";
import {SelectData} from "mdb-react-ui-kit/pro/forms/SelectV2/types";

export interface StukToevoegenModalProps {
    serviceAdresId: string;

    onClose?(): void;

    onConfirm(artikel: ArtikelRestModel): void;
}

export const StukToevoegenModal: React.FC<StukToevoegenModalProps> = (props) => {
    const {serviceAdresId, onClose, onConfirm} = props;

    const [searchText, setSearchText] = useState("");
    const [onderdeelSoort, setOnderdeelSoort] = useState<string>("all");

    const artikelen = useArtikelenForServiceAdresId(serviceAdresId);

    const artikelOnderdeelSoorten = useMemo(() => {
        const set = new Set<string>();

        for (const artikel of artikelen) {
            if (artikel.onderdeelSoort) {
                set.add(artikel.onderdeelSoort);
            }
        }

        return Array.from(set);
    }, [artikelen]);

    const filteredArtikelen = useMemo(() => {
        const cleanSearchText = searchText.trim().toLowerCase();

        return artikelen.filter((item) => {
            let matchesText = !cleanSearchText;
            if (cleanSearchText) {
                if (item.nummer.toLowerCase().includes(cleanSearchText) || item.omschrijving?.toLowerCase().includes(cleanSearchText)) {
                    matchesText = true;
                }
            }

            let matchesOnderdeelSoort: boolean;
            if (onderdeelSoort === "all") {
                matchesOnderdeelSoort = true;
            } else if (onderdeelSoort === "empty") {
                matchesOnderdeelSoort = item.onderdeelSoort === null;
            } else {
                matchesOnderdeelSoort = item.onderdeelSoort === onderdeelSoort;
            }

            return matchesText && matchesOnderdeelSoort;
        });
    }, [searchText, onderdeelSoort, artikelen]);

    const [activeIndex, setActiveIndex] = useState(0);

    const [listWrapperRef, listWrapperSize] = useDimensions();

    const onOkeClick = () => {
        onConfirm(filteredArtikelen[activeIndex]);

        onClose?.();
    };

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const Item = (props: any) => {
        const {index, style} = props;

        const artikel = filteredArtikelen[index];

        return (
            <MDBListGroupItem style={style} active={index === activeIndex} onClick={() => setActiveIndex(index)} action
                              className="p-0 border-0">
                <MDBRow className="w-100 h-100">
                    <MDBCol size="2" className="ms-2">
                        <LightboxBackgroundImage src={artikel.afbeeldingUri!}
                                                 title={
                                                     <span><strong>{artikel.nummer}</strong> {artikel.omschrijving}</span>}/>
                    </MDBCol>

                    <MDBCol className="ps-4 p-2 d-flex align-items-center">
                        <span><strong>{artikel.nummer}</strong> {artikel.omschrijving}</span>
                    </MDBCol>

                    <MDBCol size="auto" className="p-2 d-flex align-items-center">
                        <span>{artikel.onderdeelSoort || "Geen soort"}</span>
                    </MDBCol>
                </MDBRow>
            </MDBListGroupItem>
        );
    };

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>Artikelen</MDBModalHeader>

            <MDBModalBody>
                <MDBRow>
                    <MDBCol>
                        <MDBInput label="Zoeken"
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}/>
                    </MDBCol>
                    <MDBCol>
                        <MDBSelect
                            label="Soort"
                            data={[
                                {text: "Alle", value: "all"},
                                {text: "Geen soort", value: "empty"},
                                ...(artikelOnderdeelSoorten.map(item => ({text: item, value: item})))
                            ]}

                            onChange={(value: SelectData | SelectData[]) => {
                                const selectedValue = value as SelectData;

                                if (selectedValue) {
                                    setOnderdeelSoort(String(selectedValue.value));
                                }
                            }}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>

            <div className="d-flex flex-column" style={{height: "50vh"}}>
                <div className="flex-fill" ref={listWrapperRef}>
                    {listWrapperSize?.height && (
                        <MDBListGroup flush>
                            <FixedSizeList
                                width="100%"
                                height={listWrapperSize.height}
                                itemSize={80}
                                overscanCount={10}
                                itemCount={filteredArtikelen.length}
                            >
                                {Item}
                            </FixedSizeList>
                        </MDBListGroup>
                    )}
                </div>
            </div>

            <MDBModalBody className="py-1">
                <div className="d-flex justify-content-end align-items-center my-3">
                    <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick}>
                        Annuleren
                    </MDBBtn>
                    <MDBBtn type="button" color="success" size="lg" className="ms-2" onClick={onOkeClick}>
                        Oké
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </FrameModal>
    );
};
