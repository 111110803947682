import React from "react";
import {GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum} from "../../../_generated/field-service-be-openapi";
import {Config} from "../../../utilities/config";

export interface Co2OnvolledigGeleverdRedenProps {
    reden: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum;
}

export const Co2OnvolledigGeleverdReden: React.FC<Co2OnvolledigGeleverdRedenProps> = (props) => {
    const {reden} = props;

    return (
        <>{Config.labels[reden]}</>
    );
};

