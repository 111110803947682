import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

export interface IndeterminateProgressProps {
    loading?: boolean;
    className?: string;
}

export const IndeterminateProgress: React.FC<IndeterminateProgressProps> = (props) => {
    const {loading, className} = props;

    if (!loading) {
        return null;
    }

    return (
        <LinearProgress className={className}/>
    );
};
