import React from "react";
import {
    AbstractDagPlanningEntryRestModel,
    DagPlanningStopRestModelTypeEnum
} from "../../../_generated/field-service-be-openapi";
import {BezoekMarker} from "./BezoekMarker";
import {VertrekMarker} from "./VertrekMarker";
import {BestemmingMarker} from "./BestemmingMarker";
import {StopMarker} from "./StopMarker";

export interface DagPlanningEntryMarkerProps {
    entry?: AbstractDagPlanningEntryRestModel;
    index?: number;

    onClick?: () => void;
}

export const DagPlanningEntryMarker: React.FC<DagPlanningEntryMarkerProps> = (props) => {
    const {entry, index, onClick} = props;

    if (!entry) {
        return null;
    }

    switch (entry._type) {
        case "BEZOEK":
            return <BezoekMarker entry={entry} index={index} onClick={onClick}/>;
        case "STOP":
            switch (entry.type) {
                case DagPlanningStopRestModelTypeEnum.Vertrek:
                    return <VertrekMarker entry={entry} onClick={onClick}/>;
                case DagPlanningStopRestModelTypeEnum.Bestemming:
                    return <BestemmingMarker entry={entry} onClick={onClick}/>;
                case DagPlanningStopRestModelTypeEnum.Stop:
                    return <StopMarker entry={entry} onClick={onClick}/>;
            }
    }

    return null;
};
