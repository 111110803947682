import React from 'react';
import clsx from 'clsx';
import type { TypographyProps } from './types';

const MDBTypography: React.FC<TypographyProps> = React.forwardRef<HTMLAllCollection, TypographyProps>(
  (
    {
      className,
      children,
      tag: Tag = 'p',
      variant,
      color,
      blockquote,
      note,
      noteColor,
      listUnStyled,
      listInLine,
      ...props
    },
    ref
  ) => {
    const classes = clsx(
      variant && variant,
      blockquote && 'blockquote',
      note && 'note',
      color && `text-${color}`,
      noteColor && `note-${noteColor}`,
      listUnStyled && 'list-unstyled',
      listInLine && 'list-inline',
      className
    );

    if (blockquote) {
      Tag = 'blockquote';
    }

    if (listUnStyled || listInLine) {
      Tag = 'ul';
    }

    return (
      <Tag className={classes} ref={ref} {...props}>
        {children}
      </Tag>
    );
  }
);

MDBTypography.displayName = 'MDBTypography';
export default MDBTypography;
