import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
    .use(initReactI18next)
    // https://www.i18next.com/how-to/add-or-load-translations
    .use(resourcesToBackend((language, namespace, callback) => {
        import(`./locales/${language}/${namespace}.json`)
            .then((resources) => {
                callback(null, resources.default);
            })
            .catch((error) => {
                callback(error, null);
            })
    }))
    .init({
        ns: ["algemeen", "bezoeken", "co2-bestelling", "planning", "serviceadres", "toestel"],
        defaultNS: "algemeen",
        fallbackLng: {
            "nl-NL": ["nl"],
            "nl-BE": ["nl"],
            "fr-FR": ["fr"],
            "fr-BE": ["fr"],
            "default": ["nl"]
        },
        supportedLngs: ["nl", "fr"],

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

        react: {
            useSuspense: false
        }
    });
