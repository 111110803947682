import React from "react";
import {MDBCol, MDBRow} from "mdb-react-ui-kit";
import {ToestelOnderhoudServiceLogEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {useTechnici} from "../../../redux/slices/technicus/hooks";
import {Datum} from "../../Datum";
import {useTranslation} from "../../../utilities/i18nUtils";
import {ArtikelNrOmschrijving} from "../../ArtikelNrOmschrijving";

export interface ToestelOnderhoudServiceLogEntryProps {
    entry: ToestelOnderhoudServiceLogEntryRestModel;
}

export const ToestelOnderhoudServiceLogEntryComponent: React.FC<ToestelOnderhoudServiceLogEntryProps> = (props) => {
    const {entry} = props;

    const {t} = useTranslation("toestel");

    const technici = useTechnici();

    return (
        <>
            <span><Datum format="DD-MM-YYYY HH:mm" datum={entry.tijdstip}/></span>
            <span className="float-end">{entry.medewerkerId && technici[entry.medewerkerId]?.naam}</span>
            <h5>{t("Labels.onderhoud", "Onderhoud")}</h5>
            <MDBRow>
                <MDBCol md="6">
                    <strong>{t("Labels.soort", "Soort")}</strong> {entry.onderhoudSoort}
                </MDBCol>
                {entry.opmerking && (
                    <MDBCol size="12">
                        <strong>{t("Labels.opmerking", "Opmerking")}</strong> {entry.opmerking}
                    </MDBCol>
                )}

                <MDBCol size="12">
                    <ul className="check-list mdi">
                        <li>
                            {t("Labels.reinigen-en-spoelen", "Reinigen en spoelen")} {entry.reinigenEnSpoelen ? <span className="text-success">✓</span> : <span className="text-warning">✗</span>}
                        </li>

                        <li>
                            {t("Labels.uv-vervangen", "UV vervangen")} {entry.uvVervangen ? <span className="text-success">✓</span> : <span className="text-warning">✗</span>}
                        </li>

                        {!!entry.filtersVervangen?.length && (
                            <li>
                                <div className="d-inline-block">
                                    <div>{t("Labels.filters-vervangen", "Filters vervangen")}</div>
                                    {entry.filtersVervangen?.map(filter =>
                                        <div key={filter.lijnNr} className="text-muted">
                                            <ArtikelNrOmschrijving nr={filter.artikel?.nummer} omschrijving={filter.artikel?.omschrijving}/> → <ArtikelNrOmschrijving nr={filter.nieuweFilterArtikel?.nummer} omschrijving={filter.nieuweFilterArtikel?.omschrijving}/>
                                        </div>)}
                                </div>
                            </li>
                        )}

                        {entry.resultaat && (
                            <li>
                                <div>{t("Labels.controle-werking-colon", "Controle werking:")} <strong>{entry.resultaat}</strong></div>
                                <div className="text-muted">{entry.resultaatOpmerking}</div>
                            </li>
                        )}
                    </ul>
                </MDBCol>
            </MDBRow>
        </>
    );
};
