import React from "react";

export interface ArtikelNrOmschrijvingProps {
    nr?: string;
    omschrijving?: string;
}

export const ArtikelNrOmschrijving: React.FC<ArtikelNrOmschrijvingProps> = (props) => {
    const {nr, omschrijving} = props;

    return (
        <><strong>{nr}</strong> {omschrijving}</>
    );
};
