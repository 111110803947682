'use client';

import clsx from 'clsx';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useId,
  forwardRef,
  useImperativeHandle,
} from 'react';

import type { TimepickerProps } from './types';
import MDBInput from '../../../free/forms/Input/Input';
import TimepickerModal from './TimepickerModal/TimepickerModal';
import MDBBtn from '../../../free/components/Button/Button';

import { TimePickerContext } from './context';
import { destructureClockValue, regexpCheck, getCurrentTime, convertDateToTime } from './utils';
import { useOpenStatus } from '../../../utils/hooks';
import { handleHoursKeys, handleMinutesKeys, handleTab, handleClickOutside } from './navigationUtils';

import { ESCAPE, DOWN_ARROW, UP_ARROW, ENTER, TAB, LEFT_ARROW, RIGHT_ARROW } from './keycodes';

const MDBTimepicker = forwardRef<HTMLInputElement, TimepickerProps>(
  (
    {
      isInDatetimepicker,
      onDatetimepickerModeSwitch,
      datetimepickerRef,
      className,
      defaultValue,
      value,
      minHour,
      maxHour,
      maxTime,
      minTime,
      noIcon = false,
      inputID,
      justInput = false,
      inputClasses,
      inputLabel,
      invalidLabel,
      clearLabel,
      submitLabel,
      cancelLabel,
      format = '12h',
      timePickerClasses,
      customIcon = 'far fa-clock',
      customIconSize = 'sm',
      btnIcon = true,
      inline = false,
      increment = false,
      onChange,
      inputStyle,
      onOpen,
      onOpened,
      onClose,
      onClosed,
      disableFuture,
      disablePast,
      disabled = false,
      amLabel = 'AM',
      pmLabel = 'PM',
      switchHoursToMinutesOnClick = true,
      headId = '',
      bodyId = '',
      open,
      style,
      ...props
    },
    ref
  ) => {
    const formattedDefaultValue = useMemo(() => {
      if (defaultValue instanceof Date) {
        return convertDateToTime(defaultValue, format);
      }
      return defaultValue;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formattedValue = useMemo(() => {
      if (value instanceof Date) {
        return convertDateToTime(value, format);
      }
      return value;
    }, [value, format]);

    const [isOpened, setIsOpened] = useState(false);
    const [isOpenState, setIsOpenState] = useState(false);
    const isOpen = useOpenStatus(isOpenState, open);
    const [inputValue, setInputValue] = useState(formattedValue || formattedDefaultValue || '');
    const [isInvalid, setIsInvalid] = useState(false);
    const [activeHour, setActiveHour] = useState(12);
    const [activeMinute, setActiveMinute] = useState(0);
    const [period, setPeriod] = useState(format === '24h' ? '' : 'AM');
    const [maximumHour, setMaximumHour] = useState(maxHour ? maxHour : 24);
    const [minimumHour, setMinimumHour] = useState(minHour ? minHour : 1);
    const [maximumMinute, setMaximumMinute] = useState(59);
    const [minimumMinute, setMinimumMinute] = useState(0);
    const [minPeriod, setMinPeriod] = useState('');
    const [maxPeriod, setMaxPeriod] = useState('');
    const [mode, setMode] = useState('hours');
    const [handAnimation, setHandAnimation] = useState(false);
    const [hourAngle, setHourAngle] = useState(360);
    const [minuteAngle, setMinuteAngle] = useState(360);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [tabCount, setTabCount] = useState(0);

    if (defaultValue instanceof Date) {
      defaultValue = convertDateToTime(defaultValue, format);
    }
    const inputUniqueId = useId();

    const inputIDValue = inputID ?? inputUniqueId;

    const labels = {
      input: inputLabel ?? 'Select a time',
      invalid: invalidLabel ?? 'Invalid Time Format',
      clear: clearLabel ?? 'Clear',
      submit: submitLabel ?? 'Ok',
      cancel: cancelLabel ?? 'Cancel',
    };

    const classes = clsx('timepicker', className);

    const inputClassName = clsx('timepicker-input', isInvalid && 'is-invalid', inputClasses);

    const timepickerRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // it makes forwardRef returning HTMLInputElement, needed for react-hook-form
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [inputRef]);

    const handleInputChange = (e: any) => {
      setInputValue(e.target.value);
      onChange?.(e.target.value);
    };

    const handleInputFocus = (e: any) => {
      if (justInput) {
        e.target.blur();

        onOpen?.();
      }
    };

    const onCloseHandler = useCallback(() => {
      setIsOpenState(false);
      onClose?.();
    }, [onClose]);

    const onOpenHandler = useCallback(() => {
      setIsOpenState(true);
      onOpen?.();
    }, [onOpen]);

    const onClosedHandler = useCallback(() => {
      setIsOpened(false);
      onClosed?.();
      setMode('hours');
      setTabCount(0);
    }, [onClosed]);

    const onOpenedHandler = useCallback(() => {
      setIsOpened(true);
      onOpened?.();
    }, [onOpened]);

    const handleCloseOutside = useCallback(
      (e: any) => {
        isOpened && handleClickOutside(e, inline, wrapperRef.current, inputRef.current, isOpen, onCloseHandler);
      },
      [wrapperRef, inline, inputRef, isOpen, onCloseHandler, isOpened]
    );

    const keyboardNavigation = useCallback(
      (e: any) => {
        const { key } = e;

        const activeElement = document.activeElement as HTMLElement;

        const isHourBtnFocused = activeElement === wrapperRef.current?.querySelector('.timepicker-hour');
        const isMinuteBtnFocused = activeElement === wrapperRef.current?.querySelector('.timepicker-minute');
        const isFocusableItemFocused = activeElement?.closest('.timepicker-modal [tabindex="0"]');

        if (![DOWN_ARROW, UP_ARROW, ESCAPE, TAB, ENTER, LEFT_ARROW, RIGHT_ARROW].includes(key)) return;

        if (inline && !isFocusableItemFocused) {
          handleTab(setTabCount, 0, wrapperRef.current);
        }

        e.preventDefault();

        const shouldChangeHour = (!inline && mode === 'hours') || (inline && isHourBtnFocused);
        const shouldChangeMinute = (!inline && mode === 'minutes') || (inline && isMinuteBtnFocused);

        switch (key) {
          case ESCAPE:
            return onClose?.();

          case UP_ARROW:
            if (shouldChangeHour) {
              return handleHoursKeys(format, activeHour, setActiveHour, setHourAngle, true);
            }
            if (shouldChangeMinute) {
              return handleMinutesKeys(setActiveMinute, setMinuteAngle, increment, activeMinute, true);
            }
            break;

          case DOWN_ARROW:
            if (shouldChangeHour) {
              return handleHoursKeys(format, activeHour, setActiveHour, setHourAngle, false);
            }
            if (shouldChangeMinute) {
              return handleMinutesKeys(setActiveMinute, setMinuteAngle, increment, activeMinute, false);
            }
            break;

          case LEFT_ARROW:
            if (shouldChangeHour && !inline) {
              return handleHoursKeys(format, activeHour, setActiveHour, setHourAngle, false, 'isLeft');
            }
            break;

          case RIGHT_ARROW:
            if (shouldChangeHour && !inline) {
              return handleHoursKeys(format, activeHour, setActiveHour, setHourAngle, true, 'isRight');
            }
            break;

          case TAB:
            handleTab(setTabCount, tabCount, wrapperRef.current);
            break;

          case ENTER:
            activeElement.click();
            break;

          default:
            break;
        }
      },
      [activeHour, format, mode, activeMinute, increment, inline, tabCount, onClose]
    );

    useEffect(() => {
      if (!disablePast && !disableFuture) return;

      const { hours, minutes, period } = getCurrentTime(format);

      if (disablePast) {
        setMinimumHour(hours);
        setMinimumMinute(minutes);
        return setMinPeriod(period);
      }

      if (disableFuture) {
        setMaximumHour(hours);
        setMaximumMinute(minutes);
        return setMaxPeriod(period);
      }
    }, [disableFuture, disablePast, format]);

    useEffect(() => {
      if (isOpen && isOpened) {
        document.addEventListener('click', handleCloseOutside);
        document.addEventListener('keydown', keyboardNavigation);
      }

      return () => {
        document.removeEventListener('click', handleCloseOutside);
        document.removeEventListener('keydown', keyboardNavigation);
      };
    }, [isOpen, isOpened, handleCloseOutside, keyboardNavigation]);

    useEffect(() => {
      if (maxTime) {
        const { hour, minute, defaultPeriod } = destructureClockValue(maxTime);

        setMaximumHour(hour);
        setMaximumMinute(minute);

        if (defaultPeriod !== undefined) {
          setMaxPeriod(defaultPeriod);
        }
      }

      if (minTime) {
        const { hour, minute, defaultPeriod } = destructureClockValue(minTime);

        setMinimumHour(hour);
        setMinimumMinute(minute);

        if (defaultPeriod !== undefined) {
          setMinPeriod(defaultPeriod);
        }
      }
    }, [maxTime, minTime]);

    useEffect(() => {
      if (typeof value !== 'string') {
        return;
      }
      if (value && regexpCheck(value, format)) {
        setInputValue(value);
      }
    }, [value, format]);

    useEffect(() => {
      if (regexpCheck(inputValue, format) || inputValue === '') {
        setIsInvalid(false);

        if (inputValue !== '') {
          const { hour, minute, defaultPeriod } = destructureClockValue(inputValue);
          if (format === '24h') {
            hour === 0 ? setActiveHour(24) : setActiveHour(hour);
            if (hour === 0) {
              setHourAngle(360);
            } else if (hour > 12) {
              setHourAngle((hour - 12) * 30);
            } else {
              setHourAngle(hour * 30);
            }
          } else {
            setActiveHour(hour);
            setHourAngle(hour * 30);
            setPeriod(defaultPeriod);
          }

          setActiveMinute(minute);
          setMinuteAngle(minute * 6);
        }
      } else {
        setIsInvalid(true);
      }
    }, [inputValue, format]);

    useEffect(() => {
      let timer: ReturnType<typeof setTimeout>;
      if (handAnimation) {
        timer = setTimeout(() => {
          setHandAnimation(false);
        }, 400);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [handAnimation]);

    return (
      <>
        <TimePickerContext.Provider
          value={{
            isInDatetimepicker,
            onDatetimepickerModeSwitch,
            show: isOpen,
            setInputValue,
            submitLabel: labels.submit,
            clearLabel: labels.clear,
            cancelLabel: labels.cancel,
            activeHour,
            activeMinute,
            setActiveHour,
            setActiveMinute,
            format,
            period,
            setPeriod,
            defaultValue,
            maxHour: maximumHour,
            minHour: minimumHour,
            maxPeriod,
            minPeriod,
            mode,
            setMode,
            setHandAnimation,
            handAnimation,
            minMinute: minimumMinute,
            maxMinute: maximumMinute,
            hourAngle,
            setHourAngle,
            minuteAngle,
            setMinuteAngle,
            inline,
            increment,
            onChange,
            onOpen,
            onClose,
            onCloseHandler,
            amLabel,
            pmLabel,
            switchHoursToMinutesOnClick,
            headId,
            bodyId,
          }}
        >
          <>
            <div
              className={classes}
              ref={inline ? setReferenceElement : timepickerRef}
              style={{ ...style, height: 'fit-content' }}
              {...props}
            >
              {!isInDatetimepicker && (
                <MDBInput
                  onFocus={handleInputFocus}
                  ref={inputRef}
                  labelRef={labelRef}
                  className={inputClassName}
                  label={labels.input}
                  id={inputIDValue}
                  value={inputValue}
                  onChange={handleInputChange}
                  wrapperClass='timepicker'
                  style={inputStyle}
                  disabled={disabled}
                  onClick={() => {
                    justInput && onOpenHandler();
                  }}
                >
                  {!justInput &&
                    !noIcon &&
                    (!btnIcon ? (
                      <i
                        onClick={onOpenHandler}
                        className={`${customIcon} ${customIconSize} timepicker-icon timepicker-toggle-button`}
                      />
                    ) : (
                      <MDBBtn
                        className='timepicker-toggle-button'
                        onClick={onOpenHandler}
                        color='none'
                        tabIndex={0}
                        type='button'
                        disabled={disabled}
                        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                      >
                        <i className={`${customIcon} ${customIconSize} timepicker-icon`} />
                      </MDBBtn>
                    ))}
                </MDBInput>
              )}
            </div>
            <TimepickerModal
              isOpen={isOpen}
              wrapperRef={wrapperRef}
              referenceElement={datetimepickerRef ? datetimepickerRef : referenceElement}
              inline={inline}
              className={timePickerClasses}
              onClosed={onClosedHandler}
              onOpened={onOpenedHandler}
            />
          </>
        </TimePickerContext.Provider>
      </>
    );
  }
);

MDBTimepicker.displayName = 'MDBTimepicker';
export default MDBTimepicker;
