import {
    mdiAlarmLightOutline,
    mdiMoleculeCo2,
    mdiTruckDeliveryOutline,
    mdiWaterPlusOutline,
    mdiWrenchOutline
} from "@mdi/js";

export const Assets = {
    logoDark: require("./logo-dark.svg").default,
    logoLight: require("./logo-light.svg").default,

    loadingDripAnimation: require("./loading-drip.json"),
    loadingDripDarkNoBgAnimation: require("./loading-drip-dark-no-bg.json"),

    emptyFolder: require("./svg/emptyFolder.svg").default,

    orderTypes: {
        levering: mdiTruckDeliveryOutline,
        onderhoud: mdiWrenchOutline,
        interventie: mdiAlarmLightOutline,
        co2Levering: mdiMoleculeCo2,
        installatie: mdiWaterPlusOutline
    }
};
