import dayjs, {ConfigType} from "dayjs";
import "dayjs/locale/nl-be";
import localeData from "dayjs/plugin/localeData";
import duration, {DurationUnitType} from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import minMax from "dayjs/plugin/minMax";

dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(minMax);

dayjs.locale("nl-be");

export const dateNow = () => {
    return parseDate().toDate();
};

export const dateMonths = () => {
    return dayjs.months();
};

export const dateMonthsShort = () => {
    return dayjs.monthsShort();
};

export const dateWeekdays = () => {
    return dayjs.weekdays();
};

export const dateWeekdaysShort = () => {
    return dayjs.weekdaysShort();
};

export const dateWeekdaysMin = () => {
    return dayjs.weekdaysMin();
};

export const formatDuration = (format: string, time: number, unit?: DurationUnitType) => {
    return dayjs.duration(time, unit).format(format);
};

export const humanizeDuration = (time: number, unit?: DurationUnitType) => {
    return dayjs.duration(time, unit).humanize();
};

export const parseDate = (date?: ConfigType, format?: string) => {
    return dayjs(date, format);
};

export const formatDate = (date: ConfigType | undefined, format: string) => {
    return dayjs(date).format(format);
};

export const monthRange = (datum: Date): [Date, Date] => {
    return [
        dayjs(datum).startOf('month').toDate(),
        dayjs(datum).endOf('month').toDate()
    ];
};

export const formatUsingIntlDateTimeFormat = (maybeDate: ConfigType, locale: string, options?: Intl.DateTimeFormatOptions) => {
    const date = parseDate(maybeDate).toDate();
    return new Intl.DateTimeFormat(locale, options).format(date);
};

export const formatNumberAsMinutes = (amount :number, locale :string) => {
    return Intl.NumberFormat(locale, {style: "unit", unit: "minute"}).format(amount);
}