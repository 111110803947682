import { MutableRefObject, useCallback, useEffect } from 'react';

type useDatepickerClickOutsideProps = {
  isOpened: boolean;
  isOpen?: boolean;
  inline?: boolean;
  popperElement: any;
  referenceElement: any;
  backdropRef: MutableRefObject<HTMLDivElement | null>;
  onCloseHandler: () => void;
};

export const useDatepickerClickOutside = ({
  isOpened,
  isOpen,
  inline,
  popperElement,
  referenceElement,
  backdropRef,
  onCloseHandler,
}: useDatepickerClickOutsideProps): void => {
  const handleClickOutside = useCallback(
    (e: any) => {
      if (inline) {
        const shouldHidePicker =
          !popperElement?.contains(e.target as Node) &&
          !referenceElement?.parentNode?.contains(e.target as Node) &&
          !e.target.classList.contains('datepicker-view-change-button') &&
          !e.target.classList.contains('datepicker-large-cell-content');

        if (shouldHidePicker) {
          isOpen && onCloseHandler();
        }
      } else if (e.target === backdropRef.current) {
        isOpen && onCloseHandler();
      }
    },

    [popperElement, referenceElement, backdropRef, inline, isOpen, onCloseHandler]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpened, handleClickOutside]);
};

export default useDatepickerClickOutside;
