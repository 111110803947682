import {
    ArtikelApi,
    BezoekSessieApi,
    BundleApi, Configuration,
    KlantApi, PingApi,
    ServiceAdresApi,
    ShiftenApi,
    TechnicusApi,
    ToestelApi,
    UploadControllerApi
} from "../../_generated/field-service-be-openapi";
import {authMiddleware, configuration} from "./config/browser";
import {Config} from "../config";
import {RequestUtils} from "../requestUtils";

export const klantApi = new KlantApi(configuration);
export const bundleApi = new BundleApi(configuration);
export const toestelApi = new ToestelApi(configuration);
export const technicusApi = new TechnicusApi(configuration);
export const serviceAdresApi = new ServiceAdresApi(configuration);
export const bezoekSessieApi = new BezoekSessieApi(configuration);
export const uploadApi = new UploadControllerApi(configuration);
export const shiftenApi = new ShiftenApi(configuration);
export const artikelApi = new ArtikelApi(configuration);

const pingConfiguration = new Configuration({
    basePath: Config.BASE_URL,
    middleware: [authMiddleware],
    fetchApi: async (url: string, init: RequestInit) => {
        return RequestUtils.fetchWithTimeout(4_000, url, init);
    }
});
export const pingApi = new PingApi(pingConfiguration);
