import React, {useRef} from "react";
import {MDBBtn, MDBCard, MDBCardHeader} from "mdb-react-ui-kit";
import {GenericPage} from "../../../components/GenericPage";
import {Header} from "../../../components/Header";
import {useParams} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiFileDocument, mdiPlus} from "@mdi/js";
import {useBezoekSessieById, useBezoekSessieToestel} from "../../../redux/slices/bezoeksessie/hooks";
import {FabContainer} from "../../../components/FabContainer";
import {ContentContainer} from "../../../components/ContentContainer";
import {MDBFileInput} from "../../../mdb-formik/MDBFileInput";
import {useToestelVoegFotoToe} from "../../../redux/slices/toestellen/hooks";
import {ToestelBijlagenTable} from "../../../components/toestel/ToestelBijlagenTable";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface ToestelBijlagenPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ToestelBijlagenPage: React.FC = () => {
    const {bezoekSessieId, toestelId} = useParams<ToestelBijlagenPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const toestel = useBezoekSessieToestel(bezoekSessie?.id, toestelId);
    const toestelVoegFotoToe = useToestelVoegFotoToe();

    const fileInputRef = useRef<HTMLInputElement>();

    const {t} = useTranslation("toestel");

    const onPhotoChange = (event: any) => {
        if (!bezoekSessie) {
            return;
        }

        const value = event.target.value;

        if (value) {
            toestelVoegFotoToe(bezoekSessie.id, toestelId, value[0].uploadId);
        }
    };

    return (
        <GenericPage>
            <Header title={t("algemeen:bijlagen", "Bijlagen")} sticky/>

            <ContentContainer>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader><Icon path={mdiFileDocument}
                                         size={1}/> {t("algemeen:bijlagen", "Bijlagen")}</MDBCardHeader>

                    {toestel?.informatie && <ToestelBijlagenTable toestel={toestel.informatie}/>}
                </MDBCard>

                <FabContainer>
                    <MDBBtn type="button" floating size="lg" tag="span" onClick={() => {
                        fileInputRef.current?.click();
                    }}>
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <Icon path={mdiPlus} size={1}/>
                        </div>

                        <MDBFileInput type="file" accept="image/*" name="fotos" onChange={onPhotoChange}
                                      style={{display: "none"}} className="no-outline" wrapperClass="form-notch-fix"
                                      innerRef={fileInputRef as any}/>
                    </MDBBtn>
                </FabContainer>
            </ContentContainer>
        </GenericPage>
    );
};
