import React from "react";
import {Installatie, ServiceAdresCo2ArtikelRestModel} from "../../../_generated/field-service-be-openapi";
import {MDBCard, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {Assets} from "../../../assets/assets";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface GeplandWerkInstallatieCo2CardProps {
    co2VoorInstallaties: Installatie[];
    co2Artikelen: ServiceAdresCo2ArtikelRestModel[];
}

export const GeplandWerkInstallatieCo2Card: React.FC<GeplandWerkInstallatieCo2CardProps> = (props) => {

    const {t} = useTranslation("serviceadres");

    const {
        co2VoorInstallaties,
        co2Artikelen
    } = props;

    if (co2VoorInstallaties.length === 0) {
        return null;
    }

    const totaleCo2: Record<string, {
        gevraagdAantal: number;
        gevraagdExtraAantal: number;
        gevraagdOmruilAantal: number;
        oorsprong: string;
    }> = {};

    for (let installatie of co2VoorInstallaties) {
        for (const item of installatie.co2Artikelen) {
            totaleCo2[item.artikelId] = {
                gevraagdAantal: (totaleCo2[item.artikelId]?.gevraagdAantal || 0) + item.aantalTeLeveren,
                gevraagdExtraAantal: totaleCo2[item.artikelId]?.gevraagdExtraAantal || 0,
                gevraagdOmruilAantal: totaleCo2[item.artikelId]?.gevraagdOmruilAantal || 0,
                oorsprong: Array.from(new Set([totaleCo2[item.artikelId]?.oorsprong, installatie.oorsprong.verkoopOrderNr].filter(Boolean))).join(" + ")
            };
        }
    }

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={Assets.orderTypes.installatie}
                                 size={1}/>{t("planning:GeplandWerkInstallatieCO2Card.co2-bij-installaties", "CO₂ bij installaties")}</MDBCardHeader>

            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th>{t("planning:GeplandWerkInstallatieCO2Card.installatie","Installatie")}</th>
                        <th>{t("planning:GeplandWerkInstallatieCO2Card.artikel","Artikel")}</th>
                        <th>{t("planning:GeplandWerkInstallatieCO2Card.aantal","Aantal")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {Object.entries(totaleCo2).map(([artikelId, item]) => {
                        const artikel = co2Artikelen.find(co2Artikel => co2Artikel.id === artikelId);

                        return (
                            <tr key={artikelId}>
                                <td>{item.oorsprong}</td>
                                <td>
                                    <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                </td>
                                <td>
                                    {item.gevraagdAantal + (item.gevraagdExtraAantal || 0)}
                                </td>
                            </tr>
                        );
                    })}
                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    );
};
