import React from "react";
import {MDBInput, MDBValidationItem} from "mdb-react-ui-kit";
import {Field, FieldProps} from "formik";
import {SaveDraftUtils} from "../utilities/form/saveDraftUtils";

export type MDBFormikInputProps = typeof MDBInput["defaultProps"] & Partial<FieldProps>;

/**
 * Inspired by https://github.com/jannikbuschke/formik-antd/blob/master/src/input/index.tsx
 * @param props
 * @constructor
 */
export const MDBFormikInput: React.FC<MDBFormikInputProps> = (props) => {
    const {name, max, ...rest} = props;

    const getValidation = (error?: string) => {
        if (error) {
            return error;
        }

        return "";
    };

    const renderRemaining = (value?: string) => {
        if (max !== undefined && value) {
            const karaktersResterend = Math.max(0, Number(max) - value.length);

            if (karaktersResterend > 0) {
                return (
                    <div className="text-right">
                        <small className="text-muted">{karaktersResterend} resterend</small>
                    </div>
                );
            }
        }

        return "";
    };

    return (
        <Field name={name}>
            {({field: {value, onChange, onBlur}, meta: {error}, form}: FieldProps) => (
                <MDBValidationItem feedback={getValidation(error)} invalid={!!error}>
                    <MDBInput {...rest} name={name} value={value} onChange={onChange} onBlur={(e: any) => SaveDraftUtils.onBlur(name!, form, () => onBlur(e))}
                              className={`${rest.className || ""} ${!!error ? "is-invalid" : ""}`}/>
                    {renderRemaining(value)}
                </MDBValidationItem>
            )}
        </Field>
    );
};
