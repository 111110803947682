import React from "react";
import {MDBCard, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead} from "../../../mdb-react-ui-kit/index";
import Icon from "@mdi/react";
import {mdiOrderAlphabeticalAscending} from "@mdi/js";
import {Co2Levering, ServiceAdresCo2ArtikelRestModel} from "../../../_generated/field-service-be-openapi";
import {BezoekSessieCo2LeveringItem} from "../../../workers/shared/snapshot/bezoekSessieState";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface Co2LeveringBesteldCardProps {
    bestellingen?: Co2Levering[];
    bestellingenTerPlaatse?: BezoekSessieCo2LeveringItem[];

    co2Artikelen: ServiceAdresCo2ArtikelRestModel[];
}

export const Co2LeveringBesteldCard: React.FC<Co2LeveringBesteldCardProps> = (props) => {
    const {bestellingen, bestellingenTerPlaatse = [], co2Artikelen} = props;

    const {t} = useTranslation("co2-bestelling");

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={mdiOrderAlphabeticalAscending}
                                 size={1}/>{t("besteld", "Besteld")} </MDBCardHeader>

            <MDBTable responsive className="table--no-bottom-border">
                <MDBTableHead>
                    <tr>
                        <th>{t("artikel", "Artikel")}</th>
                        <th>{t("aantal-te-leveren", "Aantal te leveren")}</th>
                        <th>{t("aantal-retour", "Aantal retour")}</th>
                        <th>{t("nummer", "Nummer")}</th>
                        <th>{t("referentie-klant", "Referentie klant")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {!bestellingen?.length && !bestellingenTerPlaatse.length && (
                        <tr>
                            <td colSpan={5}>{t("geen-bestellingen", "Geen bestellingen")}</td>
                        </tr>
                    )}

                    {bestellingen?.map((item) => {
                        const artikel = co2Artikelen.find(artikel => artikel.id === item.artikelId);

                        return (
                            <tr key={item.oorsprong.bestellingId}>
                                <td>
                                    <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                </td>
                                <td>{item.gevraagdAantal + (item.gevraagdExtraAantal || 0)}</td>
                                <td>{item.gevraagdOmruilAantal}</td>
                                <td>{item.oorsprong.bestellingNummer}</td>
                                <td>{item.klantReferentie}</td>
                            </tr>
                        );
                    })}

                    {bestellingenTerPlaatse.map((bestelling) => {
                        const artikel = co2Artikelen?.find((item) => item.id === bestelling.artikelId);

                        return (
                            <tr key={`terPlaatse:${bestelling.artikelId}`}>
                                <td>
                                    <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                </td>
                                <td>{bestelling.aantalGeleverd}</td>
                                <td>{bestelling.aantalOmruilGeleverd}</td>
                                <td>{t("ter-plaatse", "Ter plaatse")}</td>
                                <td></td>
                            </tr>
                        );
                    })}
                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    );
};
