/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Shift,
} from '../models';

export interface DagPlanningByTechnicusIdAndDatumRequest {
    technicusId: string;
    van: string;
    tot: string;
}

/**
 * 
 */
export class ShiftenApi extends runtime.BaseAPI {

    /**
     */
    async dagPlanningByTechnicusIdAndDatumRaw(requestParameters: DagPlanningByTechnicusIdAndDatumRequest): Promise<runtime.ApiResponse<Array<Shift>>> {
        if (requestParameters.technicusId === null || requestParameters.technicusId === undefined) {
            throw new runtime.RequiredError('technicusId','Required parameter requestParameters.technicusId was null or undefined when calling dagPlanningByTechnicusIdAndDatum.');
        }

        if (requestParameters.van === null || requestParameters.van === undefined) {
            throw new runtime.RequiredError('van','Required parameter requestParameters.van was null or undefined when calling dagPlanningByTechnicusIdAndDatum.');
        }

        if (requestParameters.tot === null || requestParameters.tot === undefined) {
            throw new runtime.RequiredError('tot','Required parameter requestParameters.tot was null or undefined when calling dagPlanningByTechnicusIdAndDatum.');
        }

        const queryParameters: any = {};

        if (requestParameters.van !== undefined) {
            queryParameters['van'] = (requestParameters.van as any).toISOString().substr(0,10);
        }

        if (requestParameters.tot !== undefined) {
            queryParameters['tot'] = (requestParameters.tot as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/technicus/{technicusId}/shiften/`.replace(`{${"technicusId"}}`, encodeURIComponent(String(requestParameters.technicusId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async dagPlanningByTechnicusIdAndDatum(requestParameters: DagPlanningByTechnicusIdAndDatumRequest): Promise<Array<Shift>> {
        const response = await this.dagPlanningByTechnicusIdAndDatumRaw(requestParameters);
        return await response.value();
    }

}
