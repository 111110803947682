import React from "react";

export interface TruncateTextProps {
    text: string;

    max: number;
}

export const TruncateText: React.FC<TruncateTextProps> = (props) => {
    const {text, max} = props;

    if (!text) {
        return null;
    }

    if (text.length > max) {
        return <>{text.substring(0, max)}...</>;
    }

    return <>{text}</>;
};
