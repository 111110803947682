import React from "react";
import {AnimatePresence} from "framer-motion";

export const AnimatedSwitch: React.FC<{children?: React.ReactNode}> = (props) => {
    const {children} = props;

    return (
        <div className="position-relative h-100">
            <AnimatePresence mode="wait">
                {children}
            </AnimatePresence>
        </div>
    );
};
