import React from "react";
import {MDBBadge, MDBCardBody, MDBCardHeader, MDBCardText, MDBCol, MDBRipple, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAlarmLightOutline, mdiChevronRight, mdiFlagVariantOutline, mdiWrenchOutline} from "@mdi/js";
import {MDBCardStatus, MDBCardStatusEnum} from "../mdb/MDBCardStatus";
import {BezoekSessieToestel} from "../../workers/shared/snapshot/bezoekSessieState";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ToestellenCardProps {
    toestellen: BezoekSessieToestel[];
}

export const ToestellenCard: React.FC<ToestellenCardProps> = (props) => {
    const {toestellen} = props;

    const aantalOnderhouden = toestellen?.filter((item) => item.uitTeVoerenWerk?.onderhoud).length;
    const aantalInterventies = toestellen?.map((toestel) => toestel.uitTeVoerenWerk?.interventies?.length || 0).reduce((prev: number, curr: number) => prev + curr, 0);

    let cardStatusColor: MDBCardStatusEnum | undefined = undefined;
    if (toestellen.every(toestel => !(toestel.heeftUitTeVoerenWerk && !toestel.uitgevoerd))) {
        cardStatusColor = MDBCardStatusEnum.DONE;
    } else if (toestellen.some(toestel => toestel.heeftUitTeVoerenWerk && toestel.bezig && !toestel.uitgevoerd)) {
        cardStatusColor = MDBCardStatusEnum.WARNING;
    }

    const {t} = useTranslation("serviceadres");

    return (
        <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
            <MDBCardStatus status={cardStatusColor}/>
            <MDBCardHeader>
                <span className="d-flex align-items-center">
                    <Icon path={mdiFlagVariantOutline} size={1} className="me-1"/>
                    {t("algemeen:Titels.toestellen", "Toestellen")}
                </span>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBCardText>
                    <MDBRow>
                        <MDBCol>
                            <div>
                                {!!aantalOnderhouden && (
                                    <div className="d-flex align-items-center mb-2 me-2">
                                        <Icon path={mdiWrenchOutline} size={1} className="text-primary me-1"/>
                                        {aantalOnderhouden} <MDBBadge color="secondary"
                                                                      className="ms-2">{t("algemeen:Titels.onderhoud", "Onderhoud")}</MDBBadge>
                                    </div>
                                )}

                                {!!aantalInterventies && (
                                    <div className="d-flex align-items-center mb-2 me-2">
                                        <Icon path={mdiAlarmLightOutline} size={1} className="text-primary me-1"/>
                                        {aantalInterventies} <MDBBadge color="secondary"
                                                                       className="ms-2">{t("algemeen.Titels.interventie", "Interventie")}</MDBBadge>
                                    </div>
                                )}
                            </div>
                        </MDBCol>

                        <MDBCol size="auto"
                                className="d-flex align-items-center justify-content-end">
                            <Icon path={mdiChevronRight} size={2}/>
                        </MDBCol>
                    </MDBRow>
                </MDBCardText>
            </MDBCardBody>
        </MDBRipple>
    );
};
