import React, {useMemo} from "react";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAccountCircle, mdiChevronRight, mdiMoleculeCo2} from "@mdi/js";
import {Technicus} from "../../_generated/field-service-be-openapi";
import {MDBChip} from "../mdb/MDBChip";
import {MDBCardStatus, MDBCardStatusEnum} from "../mdb/MDBCardStatus";
import {BezoekSessieCo2Levering} from "../../workers/shared/snapshot/bezoekSessieState";
import {sumReducer} from "../../utilities/reduceUtils";
import {useTranslation} from "../../utilities/i18nUtils";
import {Trans} from "react-i18next";

export interface Co2LeveringCardProps {
    levering: BezoekSessieCo2Levering;
    technici: Record<string, Technicus>;
}

export const Co2LeveringCard: React.FC<Co2LeveringCardProps> = (props) => {
    const {levering, technici} = props;

    const aantal = levering.uitTeVoerenWerk?.map((item) => item.gevraagdAantal + (item.gevraagdExtraAantal || 0)).reduce((prev, curr) => (prev || 0) + (curr || 0), 0) || 0;
    const omruilAantal = levering.uitTeVoerenWerk?.map((item) => item.gevraagdOmruilAantal).filter((item) => item).reduce((prev, curr) => prev! + curr!, 0) || 0;

    const actieveTechnici = useMemo(() => {
        if (levering) {
            const ids = Array.from(new Set([...Object.values(levering.leveringen).map((item) => item.technicusId)]));

            return ids.map((id) => technici[id]);
        }
    }, [levering, technici]);

    const geleverdAantal = useMemo(() => {
        return Object.values(levering?.leveringen || {}).map((item) => item.aantalGeleverd).reduce(sumReducer, 0) || 0;
    }, [levering]);

    const geleverdOmruilAantal = useMemo(() => {
        return Object.values(levering?.leveringen || {}).map((item) => item.aantalOmruilGeleverd).reduce(sumReducer, 0) || 0;
    }, [levering]);

    const statusColor = useMemo(() => {
        if (levering?.uitgevoerd) {
            return MDBCardStatusEnum.DONE;
        }

        if (levering?.bezig) {
            return MDBCardStatusEnum.IN_PROGRESS;
        }

        return undefined;
    }, [levering]);

    const {t} = useTranslation("serviceadres");

    return (
        <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
            <MDBCardStatus status={statusColor}/>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <span className="d-flex align-items-center">
                            <Icon path={mdiMoleculeCo2} size={1} className="me-1"/>
                            {t("algemeen:Titels.co2-levering", "CO₂-levering")}
                        </span>
                    </MDBCol>

                    <MDBCol size="auto" className="d-flex">
                        {actieveTechnici?.map((item) => (
                            <MDBChip className="my-0 me-0" key={item.id}>
                                <Icon path={mdiAccountCircle} size={1}/>

                                {item.naam}
                            </MDBChip>
                        ))}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <MDBRow>
                            {(levering?.bezig || levering?.uitgevoerd) && (
                                <MDBCol sm="4" className="mb-2">
                                    <Trans t={t} i18nKey="Co2LeveringenCard.aantal-geleverd">
                                        <strong>Aantal geleverd: </strong><br/>
                                        {{geleverdAantal}} ({{geleverdOmruilAantal}} retour)
                                    </Trans>
                                </MDBCol>
                            )}

                            <MDBCol sm="4" className="mb-2">
                                <Trans t={t} i18nKey="Co2LeveringenCard.aantal-besteld">
                                    <strong>Aantal besteld: </strong><br/>
                                    {{aantal}} ({{omruilAantal}} retour)
                                </Trans>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>

                    <MDBCol size="auto"
                            className="d-flex align-items-center justify-content-end">
                        <Icon path={mdiChevronRight} size={2}/>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
