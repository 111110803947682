import React, {ReactNode} from "react";
import {LoadingDroplet} from "../components/LoadingDroplet";
import {Assets} from "../assets/assets";

export interface LoadingPageProps {
    description?: ReactNode;
    descriptionClassName?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = (props) => {
    const {description, descriptionClassName = "display-6"} = props;

    return (
        <div className="h-100 d-flex flex-column position-relative generic-page bg-primary">
            <div className="flex-grow-1 overflow-hidden">
                <LoadingDroplet lottieProps={{style: {height: "100%"}}}/>
            </div>

            {description && (
                <div className={`text-white text-center mb-5 ${descriptionClassName}`}>
                    {description}
                </div>
            )}

            <div className="d-flex justify-content-center mb-3">
                <img src={Assets.logoLight} alt="Logo" style={{maxWidth: 400}}/>
            </div>
        </div>
    );
};
