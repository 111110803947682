import React from "react";
import {ArtikelRestModel, ToestelComponent} from "../../../_generated/field-service-be-openapi";
import {Strikethrough} from "../../Strikethrough";
import {MDBBtn, MDBCol, MDBRow} from "mdb-react-ui-kit";
import {Icon} from "@mdi/react";
import {mdiSubdirectoryArrowRight, mdiTrashCanOutline, mdiUndo} from "@mdi/js";
import {Datum} from "../../Datum";
import {ArtikelNrOmschrijving} from "../../ArtikelNrOmschrijving";

export interface StuklijstComponentRowProps {
    artikel?: ArtikelRestModel;
    component?: ToestelComponent;

    toegevoegd?: boolean;
    verwijderd?: boolean;
    verwisseldArtikel?: ArtikelRestModel;

    onRemove?(): void;

    onUndo?(): void;
}

export const StuklijstComponentRow: React.FC<StuklijstComponentRowProps> = (props) => {
    const {
        artikel,
        component,
        toegevoegd,
        verwijderd = false,
        verwisseldArtikel,
        onRemove,
        onUndo
    } = props;

    let backgroundColor = "";
    if (toegevoegd) {
        backgroundColor = "#c6ffdd";
    } else if (verwijderd) {
        backgroundColor = "#fee3e8";
    }

    return (
        <tr style={{backgroundColor}}>
            <td className="align-middle">
                <MDBRow>
                    <MDBCol size="12">
                        <Strikethrough strikethrough={verwijderd}>
                            <ArtikelNrOmschrijving nr={artikel?.nummer || component?.artikelNr} omschrijving={artikel?.omschrijving || component?.omschrijving}/>
                        </Strikethrough>

                        <span className="ms-3 text-muted">{component?.serienummer}</span>
                    </MDBCol>

                    {verwisseldArtikel && (
                        <MDBCol>
                            <div
                                className="d-flex align-items-center">
                                <div>
                                    <Icon
                                        path={mdiSubdirectoryArrowRight}
                                        size={1}
                                        className="mb-2"/>
                                </div>

                                <div>
                                    <ArtikelNrOmschrijving nr={verwisseldArtikel.nummer} omschrijving={verwisseldArtikel.omschrijving}/>

                                    <span className="ms-3 text-muted">{component?.serienummer}</span>
                                </div>
                            </div>

                        </MDBCol>
                    )}
                </MDBRow>
            </td>
            <td className="align-middle">{component?.installatieDatum ? <Datum datum={component?.installatieDatum} format="DD-MM-YYYY"/> : <span className="text-muted">onbekend</span>}</td>
            <td className="align-middle text-right text-nowrap">
                {!verwijderd && !toegevoegd && !verwisseldArtikel && onRemove && (
                    <MDBBtn type="button" color="link"
                            className="px-2 py-1"
                            onClick={() => onRemove?.()}>

                        <Icon path={mdiTrashCanOutline}
                              size={1}/>
                    </MDBBtn>
                )}
                {(verwijderd || toegevoegd || verwisseldArtikel) && onUndo && (
                    <MDBBtn type="button" color="link"
                            className="px-2 py-1"
                            onClick={() => onUndo?.()}>

                        <Icon path={mdiUndo}
                              size={1}/>
                    </MDBBtn>
                )}
            </td>
        </tr>
    );
};
