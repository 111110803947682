import React from "react";
import {MDBModal, MDBModalContent, MDBModalDialog} from "mdb-react-ui-kit";

export interface ModalProps {
    modalOptions?: typeof MDBModal["defaultProps"];
    modalDialogOptions?: typeof MDBModalDialog["defaultProps"];
    children?: React.ReactNode;

    onClose?(): void;
}

export const FrameModal: React.FC<ModalProps> = (props) => {
    const {modalOptions, modalDialogOptions, onClose} = props;

    return (
        <MDBModal {...modalOptions} animationDirection="bottom" open onClose={onClose} staticBackdrop>
            <MDBModalDialog {...modalDialogOptions} position="bottom" frame scrollable>
                <MDBModalContent>
                    {props.children}
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};
