import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback} from "react";
import {bezoeksessieSaga} from "./saga";
import {bezoeksessies} from "./slice";
import {AdresAanwezigheid, BezoekSessie, BezoekSessieToestel} from "../../../workers/shared/snapshot/bezoekSessieState";
import {useMe} from "../technicus/hooks";
import {UitTeVoerenWerk} from "../../../_generated/field-service-be-openapi";

export const useStartBezoekSessieEnOnderweg = () => {
    const dispatch = useAppDispatch();

    return useCallback((datum: string, serviceAdresId: string, bezoekId: string, bezoekSessieId: string, uitTeVoerenWerk: UitTeVoerenWerk, planningVersie: number, planningVersieTijdstip: string, callback?: (error?: Error, bezoekSessieId?: string) => void) => {
        dispatch(bezoeksessieSaga.actions.startBezoeksessieEnOnderweg({
            datum,
            serviceAdresId,
            bezoekId,
            bezoekSessieId,
            uitTeVoerenWerk,
            planningVersie,
            planningVersieTijdstip,
            callback
        }));
    }, [dispatch]);
};

export const useStartBezoekSessie = () => {
    const dispatch = useAppDispatch();

    return useCallback((datum: string, serviceAdresId: string, bezoekId: string, bezoekSessieId: string, uitTeVoerenWerk: UitTeVoerenWerk, planningVersie: number, planningVersieTijdstip: string, callback?: (error?: Error, bezoekSessieId?: string) => void) => {
        dispatch(bezoeksessieSaga.actions.startBezoeksessie({
            datum,
            serviceAdresId,
            bezoekId,
            bezoekSessieId,
            uitTeVoerenWerk,
            planningVersie,
            planningVersieTijdstip,
            callback
        }));
    }, [dispatch]);
};

export const useBeeindigBezoekSessie = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string) => {
        dispatch(bezoeksessieSaga.actions.beeindigBezoeksessie({bezoekSessieId}));
    }, [dispatch]);
};

export const useBezoekSessieSnapshot = () => {
    const dispatch = useAppDispatch();

    return useCallback((snapshot: BezoekSessie) => {
        dispatch(bezoeksessies.actions.snapshot({snapshot}));
    }, [dispatch]);
};

export const useBezoekSessies = () => {
    return useAppSelector((state) => state.bezoeksessies.bezoekSessies);
};

export const useBezoekSessieById = (bezoekSessieId?: string) => {
    return useAppSelector((state) => {
        if (bezoekSessieId) {
            const bezoekSessie = state.bezoeksessies.bezoekSessies[bezoekSessieId];

            if (bezoekSessie) {
                return new BezoekSessie({
                    ...bezoekSessie,
                    toestellen: Object.entries(bezoekSessie.toestellen || {})
                        .reduce((prev, [key, toestel]) => ({...prev, [key]: new BezoekSessieToestel(toestel)}), {})
                });
            }
        }

        return null;
    });
};

export const useBezoekSessie = (bezoekId?: string) => {
    return useAppSelector((state) => {
        if (!bezoekId) {
            return null;
        }

        const bezoekSessieId = state.bezoeksessies.bezoekIdMap[bezoekId];

        if (bezoekSessieId) {
            return state.bezoeksessies.bezoekSessies[bezoekSessieId];
        }

        return null;
    });
};

export const useHuidigeBezoekSessie = () => {
    const technicus = useMe();

    return useAppSelector((state) => {
        if (!technicus) {
            return undefined;
        }

        for (const bezoekSessie of Object.values(state.bezoeksessies.bezoekSessies)) {
            if ([AdresAanwezigheid.AANWEZIG, AdresAanwezigheid.OBSERVATOR].includes(bezoekSessie.adresAanwezigheid[technicus.id])) {
                return bezoekSessie;
            }
        }
    });
};

export const useBezoekSessieToestel = (bezoekSessieId?: string, toestelId?: string) => {
    return useAppSelector((state) => {
        if (!bezoekSessieId || !toestelId) {
            return null;
        }

        const toestel = state.bezoeksessies.bezoekSessies[bezoekSessieId]?.toestellen?.[toestelId] || null;
        if (toestel) {
            return new BezoekSessieToestel(toestel);
        }

        return null;
    });
};

export const useBezoekSessieError = () => {
    return useAppSelector((state) => state.bezoeksessies.error);
};

export const useVoegTechnicusToe = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string, technicusId: string) => {
        dispatch(bezoeksessieSaga.actions.voegTechnicusToe({bezoekSessieId, technicusId}));
    }, [dispatch]);
};

export const useHerlaadBezoekUitTeVoerenWerk = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string) => {
        dispatch(bezoeksessieSaga.actions.herlaadBezoekUitTeVoerenWerk({bezoekSessieId}));
    }, [dispatch]);
};

export const useSluitBezoekSessie = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string) => {
        dispatch(bezoeksessieSaga.actions.sluitBezoekSessie({bezoekSessieId}));
    }, [dispatch]);
};

export const useDeviceInfoGelogd = () => {
    const dispatch = useAppDispatch();

    return useCallback((bezoekSessieId: string) => {
        dispatch(bezoeksessieSaga.actions.deviceInfoGelogd({
            bezoekSessieId
        }));
    }, [dispatch]);
};

export const useHasSnapshotEverBeenLoaded = () => {
    return useAppSelector((state) => state.bezoeksessies.loaded);
};

export const useBezoekSessieSetLoaded = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => dispatch(bezoeksessies.actions.setLoaded({})), [dispatch]);
};
