import { RefObject, useCallback, useEffect } from 'react';

type UseAutocompleteClosesProps = {
  dropdownEl: HTMLDivElement | null;
  inputRef: RefObject<HTMLInputElement>;
};

const useAutocompleteResize = ({ inputRef, dropdownEl }: UseAutocompleteClosesProps) => {
  const handleResize = useCallback(() => {
    if (!inputRef.current || !dropdownEl) return;
    const { width } = window.getComputedStyle(inputRef.current);
    dropdownEl.style.width = width;
  }, [dropdownEl, inputRef]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};

export default useAutocompleteResize;
