import React from "react";
import {MDBCol, MDBRow} from "mdb-react-ui-kit";
import {ToestelInGebruikServiceLogEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {useTechnici} from "../../../redux/slices/technicus/hooks";
import {Datum} from "../../Datum";

export interface ToestelInGebruikServiceLogEntryProps {
    entry: ToestelInGebruikServiceLogEntryRestModel;
}

export const ToestelInGebruikServiceLogEntry: React.FC<ToestelInGebruikServiceLogEntryProps> = (props) => {
    const {entry} = props;

    const technici = useTechnici();

    return (
        <>
            <span><Datum format="DD-MM-YYYY HH:mm" datum={entry.tijdstip}/></span>
            <span className="float-end">{entry.medewerkerId && technici[entry.medewerkerId]?.naam}</span>
            <h5>Opmerking</h5>
            <MDBRow>
                <MDBCol>
                    <strong>In gebruik genomen</strong> {entry.opmerking}
                </MDBCol>
            </MDBRow>
        </>
    );
};
