import React, {useCallback, useMemo} from "react";
import {MDBCard, MDBCardHeader, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiOrderAlphabeticalAscending, mdiOrderBoolAscendingVariant} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useServiceAdresLevering} from "../../../redux/slices/serviceAdressen/hooks";
import {useParams} from "react-router-dom";
import {Adres} from "../../../components/Adres";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {Form, Formik} from "formik";
import {useGoBack} from "../../../routes";
import {BezoekSessieAccessoireLevering, BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {BezoekMDBFormikInput} from "../../../mdb-formik/bezoek/BezoekMDBFormikInput";
import {
    AccessoireLeveringUitgevoerdAccessoireLevering,
    AccessoireLeveringUitgevoerdGeleverdArtikel
} from "../../../_generated/field-service-be-openapi";
import {Datum} from "../../../components/Datum";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {useHideModal, useShowModal} from "../../../redux/slices/modal/hooks";
import {
    AccessoireLeveringAfwijkendFormValues,
    AccessoireLeveringAfwijkendModalProps
} from "../../../components/modals/AccessoireLeveringAfwijkendModal";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";
import * as Yup from "yup";
import {OnbewaardeGegevensMelding} from "../../../components/OnbewaardeGegevensMelding";
import {SaveDraftFormik} from "../../../components/SaveDraftFormik";

export interface AccessoireLeveringFormOrder {
    geleverd: Record<string, number | undefined>;
}

export interface AccessoireLeveringFormValues {
    orders: Record<string, AccessoireLeveringFormOrder>;
}

export interface AccessoireLeveringPageProps {
    bezoekSessieId: string;
}

export const AccessoireLeveringPage: React.FC = () => {
    const {bezoekSessieId} = useParams<AccessoireLeveringPageProps>();

    const showModal = useShowModal();
    const hideModal = useHideModal();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const voerLeveringUit = useServiceAdresLevering();

    const leveringen = Object.values(bezoekSessie?.accessoireLeveringen || {});

    const goBack = useGoBack();

    const {t} = useTranslation("serviceadres");

    const initialValues = useMemo<AccessoireLeveringFormValues>(() => {
        const orders: Record<string, AccessoireLeveringFormOrder> = {};

        for (const levering of leveringen) {
            orders[levering.id] = {
                geleverd: {}
            };

            for (const artikel of levering.artikelen) {
                orders[levering.id].geleverd[artikel.artikelId] = artikel.geleverdAantal;
            }
        }

        return {
            orders
        };
    }, [leveringen]);

    const onSubmit = useCallback((values: AccessoireLeveringFormValues) => {
        if (!bezoekSessie) {
            return;
        }

        const accessoireLeveringen: AccessoireLeveringUitgevoerdAccessoireLevering[] = [];

        const afwijkendeAccessoireLeveringen = leveringen.some(levering => {
            return levering.artikelen.some(artikel => {
                const geleverdAantal = values.orders?.[levering.id]?.geleverd[artikel.artikelId] || 0;

                return geleverdAantal < (artikel.gevraagdAantal || 0);
            });
        });

        const onConfirm = (afwijkValues?: AccessoireLeveringAfwijkendFormValues) => {
            for (const levering of leveringen) {
                const entry = Object.entries(values.orders).find(([accessoireLeveringId]) => accessoireLeveringId === levering.id);
                if (!entry) {
                    continue;
                }
                const [accessoireLeveringId, order] = entry;

                const geleverdeArtikelen: AccessoireLeveringUitgevoerdGeleverdArtikel[] = levering.artikelen
                    .map((artikel) => {
                        const artikelId = artikel.artikelId;
                        const levering = order.geleverd[artikelId];

                        return {
                            artikelId,
                            artikelNr: artikel?.artikelNr || "onbekend",
                            artikelOmschrijving: artikel?.artikelOmschrijving || "onbekend",
                            geleverdAantal: levering ?? 0,
                            onvolledigeLeveringReden: afwijkValues?.accessoireLevering?.[accessoireLeveringId]?.[artikelId]?.reden
                        };
                    });

                accessoireLeveringen.push({
                    accessoireLeveringId,
                    geleverdeArtikelen
                });
            }

            voerLeveringUit({
                bezoekSessieId: bezoekSessie.id,
                accessoireLeveringen
            });

            goBack();
        };

        if (afwijkendeAccessoireLeveringen) {
            showModal({
                type: FieldServiceModal.ACCESSOIRE_LEVERING_AFWIJKEND_MODAL,
                props: {
                    co2Artikelen: serviceAdres?.co2Artikelen,
                    ingegevenLeveringen: values.orders,
                    uitTeVoerenAccessoireLeveringen: leveringen,
                    bestellingTerPlaatseToegestaan: bezoekSessie?.uitTeVoerenWerk?.co2BestellingTerPlaatseToegestaan,

                    onConfirm: (afwijkValues) => onConfirm(afwijkValues),
                    onClose: () => hideModal()
                } as AccessoireLeveringAfwijkendModalProps
            });
        } else {
            onConfirm();
        }


    }, [bezoekSessie, voerLeveringUit, leveringen, goBack, hideModal, serviceAdres?.co2Artikelen, showModal]);

    const renderLeveringen = (leveringen: BezoekSessieAccessoireLevering[]) => {
        return (
            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th>{t("AccessoireLeveringPage.accessoirebestelling", "Accessoirebestelling")}</th>
                        <th>{t("AccessoireLeveringPage.verkooporder", "Verkooporder")}</th>
                        <th>{t("AccessoireLeveringPage.aangemeld-op", "Aangemeld op")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {leveringen.map(levering => (
                        <tr key={levering.id} className={`${levering.verwijderd ? "note-warning" : ""}`}>
                            <td>
                                <div>
                                    {levering.oorsprong?.bestellingNummer ||
                                        t("AccessoireLeveringPage.geen-bestellingnummer-bekend",
                                            "geen bestellingnummer bekend")}
                                </div>

                                {levering.verwijderd && levering.uitgevoerd && (
                                    <small className="text-muted">
                                        {t("AccessoireLeveringPage.deze-bestelling-is-verwijderd-van-de-planning-maar-werd-wel-al-deels-geleverd",
                                            "Deze bestelling is verwijderd van de planning, maar werd wel al (deels) geleverd.")}
                                    </small>
                                )}
                            </td>
                            <td>{levering.oorsprong?.verkoopOrderNr}</td>
                            <td><Datum format="DD/MM/YYYY HH:mm" datum={levering.creatieTijdstip}/></td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        );
    };

    const renderLeveringForm = (levering: BezoekSessieAccessoireLevering) => {
        const naam = levering.oorsprong?.bestellingNummer || levering.oorsprong?.verkoopOrderNr;

        return (
            <MDBTable responsive key={levering.id}>
                <MDBTableHead>
                    <tr>
                        <th>{t("AccessoireLeveringPage.levering-van",
                            "Levering van {{naam}}",
                            {naam: naam})}</th>
                        <th className="text-nowrap">
                            {t("AccessoireLeveringPage.aantal-te-leveren",
                                "Aantal te leveren")}</th>
                        <th className="text-nowrap">
                            {t("AccessoireLeveringPage.aantal-geleverd",
                                "Aantal geleverd")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {levering.artikelen.map((item) => {
                        const verwijderdOfGewijzigd = levering.verwijderd || item.vorigGevraagdAantal !== undefined;

                        return (
                            <tr key={item.artikelId} className={`${verwijderdOfGewijzigd ? "note-warning" : ""}`}>
                                <td className="w-100">
                                    <div>
                                        <strong>{item.artikelNr}</strong> {item.artikelOmschrijving}
                                    </div>

                                    {levering.verwijderd && levering.uitgevoerd && (
                                        <small
                                            className="text-muted">{t("AccessoireLeveringPage.dit-artikel-is-verwijderd-van-de-originele-planning-maar-werd-wel-geleverd",
                                            "Dit artikel is verwijderd van de originele planning, maar werd wel geleverd.")}</small>
                                    )}

                                    {item.vorigGevraagdAantal !== undefined && (
                                        <small
                                            className="text-muted">{t("AccessoireLeveringPage.het-aantal-is-gewijzigd-bij-het-herladen-van-de-planning",
                                            "Het aantal is gewijzigd bij het herladen van de planning. Het vorige aantal was {aantal}.",
                                            {aantal: item.vorigGevraagdAantal})}</small>
                                    )}
                                </td>
                                <td>{item.gevraagdAantal}</td>
                                <td>
                                    <BezoekMDBFormikInput type="number"
                                                          name={`orders.${levering.id}.geleverd.${item.artikelId}`}
                                                          step="1"
                                                          placeholder={String(item.gevraagdAantal ?? 0)}
                                                          disabled={levering.verwijderd}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </MDBTableBody>
            </MDBTable>
        );
    };

    const validationSchema = useMemo(() => {
        const orderShape: any = {};
        for (let levering of leveringen) {
            const leveringShape: any = {};

            for (let artikel of levering.artikelen) {
                leveringShape[artikel.artikelId] = Yup.number().required(t("algemeen:Foutmeldingen.aantal-is-verplicht", "Aantal is verplicht") as string).min(0, t("algemeen:Foutmeldingen.aantal-is-verplicht", "Aantal is verplicht") as string);
            }

            orderShape[levering.id] = Yup.object({
                geleverd: Yup.object().shape(leveringShape)
            });
        }

        const schema = Yup.object({
            orders: Yup.object().shape(orderShape)
        });

        return schema;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GenericPage>
            <Header title={t("algemeen:Titels.accessoirelevering", "Accessoirelevering")}
                    subtitle={<><strong>{serviceAdres?.naam}</strong> <Adres adres={serviceAdres}/></>}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <Formik<AccessoireLeveringFormValues> initialValues={initialValues} validationSchema={validationSchema}
                                                      onSubmit={onSubmit}>
                    {
                        (formik) => {
                            return (
                                <Form autoComplete="off">
                                    <OnbewaardeGegevensMelding
                                        draftValues={bezoekSessie?.accessoireLeveringenDraftValues}/>
                                    <SaveDraftFormik formId={`accessoireleveringen/${bezoekSessieId}`}
                                                     bezoekSessieId={bezoekSessieId}/>

                                    <DisableAutocompletePlaceholderInput/>
                                    <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                                        <MDBCardHeader><Icon path={mdiOrderAlphabeticalAscending}
                                                             size={1}/> {t("algemeen:besteld", "Besteld")}
                                        </MDBCardHeader>

                                        {renderLeveringen(leveringen)}
                                    </MDBCard>

                                    <h5><Icon path={mdiOrderBoolAscendingVariant}
                                              size={1}/> {t("algemeen:formulier", "Formulier")}</h5>
                                    <hr/>
                                    <MDBRow>
                                        <MDBCol size="12">
                                            {leveringen.map(levering => renderLeveringForm(levering))}
                                        </MDBCol>

                                        <MDBCol className="mt-3 mb-3">
                                            <BezoekMDBBtn block size="lg"
                                                          type="submit">{t("algemeen:bewaren", "Bewaren")}</BezoekMDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </Form>
                            );
                        }
                    }
                </Formik>
            </ContentContainer>
        </GenericPage>
    );
};
