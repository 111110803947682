import React, {ReactNode, useState} from "react";
import ImageLightbox from "react-image-lightbox";

export interface LightboxImageItem {
    url: string;
    title?: ReactNode;
    caption?: ReactNode;
}

export interface LightboxProps {
    open?: boolean;
    images: LightboxImageItem[];

    onClose?(): void;
}

export const Lightbox: React.FC<LightboxProps> = (props) => {
    const {images, open, onClose} = props;

    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const currentPhoto = images[currentPhotoIndex];

    if (!open) {
        return null;
    }

    return (
        <ImageLightbox mainSrc={currentPhoto.url}
                       nextSrc={images[currentPhotoIndex + 1]?.url}
                       prevSrc={images[currentPhotoIndex - 1]?.url}
                       imageTitle={currentPhoto.title}
                       imageCaption={currentPhoto.caption}

                       onCloseRequest={() => onClose?.()}

                       onMovePrevRequest={() => setCurrentPhotoIndex((currentPhotoIndex + images.length - 1) % images.length)}
                       onMoveNextRequest={() => setCurrentPhotoIndex((currentPhotoIndex + 1) % images.length)}
        />
    );
};
