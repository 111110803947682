import React, {useEffect, useState} from "react";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import {useServiceWorker, useSetServiceWorker} from "../slices/status/hooks";
import {MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader} from "mdb-react-ui-kit";
import {motion} from "framer-motion";
import {Message} from "../../workers/shared/channels/simpleRpcChannel";
import {browser2ServiceWorkerChannel} from "../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {MessageType} from "../../workers/shared/channels/messageTypes";
import {Logger} from "../../utilities/logger";
import {LoadingPage} from "../../pages/LoadingPage";
import {useTranslation} from "../../utilities/i18nUtils";

const logger = Logger.create("ServiceWorkerProvider");

export const ServiceWorkerProvider: React.FC<{children?: React.ReactNode}> = (props) => {
    const [showRetryButton, setShowRetryButton] = useState(false);

    const {t} = useTranslation("algemeen");

    useEffect(() => {
        const handle = setTimeout(() => setShowRetryButton(true), 6_000);

        return () => {
            clearTimeout(handle);
        };
    }, []);

    const onReloadClick = () => {
        window.location.reload();
    };

    const [show, setShow] = useState(false);

    const serviceWorker = useServiceWorker();
    const setServiceWorker = useSetServiceWorker();

    useEffect(() => {
        const onUpdate = (registration: ServiceWorkerRegistration) => {
            const newServiceWorker = registration.waiting || registration.active;

            if (newServiceWorker) {
                newServiceWorker.addEventListener("statechange", event => {
                    if ((event.target as any)?.["state"] === "activated") {
                        window.location.reload();
                    }
                });

                setServiceWorker({
                    version: serviceWorker?.version!,
                    newServiceWorker,
                    updateAvailable: true
                });
            }
        };

        const onSuccess = (registration: ServiceWorkerRegistration) => {
            const newServiceWorker = registration.waiting || registration.active;

            if (newServiceWorker) {
                setServiceWorker({newServiceWorker});

                browser2ServiceWorkerChannel.sendRpc(new Message({type: MessageType.GET_VERSION}))
                    .then(({version}) => {
                        setServiceWorker({version})
                    });
            }
        };

        logger.info("Registering service worker...");
        serviceWorkerRegistration.register({onUpdate, onSuccess})
            .then((registration) => {
                logger.info("Service worker registered");

                if (registration) {
                    onSuccess(registration);
                }
            })
            .catch((error) => {
                logger.error("An error occurred registering the service worker", error);
            });
    }, [setServiceWorker, serviceWorker?.version]);

    useEffect(() => {
        if (serviceWorker?.updateAvailable) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [serviceWorker?.updateAvailable]);

    const onBijwerkenClick = () => {
        setShow(false);
        serviceWorker?.newServiceWorker?.postMessage({type: MessageType.SKIP_WAITING});
    };

    return (
        <>
            <MDBModal open={show} closeOnEsc={false} staticBackdrop>
                <MDBModalDialog position="bottom" frame className="shadow">
                    <motion.div initial={{y: "5%", opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 0.2}}>
                        <MDBModalContent className="border-bottom-left-radius-0 border-bottom-right-radius-0">
                            <MDBModalHeader>{t("Serviceworker.update-beschikbaar",
                                "Update beschikbaar")}</MDBModalHeader>
                            <MDBModalBody className="py-1">
                                <p className="mb-0">
                                    {t("Serviceworker.nieuwe-versie-beschikbaar",
                                        "Er is een nieuwe versie beschikbaar. Wenst u nu bij te werken?")}
                                </p>

                                <div className="d-flex justify-content-end align-items-center my-3">
                                    <MDBBtn type="button" color="success" size="lg" className="ms-2" onClick={onBijwerkenClick}>
                                        {t("Serviceworker.bijwerken",
                                            "Bijwerken")}
                                    </MDBBtn>
                                </div>
                            </MDBModalBody>
                        </MDBModalContent>
                    </motion.div>
                </MDBModalDialog>
            </MDBModal>

            {serviceWorker && props.children}
            {!serviceWorker && (
                <LoadingPage descriptionClassName="" description={
                    <>
                        <div className="display-6">
                            {t("Serviceworker.opstarten", "Opstarten...")}<br/>
                            <small className="text-muted">{t("Serviceworker.serviceworker-registreren",
                                "Serviceworker registreren")}
                            </small>
                        </div>

                        {showRetryButton && (
                            <div className="mt-4">
                                <div className="mb-3">
                                    <div>
                                        {t("Serviceworker.het-lijkt-er-op-dat-het-opstarten-lang-duurt",
                                            "Het lijkt erop dat het opstarten lang duurt.")}
                                    </div>

                                    <div>
                                        <small className="text-muted">
                                            {t("Serviceworker.het-herladen-van-de-applicatie-kan-helpen",
                                                "Het herladen van de applicatie kan helpen. Druk daarvoor op de onderstaande knop.")}
                                        </small>
                                    </div>
                                </div>

                                <MDBBtn color="secondary" outline onClick={onReloadClick}>{t("Serviceworker.herlaad-de-applicatie",
                                    "Herlaad de applicatie")}
                                </MDBBtn>
                            </div>
                        )}
                    </>
                }/>
            )}
        </>
    );
};
