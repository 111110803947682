import React from "react";
import {MDBRadio} from "mdb-react-ui-kit";
import {Field, FieldProps} from "formik";
import {SaveDraftUtils} from "../utilities/form/saveDraftUtils";

export type MDBFormikRadioProps = typeof MDBRadio["defaultProps"] & Partial<FieldProps>;

/**
 * Inspired by https://github.com/jannikbuschke/formik-antd/blob/master/src/input/index.tsx
 * @param props
 * @constructor
 */
export const MDBFormikRadio: React.FC<MDBFormikRadioProps> = (props) => {
    const {name, ...rest} = props;

    return (
        <Field name={name}>
            {({field: {value, onChange, onBlur}, form}: FieldProps) => (
                <MDBRadio {...rest} checked={value === props.value} name={name} onChange={onChange} onBlur={(e: any) => SaveDraftUtils.onBlur(name!, form, () => onBlur(e))}/>
            )}
        </Field>
    );
};
