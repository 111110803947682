import React from "react";
import {MDBBtn, MDBTooltip} from "../mdb-react-ui-kit";
import {SingleClickButton} from "./SingleClickButton";
import type {ButtonProps} from "../mdb-react-ui-kit/free/components/Button/types";

export interface PopoverWhenDisabledButtonProps extends ButtonProps {
    title: string;

    singleClick?: boolean;

    wrapperClass?: string;
}

export const TooltipWhenDisabledButton: React.FC<PopoverWhenDisabledButtonProps> = (props) => {
    const {title, singleClick, children, disabled, wrapperClass, ...rest} = props;

    const BtnComponent = singleClick ? SingleClickButton : MDBBtn;

    const button = (
        <BtnComponent {...rest} disabled={disabled}>
            {children}
        </BtnComponent>
    );

    if (!disabled) {
        return button;
    }

    return (
        <MDBTooltip tag="div" title={title} wrapperClass={wrapperClass}>
            {button}
        </MDBTooltip>
    );
};
