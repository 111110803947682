import React, {ChangeEvent} from "react";
import {MDBCheckbox} from "mdb-react-ui-kit";
import {Field, FieldProps} from "formik";
import { SaveDraftUtils } from "utilities/form/saveDraftUtils";

export type MDBFormikCheckboxProps = typeof MDBCheckbox["defaultProps"] & Partial<FieldProps>;

/**
 * Inspired by https://github.com/jannikbuschke/formik-antd/blob/master/src/input/index.tsx
 * @param props
 * @constructor
 */
export const MDBFormikCheckbox: React.FC<MDBFormikCheckboxProps> = (props) => {
    const {name, ...rest} = props;

    return (
        <Field name={name}>
            {({field: {value, onChange, onBlur}, form}: FieldProps) => {
                const onCheckedChange = (event: ChangeEvent<HTMLInputElement>) => {
                      onChange({
                          target: {
                              name,
                              value: event.target.checked
                          }
                      });
                };

                return (
                    <MDBCheckbox {...rest} name={name} checked={value} onChange={onCheckedChange} onBlur={(e: any) => SaveDraftUtils.onBlur(name!, form, () => onBlur(e))}/>
                );
            }}
        </Field>
    );
};
