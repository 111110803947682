import React from "react";
import {InterventieTaakTypeBadge} from "./InterventieTaakTypeBadge";
import {ToestelInterventieTaakTypeEnum} from "../../../_generated/field-service-be-openapi";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface InterventieOmschrijvingProps {
    taakType?: ToestelInterventieTaakTypeEnum;
    omschrijving?: string;
}

export const InterventieOmschrijving: React.FC<InterventieOmschrijvingProps> = (props) => {
    const {taakType, omschrijving} = props;

    const {t} = useTranslation("serviceadres")

    return (
        <div>
            <span className="me-2">
                <InterventieTaakTypeBadge type={taakType}/>
            </span>
            {omschrijving || <span className="text-muted">{t("InterventieOmschrijving.geen-omschrijving", "Geen omschrijving")}</span>}
        </div>
    );
};
