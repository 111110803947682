import React from "react";
import {MDBCol, MDBRow} from "mdb-react-ui-kit";
import {ToestelWatermeterStandServiceLogEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {useTechnici} from "../../../redux/slices/technicus/hooks";
import {Datum} from "../../Datum";

export interface ToestelWatermeterStandServiceLogEntryProps {
    entry: ToestelWatermeterStandServiceLogEntryRestModel;
}

export const ToestelWatermeterStandServiceLogEntry: React.FC<ToestelWatermeterStandServiceLogEntryProps> = (props) => {
    const {entry} = props;

    const technici = useTechnici();

    return (
        <>
            <span><Datum format="DD-MM-YYYY HH:mm" datum={entry.tijdstip}/></span>
            <span className="float-end">{entry.medewerkerId && technici[entry.medewerkerId]?.naam}</span>
            <h5>Watermeterstand</h5>
            <MDBRow>
                <MDBCol md="6">
                    <strong>Soort</strong> {entry.meterstand}
                </MDBCol>
                {entry.opmerking && (
                    <MDBCol size="12">
                        <strong>Opmerking</strong> {entry.opmerking}
                    </MDBCol>
                )}
            </MDBRow>
        </>
    );
};
