import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback} from "react";
import {ui} from "./slice";
import {EventPayload} from "../../types";
import {UiFormDraft} from "../../../_generated/field-service-be-openapi";
import {uiSaga} from "./saga";

export const useSidenavOpen = () => useAppSelector((state) => Object.freeze(state.ui.sidenavOpen));

export const useShowSidenav = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => dispatch(ui.actions.showSidenav()), [dispatch]);
};

export const useHideSidenav = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => dispatch(ui.actions.hideSidenav()), [dispatch]);
};

export const useIsHydrated = () => useAppSelector((state) => state.ui.hydrated);

export const useSetHydrated = () => {
    const dispatch = useAppDispatch();

    return useCallback((hydrated: boolean) => dispatch(ui.actions.setHydrated(hydrated)), [dispatch]);
};

export const useUiFormDraft = () => {
    const dispatch = useAppDispatch();

    return useCallback((payload: EventPayload<UiFormDraft>) => {
        dispatch(uiSaga.actions.uiFormDraft(payload));
    }, [dispatch]);
};
