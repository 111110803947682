import { Dispatch, RefObject, SetStateAction, useCallback, useEffect } from 'react';

type UseAutocompleteClosesProps = {
  isOpened: boolean;
  dropdownEl: HTMLDivElement | null;
  inputRef: RefObject<HTMLInputElement>;
  setOpenState: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
};

const useAutocompleteClose = ({
  isOpened,
  inputRef,
  dropdownEl,
  setOpenState,
  onClose,
}: UseAutocompleteClosesProps) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!dropdownEl) {
        return;
      }

      const isInput = inputRef.current === e.target;
      const isDropdown = dropdownEl === e.target;
      const isDropdownContent = dropdownEl.contains(e.target as Node);

      if (isOpened && !isInput && !isDropdown && !isDropdownContent) {
        setOpenState(false);
        onClose?.();
      }
    },
    [isOpened, setOpenState, dropdownEl, inputRef, onClose]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useAutocompleteClose;
