import React from "react";
import {MDBCol, MDBRow, MDBTable, MDBTableBody} from "../../mdb-react-ui-kit/index";
import {ArtikelNrOmschrijving} from "../ArtikelNrOmschrijving";
import {Datum} from "../Datum";
import {Toestel} from "../../_generated/field-service-be-openapi";
import {useTranslation} from "react-i18next";

export interface ToestelInformatieRowProps {
    toestel: Toestel;
}

export const ToestelInformatieRow: React.FC<ToestelInformatieRowProps> = (props) => {
    const {toestel} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBRow>
            <MDBCol sm="4" className="mb-2">
                <strong>{t("Labels.toestel", "Toestel:")}{" "}</strong><br/> {toestel.omschrijving}
            </MDBCol>
            <MDBCol sm="4" className="mb-2">
                <strong>{t("Labels.serienummer", "Serienummer:")}{" "}</strong><br/> {toestel.serieNummer}
            </MDBCol>
            <MDBCol sm="4" className="mb-2">
                <strong>{t("Labels.installatiedatum", "Installatiedatum:")}{" "}</strong><br/> {toestel.installatieDatum}
            </MDBCol>
            <MDBCol sm="4" className="mb-2">
                <strong>{t("Labels.type", "Type:")}{" "}</strong><br/> {toestel.transactieType}
            </MDBCol>
            {toestel.artikelNr && (
                <MDBCol sm="4" className="mb-2">
                    <strong>{t("algemeen:Labels.artikel", "Artikel:")}</strong><br/> <ArtikelNrOmschrijving
                    nr={toestel.artikelNr}
                    omschrijving={toestel.artikelOmschrijving}/>
                </MDBCol>
            )}

            {!!toestel.waterTeller?.recenteOpnames?.length && (
                <MDBCol size="12">
                    <strong>{t("Labels.waterstand", "Waterstand:")}{" "}</strong><br/>

                    <div className="d-block w-100" style={{maxHeight: "10rem", overflowY: "auto"}}>
                        <MDBTable responsive>
                            <MDBTableBody>
                                {toestel.waterTeller?.recenteOpnames?.map((opname, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            {opname.opname}
                                        </td>
                                        <td className="align-middle"><Datum format="DD-MM-YYYY"
                                                                            datum={opname.opnameDatum}/>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </MDBCol>
            )}
        </MDBRow>
    );
};
