import React, {useState} from "react";
import {MDBCard, MDBContainer} from "mdb-react-ui-kit";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import {useFetchShiften} from "../redux/slices/shiften/hooks";
import {useMe} from "../redux/slices/technicus/hooks";
import Kalender from "../components/Kalender";
import {Event, EventProps, stringOrDate, View} from 'react-big-calendar'
import {Shift} from "../_generated/field-service-be-openapi";
import dayjs from "dayjs";
import {useTranslation} from "../utilities/i18nUtils";

const convertToEvents = (shiften: Array<Shift> | undefined): Array<Event> => {
    return shiften ? shiften.flatMap(shift => [...convertShiftToEvent(shift)]) : [];
}

const convertShiftToEvent = (shift: Shift): Event[] => {
    const event: Event = {
        title: `${shift.vertrekAdres} - ${shift.eindAdres}`,
        start: dayjs(`${shift.datum}T${shift.beginuur}`).toDate(),
        end: dayjs(`${shift.datum}T${shift.einduur}`).toDate(),
        resource: shift
    };

    return [event];
};

const ShiftEvent: React.FC<EventProps> = (props) => {
    const {event} = props;

    const shift = event.resource as Shift;

    return (
        <div className="d-flex flex-column justify-content-between h-100" style={{fontSize: "0.9rem"}}>
            <div>
                <div>{shift.vertrekAdres}</div>
                <div>{shift.vertrekAdres2}</div>
                <small>{shift.vertrekPostcode} {shift.vertrekPlaats}</small>
            </div>
            <div>
                <div>{shift.eindAdres}</div>
                <div>{shift.eindAdres2}</div>
                <small>{shift.eindPostcode} {shift.eindPlaats}</small>
            </div>
        </div>
    );
};

export const ShiftenPage: React.FC = () => {

    const {t} = useTranslation("algemeen");

    const [van, setVan] = useState(dayjs().startOf("week").toDate());
    const [tot, setTot] = useState(dayjs().endOf("week").toDate());

    const technicus = useMe();

    const {shiften, loading} = useFetchShiften({
        technicusId: technicus?.id!,
        van: van as any,
        tot: tot as any
    }, !technicus);

    const setRange = (range: Date[] | { start: stringOrDate; end: stringOrDate }, view: View | undefined) => {

        if (Array.isArray(range)) {
            const van = dayjs(range[0]).utc(true).toDate();
            const tot = dayjs(range[range.length - 1]).utc(true).toDate();

            setVan(van);
            setTot(tot);
        } else {
            setVan(new Date(range.start));
            setTot(new Date(range.end));
        }

    }

    const kalenderEvents = convertToEvents(shiften);

    const visibleTime = new Date();
    visibleTime.setHours(6);

    const components = {
        event: ShiftEvent
    };

    return (
        <GenericPage>
            <Header title={t("algemeen:Titels.shiften","Shiften")} sticky/>

            <MDBContainer fluid className="pt-4 flex-fill bg-white">

                <MDBCard background="light" className="shadow-sm mb-4">
                    <div style={{height: "1200px"}}>
                        <Kalender
                            loading={loading}
                            events={kalenderEvents}
                            defaultView="week"
                            views={['week', 'month']}
                            onRangeChange={setRange}
                            scrollToTime={visibleTime}
                            components={components}
                        />
                    </div>
                </MDBCard>

            </MDBContainer>
        </GenericPage>
    );
};



