export enum FieldServiceModal {
    ADRES_AFWERKEN_MODAL = "ADRES_AFWERKEN_MODAL",
    ADRES_BELEMMERD_MODAL = "ADRES_BELEMMERD_MODAL",
    WISSEL_STUK_MODAL = "WISSEL_STUK_MODAL",
    STUK_TOEVOEGEN_MODAL = "STUK_TOEVOEGEN_MODAL",
    CONFIRM_MODAL = "CONFIRM_MODAL",
    TOESTELINFORMATIE_AANPASSEN_MODAL = "TOESTELINFORMATIE_AANPASSEN_MODAL",
    REEDS_AANWEZIG_OP_SERVICEADRES_MODAL = "REEDS_AANWEZIG_OP_SERVICEADRES_MODAL",
    TECHNICUS_TOEVOEGEN_MODAL = "TECHNICUS_TOEVOEGEN_MODAL",
    ONDERWEG_MODAL = "ONDERWEG_MODAL",
    CO2_LEVERING_AFWIJKEND_MODAL = "CO2_LEVERING_AFWIJKEND_MODAL",
    ACCESSOIRE_LEVERING_AFWIJKEND_MODAL = "ACCESSOIRE_LEVERING_AFWIJKEND_MODAL",

    TOESTEL_STUKLIJST_MODAL = "TOESTEL_STUKLIJST_MODAL",
    TOESTEL_SERVICELOG_MODAL = "TOESTEL_SERVICELOG_MODAL",
    TOESTEL_BIJLAGEN_MODAL = "TOESTEL_BIJLAGEN_MODAL",

    ERROR_MODAL = "ERROR_MODAL"
}
