/* tslint:disable */
/* eslint-disable */
/**
 * Field Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Toestel,
} from '../models';

export interface DownloadServiceAdresBijlageRequest {
    toestelId: string;
    bijlageId: string;
    download?: boolean;
}

export interface GetToestelByIdRequest {
    toestelId: string;
}

export interface ToestelAfbeeldingRequest {
    toestelId: string;
}

/**
 * 
 */
export class ToestelApi extends runtime.BaseAPI {

    /**
     */
    async downloadServiceAdresBijlageRaw(requestParameters: DownloadServiceAdresBijlageRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.toestelId === null || requestParameters.toestelId === undefined) {
            throw new runtime.RequiredError('toestelId','Required parameter requestParameters.toestelId was null or undefined when calling downloadServiceAdresBijlage.');
        }

        if (requestParameters.bijlageId === null || requestParameters.bijlageId === undefined) {
            throw new runtime.RequiredError('bijlageId','Required parameter requestParameters.bijlageId was null or undefined when calling downloadServiceAdresBijlage.');
        }

        const queryParameters: any = {};

        if (requestParameters.download !== undefined) {
            queryParameters['download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/toestel/{toestelId}/bijlage/{bijlageId}`.replace(`{${"toestelId"}}`, encodeURIComponent(String(requestParameters.toestelId))).replace(`{${"bijlageId"}}`, encodeURIComponent(String(requestParameters.bijlageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadServiceAdresBijlage(requestParameters: DownloadServiceAdresBijlageRequest): Promise<Blob> {
        const response = await this.downloadServiceAdresBijlageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getToestelByIdRaw(requestParameters: GetToestelByIdRequest): Promise<runtime.ApiResponse<Toestel>> {
        if (requestParameters.toestelId === null || requestParameters.toestelId === undefined) {
            throw new runtime.RequiredError('toestelId','Required parameter requestParameters.toestelId was null or undefined when calling getToestelById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/toestel/{toestelId}`.replace(`{${"toestelId"}}`, encodeURIComponent(String(requestParameters.toestelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getToestelById(requestParameters: GetToestelByIdRequest): Promise<Toestel> {
        const response = await this.getToestelByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async toestelAfbeeldingRaw(requestParameters: ToestelAfbeeldingRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.toestelId === null || requestParameters.toestelId === undefined) {
            throw new runtime.RequiredError('toestelId','Required parameter requestParameters.toestelId was null or undefined when calling toestelAfbeelding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/toestel/{toestelId}/afbeelding`.replace(`{${"toestelId"}}`, encodeURIComponent(String(requestParameters.toestelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async toestelAfbeelding(requestParameters: ToestelAfbeeldingRequest): Promise<Blob> {
        const response = await this.toestelAfbeeldingRaw(requestParameters);
        return await response.value();
    }

}
