import React, { useContext } from 'react';
import { DatepickerContext } from '../utils/DatepickerContext';
import { DatepickerHeaderProps } from './types';

const DatepickerHeader: React.FC<DatepickerHeaderProps> = ({ title, customHeader }) => {
  const { weekdaysShort, monthsShort, selectedDate, isInDatetimepicker, onDatetimepickerModeSwitch } =
    useContext(DatepickerContext);
  const date = selectedDate ? selectedDate : new Date();

  return (
    <div className='datepicker-header'>
      <div className='datepicker-title'>
        <span className='datepicker-title-text'>{title}</span>
      </div>
      <div className='datepicker-date'>
        {!customHeader && (
          <span className='datepicker-date-text'>
            {weekdaysShort[date.getDay()]}, {monthsShort[date.getMonth()]} {date.getDate()}
          </span>
        )}
        {customHeader && customHeader}
      </div>
      {isInDatetimepicker && (
        <div className='buttons-container'>
          <button type='button' className='datepicker-button-toggle'>
            <i className='far fa-calendar datepicker-toggle-icon'></i>
          </button>
          <button type='button' className='timepicker-button-toggle' onClick={onDatetimepickerModeSwitch}>
            <i className='far fa-clock fa-sm timepicker-icon'></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default DatepickerHeader;
