import React from "react";
import {
    MDBBtn,
    MDBContainer,
    MDBModalBody,
    MDBModalHeader,
    MDBModalTitle,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {FrameModal} from "./FrameModal";
import {Toestel} from "../../_generated/field-service-be-openapi";
import {StuklijstComponentRow} from "../toestel/stuklijst/StuklijstComponentRow";

export interface ToestelStuklijstModalProps {
    toestel: Toestel;

    onClose?(): void;
}

export const ToestelStuklijstModal: React.FC<ToestelStuklijstModalProps> = (props) => {
    const {toestel, onClose} = props;

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>
                <MDBModalTitle>Stuklijst</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={onClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBContainer fluid>
                    <MDBTable responsive borderless striped>
                        <MDBTableHead>
                            <tr>
                                <th scope="col">Artikelnr. - Omschrijving</th>
                                <th scope="col">Installatiedatum</th>
                                <th scope="col"/>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {toestel?.componenten?.map((item) => (
                                <StuklijstComponentRow key={item.lijnNr}
                                                       component={item}
                                />
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </MDBModalBody>
        </FrameModal>
    );
};
