import React from "react";
import {MDBTable, MDBTableBody, MDBTableHead} from "../../mdb-react-ui-kit/index";
import {ToestelBijlageDownloadButton} from "../download/ToestelBijlageDownloadButton";
import {Toestel} from "../../_generated/field-service-be-openapi";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ToestelBijlagenTableProps {
    toestel: Toestel;
}

export const ToestelBijlagenTable: React.FC<ToestelBijlagenTableProps> = (props) => {
    const {toestel} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBTable responsive>
            <MDBTableHead>
                <tr>
                    <th>{t("Kolomtitels.titel", "Titel")}</th>
                    <th>{t("Kolomtitels.omschrijving", "Omschrijving")}</th>
                    <th>{t("Kolomtitels.downloaden", "Downloaden")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {!toestel?.bijlagen?.length && (
                    <tr>
                        <td colSpan={3}>{t("Labels.geen-bijlagen-beschikbaar", "Geen bijlagen beschikbaar.")}</td>
                    </tr>
                )}

                {toestel?.bijlagen?.map((bijlage, index) =>
                    <tr key={index}>
                        <td>
                            {bijlage.naam}
                        </td>
                        <td>
                            {bijlage.omschrijving}
                        </td>
                        <td>
                            <ToestelBijlageDownloadButton toestelId={toestel.id} bijlageId={bijlage.id}/>
                        </td>
                    </tr>
                )}
            </MDBTableBody>
        </MDBTable>
    );
};
