import React, {useMemo} from "react";
import {MDBBtn, MDBCol, MDBModalBody, MDBModalHeader, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {
    Co2Levering,
    GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum,
    KlantExtraCo2FlesBeleidEnum,
    ServiceAdresCo2ArtikelRestModel,
    UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum
} from "../../_generated/field-service-be-openapi";
import {Form, Formik} from "formik";
import {FrameModal} from "./FrameModal";
import {BezoekMDBFormikSelect} from "../../mdb-formik/bezoek/BezoekMDBFormikSelect";
import {MDBFormikCheckbox} from "../../mdb-formik/MDBFormikCheckbox";
import {Co2LeveringGeleverdArtikel} from "../../pages/ServiceAdres/Opdracht/Co2LeveringPage";
import {Co2LeveringUtils} from "../../utilities/co2LeveringUtils";
import * as Yup from "yup";
import {AnySchema} from "yup";
import {Co2GebruiksrechtWaarschuwing} from "../co2Levering/Co2GebruiksrechtWaarschuwing";
import {DisableAutocompletePlaceholderInput} from "../DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../utilities/i18nUtils";

export interface Co2LeveringAfwijkendFormValues {
    artikel: Record<string, {
        reden: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum;
    }>;

    toestemmingKlantAfwijkendeBestelling: boolean;
}

export interface Co2LeveringAfwijkendModalProps {
    co2Artikelen: ServiceAdresCo2ArtikelRestModel[];

    leveringen: Record<string, Co2LeveringGeleverdArtikel>;
    bestellingen: Co2Levering[];
    bestellingTerPlaatseToegestaan: UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum;

    extraCo2FlesBeleid?: KlantExtraCo2FlesBeleidEnum;

    onConfirm?(values: Co2LeveringAfwijkendFormValues): void;

    onClose?(): void;
}

export const Co2LeveringAfwijkendModal: React.FC<Co2LeveringAfwijkendModalProps> = (props) => {
    const {co2Artikelen, leveringen, bestellingen, bestellingTerPlaatseToegestaan, extraCo2FlesBeleid, onConfirm, onClose} = props;

    const onSubmit = (values: Co2LeveringAfwijkendFormValues) => {
        onConfirm?.(values);
        onClose?.();
    };

    const gecombineerdeGevraagdeLeveringen = useMemo(() => Co2LeveringUtils.combineerGevraagdeLeveringen(bestellingen || []), [bestellingen]);

    const {
        gebruiksrechtZonderAkkoord,
        totaalAantalGebruiksrechtZonderAkkoord
    } = Co2LeveringUtils.valideer(bestellingen || [], leveringen, co2Artikelen, bestellingTerPlaatseToegestaan);

    const afwijkendeCo2Leveringen = Object.values(gecombineerdeGevraagdeLeveringen).filter(item => leveringen[item.id].geleverdAantal < item.gevraagdAantal + item.gevraagdExtraAantal);

    const validationSchema = useMemo(() => Yup.lazy(obj => {
        const gecombineerdeLeveringen = Co2LeveringUtils.combineerGevraagdeLeveringen(bestellingen || []);

        const artikelShape = Yup.object({
            reden: Yup.string().required("Reden is verplicht")
        });
        const artikel: Record<string, AnySchema> = {};

        for (const key of Object.keys(leveringen)) {
            const isOnvolledig = leveringen[key].geleverdAantal < (gecombineerdeLeveringen[key]?.gevraagdAantalGecombineerd);

            if (isOnvolledig) {
                artikel[key] = artikelShape;
            }
        }

        return Yup.object({
            artikel: Yup.object(artikel),
            toestemmingKlantAfwijkendeBestelling: Yup.boolean()
        });
    }), [leveringen, bestellingen]);

    const initialValues = useMemo(() => {
        const artikel: Co2LeveringAfwijkendFormValues["artikel"] = {}

        const gecombineerdeLeveringen = Co2LeveringUtils.combineerGevraagdeLeveringen(bestellingen || []);

        for (const key of Object.keys(leveringen)) {
            const isOnvolledig = leveringen[key].geleverdAantal < (gecombineerdeLeveringen[key]?.gevraagdAantalGecombineerd);

            if (leveringen[key]?.onvolledigeLeveringReden && isOnvolledig) {
                artikel[key] = {
                    reden: leveringen[key].onvolledigeLeveringReden!
                };
            }
        }

        return {
            artikel,
            toestemmingKlantAfwijkendeBestelling: false
        } as Co2LeveringAfwijkendFormValues;
    }, [leveringen, bestellingen]);

    const {t} = useTranslation("serviceadres");

    const toestemmingKlantAfwijkendeBestelling = t("Co2LeveringAfwijkendModal.ik-kreeg-toestemming-van-de-klant-om-af-te-wijken-van-de-bestelling",
        "Ik kreeg toestemming van de klant om af te wijken van de bestelling");
    const redenLabel = t("CO2LeveringAfwijkendModal.reden", "Reden")

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>{t("CO2LeveringAfwijkendModal.waarom-zijn-er-minder-flessen-geleverd",
                "Waarom zijn er minder flessen geleverd?")}</MDBModalHeader>
            <MDBModalBody className="py-1">
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {({isValid, values}) => (
                        <Form autoComplete="off">
                            <DisableAutocompletePlaceholderInput/>
                            <MDBTable responsive striped borderless>
                                <MDBTableHead>
                                    <tr>
                                        <th>{t("CO2LeveringAfwijkendModal.artikel", "Artikel")}</th>
                                        <th>{t("CO2LeveringAfwijkendModal.gevraagd-aantal", "Gevraagd aantal")}</th>
                                        <th>{t("CO2LeveringAfwijkendModal.geleverd-aantal", "Geleverd aantal")}</th>
                                        <th></th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {afwijkendeCo2Leveringen.map(item => {
                                        const artikel = co2Artikelen.find(artikel => artikel.id === item.id);

                                        return (
                                            <tr key={item.id}>
                                                <td className="text-nowrap">
                                                    <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                                </td>
                                                <td className="text-nowrap">
                                                    {item.gevraagdAantal} + {item.gevraagdExtraAantal} extra
                                                </td>
                                                <td className="text-nowrap">
                                                    {leveringen[item.id]?.geleverdAantal}
                                                </td>
                                                <td className="w-100">
                                                    <div className="mb-4">
                                                        <BezoekMDBFormikSelect
                                                            id={`artikel.${item.id}.reden`}
                                                            name={`artikel.${item.id}.reden`}
                                                            label={redenLabel}
                                                            data={[
                                                                {
                                                                    text: t("co2-bestelling:OnvolledigeLeveringReden.technieker-heeft-te-weinig-flessen-beschikbaar",
                                                                        "Technieker heeft te weinig flessen beschikbaar") as string,
                                                                    value: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.TeWeinigFlessenBeschikbaar
                                                                },
                                                                {
                                                                    text: t("co2-bestelling:OnvolledigeLeveringReden.andere-soort-flessen-geleverd",
                                                                        "Andere soort flessen geleverd") as string,
                                                                    value: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.AndereSoortFlessenGeleverd
                                                                },
                                                                {
                                                                    text: t("co2-bestelling:OnvolledigeLeveringReden.klant-akkoord",
                                                                        "Klant akkoord") as string,
                                                                    value: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.KlantAkkoord
                                                                },
                                                                {
                                                                    text: t("co2-bestelling:OnvolledigeLeveringReden.vraag-klant",
                                                                        "Vraag klant") as string,
                                                                    value: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.VraagKlant
                                                                },
                                                                {
                                                                    text: t("co2-bestelling:OnvolledigeLeveringReden.klant-kan-te-weinig-flessen-omruilen",
                                                                        "Klant kan te weinig CO₂-flessen omruilen") as string,
                                                                    value: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.KlantKanTeWeinigCo2FlessenOmruilen
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </MDBTableBody>
                            </MDBTable>

                            {gebruiksrechtZonderAkkoord && (
                                <MDBCol size="12" className="mb-3">
                                    <Co2GebruiksrechtWaarschuwing aantal={totaalAantalGebruiksrechtZonderAkkoord} extraCo2FlesBeleid={extraCo2FlesBeleid}/>

                                    <div className="ms-2">
                                        <MDBFormikCheckbox id="toestemmingKlantAfwijkendeBestelling"
                                                           name="toestemmingKlantAfwijkendeBestelling"
                                                           label={toestemmingKlantAfwijkendeBestelling}/>
                                    </div>
                                </MDBCol>
                            )}

                            <MDBBtn size="lg" type="submit" block className="mb-2" disabled={!isValid}>
                                {t("CO2LeveringAfwijkendModal.bewaren","Bewaren")}
                            </MDBBtn>
                        </Form>
                    )}
                </Formik>
            </MDBModalBody>
        </FrameModal>
    );
};
