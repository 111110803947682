import React from "react";
import {MDBCol, MDBRow} from "mdb-react-ui-kit";
import {ToestelInterventieServiceLogEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {useTechnici} from "../../../redux/slices/technicus/hooks";
import {Datum} from "../../Datum";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface ToestelInterventieServiceLogEntryProps {
    entry: ToestelInterventieServiceLogEntryRestModel;
}

export const ToestelInterventieServiceLogEntryComponent: React.FC<ToestelInterventieServiceLogEntryProps> = (props) => {
    const {entry} = props;

    const {t} = useTranslation("toestel");

    const technici = useTechnici();

    return (
        <>
            <span><Datum format="DD-MM-YYYY HH:mm" datum={entry.tijdstip}/></span>
            <span className="float-end">{entry.medewerkerId && technici[entry.medewerkerId]?.naam}</span>
            <h5>{t("Labels.interventie", "Interventie")}</h5>
            <MDBRow>
                <MDBCol md="6">
                    <strong>{t("Labels.soort", "Soort")}</strong> {entry.serviceOrderSoort}
                </MDBCol>
                <MDBCol md="6">
                    <strong>{t("Labels.nr", "Nr.")}</strong> {entry.serviceOrderNr}
                </MDBCol>
                <MDBCol md="6">
                    <strong>{t("Labels.omschrijving", "Omschrijving")}</strong> {entry.omschrijving}
                </MDBCol>
                {entry.opmerking && (
                    <MDBCol size="12">
                        <strong>{t("Labels.opmerking", "Opmerking")}</strong> {entry.opmerking}
                    </MDBCol>
                )}
                {entry.resultaat && (
                    <MDBCol>
                        <div>{t("Labels.controle-werking-colon", "Controle werking:")} <strong>{entry.resultaat}</strong></div>
                        <div className="text-muted">{entry.resultaatOpmerking}</div>
                    </MDBCol>
                )}
            </MDBRow>
        </>
    );
};
