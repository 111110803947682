'use client';

import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import MDBDatepickerModalContainer from './DatepickerModalContainer/DatepickerModalContainer';
import { DatepickerProps, defaultDatepickerProps } from './types';
import { usePopper } from 'react-popper';
import { flip } from '@popperjs/core';
import { getYear, getYearsOffset, isValidDate, formatDate, parseDate } from './utils/date-utils';
import DatepickerFooter from './DatepickerFooter/DatepickerFooter';
import DatepickerDayView from './DatepickerDayView/DatepickerDayView';
import DatepickerYearView from './DatepickerYearView/DatepickerYearView';
import DatepickerMonthView from './DatepickerMonthView/DatepickerMonthView';
import DatepickerHeader from './DatepickerHeader/DatepickerHeader';
import { DatepickerContext } from './utils/DatepickerContext';
import DatepickerControls from './DatepickerControls/DatepickerControls';
import DatepickerInput from './DatepickerInput/DatepickerInput';
import useDatepickerKeydown from './utils/hooks/useDatepickerKeydown';
import useDatepickerBodyScroll from './utils/hooks/useDatepickerBodyScroll';
import useDatepickerClickOutside from './utils/hooks/useDatepickerClickOutside';
import { AnimatePresence, motion } from 'framer-motion';
import { useOpenStatus } from '../../../utils/hooks';
import Portal from '../../../utils/Portal';

const MDBDatepicker = forwardRef<HTMLInputElement, DatepickerProps>(
  (
    {
      datetimepickerRef,
      isInDatetimepicker,
      onDatetimepickerModeSwitch,
      closeOnEsc = defaultDatepickerProps.closeOnEsc,
      customHeader,
      title = defaultDatepickerProps.title,
      weekdaysNarrow = defaultDatepickerProps.weekdaysNarrow,
      monthsFull = defaultDatepickerProps.monthsFull,
      monthsShort = defaultDatepickerProps.monthsShort,
      weekdaysFull = defaultDatepickerProps.weekdaysFull,
      weekdaysShort = defaultDatepickerProps.weekdaysShort,
      disableFuture,
      disablePast,
      filter,
      inline,
      className,
      min,
      max,
      format = defaultDatepickerProps.format,
      okBtnText = defaultDatepickerProps.okBtnText,
      clearBtnText = defaultDatepickerProps.clearBtnText,
      cancelBtnText = defaultDatepickerProps.cancelBtnText,
      inputToggle,
      customIcon = defaultDatepickerProps.customIcon,
      inputId,
      inputLabel = defaultDatepickerProps.inputLabel,
      inputStyle,
      startDay = defaultDatepickerProps.startDay,
      views = defaultDatepickerProps.views,
      style,
      defaultValue = '',
      onChange,
      onClose,
      onClosed,
      onOpen,
      onOpened,
      getFormattedDateValues,
      value,
      wrapperClass,
      selectOnClick = false,
      open,
      disablePortal,
      ...props
    },
    ref
  ) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isOpenState, setIsOpenState] = useState(false);
    const isOpen = useOpenStatus(isOpenState, open);
    const [activeDate, setActiveDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
    const [view, setView] = useState<'days' | 'months' | 'years'>(views);
    const [datepickerValue, setDatepickerValue] = useState(value || defaultValue);

    // popper
    const [popperElement, setPopperElement] = useState<HTMLElement>();
    const [referenceElement, setReferenceElement] = useState<HTMLInputElement>();
    const [startWeekdays, setStartWeekdays] = useState(weekdaysNarrow);

    const [yearScope, setYearScope] = useState([0, 0]);

    const backdropRef = useRef<HTMLDivElement>(null);
    const inputReference = useRef<HTMLInputElement>(null);

    const isDefault = useRef(defaultValue && true);
    const { styles, attributes } = usePopper((datetimepickerRef as HTMLElement) || referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [flip],
      ...{},
    });

    // it makes forwardRef returning HTMLInputElement, needed for react-hook-form
    useImperativeHandle(ref, () => (inline ? referenceElement : inputReference.current) as HTMLInputElement, [
      inline,
      referenceElement,
    ]);

    const setInlineDate = (date: Date) => {
      if (!inline) return;

      const isInlineDateSelected = inline;

      if (isInlineDateSelected) {
        const newDate = formatDate(date, format, weekdaysShort, weekdaysFull, monthsShort, monthsFull);

        setDatepickerValue(newDate);
        isInDatetimepicker && onDatetimepickerModeSwitch?.();
        onCloseHandler();
      }
    };

    const setModalDate = (date?: Date) => {
      const newDate = date && formatDate(date, format, weekdaysShort, weekdaysFull, monthsShort, monthsFull);

      newDate && setDatepickerValue(newDate);
    };

    const onCloseHandler = useCallback(() => {
      setIsOpenState(false);
      onClose?.();
    }, [onClose]);

    const onOpenHandler = useCallback(() => {
      setIsOpenState(true);
      onOpen?.();
    }, [onOpen]);

    const onClosedHandler = useCallback(() => {
      setIsOpened(false);
      onClosed?.();
    }, [onClosed]);

    const onOpenedHandler = useCallback(() => {
      setIsOpened(true);
      onOpened?.();
    }, [onOpened]);

    const { tabCount, modalRef } = useDatepickerKeydown({
      closeOnEsc,
      isOpen,
      activeDate,
      setActiveDate,
      min,
      max,
      view,
      setView,
      setSelectedDate,
      filter,
      setInlineDate,
      disableFuture,
      disablePast,
    });

    useDatepickerBodyScroll({ isOpen, inline });
    useDatepickerClickOutside({
      isOpened,
      isOpen,
      inline,
      referenceElement,
      popperElement,
      backdropRef,
      onCloseHandler,
    });

    useEffect(() => {
      const activeYear = getYear(activeDate);
      const yearsOffset = getYearsOffset(activeDate, 24);
      const firstYearInView = activeYear - yearsOffset;

      setYearScope([firstYearInView, firstYearInView + 23]);
    }, [activeDate]);

    useEffect(() => {
      const sortedWeekdays = weekdaysNarrow.slice(startDay).concat(weekdaysNarrow.slice(0, startDay));

      setStartWeekdays(sortedWeekdays);
    }, [weekdaysNarrow, startDay]);

    useEffect(() => {
      if (!isOpen) return;

      const inputElement = inline ? referenceElement : inputReference.current;
      const inputWrapper = inputElement?.parentNode;

      const inputBtn = inputWrapper?.querySelector('button');

      inputBtn ? inputBtn.blur() : inputElement?.blur();
    }, [isOpen, inputReference, referenceElement, inline, onOpen]);

    useEffect(() => {
      if (open && !isOpen && !isOpened) {
        onOpen?.();
        setIsOpenState(true);
      }
    }, [open, isOpen, isOpened, onOpen]);

    useEffect(() => {
      const isDefaultValue = isDefault.current;

      if (!isDefaultValue) return;

      const newDate = parseDate(datepickerValue, format, monthsFull, monthsShort);

      if (newDate && isValidDate(newDate)) {
        setActiveDate(newDate);
        setSelectedDate(newDate);
      }

      isDefault.current = false;
    }, [defaultValue, datepickerValue, format, monthsFull, monthsShort]);

    useEffect(() => {
      const newDate = value && parseDate(value, format, monthsFull, monthsShort);

      if (newDate && isValidDate(newDate)) {
        setActiveDate(newDate);
        setSelectedDate(newDate);
        setDatepickerValue(value);
      }
      if (value === '') {
        setActiveDate(new Date());
        setSelectedDate(undefined);
        setDatepickerValue(value);
      }
    }, [value, format, monthsFull, monthsShort]);

    useEffect(() => {
      if (!isOpen) {
        setView(views);

        if (!datepickerValue) {
          setActiveDate(new Date());
          setSelectedDate(undefined);
        }
      }
    }, [isOpen, views, datepickerValue]);

    useEffect(() => {
      onChange?.(datepickerValue, activeDate);
    }, [datepickerValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const date = selectedDate ? selectedDate : new Date();
      const dayNumber = String(date.getDate());
      const weekdayShort = weekdaysShort[date.getDay()];
      const weekdayFull = weekdaysFull[date.getDay()];

      const monthShort = monthsShort[date.getMonth()];
      const monthFull = monthsFull[date.getMonth()];

      const yearFull = String(date.getFullYear());
      const yearShort = yearFull.slice(-2);

      getFormattedDateValues?.({ dayNumber, weekdayShort, weekdayFull, monthShort, monthFull, yearFull, yearShort });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
      <DatepickerContext.Provider
        value={{
          isInDatetimepicker,
          onDatetimepickerModeSwitch,
          view,
          setView,
          activeDate,
          setActiveDate,
          selectedDate,
          setSelectedDate,
          weekdaysShort,
          monthsShort,
          monthsFull,
          min,
          max,
          weekdaysFull,
          yearScope,
          tabCount,
          isOpen,
          disablePast,
          disableFuture,
        }}
      >
        <>
          {!isInDatetimepicker && (
            <DatepickerInput
              inputClasses={className}
              labelText={inputLabel}
              inline={inline}
              setReferenceElement={setReferenceElement}
              value={datepickerValue}
              setDatepickerValue={setDatepickerValue}
              style={style}
              inputStyle={inputStyle}
              format={format}
              icon={customIcon}
              input={inputReference}
              inputToggle={inputToggle}
              onOpenHandler={onOpenHandler}
              {...props}
            />
          )}
          <Portal disablePortal={disablePortal}>
            <AnimatePresence>
              {isOpen && (
                <>
                  <MDBDatepickerModalContainer
                    className={wrapperClass}
                    dropdown={inline}
                    styles={styles}
                    attributes={attributes}
                    setPopperElement={setPopperElement}
                    key={'datepicker-modal-container'}
                    onClosed={onClosedHandler}
                    onOpened={onOpenedHandler}
                  >
                    {!inline && <DatepickerHeader title={title} customHeader={customHeader} onClose={onCloseHandler} />}
                    <div className='datepicker-main' ref={modalRef}>
                      <DatepickerControls />
                      <div className='datepicker-view'>
                        {view === 'days' && (
                          <DatepickerDayView
                            startWeekdays={startWeekdays}
                            startDay={startDay}
                            filter={filter}
                            inlineDayClick={setInlineDate}
                            selectDate={setModalDate}
                            selectOnClick={selectOnClick}
                            onClose={onCloseHandler}
                          />
                        )}
                        {view === 'years' && <DatepickerYearView />}
                        {view === 'months' && <DatepickerMonthView />}
                      </div>
                      {!inline && (
                        <DatepickerFooter
                          okBtnText={okBtnText}
                          clearBtnText={clearBtnText}
                          cancelBtnText={cancelBtnText}
                          setValue={setDatepickerValue}
                          selectDate={setModalDate}
                          onClose={onCloseHandler}
                        />
                      )}
                    </div>
                  </MDBDatepickerModalContainer>

                  {!inline && (
                    <motion.div
                      className='datepicker-backdrop'
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      ref={backdropRef}
                    ></motion.div>
                  )}
                </>
              )}
            </AnimatePresence>
          </Portal>
        </>
      </DatepickerContext.Provider>
    );
  }
);

MDBDatepicker.displayName = 'MDBDatepicker';
export default MDBDatepicker;
