import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, select} from "redux-saga/effects";
import {UiFormDraft} from "../../../_generated/field-service-be-openapi";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {PayloadAction} from "@reduxjs/toolkit";
import {selectMe} from "../technicus/slice";
import {EventMessagePayload} from "../../../workers/shared/channels/eventMessage";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";
import {EventPayload} from "redux/types";

export const uiSaga = createSliceSaga({
    name: "uiSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * uiFormDraft(action: PayloadAction<EventPayload<UiFormDraft>>) {
            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                event: {
                    _type: "UI_FORM_DRAFT",
                    technicusId,
                    formId: action.payload.formId,
                    formValues: action.payload.formValues,
                    oorsprong: action.payload.oorsprong
                },
                bezoekSessieId: action.payload.bezoekSessieId,
                technicusId
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        }
    }
});
