import {useAppSelector} from "../../hooks";
import {DagPlanningBezoekEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {useHuidigeDagPlanning} from "../dagPlanning/hooks";

export const useDagPlanningBezoek = (serviceAdresId: string) => {
    return useAppSelector((state) => {
        const list = state.dagPlanningEntry.lists[`serviceAdres:${serviceAdresId}`];

        if (list?.[0]) {
            return state.dagPlanningEntry.resources[list[0]] as DagPlanningBezoekEntryRestModel;
        }

        return undefined;
    });
};

export const useBezoekByBezoekSessieId = (bezoekSessieId?: string) => {
    const [dagPlanning] = useHuidigeDagPlanning();

    return dagPlanning?.entries?.find(item => item._type === "BEZOEK" && item.toekomstigBezoekSessieId === bezoekSessieId) as DagPlanningBezoekEntryRestModel | undefined;
};

export const useBezoek = (bezoekId?: string) => {
    return useAppSelector((state) => {
        if (!bezoekId) {
            return undefined;
        }

        const list = state.dagPlanningEntry.lists[`bezoekId:${bezoekId}`];
        console.log(state.dagPlanningEntry.lists);

        if (list?.[0]) {
            return state.dagPlanningEntry.resources[list[0]] as DagPlanningBezoekEntryRestModel;
        }

        return undefined;
    });
};
