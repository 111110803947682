import React, {ChangeEvent, useMemo, useState} from "react";
import {MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow} from "mdb-react-ui-kit";
import {Header} from "../components/Header";
import {GenericPage} from "../components/GenericPage";
import Icon from "@mdi/react";
import {mdiQrcodeScan} from "@mdi/js";
import {ServiceAdresToestelCard} from "./ServiceAdres/Toestel/ServiceAdresToestelCard";
import {useToestellen} from "../redux/slices/toestellen/hooks";
import {Assets} from "../assets/assets";
import {useHuidigeBezoekSessie} from "../redux/slices/bezoeksessie/hooks";
import {Adres} from "../components/Adres";
import {useTranslation} from "../utilities/i18nUtils";

export const ZoekToestelPage: React.FC = () => {
    const toestellen = useToestellen();
    const huidigeBezoekSessie = useHuidigeBezoekSessie();

    const [serienummer, setSerienummer] = useState("");

    const gevondenToestellen = useMemo(() => {
        if (!serienummer) {
            return [];
        }

        return Object.values(toestellen).filter((toestel) => {
            return toestel.serieNummer?.includes(serienummer);
        });
    }, [serienummer, toestellen]);

    const gevondenToestellenOpHuidigServiceAdres = useMemo(() => {
        if (!huidigeBezoekSessie) {
            return [];
        }

        return gevondenToestellen.filter((toestel) => huidigeBezoekSessie?.serviceAdres?.toestelIds?.includes(toestel.id));
    }, [huidigeBezoekSessie, gevondenToestellen]);

    const gevondenToestellenOpAndereServiceAdressen = useMemo(() => {
        return gevondenToestellen.filter((toestel) => !huidigeBezoekSessie || !huidigeBezoekSessie?.serviceAdres?.toestelIds?.includes(toestel.id));
    }, [huidigeBezoekSessie, gevondenToestellen]);

    const isEmpty = !gevondenToestellenOpHuidigServiceAdres.length && !gevondenToestellenOpAndereServiceAdressen.length;

    const {t} = useTranslation("serviceadres");

    const titelLabel = t("ZoekToestelPage.scan-toestel", "Scan Toestel")

    const zoekenOpSerienummerLabel = t("ZoekToestelPage.zoeken-op-serienummer",
        "Zoeken op (serie)nummer");

    return (
        <GenericPage>
            <Header title={titelLabel} sticky/>

            <MDBContainer fluid className="p-4 flex-fill bg-white">
                <MDBRow className="my-3 align-items-center d-flex flex-nowrap">
                    <MDBCol className="flex-fill">
                        <MDBInput type="text" label={zoekenOpSerienummerLabel} size="lg" value={serienummer}
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => setSerienummer(event.target.value)}/>
                    </MDBCol>

                    <MDBCol>
                        <MDBBtn type="button" color="primary">
                            <Icon path={mdiQrcodeScan} size={1}/>
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>

                {isEmpty && (
                    <div className="d-flex justify-content-center">
                        <div style={{width: "30%"}}>
                            <img src={Assets.emptyFolder} className="img-fluid" alt="Leeg"/>
                        </div>
                    </div>
                )}

                {!!gevondenToestellenOpHuidigServiceAdres.length && (
                    <>
                        <h3>{t("ZoekToestelPage.toestellen-op-het-huidige-adres", "Toestellen op het huidig adres")}</h3>
                        <h5 className="text-muted"><Adres adres={huidigeBezoekSessie?.serviceAdres}/></h5>

                        {gevondenToestellenOpHuidigServiceAdres.map((toestel) => {
                            return (
                                <ServiceAdresToestelCard key={toestel.id}
                                                         toestel={toestel}
                                                         link={`/bezoek/taken/${huidigeBezoekSessie?.id}/toestel/${toestel.id}`}
                                                         linkOnClick={() => {
                                                         }} // Check in
                                />
                            );
                        })}
                    </>
                )}

                {!!gevondenToestellenOpAndereServiceAdressen.length && (
                    <>
                        <h3>Toestellen op andere adressen</h3>

                        {gevondenToestellenOpAndereServiceAdressen.map((toestel) => {
                            return (
                                <>


                                    <ServiceAdresToestelCard key={toestel.id}
                                                             toestel={toestel}
                                    />
                                </>
                            );
                        })}
                    </>
                )}
            </MDBContainer>
        </GenericPage>
    );
};
