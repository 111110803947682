import {BrowserCacheLocation, Configuration, ProtocolMode, PublicClientApplication} from "@azure/msal-browser";
import {Config} from "../config";

const configuration: Configuration = {
    auth: {
        protocolMode: ProtocolMode.OIDC,
        clientId: Config.OAUTH_CLIENT_ID,
        authority: Config.OAUTH_AUTHORITY,
        knownAuthorities: [Config.OAUTH_AUTHORITY],
        postLogoutRedirectUri: typeof window !== "undefined" ? window.location.origin : undefined,
        redirectUri: typeof window !== "undefined" ? window.location.origin : undefined
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        secureCookies: false,
        storeAuthStateInCookie: false,
        claimsBasedCachingEnabled: true
    }
};

export const authClient = new PublicClientApplication(configuration);
