import React from "react";
import {BezoekSessieStatus, BezoekSessieToestel} from "../../../../workers/shared/snapshot/bezoekSessieState";
import {MDBCardDisabledOverlay} from "../../../../components/mdb/MDBCardDisabledOverlay";
import {MDBCardStatus, MDBCardStatusEnum} from "../../../../components/mdb/MDBCardStatus";
import {NavLink} from "react-router-dom";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBRipple,
    MDBRow
} from "../../../../mdb-react-ui-kit/index";
import Icon from "@mdi/react";
import {mdiChevronRight, mdiWaterOutline} from "@mdi/js";
import {WaterstandOpgenomenResultaatEnum} from "../../../../_generated/field-service-be-openapi";
import {useTranslation} from "../../../../utilities/i18nUtils";

export interface ToestelWatermeterStandOpnameCardProps {
    bezoekSessieId: string;

    bezoekSessieToestel: BezoekSessieToestel;
    bezoekSessieStatus: BezoekSessieStatus;

    toestelIngecheckt: boolean;
}

export const ToestelWatermeterStandOpnameCard: React.FC<ToestelWatermeterStandOpnameCardProps> = (props) => {
    const {toestelIngecheckt, bezoekSessieId, bezoekSessieToestel, bezoekSessieStatus} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBCard background="light" className="mt-3 mb-4 shadow-sm">
            {(!toestelIngecheckt && bezoekSessieStatus !== BezoekSessieStatus.GESLOTEN) &&
                <MDBCardDisabledOverlay text={t("gelieve-het-toestel-eerst-in-te-checken",
                    "Gelieve het toestel eerst in te checken")}/>}

            <MDBCardStatus status={bezoekSessieToestel.waterstand !== undefined ? MDBCardStatusEnum.DONE : undefined}/>
            <NavLink
                to={`/bezoek/taken/${bezoekSessieId}/toestel/${bezoekSessieToestel.informatie?.id}/waterstand`}>
                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    <MDBCardHeader>
                                    <span className="d-flex align-items-center">
                                        <Icon path={mdiWaterOutline} size={1} className="me-1"/>
                                        {t("algemeen:waterstand-ingeven", "Waterstand ingeven")}
                                    </span>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow>
                                <MDBCol>
                                    <MDBRow>
                                        <MDBCol sm="4" className="mb-2">
                                            <strong>{t("ToestelWatermeterstandOpnameCard.vorige-waterstand", "Vorige waterstand: ")}</strong><br/> {bezoekSessieToestel.informatie?.waterTeller?.recenteOpnames?.[0]?.opname ||
                                            <span
                                                className="text-muted">{t("ToestelWatermeterstandOpnameCard.onbekend", "onbekend")}</span>}
                                        </MDBCol>
                                        {bezoekSessieToestel.waterstand !== undefined && (
                                            <MDBCol sm="4" className="mb-2">
                                                <strong>{t("ToestelWatermeterstandOpnameCard.opgenomen-waterstand",
                                                    "Opgenomen waterstand:")}</strong><br/> {bezoekSessieToestel.waterstand.resultaat === WaterstandOpgenomenResultaatEnum.GeenMeterAanwezig ? "geen watermeter aanwezig" : bezoekSessieToestel.waterstand?.meterstand}
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol size="auto"
                                        className="d-flex align-items-center justify-content-end">
                                    <Icon path={mdiChevronRight} size={2}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBRipple>
            </NavLink>
        </MDBCard>
    );
};
