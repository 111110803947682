import React, {useState} from "react";
import {MDBBtn} from "mdb-react-ui-kit";
import {mdiFileDownload} from "@mdi/js";
import Icon from "@mdi/react";
import {useDownloadToestelBijlage} from "../../redux/slices/toestellen/hooks";
import {blobToDataUri, saveFile} from "../../utilities/downloadUtils";
import {Lightbox} from "../Lightbox";

export interface ServiceRapportDownloadLinkProps {
    toestelId: string;
    bijlageId: string;
}

export const ToestelBijlageDownloadButton: React.FC<ServiceRapportDownloadLinkProps> = (props) => {
    const {toestelId, bijlageId} = props;

    const [imageUri, setImageUri] = useState<string>();
    const [download] = useDownloadToestelBijlage(toestelId, bijlageId);

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();

        if (imageUri) {
            return;
        }

        download(false, async (fileDownload) => {
            if (fileDownload.mediaType.startsWith("image/")) {
                const blob = await fileDownload.body.blob();
                const dataUri = await blobToDataUri(blob);

                setImageUri(dataUri);
            } else {
                setImageUri(undefined);
                saveFile(fileDownload);
            }
        });
    };

    return (
        <MDBBtn type="button" onClick={onClick} color="link" className="p-0">
            <Icon path={mdiFileDownload} size={1}/>

            {imageUri && <Lightbox images={[{url: imageUri}]} open onClose={() => setImageUri(undefined)} />}
        </MDBBtn>
    );
};
