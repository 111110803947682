import React from "react";
import Icon from "@mdi/react";
import {mdiPause} from "@mdi/js";
import {MDBCard, MDBCardBody, MDBCardTitle, MDBRipple} from "mdb-react-ui-kit";
import {useTranslation} from "../../utilities/i18nUtils";

export interface PlanningPauzeCardProps {
    duurtijd?: number;

    onClick?: () => void;

    active?: boolean;
}

export const PlanningPauzeCard: React.FC<PlanningPauzeCardProps> = (props) => {
    const {duurtijd} = props;

    const {t} = useTranslation("planning");

    return (
        <div className="ps-3 mb-4">
            <div className="timeline-drive">
                <div className="dot">
                    <Icon path={mdiPause} size={1} className="text-primary"/>
                </div>
                <MDBCard className="shadow-none">
                    <span>
                        <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                            <MDBCardBody>
                                <MDBCardTitle className="mb-0 d-flex align-items-center">
                                    <div>
                                        {t("Labels.pauze", "Pauze")}{" "}
                                        {duurtijd && <span className="text-muted font-smaller">{duurtijd} min</span>}
                                    </div>
                                </MDBCardTitle>
                            </MDBCardBody>
                        </MDBRipple>
                    </span>
                </MDBCard>
            </div>
        </div>
    );
};
