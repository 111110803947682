import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBRow
} from "mdb-react-ui-kit";
import {Header} from "../../components/Header";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {GoogleMap, Marker} from "@react-google-maps/api";
import Icon from "@mdi/react";
import {mdiArrowTopRight, mdiInformationOutline} from "@mdi/js";
import {GenericPage} from "../../components/GenericPage";
import {useHideModal, useShowModal} from "../../redux/slices/modal/hooks";
import {FieldServiceModal} from "../../components/FieldServiceModal";
import {
    useBezoekSessieById,
    useDeviceInfoGelogd,
    useStartBezoekSessieEnOnderweg
} from "../../redux/slices/bezoeksessie/hooks";
import {
    useAdresBelemmerd,
    useServiceAdres,
    useServiceAdresCheckIn,
    useServiceAdresCheckOut
} from "../../redux/slices/serviceAdressen/hooks";
import {Adres} from "../../components/Adres";
import {useTechnici} from "../../redux/slices/technicus/hooks";
import {Tijdsduur} from "../../components/Tijdsduur";
import {
    AdresBelemmerdRedenEnum,
    CheckOutVanAdresRedenNietAfgewerktEnum,
    DagPlanningBezoekEntryRestModel
} from "../../_generated/field-service-be-openapi";
import {ReedsIngechecktOpServiceAdresError} from "../../redux/slices/serviceAdressen/errors";
import {useSessionStorageState} from "../../utilities/storageHooks";
import {AdresAanwezigheid, BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";
import {useKlant} from "../../redux/slices/klant/hooks";
import {Datum} from "../../components/Datum";
import {useSyncingActieveBezoekSessies} from "../../redux/slices/status/hooks";
import {Openingsuren} from "../../components/Openingsuren";
import {InAdresAanwezigheidStatus} from "../../components/InAdresAanwezigheidStatus";
import {BezoekSessieGeslotenMelding} from "../../components/BezoekSessieGeslotenMelding";
import {dateNow} from "../../utilities/dateUtils";
import dayjs from "dayjs";
import {useHuidigeDagPlanning} from "../../redux/slices/dagPlanning/hooks";
import {TooltipWhenDisabledButton} from "../../components/TooltipWhenDisabledButton";
import {useTranslation} from "../../utilities/i18nUtils";
import {AdresAanwezigheidPill} from "../../components/bezoek/AdresAanwezigheidPill";

const datumToday = dateNow();
const datumTodayFormatted = dayjs(datumToday).format("YYYY-MM-DD");

export interface ServiceAdresDetailPageParams {
    bezoekSessieId: string;
}

export const ServiceAdresDetailPage: React.FC = () => {
    const {t} = useTranslation("serviceadres");

    const {bezoekSessieId} = useParams<ServiceAdresDetailPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const [dagPlanning] = useHuidigeDagPlanning();

    const bezoek = dagPlanning?.entries?.find(item => item._type === "BEZOEK" && item.toekomstigBezoekSessieId === bezoekSessieId) as DagPlanningBezoekEntryRestModel | undefined;

    let serviceAdres = useServiceAdres(bezoek?.serviceAdresId);
    if (!serviceAdres && bezoekSessie) {
        serviceAdres = bezoekSessie.serviceAdres;
    }

    const technici = useTechnici();

    const klant = useKlant(serviceAdres?.klantId);

    const [geselecteerdeDag] = useSessionStorageState("geselecteerdeDag", datumTodayFormatted);

    const [map, setMap] = useState<google.maps.Map>();

    const history = useHistory();
    const showModal = useShowModal();
    const hideModal = useHideModal();

    const syncingActieveBezoekSessies = useSyncingActieveBezoekSessies();

    const startBezoekSessieEnOnderweg = useStartBezoekSessieEnOnderweg();
    const checkInOpServiceAdres = useServiceAdresCheckIn();
    const checkOutVanServiceAdres = useServiceAdresCheckOut();
    const belemmerServiceAdres = useAdresBelemmerd();
    const logDeviceInfo = useDeviceInfoGelogd();

    const onMapsLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    const onMapsUnmount = useCallback(() => {
        setMap(undefined);
    }, []);

    const onBelemmerdClick = () => {
        if (bezoekSessie) {
            logDeviceInfo(bezoekSessie.id);
        }

        showModal({
            type: FieldServiceModal.ADRES_BELEMMERD_MODAL, props: {
                onConfirm: (reden: AdresBelemmerdRedenEnum, toelichting?: string) => {
                    if (bezoekSessie) {
                        belemmerServiceAdres(bezoekSessie.id, reden, toelichting);

                        history.replace("/");
                    }
                },
                onClose: () => hideModal()
            }
        });
    };

    const onOnderwegClick = () => {
        if (geselecteerdeDag && bezoek && serviceAdres && dagPlanning) {
            startBezoekSessieEnOnderweg(geselecteerdeDag, serviceAdres.id!, bezoek.bezoekId, bezoek.toekomstigBezoekSessieId, bezoek.uitTeVoerenWerk, dagPlanning.versie!, dagPlanning.versieTijdstip!, (error, bezoekSessieId) => {
                if (error) {
                    if (error instanceof ReedsIngechecktOpServiceAdresError) {
                        showModal({
                            type: FieldServiceModal.REEDS_AANWEZIG_OP_SERVICEADRES_MODAL,
                            props: {
                                onClose: () => hideModal()
                            }
                        });
                    }
                } else if (bezoekSessieId) {
                    logDeviceInfo(bezoekSessieId);
                }
            });
        }
    };

    const onCheckInClick = () => {
        if (bezoekSessie) {
            logDeviceInfo(bezoekSessie.id);

            checkInOpServiceAdres(bezoekSessie.id!, (error) => {
                if (error) {
                    if (error instanceof ReedsIngechecktOpServiceAdresError) {
                        showModal({
                            type: FieldServiceModal.REEDS_AANWEZIG_OP_SERVICEADRES_MODAL,
                            props: {
                                onClose: () => hideModal()
                            }
                        });
                    }
                }
            });
        }
    };

    const onVerlatenClick = () => {
        if (!bezoekSessie) {
            return;
        }

        logDeviceInfo(bezoekSessie.id);

        if (bezoekSessie.nietUitgevoerdWerk.length === 0) {
            checkOutVanServiceAdres(bezoekSessie.id);

            history.replace("/");
        } else {
            showModal({
                type: FieldServiceModal.ADRES_AFWERKEN_MODAL, props: {
                    bezoekSessieId,
                    nietUitgevoerdWerk: bezoekSessie.nietUitgevoerdWerk,
                    onConfirm: (reden: CheckOutVanAdresRedenNietAfgewerktEnum, toelichting?: string) => {
                        if (bezoekSessie) {
                            checkOutVanServiceAdres(bezoekSessie.id, reden, toelichting);

                            history.replace("/");
                        }
                    },
                    onClose: () => hideModal()
                }
            });
        }
    };

    const onTakenOpDitAdresClick = () => {
        if (bezoekSessie) {
            logDeviceInfo(bezoekSessie.id);
        }

        history.push(`/bezoek/taken/${bezoekSessieId}`);
    };

    const serviceRapportenClick = () => {

        const foutmelding = t("ServiceAdresDetailPage.onbekende-fout-serviceadres-niet-beschikbaar", "Onbekende fout: serviceadres niet beschikbaar");

        if (serviceAdres) {
            history.push(`/bezoek/${bezoekSessieId}/adres/${serviceAdres.id}/servicerapporten`);
        } else {
            throw new Error(foutmelding);
        }
    };

    const bounds = useMemo(() => {
        const bounds = new google.maps.LatLngBounds();

        if (serviceAdres) {
            for (const item of serviceAdres.coordinaten || []) {
                bounds.extend({lat: item.latitude!, lng: item.longitude!});
            }
        }

        return bounds;
    }, [serviceAdres]);

    useEffect(() => {
        if (map) {
            google.maps.event.addListenerOnce(map, "bounds_changed", () => {
                map.setZoom(Math.min(16, map.getZoom() || 16));
            });
            map.fitBounds(bounds, 200);
        }
    }, [map, bounds]);

    if (!serviceAdres) {
        return null;
    }

    return (
        <GenericPage>
            <Header title="Serviceadres" sticky shadow className="position-sticky text-white"
                    style={{left: 0, right: 0}} background="rgba(249, 249, 249, 0.8)"/>

            <MDBContainer fluid className="flex-fill bg-white">
                <GoogleMap
                    mapContainerClassName="mx-n4 mb-n5"
                    mapContainerStyle={{width: "100vw", height: "40vh", zIndex: 10}}

                    onLoad={onMapsLoad}
                    onUnmount={onMapsUnmount}

                    options={{
                        mapTypeControl: false,
                        zoomControl: false,
                        streetViewControl: false,
                        fullscreenControlOptions: {
                            position: google.maps.ControlPosition.RIGHT_CENTER
                        },
                        streetViewControlOptions: {
                            position: google.maps.ControlPosition.RIGHT_CENTER
                        }
                    }}
                >
                    <>
                        {serviceAdres.coordinaten?.map((coordinaat, index) => (
                            <Marker key={index} position={{lat: coordinaat.latitude!, lng: coordinaat.longitude!}}/>
                        ))}
                    </>
                </GoogleMap>

                <MDBCol className="container-lg">
                    <MDBCard className="overflow-visible" style={{zIndex: 100}}>
                        <MDBCardHeader>
                            <MDBRow>
                                <MDBCol>
                                    <div className="ps-0 position-relative">
                                        <MDBCardTitle className="d-flex align-items-center justify-content-center">
                                            <div>
                                                {klant?.naam}{" "}

                                                <small
                                                    className="text-muted font-weight-normal">{serviceAdres?.naam}</small>
                                            </div>
                                        </MDBCardTitle>
                                        <MDBCardText className="text-center">
                                            <Adres adres={serviceAdres}/>
                                        </MDBCardText>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>

                        <MDBCardBody className="text-center">
                            {Object.keys(bezoekSessie?.adresAanwezigheid || {}).length > 0 && (
                                <p>
                                    {Object.entries(bezoekSessie?.adresAanwezigheid || {}).map(([technicusId, aanwezigheid]) => (
                                        <span className="d-inline-flex align-items-center me-2" key={technicusId}>
                                            {technici[technicusId]?.naam}
                                            <AdresAanwezigheidPill aanwezigheid={aanwezigheid}/>
                                        </span>
                                    ))}
                                </p>
                            )}

                            <BezoekSessieGeslotenMelding
                                show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>
                        </MDBCardBody>

                        <MDBCardFooter>
                            <MDBRow className="justify-content-center">
                                <InAdresAanwezigheidStatus adresAanwezigheid={bezoekSessie?.adresAanwezigheid}
                                                           anyOf={[undefined, AdresAanwezigheid.OBSERVATOR]}
                                >
                                    <MDBCol md="4">
                                        <TooltipWhenDisabledButton type="button" color="primary" block
                                                                   className="mb-2 btn-lg text-nowrap"
                                                                   onClick={onOnderwegClick}
                                                                   disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}
                                                                   title={t("ServiceAdresDetailPage.even-geduld-terwijl-we-gegevens-ophalen",
                                                                       "Even geduld terwijl we gegevens ophalen...")}
                                        >
                                            {t("ServiceAdresDetailPage.op-weg-naar-dit-adres","Op weg naar dit adres")}
                                        </TooltipWhenDisabledButton>
                                    </MDBCol>
                                </InAdresAanwezigheidStatus>

                                <InAdresAanwezigheidStatus adresAanwezigheid={bezoekSessie?.adresAanwezigheid}
                                                           anyOf={[AdresAanwezigheid.OBSERVATOR, AdresAanwezigheid.VERLATEN, AdresAanwezigheid.ONDERWEG]}
                                >
                                    <MDBCol md="4">
                                        <MDBDropdown className="btn-group w-100 mb-2">
                                            <TooltipWhenDisabledButton onClick={onCheckInClick} block
                                                                       size="lg" wrapperClass="w-100"
                                                                       disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}
                                                                       title={t("ServiceAdresDetailPage.even-geduld-terwijl-we-gegevens-ophalen",
                                                                           "Even geduld terwijl we gegevens ophalen...")}
                                            >
                                                {t("ServiceAdresDetailPage.inchecken", "Inchecken")}
                                            </TooltipWhenDisabledButton>
                                            <MDBDropdownToggle split/>

                                            <MDBDropdownMenu>
                                                <MDBDropdownItem link childTag="div" disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN} onClick={onCheckInClick}>{t("ServiceAdresDetailPage.inchecken", "Inchecken")}</MDBDropdownItem>
                                                <MDBDropdownItem link childTag="div" disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN} onClick={onBelemmerdClick}>{t("ServiceAdresDetailPage.adres-belemmerd", "Adres belemmerd")}</MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </MDBCol>
                                </InAdresAanwezigheidStatus>

                                <InAdresAanwezigheidStatus adresAanwezigheid={bezoekSessie?.adresAanwezigheid}
                                                           anyOf={[AdresAanwezigheid.AANWEZIG, AdresAanwezigheid.OBSERVATOR, AdresAanwezigheid.VERLATEN]}
                                >
                                    <MDBCol md="4">
                                        <MDBBtn type="button" color="primary" block className="mb-2 text-nowrap"
                                                size="lg"
                                                onClick={onTakenOpDitAdresClick} disabled={syncingActieveBezoekSessies}>
                                            {t("ServiceAdresDetailPage.taken-op-dit-adres", "Taken op dit adres")}
                                        </MDBBtn>
                                    </MDBCol>
                                </InAdresAanwezigheidStatus>

                                <InAdresAanwezigheidStatus adresAanwezigheid={bezoekSessie?.adresAanwezigheid}
                                                           anyOf={[AdresAanwezigheid.AANWEZIG, AdresAanwezigheid.OBSERVATOR]}
                                >
                                    <MDBCol md="4">
                                        <MDBDropdown className="btn-group w-100">
                                            <MDBBtn type="button" onClick={onVerlatenClick} block size="lg"
                                                    disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}>{t("ServiceAdresDetailPage.uitchecken", "Uitchecken")}</MDBBtn>
                                            <MDBDropdownToggle split/>

                                            <MDBDropdownMenu>
                                                <MDBDropdownItem link childTag="div" disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN} onClick={onVerlatenClick}>{t("ServiceAdresDetailPage.uitchecken", "Uitchecken")}</MDBDropdownItem>
                                                <MDBDropdownItem link childTag="div" disabled={syncingActieveBezoekSessies || bezoekSessie?.status === BezoekSessieStatus.GESLOTEN} onClick={onBelemmerdClick}>{t("ServiceAdresDetailPage.adres-belemmerd", "Adres belemmerd")}</MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </MDBCol>
                                </InAdresAanwezigheidStatus>
                            </MDBRow>
                        </MDBCardFooter>

                        <MDBCardFooter>
                            <MDBRow>
                                <MDBCol md="4">
                                    <NavLink to={`/bezoek/${bezoekSessieId}/overzicht`}
                                             className="mb-2 text-nowrap btn btn-lg btn-link btn-block">
                                        {t("ServiceAdresDetailPage.takenoverzicht", "Takenoverzicht")}
                                    </NavLink>
                                </MDBCol>

                                <MDBCol md="4">
                                    <MDBBtn type="button" block className="btn-lg text-nowrap" color="link"
                                            onClick={serviceRapportenClick}>
                                        {t("ServiceAdresDetailPage.servicerapporten", "Servicerapporten")}
                                    </MDBBtn>
                                </MDBCol>

                                <MDBCol md="4">
                                    <NavLink to={`/bezoek/${bezoekSessieId}/bijlagen`}
                                             className="text-nowrap btn btn-lg btn-link btn-block">
                                        {t("ServiceAdresDetailPage.bijlagen", "Bijlagen")} {!!serviceAdres?.bijlagen?.length &&
                                        <MDBBadge pill>{serviceAdres?.bijlagen?.length}</MDBBadge>}
                                    </NavLink>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardFooter>
                    </MDBCard>

                    {(serviceAdres.vasteInstructie || bezoek?.bezoekInstructie) && (
                        <MDBCard className="mt-3 mb-4 note-warning"
                                 style={{zIndex: 100, borderTop: "4px solid var(--mdb-warning)"}}>
                            <MDBCardBody>
                                <MDBRow>
                                    {bezoek?.bezoekInstructie && (
                                        <MDBCol md="12" className="mb-2">
                                            <strong>{t("ServiceAdresDetailPage.plannerinstructies",
                                                "Plannerinstructies:")}</strong><br/>
                                            {bezoek?.bezoekInstructie}
                                        </MDBCol>
                                    )}

                                    {serviceAdres.vasteInstructie && (
                                        <MDBCol md="12" className="mb-2">
                                            <strong>{t("ServiceAdresDetailPage.vaste-adresinstructies",
                                                "Vaste adresinstructies:")}</strong><br/>
                                            {serviceAdres.vasteInstructie}
                                        </MDBCol>
                                    )}
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    )}

                    <MDBCard className="mt-3 mb-4" style={{zIndex: 100}}>
                        <MDBCardHeader>
                            <Icon path={mdiInformationOutline} size={1}/> {t("ServiceAdresDetailPage.informatie","Informatie")}
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol md="3" className="mb-2">
                                    <strong>{t("ServiceAdresDetailPage.gps-coördinaten", "Gps-coördinaten:")}</strong><br/>
                                    {serviceAdres.coordinaten?.[0] && (
                                        <a href={`geo:${serviceAdres.coordinaten?.[0]?.latitude},${serviceAdres.coordinaten?.[0]?.longitude}?q=${serviceAdres.coordinaten?.[0]?.latitude},${serviceAdres.coordinaten?.[0]?.longitude}`}>{serviceAdres.coordinaten?.[0]?.latitude}, {serviceAdres.coordinaten?.[0]?.longitude}</a>
                                    )}
                                    {!serviceAdres.coordinaten?.[0] && <span className="text-muted">{t("ServiceAdresDetailPage.serviceadres-onbekend","onbekend")}</span>}
                                </MDBCol>
                                <MDBCol md="3" className="mb-2">
                                    <strong>{t("ServiceAdresDetailPage.contactpersoon", "Contactpersoon:")}</strong><br/> {serviceAdres.contactNaam}{" "}
                                    {serviceAdres.contactNaam && (<a href={`tel:${serviceAdres.contactTelefoon}`}>{serviceAdres.contactTelefoon}</a>)}
                                </MDBCol>
                                <MDBCol md="6" className="mb-2">
                                    <strong>{t("ServiceAdresDetailPage.klant", "Klant:")}</strong><br/>
                                    {klant?.nr} {klant?.naam}
                                </MDBCol>
                                <MDBCol md="3" className="mb-2">
                                    <strong>{t("ServiceAdresDetailPage.geplande-tijd", "Geplande tijd:")}
                                    </strong><br/>
                                    <Datum datum={bezoek?.geplandStart} format="HH:mm"
                                                                    parseFormat="HH:mm:ss"/> - <Datum
                                    datum={bezoek?.geplandEind} format="HH:mm" parseFormat="HH:mm:ss"/>
                                </MDBCol>
                                <MDBCol md="3" className="mb-2">
                                    <strong>{t("ServiceAdresDetailPage.afgesproken-tijd", "Afgesproken tijd:")}</strong><br/>
                                    {bezoek?.displayStart ?
                                        <Datum datum={bezoek?.displayStart} format="HH:mm" parseFormat="HH:mm:ss"/> :
                                        <span className="text-muted">
                                            {t("ServiceAdresDetailPage.geen", "Geen")}
                                        </span>}
                                </MDBCol>
                                <MDBCol md="3" className="mb-2">
                                    <strong>{t("ServiceAdresDetailPage.voorziene-tijd", "Voorziene tijd:")}</strong><br/>
                                    {!!bezoek?.duurtijd && (
                                        <Tijdsduur tijdsduur={bezoek?.duurtijd} eenheid="minutes"/>
                                    )}
                                    {!bezoek?.duurtijd && <span className="text-muted">{t("ServiceAdresDetailPage.voorziene-tijd-onbekend", "onbekend")}</span>}
                                </MDBCol>

                                <MDBCol md="3" className="mb-2">
                                    <MDBPopover color="link" tag="div" btnChildren={
                                        <>
                                            <strong>{t("ServiceAdresDetailPage.openingsuren","Openingsuren:")}<Icon path={mdiArrowTopRight}
                                                                        size={0.8}/></strong><br/>
                                            <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                          geslotenLabel={t("ServiceAdresDetailPage.vandaag-gesloten", "Vandaag gesloten")}/>
                                        </>
                                    }>
                                        <MDBPopoverHeader>{t("ServiceAdresDetailPage.popover.titel", "Openingsuren")}</MDBPopoverHeader>
                                        <MDBPopoverBody>
                                            <MDBRow tag="dl">
                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.maandag", "Maandag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="maandag"/>
                                                </MDBCol>

                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.dinsdag", "Dinsdag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="dinsdag"/>
                                                </MDBCol>

                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.woensdag", "Woensdag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="woensdag"/>
                                                </MDBCol>

                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.donderdag", "Donderdag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="donderdag"/>
                                                </MDBCol>

                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.vrijdag", "Vrijdag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="vrijdag"/>
                                                </MDBCol>

                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.zaterdag", "Zaterdag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="zaterdag"/>
                                                </MDBCol>

                                                <MDBCol tag="dt" sm="5">
                                                    <strong>{t("ServiceAdresDetailPage.zondag", "Zondag:")}</strong>
                                                </MDBCol>
                                                <MDBCol tag="dd" sm="7">
                                                    <Openingsuren openingsuren={serviceAdres.openingsuren}
                                                                  dag="zondag"/>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBPopoverBody>
                                    </MDBPopover>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBContainer>
        </GenericPage>
    );
};
