import {useCallback} from "react";
import {browser2ServiceWorkerChannel} from "../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {useMe} from "../redux/slices/technicus/hooks";
import {workerRegistry} from "../workers/shared/workerRegistry";
import {WebWorkerName} from "../workers/webworker/webWorkerName";
import {useGeselecteerdeDag} from "../redux/slices/dagPlanning/hooks";
import dayjs from "dayjs";
import {WorkerMessageFactory} from "../workers/shared/workerMessageFactory";

export const useManualSyncToServer = () => {
    return useCallback(() => {
        browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createManualSyncToServerMessage());
    }, []);
};

export const useHydrateFrontend = () => {
    return useCallback(() => {
        browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createHydrateFrontendMessage());
    }, []);
};

export const useCalculateBezoeksessieSnapshot = () => {
    return useCallback(() => {
        return workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SNAPSHOT_WEB_WORKER)
            .sendRpc(WorkerMessageFactory.createCalculateBezoekSessieSnapshotMessage());
    }, []);
};

export const useSyncFromServer = () => {
    const me = useMe();

    return useCallback((datum: string) => {
        workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER)
            .publish(WorkerMessageFactory.createSyncPlanningMessage(datum, me?.id));
    }, [me]);
};

export const useSyncBezoekSessiesOpDatum = () => {
    return useCallback((datum: string) => {
        workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER)
            .publish(WorkerMessageFactory.createSyncActieveBezoekSessiesMessage(datum));
    }, []);
};

export const useSyncBezoekSessiesOpGeselecteerdeDag = () => {
    const sync = useSyncBezoekSessiesOpDatum();
    const [geselecteerdeDag] = useGeselecteerdeDag();

    return useCallback(() => sync(dayjs(geselecteerdeDag).format("YYYY-MM-DD")), [sync, geselecteerdeDag]);
};

export const useSyncBezoekSessieById = () => {
    return useCallback((bezoekSessieId: string) => {
        workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER)
            .publish(WorkerMessageFactory.createSyncBezoekSessieByIdMessage(bezoekSessieId));
    }, []);
};

export const useSyncActieveBezoekSessies = () => {
    return useCallback(() => {
        workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER)
            .publish(WorkerMessageFactory.createSyncActieveBezoekSessiesMessage());
    }, []);
};

export const useSyncArtikelen = () => {
    return useCallback((force = false) => {
        workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER)
            .publish(WorkerMessageFactory.createSyncArtikelenMessage(force));
    }, []);
};

export const useSyncArtikelAfbeeldingen = () => {
    return useCallback(() => {
        workerRegistry.getBrowserRpcChannelForWorker(WebWorkerName.SYNC_ARTIKEL_AFBEELDINGEN_WEB_WORKER)
            .publish(WorkerMessageFactory.createSyncArtikelAfbeeldingenMessage());
    }, []);
};
