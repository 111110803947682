import {combineReducers} from "redux";
import {CaseReducer, configureStore, PayloadAction, PrepareAction} from "@reduxjs/toolkit";
import {status} from "./slices/status/slice";
import {StatusState} from "./slices/status/types";
import createSagaMiddleware from "redux-saga";
import {rootSaga} from "./sagas";
import {ModalState} from "./slices/modal/types";
import {modal} from "./slices/modal/slice";
import {UiState} from "./slices/ui/types";
import {ui} from "./slices/ui/slice";

import {ResourceState} from "redux-resource";
import {klanten} from "./slices/klant/slice";
import {technici} from "./slices/technicus/slice";
import {ArtikelRestModel, Klant, ServiceAdres, Shift, Technicus, Toestel} from "../_generated/field-service-be-openapi";
import {bezoeksessies} from "./slices/bezoeksessie/slice";
import {AuthState} from "./slices/auth/types";
import {auth} from "./slices/auth/slice";
import {dagPlanning} from "./slices/dagPlanning/slice";
import {serviceAdressen} from "./slices/serviceAdressen/slice";
import {toestellen} from "./slices/toestellen/slice";
import {browser2ServiceWorkerChannel} from "../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {BezoekSessieState} from "../workers/shared/snapshot/bezoekSessieState";
import {downloads} from "./slices/download/slice";
import {MessageType} from "../workers/shared/channels/messageTypes";
import {shiften} from "./slices/shiften/slice";
import {dagPlanningEntry} from "./slices/dagPlanningEntry/slice";
import {artikelen} from "./slices/artikel/slice";
import {DagPlanningRecord} from "../utilities/db/repository/dagPlanningRepository";
import {DagPlanningEntryRecord} from "../utilities/db/repository/dagPlanningEntryRepository";

export type CaseReducerWithPayload<TState, TPayload> = CaseReducer<TState, PayloadAction<TPayload>> |
    { // type CaseReducerWithPrepare uses PayloadAction's default generic parameters which makes is unusable
        reducer: CaseReducer<TState, PayloadAction<TPayload, string, any, any>>;
        prepare: PrepareAction<TPayload>;
    };

export type CaseReducerWithoutPayload<TState> = CaseReducerWithPayload<TState, undefined>;

const reducer = combineReducers({
    auth: auth.reducer,
    ui: ui.reducer,
    status: status.reducer,
    modal: modal.reducer,
    downloads: downloads.reducer,

    artikelen: artikelen.reducer,
    dagPlanning: dagPlanning.reducer,
    dagPlanningEntry: dagPlanningEntry.reducer,
    klanten: klanten.reducer,
    serviceAdressen: serviceAdressen.reducer,
    technici: technici.reducer,
    bezoeksessies: bezoeksessies.reducer,
    toestellen: toestellen.reducer,
    shiften: shiften.reducer
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
    }).concat(
        sagaMiddleware
    )
});

sagaMiddleware.run(rootSaga);

export interface RootState {
    auth: AuthState;
    ui: UiState;
    status: StatusState;
    modal: ModalState;

    artikelen: ResourceState<string, ArtikelRestModel>;
    klanten: ResourceState<string, Klant>;
    serviceAdressen: ResourceState<string, ServiceAdres>;
    technici: ResourceState<string, Technicus>;
    dagPlanning: ResourceState<string, DagPlanningRecord>;
    dagPlanningEntry: ResourceState<string, DagPlanningEntryRecord>;
    toestellen: ResourceState<string, Toestel>;
    bezoeksessies: BezoekSessieState;
    downloads: ResourceState<string, void>;
    shiften: ResourceState<string, Shift>;
}

export type AppDispatch = typeof store.dispatch;

browser2ServiceWorkerChannel.subscribe(MessageType.REDUX, (message) => {
    store.dispatch(message.payload);
});
