import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion'; // Import AnimatePresence and motion.div
import type { AlertProps } from './types';
import { useOpenStatus } from '../../../utils/hooks';
import Portal from '../../../utils/Portal';

const defaultAnimationVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const MDBAlert: React.FC<AlertProps> = ({
  className,
  alertRef,
  position,
  delay = 1000,
  autohide,
  width = 'unset',
  containerRef,
  appendToBody,
  color,
  children,
  defaultOpen = false,
  open,
  onClose,
  onClosed,
  onOpen,
  onOpened,
  dismissBtn = false,
  animationVariants = {},
  initialAnimation = false,
  style,
  ...props
}) => {
  const [isOpenState, setIsOpenState] = useState(defaultOpen);
  const isOpen = useOpenStatus(isOpenState, open);

  const thisAlert = useRef<HTMLDivElement>(null);
  const alertReference = alertRef ? alertRef : thisAlert;

  const alertClasses = clsx(
    'alert',
    'fade',
    position && !containerRef && 'alert-fixed',
    dismissBtn && 'alert-dismissible',
    position && `alert-${position}`,
    containerRef && 'alert-absolute',
    color ? `alert-${color}` : 'alert-primary',
    isOpen && 'show',
    className
  );

  useEffect(() => {
    if (containerRef !== null && containerRef !== undefined) {
      containerRef.current.classList.add('parent-alert-relative');
    }
  }, [containerRef]);

  useEffect(() => {
    if (isOpen) {
      onOpen?.();
    }
  }, [isOpen, onOpen]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (autohide && isOpen) {
      timer = setTimeout(() => {
        onClose?.();
        setIsOpenState(false);
      }, delay);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [autohide, isOpen, delay, onClose]);

  const alertContent = (
    <AnimatePresence initial={initialAnimation}>
      {isOpen && (
        <motion.div
          {...props}
          variants={{ ...defaultAnimationVariants, ...animationVariants }}
          initial='closed'
          animate='open'
          exit='closed'
          onAnimationComplete={(e) => {
            if (e === 'open') {
              onOpened?.();
            } else {
              onClosed?.();
            }
          }}
          className={alertClasses}
          ref={alertReference}
          style={{
            ...style,
            width: width === 'unset' ? width : `${width}px`,
          }}
        >
          {children}
          {dismissBtn && (
            <button
              type='button'
              className='btn-close'
              aria-label='Close'
              onClick={() => {
                onClose?.();
                setIsOpenState(false);
              }}
            ></button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );

  return <Portal disablePortal={!appendToBody}>{alertContent}</Portal>;
};

export default MDBAlert;
