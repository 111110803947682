export class ObjectUtils {

    public static groupBy<T>(objectArray: T[], keyExtractor: (obj: T) => string) {
        const groupedObj: Partial<Record<string, T[]>> = {};

        for (const obj of objectArray) {
            const key = keyExtractor(obj)

            if (!groupedObj[key]) {
                groupedObj[key] = [];
            }

            groupedObj[key]!.push(obj);
        }

        return groupedObj;
    }

}
