import React, {useMemo} from "react";
import {
    MDBAlert,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTypography
} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiInformationOutline, mdiPlus} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useParams} from "react-router-dom";
import {useBezoekSessieById, useBezoekSessieToestel} from "../../../redux/slices/bezoeksessie/hooks";
import {useGoBack} from "../../../routes";
import {useArtikelen} from "../../../redux/slices/artikel/hooks";
import {
    ArtikelRestModel,
    StukToegevoegd,
    StukVerwijderd,
    ToestelComponent
} from "../../../_generated/field-service-be-openapi";
import * as Yup from "yup";
import {Form, Formik, FormikProps} from "formik";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {useHideModal, useShowModal} from "../../../redux/slices/modal/hooks";
import {StuklijstComponentRow} from "../../../components/toestel/stuklijst/StuklijstComponentRow";
import {useToestelStuklijstAangepast} from "../../../redux/slices/toestellen/hooks";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";
import {Trans} from "react-i18next";

export interface ToestelStuklijstFormValues {
    toegevoegdeStukken: StukToegevoegd[];
    verwijderdeStukken: StukVerwijderd[];
}

export interface ToestelStuklijstPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ToestelStuklijstPage: React.FC = () => {
    const goBack = useGoBack();

    const {bezoekSessieId, toestelId} = useParams<ToestelStuklijstPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const bezoekSessieToestel = useBezoekSessieToestel(bezoekSessie?.id, toestelId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const toestelStuklijstAangepast = useToestelStuklijstAangepast();

    const artikelen = useArtikelen();

    const showModal = useShowModal();
    const hideModal = useHideModal();

    const {t} = useTranslation("toestel");
    const serienummerIsVerplichtLabel = t("algemeen:Foutmeldingen.serienummer-is-verplicht",
        "Serienummer is verplicht");
    const locatieBeschrijvingIsVerplichtLabel = t("algemeen:Foutmeldingen.locatiebeschrijving-is-verplicht",
        "Locatiebeschrijving is verplicht");

    const initialValues = useMemo<ToestelStuklijstFormValues>(() => {
        return {
            serieNummer: bezoekSessieToestel?.informatie?.serieNummer || "",
            locatieBeschrijving: bezoekSessieToestel?.informatie?.locatieBeschrijving || "",
            nieuweToestelOpmerking: "",

            toegevoegdeStukken: bezoekSessieToestel?.stuklijstToegevoegdeStukken || [],
            verwijderdeStukken: bezoekSessieToestel?.stuklijstVerwijderdeStukken || []
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bezoekSessieToestel]);

    const validationSchema = useMemo(() => Yup.object().shape({
        serieNummer: Yup.string().required(serienummerIsVerplichtLabel),
        locatieBeschrijving: Yup.string().required(locatieBeschrijvingIsVerplichtLabel),
        nieuweToestelOpmerking: Yup.string().optional()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const onStukToevoegenClick = (formik: FormikProps<ToestelStuklijstFormValues>) => {
        showModal({
            type: FieldServiceModal.STUK_TOEVOEGEN_MODAL,
            props: {
                serviceAdresId: serviceAdres?.id,
                onConfirm: (artikel: ArtikelRestModel) => {
                    formik.setFieldValue("toegevoegdeStukken", [
                        ...(formik.values.toegevoegdeStukken || []),
                        {
                            artikelId: artikel.id
                        } as StukToegevoegd
                    ]);
                },
                onClose: () => hideModal()
            }
        });
    };

    const onRemoveClick = (item: ToestelComponent, formik: FormikProps<ToestelStuklijstFormValues>) => {
        showModal({
            type: FieldServiceModal.CONFIRM_MODAL,
            props: {
                title: t("algemeen:bevestig", "Bevestig"),
                content: t("ToestelStuklijstPage.weet-u-zeker-dat-dit-stuk-verwijderd-mag-worden",
                    "Weet u zeker dat dit stuk verwijderd mag worden?"),
                confirmColor: "danger",
                onConfirm: () => {
                    formik.setFieldValue("verwijderdeStukken", [
                        ...(formik.values.verwijderdeStukken || []),
                        {
                            id: item.id
                        } as StukVerwijderd
                    ]);
                },
                onCancel: () => {

                },
                onClose: () => hideModal()
            }
        });
    };

    const onUndoRemoveClick = (id: string, formik: FormikProps<ToestelStuklijstFormValues>) => {
        const stukken = formik.values.verwijderdeStukken || [];

        formik.setFieldValue("verwijderdeStukken", stukken.filter((item) => item.id !== id));
    };

    const onUndoAddClick = (artikelId: string, formik: FormikProps<ToestelStuklijstFormValues>) => {
        const stukken = formik.values.toegevoegdeStukken || [];

        formik.setFieldValue("toegevoegdeStukken", stukken.filter((item) => item.artikelId !== artikelId));
    };

    const onSubmit = (values: ToestelStuklijstFormValues) => {
        if (!bezoekSessie) {
            return;
        }

        toestelStuklijstAangepast(bezoekSessie.id, toestelId, {
            toegevoegdeStukken: values.toegevoegdeStukken,
            verwijderdeStukken: values.verwijderdeStukken
        });

        goBack();
    };

    if (!serviceAdres || !bezoekSessieToestel) {
        return null;
    }

    return (
        <GenericPage>
            <Header title={bezoekSessieToestel.informatie?.locatieBeschrijving || "Onbekende locatie"}
                    subtitle={t("ToestelStuklijstPage.stuklijst", "Stuklijst")}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <MDBAlert open color="warning">
                    <strong>{t("algemeen:opgelet", "Opgelet")}</strong><br/>
                    <Trans t={t}
                        i18nKey="stuklijst-aanpassen-beschrijven">
                    Met deze functie kan je de stuklijst aanpassen aan hoe je het toestel bij aankomst aangetroffen
                    hebt. Heb je de stuklijst aangepast door een onderhoud of een interventie, moet je dit op enkel op
                    het onderhoud- of interventiescherm ingeven.
                    </Trans>
                </MDBAlert>

                <Formik initialValues={initialValues} onSubmit={onSubmit}
                        validationSchema={validationSchema}>
                    {(formik) => {
                        const {values} = formik;

                        return (
                            <Form autoComplete="off">
                                <DisableAutocompletePlaceholderInput/>
                                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                                    <MDBCardHeader>
                                        <MDBRow>
                                            <MDBCol><Icon path={mdiInformationOutline}
                                                          size={1}/> {t("toestelinformatie","Toestelinformatie")}</MDBCol>
                                            <MDBCol size="auto">
                                                <BezoekMDBBtn onClick={() => onStukToevoegenClick(formik)}>
                                                    <Icon path={mdiPlus} size={1}/>
                                                    {t("ToestelStuklijstPage.stuk-toevoegen",
                                                        "Stuk toevoegen")}
                                                </BezoekMDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardHeader>
                                    <MDBCardBody className="p-0">
                                        <MDBTable responsive borderless striped>
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope="col">{t("algemeen:Titels.artikelnr-omschrijving", "Artikelnr. - Omschrijving")}</th>
                                                    <th scope="col">{t("algemeen:Titels.installatiedatum", "Installatiedatum")}</th>
                                                    <th scope="col"/>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {bezoekSessieToestel?.informatie?.componenten?.map((item) => (
                                                    <StuklijstComponentRow key={item.lijnNr}
                                                                           component={item}

                                                                           verwijderd={!!values.verwijderdeStukken?.map((item) => item.id)?.includes(item.id)}

                                                                           onRemove={() => onRemoveClick(item, formik)}
                                                                           onUndo={() => onUndoRemoveClick(item.id, formik)}
                                                    />
                                                ))}

                                                {values.toegevoegdeStukken?.map((item, index) => (
                                                    <StuklijstComponentRow key={index}
                                                                           artikel={artikelen[item.artikelId]}
                                                                           toegevoegd
                                                                           verwijderd={false}

                                                                           onUndo={() => onUndoAddClick(item.artikelId, formik)}
                                                    />
                                                ))}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCardBody>
                                </MDBCard>

                                {!bezoekSessieToestel?.aanwezig && (
                                    <MDBTypography note noteColor="warning">
                                        <Trans t={t}
                                            i18nKey="ToestelStuklijstPage.gelieve-eerst-in-te-checken">
                                        Gelieve eerst in te checken bij dit toestel om de stuklijst aan te kunnen
                                        passen.
                                        </Trans>
                                    </MDBTypography>
                                )}
                                <BezoekMDBBtn block size="lg" type="submit" className="mb-4"
                                              disabled={!bezoekSessieToestel?.aanwezig}>
                                    {t("algemeen:bewaren", "Bewaren")}
                                </BezoekMDBBtn>
                            </Form>
                        );
                    }}
                </Formik>
            </ContentContainer>
        </GenericPage>
    );
};
