import {createSlice} from "@reduxjs/toolkit";
import {AuthReducers, AuthState} from "./types";

export const auth = createSlice<AuthState, AuthReducers>({
    name: "auth",
    initialState: {},
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        }
    }
});
