import React, {ReactNode} from "react";

export interface OpsommingProps {
    items: (ReactNode | undefined | null)[];
}

export const Opsomming: React.FC<OpsommingProps> = (props) => {
    const {items} = props;

    const itemsToRender: ReactNode[] = [];

    const nonNullItems = items.filter(Boolean);

    for (let i = 0; i < nonNullItems.length; i++) {
        const item = nonNullItems[i];

        itemsToRender.push(<React.Fragment key={i}>{item}</React.Fragment>);

        const hasNext = nonNullItems[i + 1];
        const isNextLast = i + 1 === nonNullItems.length - 1;

        if (isNextLast) {
            itemsToRender.push(<span key={i}> en </span>);
        } else if (hasNext) {
            itemsToRender.push(<span key={i}>, </span>);
        }
    }

    return <>{itemsToRender}</>;
};
