import React, {useCallback, useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";
import Icon from "@mdi/react";
import {mdiCameraFlipOutline, mdiCameraIris} from "@mdi/js";
import {GenericPage} from "../components/GenericPage";
import {useDimensions} from "../utilities/useDimensions";

export interface CameraPageProps {
    onCapture?(imageData: string): void;
}

export const CameraPage: React.FC<CameraPageProps> = (props) => {
    const {onCapture} = props;

    const [containerRef, containerSize] = useDimensions();
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0);

    const videoRef = useRef<any>();

    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const videoDevices = mediaDevices.filter((device) => device.kind === "videoinput");

            // Label contains "camera2 0, facing back", "camera2 2, facing back", "camera2 1, facing front", ...
            // Comparing the strings gives us the sort we need (first the backs, then the fronts)
            setDevices(
                videoDevices.sort((v1, v2) => v1.label.localeCompare(v2.label))
            );
        },
        [setDevices]
    );

    // https://github.com/mozmorris/react-webcam/issues/244
    const stopTracks = () => {
        if (videoRef.current) {
            let stream = videoRef.current.video.srcObject;
            if (stream) {
                videoRef.current.video.srcObject = null;

                stream.getTracks().forEach((track: MediaStreamTrack) => {
                    stream.removeTrack(track);
                    track.stop();
                });
                stream = null; // this is probably redundant, but it fixes it so I'm happy.
            }
        }
    };

    useEffect(() => {
        return () => stopTracks();
    });

    useEffect(
        () => {
            navigator.mediaDevices?.enumerateDevices?.().then(handleDevices);
        },
        [handleDevices]
    );

    useEffect(() => {
        stopTracks();
    }, [selectedDeviceIndex]);

    const videoConstraints: MediaStreamConstraints["video"] = {
        width: {min: 1280, ideal: 4096, max: 4096},
        height: {min: 720, ideal: 2160, max: 2160},
        deviceId: devices?.[selectedDeviceIndex]?.deviceId
    };

    const capture = React.useCallback(
        () => {
            if (videoRef.current) {
                return videoRef.current.getScreenshot();
            }
        },
        [videoRef]
    );

    const onFlipClick = () => {
        if (selectedDeviceIndex + 1 === devices.length) {
            setSelectedDeviceIndex(0);

            return;
        }

        setSelectedDeviceIndex(selectedDeviceIndex + 1);
    };

    const onCaptureClick = useCallback(async () => {
        const image = capture();

        onCapture?.(image);
    }, [capture, onCapture]);

    return (
        <GenericPage style={{zIndex: 3000}}>
            <div className="w-100 flex-fill bg-black d-flex justify-content-center align-items-center"
                 ref={containerRef}>
                {devices?.[selectedDeviceIndex]?.deviceId && containerSize?.height && (
                    <Webcam audio={false} videoConstraints={videoConstraints} width={containerSize.width}
                            height={containerSize.height} ref={videoRef} style={{width: "100%", height: "auto"}}
                            onUserMediaError={(error) => console.error(error)}
                            screenshotFormat="image/jpeg" forceScreenshotSourceSize
                    />
                )}
            </div>

            <div style={{position: "absolute", left: "50%", bottom: 100, transform: "translateX(-50%)"}}>
                {/*<MDBRipple rippleColor="dark" tag="div" onClick={onShutterClick}*/}
                {/*           style={{backgroundColor: "white", borderRadius: "50%", width: 80, height: 80}}/>*/}

                <div onClick={onCaptureClick}>
                    <Icon path={mdiCameraIris} size={4} color="white"/>
                </div>
            </div>
            <div style={{position: "absolute", right: "1rem", bottom: "1rem"}}>
                <div onClick={onFlipClick}>
                    <Icon path={mdiCameraFlipOutline} size={2} color="white"/>
                </div>
            </div>
        </GenericPage>
    );
};
