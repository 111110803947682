import * as React from "react";
import {Calendar, CalendarProps, momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import dayjs from "dayjs";
import {MDBSpinner} from "mdb-react-ui-kit";
import {useTranslation} from "../utilities/i18nUtils";

export interface KalenderProps extends Omit<CalendarProps, "localizer"> {
    loading: boolean;
}

const Kalender: React.FC<KalenderProps> = (props) => {

    const {t} = useTranslation("algemeen");

    const localizer = momentLocalizer(dayjs);

    const messages = {
        date: t("Kalender.datum", "Datum"),
        time: t("Kalender.tijd", "Tijd"),
        event: t("Kalender.gebeurtenis", "Gebeurtenis"),
        allDay: t("Kalender.de-hele-dag", "De Hele Dag"),
        week: t("Kalender.week", "Week"),
        work_week: t("Kalender.werkweek", "Werkweek"),
        day: t("Kalender.dag", "Dag"),
        month: t("Kalender.maand", "Maand"),
        previous: t("Kalender.vorige", "Vorige"),
        next: t("Kalender.volgende", "Volgende"),
        yesterday: t("Kalender.gisteren", "Gisteren"),
        tomorrow: t("Kalender.morgen", "Morgen"),
        today: t("Kalender.vandaag", "Vandaag"),
        agenda: t("Kalender.agenda", "Agenda"),
        noEventsInRange: t("Kalender.er-zijn-geen-gebeurtenissen-in-dit-bereik", "Er zijn geen gebeurtenissen in dit bereik."),
        showMore: function showMore(total: any) {
            return t("Kalender.toon-meer",
                "+ {{total}} meer",
                {total: total});
        }
    };

    return (
        <React.Fragment>
            {(props.loading) &&
            <MDBSpinner style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
            }}/>
            }
            <Calendar
                {...props}
                localizer={localizer}
                messages={messages}
            />
        </React.Fragment>
    );
};

export default Kalender;
