import React from "react";
import {AdresAanwezigheid} from "../../workers/shared/snapshot/bezoekSessieState";
import {installTranslationKey, useTranslation} from "../../utilities/i18nUtils";
import {MDBBadge} from "../../mdb-react-ui-kit/index";

export interface AdresAanwezigheidPillProps {
    aanwezigheid: AdresAanwezigheid;
}

export const AdresAanwezigheidPill: React.FC<AdresAanwezigheidPillProps> = (props) => {

    const aanwezigheid = props.aanwezigheid;

    const {t} = useTranslation("algemeen");

    const translationKeys: Record<AdresAanwezigheid, string> = {
        [AdresAanwezigheid.AANWEZIG] : installTranslationKey("algemeen:AdresAanwezigheid.aanwezig", "AANWEZIG"),
        [AdresAanwezigheid.ONDERWEG] : installTranslationKey("algemeen:AdresAanwezigheid.onderweg", "ONDERWEG"),
        [AdresAanwezigheid.OBSERVATOR] : installTranslationKey("algemeen:AdresAanwezigheid.observator", "OBSERVATOR"),
        [AdresAanwezigheid.VERLATEN] : installTranslationKey("algemeen:AdresAanwezigheid.verlaten", "VERLATEN")
    };

    return (
        <MDBBadge pill
                          className="ms-2"
                          color={[AdresAanwezigheid.ONDERWEG, AdresAanwezigheid.AANWEZIG].includes(aanwezigheid) ? "success" : undefined}>
            {t(translationKeys[aanwezigheid], aanwezigheid)}

    </MDBBadge>)
}


