import React, {useMemo} from "react";
import {MDBBtn, MDBModalBody, MDBModalHeader, MDBTypography} from "mdb-react-ui-kit";
import {useServiceAdres} from "../../redux/slices/serviceAdressen/hooks";
import {GoogleMapsUtils} from "../../utilities/googleMapsUtils";
import {CoordinaatRestModel, CoordinaatRestModelTypeEnum} from "../../_generated/field-service-be-openapi";
import {FrameModal} from "./FrameModal";
import {useKlant} from "../../redux/slices/klant/hooks";
import Icon from "@mdi/react";
import {mdiArrowTopRight} from "@mdi/js";
import {NavLink} from "react-router-dom";
import {useHuidigeBezoekSessie} from "../../redux/slices/bezoeksessie/hooks";
import {useTranslation} from "../../utilities/i18nUtils";
import {Trans} from "react-i18next";

export interface OnderwegModalProps {
    serviceAdresId?: string;
    coordinaten?: CoordinaatRestModel;

    onOnderwegClick?(): void;

    onClose?(): void;
}

export const OnderwegModal: React.FC<OnderwegModalProps> = (props) => {
    const {serviceAdresId, coordinaten, onOnderwegClick, onClose} = props;

    const serviceAdres = useServiceAdres(serviceAdresId);
    const huidigBezoek = useHuidigeBezoekSessie();
    const klant = useKlant(huidigBezoek?.serviceAdres?.klantId);

    const onAnnuleerClick = () => {
        onClose?.();
    };

    const coordinaat = useMemo(() => {
        return coordinaten || serviceAdres?.coordinaten?.find((item) => item.type === CoordinaatRestModelTypeEnum.NavigerenNaar);
    }, [serviceAdres, coordinaten]);

    const {t} = useTranslation("bezoeken");

    const huidigeKlantNaam = klant?.naam;

    const huidigServiceAdresNaam = huidigBezoek?.serviceAdres?.naam;

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>{t("OnderwegModal.onderweg", "Onderweg")}</MDBModalHeader>
            <MDBModalBody className="py-1 pt-3">
                {huidigBezoek && (

                    <MDBTypography note noteColor="warning" className="d-flex justify-content-between">
                        <div>
                            <Trans t={t}
                                i18nKey="OnderwegModal.opgelet-je-bent-reeds-op-weg">
                            <strong>Opgelet</strong> Je bent reeds op weg naar {{huidigServiceAdresNaam}} bij
                            klant {{huidigeKlantNaam}}. Check daar eerst uit door ernaar te navigeren (klik op de pijl).
                            </Trans>
                        </div>

                        <NavLink className="p-2 btn btn-link" to={`/bezoek/${huidigBezoek.id}`}
                                 onClick={() => onClose?.()}>
                            <Icon path={mdiArrowTopRight} size={1}/>
                        </NavLink>
                    </MDBTypography>
                )}

                <div className="d-flex justify-content-end align-items-center my-3">
                    <MDBBtn type="button" size="lg" className="ms-2" onClick={onAnnuleerClick} color="secondary">
                        {t("OnderwegModal.annuleren", "Annuleren")}
                    </MDBBtn>
                    <a className="ms-2 btn btn-primary btn-lg" type="submit"
                       href={coordinaat && GoogleMapsUtils.getGeoLink(coordinaat.latitude!, coordinaat.longitude!)}>
                        {t("OnderwegModal.open-navigatie", "Open navigatie")}
                    </a>
                    {serviceAdresId && (
                        <MDBBtn onClick={onOnderwegClick} color="success" size="lg" className="ms-2"
                                type="submit" disabled={!!huidigBezoek}>
                            {t("OnderwegModal.registreer-mij-als-onderweg", "Registreer mij als onderweg")}
                        </MDBBtn>
                    )}
                </div>
            </MDBModalBody>
        </FrameModal>
    );
};
