import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, put} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {shiften} from "./slice";
import {DagPlanningByTechnicusIdAndDatumRequest, Shift} from "../../../_generated/field-service-be-openapi";
import {shiftenApi} from "../../../utilities/api";

export const shiftenSaga = createSliceSaga({
    name: "shiftenSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * fetchShiften(action: PayloadAction<{ request: DagPlanningByTechnicusIdAndDatumRequest,  onSuccess?(): void }>) {
            const { technicusId,  van, tot} = action.payload.request;
            const { onSuccess } = action.payload;

            const requestKey = `technicusId=${technicusId}_van=${van}_tot${tot}`;

            yield put(shiften.actions.pending({requestKey}));

            try {
                const result = (yield call(shiftenApi.dagPlanningByTechnicusIdAndDatum.bind(shiftenApi), action.payload.request)) as unknown as Array<Shift>;

                if (result) {
                    const resultWithIds = result.map(shift => ({...shift, id: shift.datum}));

                    yield put(shiften.actions.succeeded({requestKey, resources: resultWithIds}));
                }

                if (onSuccess) {
                    yield call(onSuccess);
                }

            } catch (error) {
                yield put(shiften.actions.failed({requestKey, requestProperties: {error: JSON.stringify({error})}}));
            }

        }
    }
});
