import React from "react";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {FormikTimeInput, FormikTimeInputProps} from "../FormikTimeInput";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";

export const BezoekFormikTimeInput: React.FC<FormikTimeInputProps> = (props) => {
    const {bezoekSessieId} = useParams<{ bezoekSessieId?: string }>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    return (
        <FormikTimeInput {...props} disabled={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>
    );
};
