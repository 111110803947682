import React, {useEffect, useState} from "react";
import {LoadingPage} from "../pages/LoadingPage";
import {workerRegistry} from "../workers/shared/workerRegistry";
import {WebWorkerName} from "../workers/webworker/webWorkerName";
import {store} from "../redux/store";
import {Logger} from "../utilities/logger";
import {MDBBtn} from "../mdb-react-ui-kit/index";
import {useTranslation} from "../utilities/i18nUtils";

const logger = Logger.create("WorkerLoader");

export const WorkerLoader: React.FC<{children?: React.ReactNode}> = (props) => {
    const [showRetryButton, setShowRetryButton] = useState(false);

    useEffect(() => {
        const handle = setTimeout(() => setShowRetryButton(true), 6_000);

        return () => {
            clearTimeout(handle);
        };
    }, []);

    const onReloadClick = () => {
        window.location.reload();
    };

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        async function run() {
            logger.info("Loading workers...");

            const syncFromServerWebWorker = new Worker(new URL("../workers/webworker/workers/syncFromServerWebWorker", import.meta.url));
            const syncArtikelAfbeeldingenWebWorker = new Worker(new URL("../workers/webworker/workers/syncArtikelAfbeeldingenWebWorker", import.meta.url));
            const snapshotWebWorker = new Worker(new URL("../workers/webworker/workers/snapshotWebWorker", import.meta.url));

            logger.info("Registering workers...");
            await workerRegistry.register(WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER, syncFromServerWebWorker);
            await workerRegistry.register(WebWorkerName.SYNC_ARTIKEL_AFBEELDINGEN_WEB_WORKER, syncArtikelAfbeeldingenWebWorker);
            await workerRegistry.register(WebWorkerName.SNAPSHOT_WEB_WORKER, snapshotWebWorker);

            logger.info("Subscribing workers to Redux store...");
            workerRegistry.subscribeReduxStore(message => store.dispatch(message.payload));

            await workerRegistry.start();

            logger.info("Loaded");
            setLoaded(true);
        }

        run();
    }, []);

    const {t} = useTranslation("algemeen");

    if (!loaded) {
        return (
            <LoadingPage descriptionClassName="" description={
                <>
                    <div className="display-6">
                        {t("Workerloader.opstarten", "Opstarten...")}
                        <br/>
                        <small className="text-muted">{t("Workerloader.webworker-registreren", "Webworkers registreren")}</small>
                    </div>

                    {showRetryButton && (
                        <div className="mt-4">
                            <div className="mb-3">
                                <div>
                                    {t("Workerloader.opstarten-duurt-lang",
                                        "Het lijkt erop dat het opstarten lang duurt.")}
                                </div>

                                <div>
                                    <small className="text-muted">
                                        {t("Workerloader.herladen-kan-helpen",
                                            "Het herladen van de applicatie kan helpen. Druk daarvoor op de onderstaande knop.")}
                                    </small>
                                </div>
                            </div>

                            <MDBBtn color="secondary" outline onClick={onReloadClick}>
                                {t("Workerloader.herlaad-de-applicatie", "Herlaad de applicatie")}
                            </MDBBtn>
                        </div>
                    )}
                </>
            }/>
        );
    }

    return <>{props.children}</>;
};
