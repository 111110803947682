import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {BezoekSessie, BezoekSessieState} from "../../../workers/shared/snapshot/bezoekSessieState";
import {CaseReducerWithoutPayload, CaseReducerWithPayload} from "../../store";

const initialState: BezoekSessieState = Object.freeze({
    loaded: false,
    bezoekSessies: {},
    bezoekIdMap: {}
});

export interface BezoekSessieReducers extends SliceCaseReducers<BezoekSessieState> {
    snapshot: CaseReducerWithPayload<BezoekSessieState, {snapshot: BezoekSessie}>;
    reset: CaseReducerWithoutPayload<BezoekSessieState>;
}

export const bezoeksessies = createSlice<BezoekSessieState, BezoekSessieReducers>({
    name: "bezoeksessies",
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true;
        },
        snapshot(state, action) {
            const bezoekSessieId = action.payload.snapshot.id;
            const bezoekId = action.payload.snapshot.bezoekId!;
            const newState = action.payload.snapshot;

            state.loaded = true;
            state.bezoekSessies[bezoekSessieId] = newState;
            state.bezoekIdMap[bezoekId] = bezoekSessieId;
        },
        reset() {
            return initialState;
        }
    }
});
