import React from "react";
import Icon from "@mdi/react";
import {mdiHammerWrench} from "@mdi/js";
import {DagPlanningBezoekEntryRestModel} from "../../../_generated/field-service-be-openapi";
import {useBezoekSessie} from "../../../redux/slices/bezoeksessie/hooks";

export interface BezoekMarkerProps {
    entry: DagPlanningBezoekEntryRestModel;
    index?: number;

    onClick?: () => void;
}

export const BezoekMarker: React.FC<BezoekMarkerProps> = (props) => {
    const {entry, index, onClick} = props;

    const bezoekSessie = useBezoekSessie(entry.bezoekId);

    let color = "#a1a1a1";

    if (bezoekSessie?.onderweg) {
        color = "#fd6535";
    } else if (bezoekSessie?.aanwezig) {
        color = "#39afd1";
    } else if (bezoekSessie?.verlaten) {
        color = "#42d29d";
    }

    const active = bezoekSessie?.onderweg || bezoekSessie?.aanwezig;

    return (
        <div style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            backgroundColor: color,
            border: "2px solid #fff",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }} onClick={onClick} className={`position-relative d-flex align-items-center justify-content-center map-marker ${active ? "active" : ""}`}>
            <span className="text-white" style={{fontSize: "1rem"}}>{(index || 0) + 1}</span>

            <Icon path={mdiHammerWrench} size={0.6} color="#000" style={{position: "absolute", right: -4, bottom: -4}}/>
        </div>
    );
};
