"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSliceSaga = exports.getType = exports.createSagas = exports.createSaga = exports.SagaType = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const effects_1 = require("redux-saga/effects");
var SagaType;
(function (SagaType) {
    SagaType[SagaType["Watch"] = 0] = "Watch";
    SagaType[SagaType["Normal"] = 1] = "Normal";
    SagaType[SagaType["TakeLatest"] = 2] = "TakeLatest";
    SagaType[SagaType["TakeEvery"] = 3] = "TakeEvery";
})(SagaType = exports.SagaType || (exports.SagaType = {}));
function createSaga(sagaType, type, sagaFunction) {
    switch (sagaType) {
        case SagaType.TakeLatest:
            return function* () {
                yield effects_1.takeLatest(type, sagaFunction);
            };
        case SagaType.Watch:
            return function* () {
                while (true) {
                    const action = yield effects_1.take(type);
                    yield effects_1.fork(sagaFunction, action);
                }
            };
        default:
            return function* () {
                yield effects_1.takeEvery(type, sagaFunction);
            };
    }
}
exports.createSaga = createSaga;
function createSagas(sagas) {
    const sagaTemp = sagas.map((saga) => {
        return effects_1.call(saga);
    });
    return function* () {
        yield effects_1.all(sagaTemp);
    };
}
exports.createSagas = createSagas;
function getType(slice, actionKey) {
    return `${slice}/${actionKey}`;
}
exports.getType = getType;
function isCustomCaseSaga(value) {
    return (typeof value === 'object' &&
        typeof value.fn === 'function');
}
function createSliceSaga(options) {
    const { caseSagas, name, sagaType: globalSagaType } = options;
    const caseSagasNames = Object.keys(caseSagas);
    const actionCreators = {};
    const sagas = [];
    caseSagasNames.forEach((sagaName) => {
        let toBeCalled;
        const type = getType(name, sagaName);
        const currentCaseSaga = caseSagas[sagaName];
        actionCreators[sagaName] = toolkit_1.createAction(type);
        if (isCustomCaseSaga(currentCaseSaga)) {
            toBeCalled =
                currentCaseSaga.sagaType === SagaType.Normal
                    ? currentCaseSaga.fn
                    : createSaga(currentCaseSaga.sagaType, type, currentCaseSaga.fn);
        }
        else {
            toBeCalled =
                globalSagaType === SagaType.Normal
                    ? currentCaseSaga
                    : createSaga(globalSagaType, type, currentCaseSaga);
        }
        sagas.push(effects_1.call(toBeCalled));
    });
    function* saga() {
        yield effects_1.all(sagas);
    }
    return {
        saga,
        name,
        actions: actionCreators,
        sagaType: globalSagaType,
    };
}
exports.createSliceSaga = createSliceSaga;
