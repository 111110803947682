import React, {useCallback, useRef, useState} from "react";
import {MDBBtn, MDBCol, MDBDatepicker, MDBRow, MDBSelect} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiCalendarOutline, mdiCarOutline, mdiMapMarkerOutline, mdiTimerOutline} from "@mdi/js";
import {Variants} from "framer-motion";
import {GenericPage} from "../components/GenericPage";
import {useDagPlanning, useGeselecteerdeDag} from "../redux/slices/dagPlanning/hooks";
import dayjs from "dayjs";
import {
    AbstractDagPlanningEntryRestModel,
    DagPlanningBezoekEntryRestModel,
    DagPlanningRitRestModel
} from "../_generated/field-service-be-openapi";
import {useBezoekSessieError} from "../redux/slices/bezoeksessie/hooks";
import {PlanningLeeg} from "../components/dagPlanning/PlanningLeeg";
import {Afstand} from "../components/Afstand";
import {
    dateMonths,
    dateMonthsShort,
    dateNow,
    dateWeekdays,
    dateWeekdaysMin,
    dateWeekdaysShort,
    formatDate,
    formatDuration,
    parseDate
} from "../utilities/dateUtils";
import {useShowSidenav} from "../redux/slices/ui/hooks";
import {PlanningLoading} from "../components/dagPlanning/PlanningLoading";
import {PlanningError} from "components/dagPlanning/PlanningError";
import {Datum} from "../components/Datum";
import {HomePageMap} from "../components/map/HomePageMap";
import {PlanningEntryCard} from "../components/dagPlanning/PlanningEntryCard";
import {useSyncingPlanning} from "../redux/slices/status/hooks";
import {Assets} from "../assets/assets";
import {sumReducer} from "../utilities/reduceUtils";
import {HomePageHeader} from "./HomePageHeader";
import {PlanningEindeVanDeDagWidget} from "../components/dagPlanning/PlanningEindeVanDeDagWidget";
import {PlanningHeader} from "../components/dagPlanning/PlanningHeader";
import {useTranslation} from "../utilities/i18nUtils";
import {requestStatuses} from "redux-resource";
import {SelectData} from "../mdb-react-ui-kit/pro/forms/SelectV2/types";

const homePageVariants: Variants = {
    initial: {
        y: "-5%"
    },
    in: {
        y: 0
    },
    out: {
        y: "-5%"
    }
};

const datumToday = dateNow();
const datumTodayFormatted = dayjs(datumToday).format("YYYY-MM-DD");

let timeoutHandle: NodeJS.Timeout | undefined = undefined;

export const HomePage: React.FC = () => {
    const {t} = useTranslation("algemeen");

    const entryRefs = useRef<{ ref: HTMLDivElement, entry: AbstractDagPlanningEntryRestModel }[]>([]);
    const [selectedEntryId, setSelectedEntryId] = useState<string>();

    const datePickerContainerRef = useRef<HTMLDivElement>();

    const [geselecteerdeDag, setGeselecteerdeDag] = useGeselecteerdeDag();

    const bezoekSessieError = useBezoekSessieError();

    const syncingPlanning = useSyncingPlanning();

    const [dagPlanning, metadata] = useDagPlanning(geselecteerdeDag!);
    const showSidenav = useShowSidenav();

    const openDatePicker = useCallback(() => {
        const toggleButton = datePickerContainerRef.current?.querySelector(".datepicker-toggle-button");

        if (toggleButton) {
            (toggleButton as HTMLButtonElement).click();
        }
    }, []);

    const totaleDuurtijd = dagPlanning?.entries?.map(item => item.duurtijd).reduce((prev, curr) => (prev || 0) + (curr || 0), 0);
    const totaleAfstand = dagPlanning?.entries?.filter(item => item._type === "RIT")?.map((item: DagPlanningRitRestModel) => item.afstand)?.reduce((prev, curr) => (prev || 0) + (curr || 0), 0) || 0;
    const aantalInstallaties = (dagPlanning?.entries?.filter(item => item._type === "BEZOEK") || [])
        .map((entry) => {
            const item = entry as DagPlanningBezoekEntryRestModel;
            return item.uitTeVoerenWerk?.installaties?.length || 0;
        })
        .reduce(sumReducer, 0);
    const aantalOnderhouden = (dagPlanning?.entries?.filter(item => item._type === "BEZOEK") || [])
        .map((entry) => {
            const item = entry as DagPlanningBezoekEntryRestModel;
            return item.uitTeVoerenWerk?.toestellen?.filter(toestel => toestel.onderhoud)?.length || 0;
        })
        .reduce(sumReducer, 0);
    const aantalInterventies = (dagPlanning?.entries?.filter(item => item._type === "BEZOEK") || [])
        .map((entry) => {
            const item = entry as DagPlanningBezoekEntryRestModel;

            return item.uitTeVoerenWerk?.toestellen?.map(toestel => toestel.interventies?.length || 0).reduce(sumReducer, 0);
        })
        .reduce(sumReducer, 0);

    const onMarkerClick = useCallback((entry: AbstractDagPlanningEntryRestModel) => {
        const ref = entryRefs.current.find((item) => item.entry.id === entry.id);

        if (ref?.ref) {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }

            setSelectedEntryId(entry.id);
            ref.ref.scrollIntoView({
                behavior: "smooth"
            });

            timeoutHandle = setTimeout(() => setSelectedEntryId(undefined), (1200 * 4) + 1000);
        }
    }, []);

    const renderLoadingOrEmptyOrError = () => {
        if (metadata.request?.status === requestStatuses.FAILED && metadata.request?.error) {
            return (
                <MDBCol size="12" className="mb-4">
                    <PlanningError error={metadata.request.error}/>
                </MDBCol>
            );
        }

        if (!syncingPlanning && !dagPlanning?.entries?.length) {
            return (
                <MDBCol size="12" className="mb-4">
                    <PlanningLeeg dagPlanning={dagPlanning}/>
                </MDBCol>
            );
        }

        if (syncingPlanning && !dagPlanning) {
            return (
                <MDBCol size="12" className="mb-4">
                    <PlanningLoading/>
                </MDBCol>
            );
        }
    };

    const datePickerAnnuleerKnopLabel = t("DatePicker.annuleer", "Annuleer");
    const datePickerHerstelKnopLabel = t("DatePicker.herstel", "Herstel");
    const datePickerTitelLabel = t("DatePicker.titel", "Selecteer een datum");

    return (
        <GenericPage variants={homePageVariants}>
            <div className="position-sticky" style={{top: 0, bottom: 0, zIndex: -1}}>
                <HomePageMap dagPlanning={dagPlanning} onMarkerClick={onMarkerClick}/>

                <div className="position-absolute d-flex justify-content-center pt-3 px-2"
                     style={{left: 0, top: 0, right: 0}}>
                    <div className="container-lg">
                        <MDBRow>
                            <MDBCol sm="12" md="3"
                                    className="d-flex bg-light shadow text-primary justify-content-center align-items-center"
                                    style={{borderRadius: "2rem"}}>
                                <MDBBtn type="button" color="link" className="ps-1 pe-2" onClick={openDatePicker}>
                                    <Icon path={mdiCalendarOutline} size={1} className="text-primary"/>
                                </MDBBtn>

                                {/* TODO: verplaatsen naar een select component waar de getoonde waarde anders kan zijn */}
                                <div className="position-absolute pointer-events-none">
                                    <Datum datum={geselecteerdeDag!} format="DD/MM/YYYY" relativeDay/>
                                </div>

                                <MDBSelect
                                    className="borderless p-0 m-0 text-primary w-100"
                                    style={{opacity: 0}}
                                    data={[
                                        {text: t("algemeen:Datums.gisteren","Gisteren") as string, value: -1},
                                        {text: t("algemeen:Datums.vandaag", "Vandaag") as string, value: 0},
                                        {text: t("algemeen:Datums.morgen", "Morgen") as string, value: 1},
                                        {text: t("algemeen:Datums.overmorgen", "Overmorgen") as string, value: 2},
                                        {text: t("algemeen:Datums.binnen-3-dagen", "Binnen 3 dagen") as string, value: 3}
                                    ]}

                                    onChange={(value: SelectData[] | SelectData) => {
                                        const selectedValue = value as SelectData;
                                        if (selectedValue) {
                                            setGeselecteerdeDag(dayjs().add(Number(selectedValue.value), "day").format("YYYY-MM-DD"));
                                        }
                                    }}
                                />

                                <div className="d-block d-md-none">
                                    <HomePageHeader showSidenav={showSidenav}/>
                                </div>
                            </MDBCol>

                            <MDBCol className="ms-md-3 bg-light shadow text-primary d-none d-md-block"
                                    style={{borderRadius: "2rem"}}>
                                <MDBRow>
                                    <MDBCol className="d-flex align-items-center text-nowrap">
                                        <MDBRow>
                                            <MDBCol className="d-flex align-items-center justify-content-center me-2">
                                                <Icon path={mdiTimerOutline} size={1} className="me-1"/>
                                                {formatDuration("HH:mm", totaleDuurtijd || 0, "minutes")}
                                            </MDBCol>
                                            <MDBCol className="d-flex align-items-center justify-content-center me-2">
                                                <Icon path={mdiMapMarkerOutline} size={1} className="me-1"/>
                                                {dagPlanning?.entries?.filter(item => item._type === "BEZOEK")?.length || 0}
                                            </MDBCol>
                                            {!!aantalInstallaties && (
                                                <MDBCol
                                                    className="d-flex align-items-center justify-content-center me-2">
                                                    <Icon path={Assets.orderTypes.installatie} size={1}
                                                          className="me-1"/>
                                                    {aantalInstallaties}
                                                </MDBCol>
                                            )}
                                            {!!aantalInterventies && (
                                                <MDBCol
                                                    className="d-flex align-items-center justify-content-center me-2">
                                                    <Icon path={Assets.orderTypes.interventie} size={1}
                                                          className="me-1"/>
                                                    {aantalInterventies}
                                                </MDBCol>
                                            )}
                                            {!!aantalOnderhouden && (
                                                <MDBCol
                                                    className="d-flex align-items-center justify-content-center me-2">
                                                    <Icon path={Assets.orderTypes.onderhoud} size={1} className="me-1"/>
                                                    {aantalOnderhouden}
                                                </MDBCol>
                                            )}
                                            <MDBCol className="d-flex align-items-center justify-content-center me-2">
                                                <Icon path={mdiCarOutline} size={1} className="me-1"/>
                                                <Afstand afstand={totaleAfstand}/>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>

                                    <HomePageHeader showSidenav={showSidenav}/>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </div>

                    <div className="visually-hidden" ref={datePickerContainerRef as any}>
                        <MDBDatepicker onChange={(date: string) => {
                            if (date === "") {
                                return;
                            }

                            if (date) {
                                setGeselecteerdeDag(formatDate(parseDate(date, "DD/MM/YYYY"), "YYYY-MM-DD"));
                            } else {
                                setGeselecteerdeDag(datumTodayFormatted);
                            }
                        }}

                                       cancelBtnText={datePickerAnnuleerKnopLabel}
                                       clearBtnText={datePickerHerstelKnopLabel}

                                       monthsFull={dateMonths()}
                                       monthsShort={dateMonthsShort()}
                                       weekdaysFull={dateWeekdays()}
                                       weekdaysShort={dateWeekdaysShort()}
                                       weekdaysNarrow={dateWeekdaysMin()}
                                       startDay={1}
                                       title={datePickerTitelLabel}

                                       startDate={geselecteerdeDag}
                        />
                    </div>
                </div>
            </div>

            <div className="pb-3 flex-fill bg-white mt-n1 shadow-top container-lg"
                 style={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}>

                <div className="mb-3 mb-sm-4">
                    <PlanningHeader/>
                </div>

                <MDBRow className="pb-4 px-sm-2">
                    {renderLoadingOrEmptyOrError()}

                    {!syncingPlanning && !dagPlanning?.entries?.length && bezoekSessieError && (
                        <MDBCol className="px-4" size="12">
                            <PlanningError error={bezoekSessieError}/>
                        </MDBCol>
                    )}

                    <MDBCol>
                        {dagPlanning?.entries?.map((item, index, array) => (
                            <PlanningEntryCard key={index} entry={item} index={index}
                                               dagPlanning={dagPlanning} datum={geselecteerdeDag}
                                               containerRef={(ref) => entryRefs.current.push({ref, entry: item})}
                                               className={item.id === selectedEntryId ? "pulse" : ""}
                            />
                        ))}
                    </MDBCol>
                </MDBRow>

                <PlanningEindeVanDeDagWidget/>
            </div>
        </GenericPage>
    );
};
