import "./apm";
import React, {useEffect} from "react";
import "./i18n";
import {BrowserRouter as Router} from "react-router-dom";
import "./App.scss";
import {Footer} from "./components/Footer";
import {Routes} from "./routes/Routes";
import {useJsApiLoader} from '@react-google-maps/api';
import {Config} from "./utilities/config";
import {ServiceWorkerProvider} from "./redux/providers/serviceWorkerProvider";
import {ModalRoot} from "./components/ModalRoot";
import {Sidenav} from "./components/Sidenav";
import {ViewportBorder} from "./components/ViewportBorder";
import {AuthProvider} from "./redux/providers/authProvider";
import {SnapshotProvider} from "./redux/providers/snapshotProvider";
import {LoadingPage} from "./pages/LoadingPage";
import {NetworkStatusProvider} from "./redux/providers/networkStatusProvider";
import {GpsUtils} from "./utilities/gpsUtils";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BatteryProvider} from "./redux/providers/batteryProvider";
import {ErrorBoundaryContainer} from "./components/error/ErrorBoundaryContainer";
import {WorkerLoader} from "./components/WorkerLoader";
import {InloggenLoadingPage} from "./pages/InloggenLoadingPage";

export const App: React.FC = () => {
    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: Config.GOOGLE_MAPS_KEY
    });

    useEffect(() => {
        if (!GpsUtils.checkPermission()) {
            GpsUtils.getLocation();
        }
    }, []);

    if (!isLoaded) {
        return <LoadingPage description="Google Maps inladen..."/>;
    }

    return (
        <Provider store={store}>
            {Config.VIEWPORT_BORDER_COLOR && <ViewportBorder color={Config.VIEWPORT_BORDER_COLOR}/>}

            <ErrorBoundaryContainer>
                <ServiceWorkerProvider>
                    <NetworkStatusProvider/>

                    <AuthProvider unauthenticatedChildren={<InloggenLoadingPage/>}>
                        <WorkerLoader>
                            <BatteryProvider/>

                            <SnapshotProvider>
                                <Router>
                                    <ModalRoot/>
                                    <Sidenav/>

                                    <Routes/>

                                    <Footer/>
                                </Router>
                            </SnapshotProvider>
                        </WorkerLoader>
                    </AuthProvider>
                </ServiceWorkerProvider>
            </ErrorBoundaryContainer>
        </Provider>
    );
}

