import {resourceReducer} from "redux-resource";
import createActionCreators from "redux-resource-action-creators";

const reducer = resourceReducer("shiften");
const actions = createActionCreators("read", {
    resourceType: "shiften"
});

export const shiften = {
    reducer,
    actions
};
