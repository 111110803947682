import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCallback} from "react";
import {ModalStackItem} from "./types";
import {modal} from "./slice";
import {useHistory} from "react-router-dom";

export const useModalStack = () => useAppSelector((state) => Object.freeze(state.modal.modalStack));

export const useShowModal = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    return useCallback((payload: ModalStackItem<any>) => {
        // TODO: vervang ModalRoot om op basis van history te werken zodat deze "hack" niet nodig is
        // dan zal back button "history back" doen waardoor modal vanzelf verdwijnt ipv achterliggende pagina
        const originalOnClose = payload.props.onClose;

        // History kan undefined zijn indien niet binnen react-router context
        const unregister = history?.listen((location, action) => {
            if (action === "POP") {
                history.go(1);

                unregister();
                originalOnClose?.();
            }
        });

        const onClose = () => {
            unregister?.();
            originalOnClose?.();
        };

        dispatch(modal.actions.showModal({
            ...payload,
            props: {
                ...payload.props,
                onClose
            }
        }));
    }, [dispatch, history]);
};

export const useHideModal = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => dispatch(modal.actions.hideModal()), [dispatch]);
};
