import {
    Co2Levering,
    ServiceAdresCo2ArtikelRestModel,
    UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum
} from "../_generated/field-service-be-openapi";
import {sumReducer} from "./reduceUtils";

export interface Co2LeveringGeleverdArtikel {
    geleverdAantal: number;
    geleverdOmruilAantal: number;
}

export interface Co2LeveringGecombineerd {
    id: string;
    gevraagdAantal: number;
    gevraagdExtraAantal: number;
    gevraagdAantalGecombineerd: number;
    gevraagdOmruilAantal: number;
    geleverdAantal: number;
    geleverdOmruilAantal: number;
    aantalGebruiksrechtZonderAkkoord: number;
}

export interface Co2LeveringValidatie {
    meerOmruilDanGevraagd: boolean;
    meerExtraDanGevraagd: boolean;
    legeFlessenZonderOmruil: boolean;
    gebruiksrechtZonderAkkoord: boolean;
    extraGeleverdZonderToestemming: boolean;

    totaalAantalGebruiksrechtZonderAkkoord: number;
}

export class Co2LeveringUtils {

    public static valideer(gevraagdeLeveringen: Co2Levering[],
                           geleverd: Record<string, Co2LeveringGeleverdArtikel>,
                           co2ArtikelenOpAdres: ServiceAdresCo2ArtikelRestModel[],
                           bestellingTerPlaatseToegestaan: UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum): Co2LeveringValidatie {

        const gecombineerd = this.combineer(gevraagdeLeveringen, geleverd, co2ArtikelenOpAdres);

        const meerExtraDanGevraagd = this.heeftMeerExtraDanGevraagdWarning(gecombineerd);
        const extraZonderToestemming = bestellingTerPlaatseToegestaan === UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum.Ja;
        const extraGeleverdZonderToestemming = meerExtraDanGevraagd && !extraZonderToestemming;

        return {
            meerOmruilDanGevraagd: this.heeftMeerOmruilDanGevraagdWarning(gecombineerd),
            meerExtraDanGevraagd,
            legeFlessenZonderOmruil: this.heeftLegeFlessenZonderOmruilWarning(geleverd),
            gebruiksrechtZonderAkkoord: this.heeftGebruiksrechtZonderAkkoordWarning(gecombineerd),
            extraGeleverdZonderToestemming,

            totaalAantalGebruiksrechtZonderAkkoord: this.totaalAantalGebruiksrechtZonderAkkoord(gecombineerd)
        };
    }

    public static combineerGevraagdeLeveringen(gevraagdeLeveringen: Co2Levering[]) {
        return this.combineer(gevraagdeLeveringen, {}, undefined);
    }

    public static combineer(gevraagdeLeveringen: Co2Levering[], geleverd: Record<string, Co2LeveringGeleverdArtikel>, co2ArtikelenOpAdres: ServiceAdresCo2ArtikelRestModel[] | undefined): Record<string, Co2LeveringGecombineerd> {
        const gecombineerd: Record<string, Co2LeveringGecombineerd> = {};

        const gevraagdeLeveringenArtikelIds = gevraagdeLeveringen.map(item => item.artikelId);
        const geleverdArtikelIds = Object.keys(geleverd || []);
        const artikelIds = Array.from(new Set([...gevraagdeLeveringenArtikelIds, ...geleverdArtikelIds]));

        for (const artikelId of artikelIds) {
            const gevraagdeLeveringenVoorArtikel = gevraagdeLeveringen.filter(item => item.artikelId === artikelId);

            gecombineerd[artikelId] = {
                id: artikelId,
                gevraagdAantal: 0,
                gevraagdExtraAantal: 0,
                gevraagdOmruilAantal: 0,

                gevraagdAantalGecombineerd: 0,

                geleverdAantal: 0,
                geleverdOmruilAantal: 0,

                aantalGebruiksrechtZonderAkkoord: 0
            };

            for (const gevraagdeLevering of gevraagdeLeveringenVoorArtikel) {
                if (gecombineerd[artikelId]) {
                    gecombineerd[artikelId].gevraagdAantal += gevraagdeLevering?.gevraagdAantal || 0;
                    gecombineerd[artikelId].gevraagdExtraAantal += gevraagdeLevering?.gevraagdExtraAantal || 0;
                    gecombineerd[artikelId].gevraagdOmruilAantal += gevraagdeLevering?.gevraagdOmruilAantal || 0;

                    gecombineerd[artikelId].gevraagdAantalGecombineerd = gecombineerd[artikelId].gevraagdAantal + gecombineerd[artikelId].gevraagdExtraAantal;
                }
            }

            if (geleverd[artikelId]) {
                gecombineerd[artikelId].geleverdAantal = geleverd?.[artikelId]?.geleverdAantal || 0;
                gecombineerd[artikelId].geleverdOmruilAantal = geleverd?.[artikelId]?.geleverdOmruilAantal || 0;
            }
        }

        for (const artikelId of Object.keys(geleverd)) {
            const co2Artikel = co2ArtikelenOpAdres?.find(item => item.id === artikelId);

            let aantalGebruiksrechtNodigGeleverd = 0;
            if (co2Artikel?.hervulbaar) {
                const aantalMetGebruiksrechtAkkoord = gecombineerd[artikelId]?.gevraagdAantalGecombineerd || 0;
                const aantalGeleverdMetGebruiksrecht = Math.max(gecombineerd[artikelId].geleverdAantal || 0, gecombineerd[artikelId].geleverdOmruilAantal || 0);
                const aantalTeVeelOmruil = Math.max(0, gecombineerd[artikelId].geleverdOmruilAantal - gecombineerd[artikelId].gevraagdOmruilAantal);

                aantalGebruiksrechtNodigGeleverd = Math.max(0, aantalGeleverdMetGebruiksrecht - aantalMetGebruiksrechtAkkoord - aantalTeVeelOmruil);
            }

            gecombineerd[artikelId].aantalGebruiksrechtZonderAkkoord = Math.max(0, aantalGebruiksrechtNodigGeleverd);
        }

        return gecombineerd;
    }

    public static heeftMeerOmruilDanGevraagdWarning(gecombineerdeLeveringen: Record<string, Co2LeveringGecombineerd>) {
        return Object.values(gecombineerdeLeveringen).some(item => (gecombineerdeLeveringen[item.id]?.geleverdOmruilAantal || 0) > (item.gevraagdOmruilAantal || 0));
    }

    public static heeftMeerExtraDanGevraagdWarning(gecombineerdeLeveringen: Record<string, Co2LeveringGecombineerd>) {
        return Object.values(gecombineerdeLeveringen).some(item => (gecombineerdeLeveringen[item.id]?.geleverdAantal || 0) > (item.gevraagdAantalGecombineerd || 0));
    }

    public static heeftLegeFlessenZonderOmruilWarning(geleverd: Record<string, Co2LeveringGeleverdArtikel>) {
        for (const levering of Object.values(geleverd)) {
            if (levering.geleverdAantal < levering.geleverdOmruilAantal) {
                return true;
            }
        }

        return false;
    }

    public static heeftGebruiksrechtZonderAkkoordWarning(gecombineerdeLeveringen: Record<string, Co2LeveringGecombineerd>) {
        return this.totaalAantalGebruiksrechtZonderAkkoord(gecombineerdeLeveringen) > 0;
    }

    public static totaalAantalGebruiksrechtZonderAkkoord(gecombineerdeLeveringen: Record<string, Co2LeveringGecombineerd>) {
        return Object.values(gecombineerdeLeveringen).map(item => item.aantalGebruiksrechtZonderAkkoord).reduce(sumReducer, 0);
    }

}
