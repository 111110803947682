import React from "react";
import {MDBBadge, MDBCol, MDBRow} from "../../mdb-react-ui-kit/index";
import {NavLink} from "react-router-dom";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ToestelLinksRowProps {
    bezoekSessieId: string;
    toestelId: string;
    bijlagenAantal?: number;
}

export const ToestelLinksRow: React.FC<ToestelLinksRowProps> = (props) => {
    const {bezoekSessieId, toestelId, bijlagenAantal} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBRow className="justify-content-around">
            <MDBCol md="4">
                <NavLink to={`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}/servicelog`}
                         className="btn btn-link btn-block btn-lg">{t("algemeen:Titels.servicelog", "Servicelog")}</NavLink>
            </MDBCol>
            <MDBCol md="4">
                <NavLink to={`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}/bijlagen`}
                         className="btn btn-link btn-block btn-lg">{t("algemeen:Titels.bijlagen","Bijlagen")} {!!bijlagenAantal &&
                    <MDBBadge
                        pill>{bijlagenAantal}</MDBBadge>}</NavLink>
            </MDBCol>
            <MDBCol md="4">
                <NavLink to={`/bezoek/taken/${bezoekSessieId}/toestel/${toestelId}/stuklijst`}
                         className="btn btn-link btn-block btn-lg">{t("algemeen:Titels.stuklijst", "Stuklijst")}</NavLink>
            </MDBCol>
        </MDBRow>
    );
};
