import React from "react";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../redux/slices/bezoeksessie/hooks";
import {BezoekSessieStatus} from "../../workers/shared/snapshot/bezoekSessieState";
import {MDBFormikSelect, MDBFormikSelectProps} from "../MDBFormikSelect";

export const BezoekMDBFormikSelect: React.FC<MDBFormikSelectProps> = (props) => {
    const {bezoekSessieId} = useParams<{ bezoekSessieId?: string }>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);

    return (
        <MDBFormikSelect {...props} disabled={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>
    );
};
