export enum WebWorkerMessageType {
    INITIALIZE = "INITIALIZE",
    INITIALIZED = "INITIALIZED",
    START = "START",

    CREATE_CONNECTION = "CREATE_CONNECTION",
    REGISTER_CONNECTION = "REGISTER_CONNECTION",

    SEND_MESSAGE_TO_SERVICEWORKER = "SEND_MESSAGE_TO_SERVICEWORKER",
    SEND_MESSAGE_TO_WEBWORKERS = "SEND_MESSAGE_TO_WEBWORKERS"
}
