import React from "react";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAccountCircle, mdiChevronRight, mdiTruckDeliveryOutline} from "@mdi/js";
import {MDBChip} from "../mdb/MDBChip";
import {MDBCardStatus, MDBCardStatusEnum} from "../mdb/MDBCardStatus";
import {BezoekSessieAccessoireLevering} from "../../workers/shared/snapshot/bezoekSessieState";
import {useTechnici} from "../../redux/slices/technicus/hooks";
import {sumReducer} from "../../utilities/reduceUtils";
import {useTranslation} from "../../utilities/i18nUtils";

export interface LeveringCardProps {
    leveringen: BezoekSessieAccessoireLevering[];
}

export const AccessoireLeveringCard: React.FC<LeveringCardProps> = (props) => {
    const {leveringen} = props;

    const technici = useTechnici();

    const totaalAantal = leveringen?.flatMap(item => item.artikelen).map((item) => item.gevraagdAantal || 0)
        .reduce(sumReducer, 0);

    let status: MDBCardStatusEnum | undefined = undefined;
    if (leveringen?.every(item => item.uitgevoerd)) {
        status = MDBCardStatusEnum.DONE;
    }

    const actieveTechnici = Array.from(new Set(leveringen.map(item => item.laatstActieveTechnicusId))).filter(id => id).map(id => technici[id!]);

    const {t} = useTranslation("bezoeken");

    return (
        <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
            <MDBCardStatus status={status}/>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <span className="d-flex align-items-center">
                            <Icon path={mdiTruckDeliveryOutline} size={1} className="me-1"/>
                            {t("algemeen:Titels.accessoirelevering", "Accessoirelevering")}
                        </span>
                    </MDBCol>

                    <MDBCol size="auto" className="d-flex">
                        {actieveTechnici?.map((item) => (
                            <MDBChip className="my-0 me-0">
                                <Icon path={mdiAccountCircle} size={1}/>

                                {item.naam}
                            </MDBChip>
                        ))}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <MDBRow>
                            <MDBCol sm="4" className="mb-2">
                                    <strong>{t("algemeen:Labels.accessoires", "Accessoires:")}</strong><br/>
                                    {totaalAantal}
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>

                    <MDBCol size="auto"
                            className="d-flex align-items-center justify-content-end">
                        <Icon path={mdiChevronRight} size={2}/>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
