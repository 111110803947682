import React, {ReactNode} from "react";
import {MDBBtn, MDBModalBody, MDBModalHeader} from "mdb-react-ui-kit";
import {FrameModal} from "./FrameModal";
import {useTranslation} from "../../utilities/i18nUtils";
import {ButtonProps} from "../../mdb-react-ui-kit/free/components/Button/types";

export interface ConfirmModalProps {
    title?: ReactNode;
    content?: ReactNode;

    confirmLabel?: ReactNode;
    cancelLabel?: ReactNode;

    confirmColor?: ButtonProps["color"];
    cancelColor?: ButtonProps["color"];

    onConfirm?(): void;

    onCancel?(): void;

    onClose?(): void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {

    const {t} = useTranslation("serviceadres");

    const {
        title,
        content,
        confirmLabel = t("ConfirmModal.oke", "Oké"),
        cancelLabel = t("ConfirmModal.annuleer", "Annuleer"),
        confirmColor = "success",
        cancelColor,
        onConfirm,
        onCancel,
        onClose
    } = props;

    const onOkeClick = () => {
        onConfirm?.();
        onClose?.();
    };

    const onAnnuleerClick = () => {
        onCancel?.();
        onClose?.();
    };

    return (
        <FrameModal onClose={onClose}>
            <MDBModalHeader>{title}</MDBModalHeader>

            <MDBModalBody className="py-1">
                <p className="mb-0">
                    {content}
                </p>

                <div className="d-flex justify-content-end align-items-center my-3">
                    <MDBBtn type="button" color={cancelColor} size="lg" className="ms-2" onClick={onAnnuleerClick}>
                        {cancelLabel}
                    </MDBBtn>
                    <MDBBtn type="button" color={confirmColor} size="lg" className="ms-2" onClick={onOkeClick}>
                        {confirmLabel}
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </FrameModal>
    );
};
