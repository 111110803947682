import React, {useCallback, useEffect} from "react";
import {
    useBrowserNetworkStatus,
    useServiceWorkerNetworkStatus,
    useSetBrowserNetworkStatus,
    useSetServiceWorkerNetworkStatus
} from "../slices/status/hooks";
import {NetworkStatus} from "../slices/status/types";
import {browser2ServiceWorkerChannel} from "../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {Message} from "../../workers/shared/channels/simpleRpcChannel";
import {MessageType} from "../../workers/shared/channels/messageTypes";
import {Logger} from "../../utilities/logger";
import {pingApi} from "../../utilities/api";

const logger = Logger.create("NetworkStatusProvider");

export const NetworkStatusProvider: React.FC = (props) => {
    const browserNetworkStatus = useBrowserNetworkStatus();
    const serviceWorkerNetworkStatus = useServiceWorkerNetworkStatus();

    const setBrowserNetworkStatus = useSetBrowserNetworkStatus();
    const setServiceWorkerNetworkStatus = useSetServiceWorkerNetworkStatus();

    const changeBrowserNetworkStatus = useCallback((networkStatus: NetworkStatus) => {
        if (browserNetworkStatus !== networkStatus) {
            setBrowserNetworkStatus(networkStatus);
        }
    }, [browserNetworkStatus, setBrowserNetworkStatus]);

    const changeServiceWorkerNetworkStatus = useCallback((networkStatus: NetworkStatus) => {
        if (serviceWorkerNetworkStatus !== networkStatus) {
            setServiceWorkerNetworkStatus(networkStatus);
        }
    }, [serviceWorkerNetworkStatus, setServiceWorkerNetworkStatus]);

    const onNetworkBackOnline = useCallback(() => {
        if (browserNetworkStatus === NetworkStatus.OFFLINE) {
            logger.info("Informing the service worker the connection might be back...");

            browser2ServiceWorkerChannel.publish(new Message({type: MessageType.BROWSER_ONLINE}));
        }
    }, [browserNetworkStatus])

    const onNetworkOffline = useCallback(() => {
        if (browserNetworkStatus === NetworkStatus.ONLINE || browserNetworkStatus === NetworkStatus.SLOW) {
            logger.info("Informing the service worker the connection might be down...");

            browser2ServiceWorkerChannel.publish(new Message({type: MessageType.BROWSER_OFFLINE}));
        }
    }, [browserNetworkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | undefined = undefined;

        const checkNetwork = async () => {
            const startTime = Date.now();
            let responseTime: number;

            let result: Response | undefined = undefined;

            try {
                result = (await pingApi.pingRaw()).raw;
            } catch (ignore) {
            } finally {
                responseTime = Date.now() - startTime;

                logger.debug(`Online check in ${responseTime}ms`);

                if (result && result.status === 200) {
                    logger.debug("We're online");

                    onNetworkBackOnline();

                    if (responseTime <= 1_000) {
                        changeBrowserNetworkStatus(NetworkStatus.ONLINE);
                    } else {
                        changeBrowserNetworkStatus(NetworkStatus.SLOW);
                    }
                } else {
                    logger.debug("We're offline");

                    onNetworkOffline();

                    changeBrowserNetworkStatus(NetworkStatus.OFFLINE);
                }
            }

            timeoutId = setTimeout(checkNetwork, 10_000);
        };

        const clearCheckNetworkTimeout = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };

        const checkImmediately = () => {
            clearCheckNetworkTimeout();
            checkNetwork();
        };

        window.addEventListener("online", checkImmediately);
        window.addEventListener("offline", checkImmediately);

        if (!timeoutId) {
            checkNetwork();
        }

        return () => {
            clearCheckNetworkTimeout();

            window.removeEventListener("online", checkImmediately);
            window.removeEventListener("offline", checkImmediately);
        };
    }, [changeBrowserNetworkStatus, onNetworkBackOnline, onNetworkOffline]);

    useEffect(() => {
        browser2ServiceWorkerChannel.subscribe(MessageType.SW_ONLINE, () => {
            changeServiceWorkerNetworkStatus(NetworkStatus.ONLINE);
        });

        browser2ServiceWorkerChannel.subscribe(MessageType.SW_OFFLINE, () => {
            changeServiceWorkerNetworkStatus(NetworkStatus.OFFLINE);
        });
    }, [changeServiceWorkerNetworkStatus]);

    return null;
};
